import { useForm } from "react-hook-form";
import { CustomInput, Form, FormGroup, Label } from 'reactstrap';
import { useDispatch } from "react-redux";
import { ReactstrapInputBlurChangeDetector } from '../../form/input';
import { updateNavigation } from '@wip/common/event-store/website';
import { updateEcommerce } from '@wip/common/event-store/website';

const blogTags = (props) => {
    const dispatch = useDispatch();

    const handleCheckChangeEvent = (event, id, key, value) => {
        if (event) {
            key = event.target.name;
            value = event.target.checked;
        }

        if (!key) throw new Error('Handle change event `key` is missing.')
        if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

        if (key == 'autogenerateShopPage') {
            dispatch(updateEcommerce({ key, value }));
        }
    };

    const bookViewElelement = (
        <Form>
            <FormGroup>
                <CustomInput id="autogenerateShopPage" name="autogenerateShopPage" defaultChecked={props.websiteData.ecommerce.autogenerateShopPage} type="checkbox" label="Auto-Generate Shop Page?" onChange={handleCheckChangeEvent} />
            </FormGroup>
        </Form>
    );

    return bookViewElelement;
}

export default blogTags;
