import url from "url";
import path from "path";

// https://stackoverflow.com/a/31205878/173957
export function appendToFilename(filename, string) {
  var dotIndex = filename.lastIndexOf(".");
  if (dotIndex == -1) return filename + string;
  else return filename.substring(0, dotIndex) + string + filename.substring(dotIndex);
}

// https://stackoverflow.com/questions/27006363/node-js-parse-filename-from-url/27006555
export function getFilenameFromUrl(inputUrl) {
  const parsed = url.parse(inputUrl);
  
  const retVal = path.basename(parsed.pathname);

  return retVal;
}

// https://stackoverflow.com/questions/27006363/node-js-parse-filename-from-url/27006555
export function getExtensionFromFilename(filename) {
  const retVal = filename.split('.').pop();

  return retVal;
}

