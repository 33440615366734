import { fetcher } from '@wip/common/lib/data-utils';
import { encode } from 'firebase-encode';
import humps from 'humps';
import _ from 'lodash';
import getFirebaseClient, { prepareFirebaseObjectV4, prepareFirebaseObjectV3, _decodeFirebaseKey } from '../lib/firebase';
import * as objectUtils from '../lib/object-utils';
import { convertToSlug } from '../lib/text-utils';
import Website from './website';
import { convertListItemToCheckoutItemSiteData_v1_0_0 } from '../app/wiml/versions/v1/v1.0.0/site-data/retrieval';

const TRANSACTIONAL_PATH = 'transactional';
const BACKUP_PATH = 'backup';

// importing WEBSITE_PREFIXES was causing some reference error intermittently so for now i am just duplicating it
const WEBSITE_PREFIXES = {
    LIST_ITEM_PREFIX: 'li',
    WEBSITE_PREFIX: 'site',
}; const newLocal = Object.values(WEBSITE_PREFIXES);
const objectFormattingOptions = { prefixesArray: newLocal };

export function getClient(firebaseClient) {
    async function _getPendingChanges(websiteId) {
        let retVal;

        try {
            const ref = firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website_pending_changes/${websiteId}`);
            const refSnapshot = await ref.once('value');
            const val = refSnapshot.val();

            if (val) {
                // was causing error: VM1552:1 Uncaught TypeError: Cannot use 'in' operator to search for
                // retVal = objectUtils.convertObjectToArray(val).map(humps.camelizeKeys);
                retVal = objectUtils.convertObjectToArray(val).map(r => humps.camelizeKeys(r));
            } else {
                retVal = [];
            }

            return retVal;
        } catch (e) {
            console.error('error', e)
            const rethrow = new Error('error getting website pending changes: ' + e);
            rethrow.stack = e.stack;
            throw rethrow;
        }
    }

    async function _getLookupId(lookupPath, lookupKey, lookupValue) {
        const idLookupRef = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/id_lookup/${lookupPath}:${lookupKey}:${lookupValue}`).once('value');
        const idLookupVal = idLookupRef.val();
        return idLookupVal;
    }

    async function _getWebsiteId(lookupKey, lookupValue) {
        const websiteId = await _getLookupId('website', lookupKey, lookupValue);
        return websiteId;
    }

    async function _getDataByLookup(lookupKey, lookupValue, version) {
        if (!lookupKey) throw new Error('key is missing.')
        if (!lookupValue) throw new Error('websiteValue is missing.')
        const websiteId = await _getWebsiteId(lookupKey, lookupValue);

        const retVal = await _getData(websiteId, version);

        return retVal;
    }

    async function _getData(websiteId, version) {
        if (!websiteId) throw new Error('websiteId is missing.')
        if (!version) throw new Error('Version is missing for site:' + websiteId);

        let retVal;

        try {
            const snapshot = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website/${websiteId}`).once('value');
            let data = snapshot.val();

            if (data) {
                // _deserializeWebsite is no longer asnyc
                // retVal = await _deserializeWebsite(data, version, websiteId);
                retVal = _deserializeWebsite(data, version, websiteId);
            }

            return retVal;
        } catch (e) {
            console.error('error', e)
            const rethrow = new Error('error getting website data: ' + e + '\n\n' + websiteId + ': ' + version);
            rethrow.stack = e.stack;
            throw rethrow;
        }
    }

    async function _getRawData(websiteId, version) {
        if (!websiteId) throw new Error('websiteId is missing.')
        if (!version) throw new Error('Version is missing for site:' + websiteId);

        let retVal;

        try {
            const snapshot = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website/${websiteId}`).once('value');
            let data = snapshot.val();

            if (data) {
                let camelizedData = objectUtils.rehydrateDomainObject(data, objectFormattingOptions);
                retVal = camelizedData;
            }

            return retVal;
        } catch (e) {
            console.error('error', e)
            const rethrow = new Error('error getting website data: ' + e + '\n\n' + websiteId + ': ' + version);
            rethrow.stack = e.stack;
            throw rethrow;
        }
    }

    async function _getWebsiteChildProp(websiteId, childProp) {
        if (!websiteId) throw new Error('websiteId is missing.')
        if (!childProp) throw new Error('childProp is missing for site:' + websiteId);

        let retVal;

        try {
            const snapshot = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website/${websiteId}/${childProp}`).once('value');
            let data = snapshot.val();

            if (data) {
                retVal = humps.camelizeKeys(data);
            }

            return retVal;
        } catch (e) {
            console.error('error', e)
            const rethrow = new Error('error getting website data: ' + e + '\n\n' + websiteId + ': ' + childProp);
            rethrow.stack = e.stack;
            throw rethrow;
        }
    }

    async function _getAllData() {
        let retVal;

        try {
            const snapshot = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website`).once('value');
            let data = snapshot.val();

            if (data) {
                const websites = Object.keys(data)
                    .map(key => data[key])
                    .map(data => _deserializeWebsite(data, 'latest', data.id));

                // _deserializeWebsite is no longer asnyc
                // const deserializeWork = Object.keys(data)
                //     .map(key => data[key])
                //     .map(data => _deserializeWebsite(data, 'latest', data.id));

                // const websites = await Promise.all(deserializeWork);

                retVal = websites; ``
            }

            return retVal;
        } catch (e) {
            console.error('error', e)
            const rethrow = new Error('error getting website data: ' + e);
            rethrow.stack = e.stack;
            throw rethrow;
        }
    }

    async function _getAllIds() {
        let retVal;

        try {
            const snapshot = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/id_lookup`).once('value');
            let data = snapshot.val();

            if (data) {
                const websiteIds = _.uniq(Object.values(data));


                // _deserializeWebsite is no longer asnyc
                // const deserializeWork = Object.keys(data)
                //     .map(key => data[key])
                //     .map(data => _deserializeWebsite(data, 'latest', data.id));

                // const websites = await Promise.all(deserializeWork);

                retVal = websiteIds;
            }

            return retVal;
        } catch (e) {
            console.error('error', e)
            const rethrow = new Error('error getting website data: ' + e);
            rethrow.stack = e.stack;
            throw rethrow;
        }
    }

    async function _getStripeData(stripeData) {
        const websiteId = stripeData.metadata.websiteId;
        const websiteData = await _getData(websiteId, 'latest');

        // todo store metadata in stripedata's metadtata - do not retrieven extra data at runtime.
        // refer to https://app.clickup.com/t/863g9rfyu
        let metadata;
        if (websiteData.meta.appVersion == 'v4') {
            metadata = v4ProductMetadata(websiteData, stripeData);
        } else {
            metadata = v3ProductMetadata(websiteData, stripeData);
        }

        const retVal = { ...stripeData, metadata: { ...stripeData.metadata, ...metadata } };

        return retVal;
    }

    const client = {
        getAllData: async function () {
            const retVal = await _getAllData();

            return retVal;
        },
        getAllIds: async function () {
            const retVal = await _getAllIds();

            return retVal;
        },

        getData: async function (id, version) {
            const retVal = await _getData(id, version);

            return retVal;
        },


        getRawData: async function (id, version) {
            const retVal = await _getRawData(id, version);

            return retVal;
        },

        getDataBySlug: async function (slug, version) {
            const retVal = await _getDataByLookup('slug', slug, version);

            return retVal;
        },

        getDataByCustomDomain: async function (customDomain, version) {
            const formattedKey = encode(customDomain);
            const retVal = await _getDataByLookup('custom_domain', formattedKey, version);

            return retVal;
        },

        getMetadata: async function (id) {
            const retVal = await _getWebsiteChildProp(id, 'meta');

            return retVal;
        },

        getWimlMarkup: async function (id) {
            const retVal = await _getWebsiteChildProp(id, 'style/wiml/theme_data/markup');

            return retVal;
        },

        getPendingChanges: async function (websiteId) {
            const retVal = await _getPendingChanges(websiteId);

            return retVal;
        },

        listenForPendingChanges: function (websiteId, callback) {
            const ref = firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website_pending_changes/${websiteId}`);
            ref.on('value', (snapshot) => {
                let retVal;
                const val = snapshot.val();
                if (val) {
                    // was causing error: VM1552:1 Uncaught TypeError: Cannot use 'in' operator to search for
                    // retVal = objectUtils.convertObjectToArray(val).map(humps.camelizeKeys);
                    retVal = objectUtils.convertObjectToArray(val).map(r => humps.camelizeKeys(r));
                } else {
                    retVal = [];
                }
                callback(retVal);
            });
        },
        savePendingChange: async function (websiteData, action, user) {
            if (!websiteData) throw new Error(`websiteData must exist to record changes.`);
            if (!action) throw new Error(`action must exist to record changes.`);
            if (!user) throw new Error(`user must exist to record changes.`);

            let retVal;
            let firebaseData;
            const websiteId = websiteData.id;
            const userData = { displayName: user.displayName, email: user.email, uid: user.uid };
            const pendingChangePayload = {
                action,
                user: userData,
            };
            try {
                // todo wrap in transaction
                const ref = firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website_pending_changes/${websiteId}`);
                // keep in mind, humps will lowercase all keys
                if (websiteData.meta.appVersion == 'v4') {
                    firebaseData = prepareFirebaseObjectV4(pendingChangePayload, objectFormattingOptions);
                } else {
                    firebaseData = prepareFirebaseObjectV3(pendingChangePayload, objectFormattingOptions);
                }

                const newPendingChange = await ref.push(firebaseData);
                retVal = { id: newPendingChange.key, data: pendingChangePayload };

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error saving website pending changes: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        deletePendingChanges: async function (websiteId) {
            let retVal;
            try {

                const ref = firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website_pending_changes/${websiteId}`);
                retVal = await ref.remove();

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error deleting website pending changes: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        getHistory: async function (websiteId) {
            let retVal;

            try {

                const ref = firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website_snapshot/${websiteId}`);
                const refSnapshot = await ref.once('value');
                const val = refSnapshot.val();

                if (val) {
                    retVal = objectUtils.convertObjectToArray(val);
                } else {
                    retVal = [];
                }

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error getting website history: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        saveWebsiteData: async function (comment, websiteData) {
            let retVal;
            let snapshotData;
            const websiteId = websiteData.id;

            try {
                // preparing ensures the UI passed in serializable data
                // it ensures keys are accepted by firebase
                const firebaseWebsiteData = _prepareFirebaseData(websiteData);
                if (websiteData.meta.appVersion != 'v4') {
                    const customHtmlPages = firebaseWebsiteData.custom_html.pages;
                    if (customHtmlPages) {
                        firebaseWebsiteData.custom_html.pages = objectUtils.camelizeFirstLayer(customHtmlPages);
                    }
                }

                let status = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website/${websiteId}`).set(firebaseWebsiteData);

                snapshotData = { comment, payload: firebaseWebsiteData, dateCreated: new Date().toISOString() };
                status = await firebaseClient.database().ref(BACKUP_PATH).child(`/website_snapshot/${websiteId}`).push(snapshotData);

                retVal = status.key;

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error getting saving website changes: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        createWebsite: async function ({ websiteType, slug, source, firstName, lastName, email, bookCategory, inviteCode, wimlThemeId, wimlMarkup }) {
            if (!websiteType) throw new Error(`websiteType required.`);
            if (!slug) throw new Error(`slug required.`);
            if (!firstName) throw new Error(`firstName required.`);
            if (!lastName) throw new Error(`lastName required.`);
            if (!email) throw new Error(`email required.`);
            if (!wimlThemeId) throw new Error(`wimlThemeId required.`);
            if (!wimlMarkup) throw new Error(`wimlMarkup required.`);

            try {
                // const websiteRef = firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`website/`);
                // const newSiteRef = await websiteRef.push(decamelizedData);
                // const websiteId = newSiteRef.key;

                const websiteId = objectUtils.generateId(WEBSITE_PREFIXES.WEBSITE_PREFIX);

                // this is old
                // const websiteId = getFirebaseKey();

                const website = Website.createNewWebsite(websiteId, { websiteType, slug, source, firstName, lastName, email, bookCategory, inviteCode, wimlThemeId, wimlMarkup });

                const saveComment = `Starting new website for ${firstName} ${lastName}.`;
                await this.saveWebsiteData(saveComment, website);

                const idLookupSlugRef = firebaseClient.database().ref(TRANSACTIONAL_PATH).child('id_lookup').child(`website:slug:${slug}`);
                await idLookupSlugRef.set(websiteId);

                return websiteId;
            } catch (e) {
                const rethrow = new Error('error creating website: ' + e);
                rethrow.stack = e.stack;

                throw rethrow;
            }
        },

        upgradePlan: async function (slug) {
            let retVal;

            try {
                const websiteId = await _getWebsiteId('slug', slug);

                let status = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website/${websiteId}/meta/purchased`).set(true);

                retVal = status;

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error upgrading website: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        getEcommerceInfo: async function (websiteId) {
            let retVal;

            try {
                const snapshot = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/ecommerce/${websiteId}/`).once('value');

                retVal = snapshot.val();

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error retrieving ecommerce info for website: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        beginEcommerceAccountOnboarding: async function (slug) {
            let retVal;

            try {
                const websiteId = await _getWebsiteId('slug', slug);

                let status = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website/${websiteId}/ecommerce/`).set({ onboard_in_progress: true, account_active: false });

                retVal = status;

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error saving stripe acct id info for website: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        getWebsiteId: async function (slug) {
            try {
                const websiteId = await _getWebsiteId('slug', slug);

                return websiteId;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error getting website id: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },


        getUniqueSlug: async function (firstName, lastName) {
            if (!firstName) throw new Error('first name required.');
            if (!lastName) throw new Error('last name required.');

            let slug = convertToSlug(firstName);

            try {
                const firstNameSlugId = await _getWebsiteId('slug', slug);

                if (firstNameSlugId) {
                    const lastInitial = lastName[0];
                    slug = convertToSlug(firstName + lastInitial);

                    const firstNameLastInitialSlugId = await _getWebsiteId('slug', slug);

                    if (firstNameLastInitialSlugId) {
                        slug = convertToSlug(firstName + lastName);

                        const firstNameLastNameSlugId = await _getWebsiteId('slug', slug);
                        if (firstNameLastNameSlugId) {
                            throw new Error(`could not find unique slug for ${firstName} ${lastName}.`);
                        }
                    }
                }

            } catch (e) {
                const rethrow = new Error('error creating slug: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }

            return slug;
        },

        completeEcommerceAccountOnboarding: async function (slug) {
            let retVal;

            try {
                const websiteId = await _getWebsiteId('slug', slug);

                let status = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/website/${websiteId}/ecommerce/`).update({ onboard_in_progress: false, account_active: true });

                retVal = status;

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error saving stripe acct id info for website: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        getSlugFromStripeConnectedAccountId: async function (stripeAccountId) {
            let retVal;

            try {
                const websiteId = await _getLookupId('stripe', 'account', stripeAccountId);
                const websiteData = await _getData(websiteId, 'latest');
                const slug = websiteData.meta.slug;

                retVal = slug;

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error getting slug from stripe account: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        saveStripeAccountId: async function (slug, stripeAccountId) {
            let retVal;

            try {
                const websiteId = await _getWebsiteId('slug', slug);

                let status = firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`id_lookup/stripe:account:${stripeAccountId}`).set(websiteId);
                status = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/ecommerce/${websiteId}/stripe/acct_id`).set(stripeAccountId);

                retVal = status;

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error saving stripe acct id info for website: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        saveStripeOrder: async function (orderData) {
            let retVal;

            try {
                const orderId = orderData.id;

                // metatadata is created in /charge when session is created
                // const websiteId = await _getLookupId('stripe', 'account', stripeAccountId);
                // const orderDate = new Date();
                // const firebaseData = {
                //     ...orderData, 
                //     metadata: {
                //         account_id:stripeAccountId, 
                //         website_id: websiteId,
                //         date: orderDate
                //     }
                // };

                const status = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/order/stripe/${orderId}/`).set(orderData);

                retVal = status;

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error saving stripe order info for website: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        getStripeOrder: async function (stripeOrderId) {
            try {
                const orderSnapshot = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/order/stripe/${stripeOrderId}/`).once('value');
                const stripeData = humps.camelizeKeys(orderSnapshot.val());

                if (!stripeData) throw new Error(`Order does not exist: ${stripeOrderId}.`);

                const retVal = await _getStripeData(stripeData);

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error getting stripe order info for website: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        fulfillStripeOrder: async function (fulfillmentApiUrl, orderData) {
            if (!fulfillmentApiUrl) throw new Error('backupApiUrl is missing');
            if (!orderData) throw new Error('orderData is missing');

            const POSTWebhookData = orderData;

            const POSTOptions = {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(POSTWebhookData)
            };

            let status;
            try {
                // todo this data should prabably be decamelized -- but then you have to update zapier fullfil zap
                const res = await fetcher(fulfillmentApiUrl, POSTOptions, { getJson: false });
                status = res.status;
            } catch (e) {
                console.log("Error saving order data:", e);
                const rethrow = new Error('Error saving order data: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }

            return status;
        },

        saveWebsiteSnapshotBackup: async function (backupApiUrl, websiteSlug, websiteData, snapshotComment) {
            if (!backupApiUrl) throw new Error('backupApiUrl is missing');
            const snapshotData = {
                websiteSlug,
                // Zapier is too helpful and parses nested json - but we just want a strip repr.
                snapshotJson: JSON.stringify(JSON.stringify(websiteData)),
                snapshotComment: snapshotComment,
            };

            const newData = humps.decamelizeKeys(snapshotData);

            //TODO THIs is duplicated in /website/common/contact
            const POSTWebhookData = Object.entries(newData).map(([key, value]) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(value);
            }).join('&');

            // this content type prevents preflight problem to zapier:
            // https://zapier.com/help/doc/common-problems-with-webhooks#posting-json-from-web-browser-access-control-allow-headers-in-preflight-response-error
            // why? https://stackoverflow.com/questions/39725955/why-is-there-no-preflight-in-cors-for-post-requests-with-standard-content-type
            // setting the body: https://github.com/github/fetch/issues/263#issuecomment-209548790
            const POSTOptions = {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8;" },
                body: POSTWebhookData
            };

            let status;
            try {
                const res = await fetcher(backupApiUrl, POSTOptions, { getJson: false });
                status = res.status;
            } catch (e) {
                console.log("Error saving snapshot backup:", e);
                const rethrow = new Error('Error saving snapshot backup: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }

            return status;
        },

        updloadFile: function (websiteId, fileName, file) {
            const uploadTask = firebaseClient.storage().ref(`/user-content/${websiteId}/${fileName}`).put(file);

            /* USAGE
            const uploadPromise = new Promise((resolve, reject) => {
                uploadTask.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    saveDataProgress(progress);
                }, error => {
                    reject(error);
                }, async () => {
                    const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                    resolve(downloadURL);
                });
            });
            */

            return uploadTask;
        }
    };

    return client;
}

function _prepareFirebaseData(websiteData) {
    let firebaseWebsiteData;
    if (websiteData.meta.appVersion == 'v4') {
        firebaseWebsiteData = prepareFirebaseObjectV4(websiteData);
    } else {
        firebaseWebsiteData = prepareFirebaseObjectV3(websiteData);
    }
    return firebaseWebsiteData;
}

export function v4ProductMetadata(websiteData, stripeData) {
    const listId = stripeData.metadata.listId;
    const listItemId = stripeData.metadata.listItemId;

    const listItemCheckoutData = convertListItemToCheckoutItemSiteData_v1_0_0(websiteData, listId, listItemId);

    // this is the format needed for fullfilling api
    const productData = websiteData.style.wiml.themeData.meta.version == '1.3.0'
        ? {
            confirmation: { emailContent: listItemCheckoutData.data.text__confirmation_email.content },
            about: { title: listItemCheckoutData.data.heading__title.content },
        }
        : {
            confirmation: { emailContent: listItemCheckoutData.data.textConfirmationEmail.content },
            about: { title: listItemCheckoutData.data.heading.content },
        };
    const aboutData = websiteData.about;
    const contactData = websiteData.contact;

    const metadata = {
        productData,
        aboutData,
        contactData,
    };
    return metadata;
}

function v3ProductMetadata(websiteData, stripeData) {
    const productId = stripeData.metadata.productId;

    const productData = websiteData.products.find(p => p.id == productId);
    const aboutData = websiteData.about;
    const contactData = websiteData.contact;

    const metadata = {
        productData,
        aboutData,
        contactData,
    };
    return metadata;
}

// import * as bubbleApi from './bubble-api';

// export async function getWebsiteDataBySlug(websiteSlug, version) {
//     const retVal = await bubbleApi.getWebsiteDataBySlug(websiteSlug, version);

//     return retVal;
// }

// export async function getWebsiteDataByCustomDomain(customDomain, version) {
//     const retVal = await bubbleApi.getWebsiteDataByCustomDomain(customDomain, version);

//     return retVal;
// }

// export async function saveWebsiteSnapshot(websiteSlug, snapshotComment) {
//     let retVal;

//     const [status, snapshotData] = await bubbleApi.saveWebsiteSnapshot(websiteSlug, snapshotComment);
//     retVal = status;
//     const { snapshotJson } = snapshotData;

//     const currentEnv = process.env.NODE_ENV;
//     if (currentEnv == 'production') {
//         const backupStatus = await bubbleApi.saveWebsiteSnapshotBackup(websiteSlug, snapshotJson, snapshotComment);
//         retVal = backupStatus;
//     }

//     return [retVal, snapshotData];
// }

// export async function revertWebsiteToSnapshot(websiteSlug, version) {
//     const retVal = await bubbleApi.revertWebsiteToSnapshot(websiteSlug, version);

//     return retVal;
// }



function _deserializeWebsite(data, version, websiteId) {
    let retVal;
    // data is not yet formatted, use underscores
    if (data.meta.app_version == 'v4') {
        retVal = _deserializeWebsiteV4(data, version, websiteId);
    } else {
        retVal = _deserializeWebsiteV3(data, version, websiteId);
    }

    if (process.env.NODE_ENV == 'development') {
        // get nested keys of entire object graph 
        const inputDataKeys = objectDeepKeys(data);
        const inputKeyLength = inputDataKeys.length;

        const outputDataKeys = objectDeepKeys(retVal)
        const outputKeyLength = outputDataKeys.length;

        if (inputKeyLength > outputKeyLength) {
            throw new Error(`Deserialization error. Input keys: ${inputKeyLength} > Output keys: ${outputKeyLength}.
Are you sure transform-class-properties is installed and working?`);
            console.error(`Deserialization error: ${websiteId} - ${inputKeyLength} > ${outputKeyLength}`);
        }
    }

    return retVal;
}
// https://stackoverflow.com/questions/42674473/get-all-keys-of-a-deep-object-in-javascript
function objectDeepKeys(obj) {
    return Object.keys(obj).filter(key => obj[key] instanceof Object).map(key => objectDeepKeys(obj[key]).map(k => `${key}.${k}`)).reduce((x, y) => x.concat(y), Object.keys(obj))
}
export function _deserializeWebsiteV4(data, version, websiteId) {
    if (!data) throw new Error('data required.');

    let retVal;

    const wimlVer = data.style?.wiml?.theme_data?.meta?.version || "1.0.0";
    // encode expects all keys. E.g. "." becomes "%2E" -- listBookSeries.listBooks
    if (wimlVer == "1.0.0" || wimlVer == "1.1.0") {
        const decodedKeys = objectUtils.replaceKeys(data, _decodeFirebaseKey);
        let camelizedData = objectUtils.rehydrateDomainObject(decodedKeys, objectFormattingOptions);
        retVal = new Website(websiteId, camelizedData);
    }
    else if (wimlVer == "1.2.0" || wimlVer == "1.3.0") {
        const decodedKeys = objectUtils.replaceKeys(data, _decodeFirebaseKey);
        let camelizedData = objectUtils.rehydrateDomainObject2(decodedKeys, objectFormattingOptions);
        retVal = new Website(websiteId, camelizedData);
    } else {
        throw new Error(`Unsupported WIML version: ${wimlVer}`);
    }

    return retVal;
}

// todo - i believe this is causing a lot of bugs. e.g. book labels, id's with '_', etc.
// this is why it sometimes works before saving. because only when it's persisted to fb do we run the caemlizeKeys func.
// what did nav group point to page id of "-_7ddyxdQXD6zByqbS6yK" but in firebase the key is 
export function _deserializeWebsiteV3(data, version, websiteId) {
    if (!data) throw new Error('data required.');

    let retVal;

    let camelizedData = objectUtils.rehydrateDomainObject(data);

    setV3Data(camelizedData);

    retVal = new Website(websiteId, camelizedData);
    return retVal;
}

function setV3Data(camelizedData) {
    const setChildren = (childKey, data = camelizedData) => {
        const childData = data[childKey];
        if (childData) {
            data[childKey] = objectUtils.convertObjectToArray(childData);
        } else {
            data[childKey] = [];
        }
    };

    const setNestedChildren = (childKey, nestedChildKey, data = camelizedData) => {
        const childData = data[childKey];
        if (childData) {
            childData.forEach(child => setChildren(nestedChildKey, child));
        }
    };
    // TODO - FIX THIS - IT'S HARD TO MAINTAIN. MAKE IT AUTOMATIC WITH FIREBASE PREPARE FUNC
    // -- UPDATE --                 
    // TODO A BETTER WAY TO FIX IS TO NOT USE ARRAYS BETWEEN FIREBASE AND REDUX. JUST STICK TO OBJECTS
    // ARRAYS CAN BE REPRESENTED AS READ-ONLY PROPS.
    // --
    // I THINK ANOTHER IDEA: FB AND REDUX STATE SHOULD HAVE A OBJ REPR. I'VE READ THAT THIS IS BETTER AND 
    // THERE IS A NPM LIB FOR MAKING RELATIONSHIPS IN A JSON TREE
    // THE WEBSITE SERVICE AND REACT LAYER IS FINE TO USE POJOS
    // -- EVENTUALLY, EITHER THE WEBSITE POJO NEEDS TO KNOW OR SOME OTHER LAYER (PERHPAS A FACTORY)
    // NEEDS TO KNOW HOW TO CONSTURCT A WEBISTE FROM FIREBASE DATA.
    setChildren('galleries', camelizedData.about);
    setChildren('books');
    setChildren('bookSeries');
    setNestedChildren('books', 'products');
    setChildren('posts');
    // todo - this is not working - post tags are now just a string
    // setNestedChildren('posts', 'tags');
    setChildren('events');
    setChildren('customPages');
    setNestedChildren('customPages', 'products');
    setNestedChildren('customPages', 'galleries');
    setChildren('landingPages');
    setNestedChildren('landingPages', 'products');
    setNestedChildren('landingPages', 'galleries');
    setChildren('slides');
    setChildren('slides2');
    setChildren('products');
    setChildren('galleries');
    setNestedChildren('galleries', 'items');
    setChildren('navigationGroups');
    setNestedChildren('navigationGroups', 'items');
}

function getBook(key) {

    const book = {
        id: `book-${key}`,
        "about": {
            "description": "<p>In a world on the verge of repeating a cataclysmic history, Sophia, a Salinian entertainer, unknowingly holds the key to the world's salvation. She intentionally overlooks their king's tyranny to ensure her happiness, but not even the gods can protect her when the eyes of the castle alight upon her town. After narrowly escaping death with the aid of a mysterious man who whisks her away to once-forbidden lands, Sophia is unwillingly sucked into the brewing war she had so desperately evaded. <br /><br />In <em>Island Eight</em> by M.Z. Medenciy, Sophia embarks on an unprecedented journey where she encounters new dangers through magic-wielding Fae, discovers a resilience within her she never thought possible, and uncovers a history of the gods and their planet that will change the lives of all forever.</p>\n<p><img src=\"https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2FyzCi9S1G3GFJCyYJya4iV%2FUntitled%20design%20-%202022-08-21T000036.911_1661058687208.png?alt=media&amp;token=f119ad9b-2627-4017-a5db-0763186b3d2c\" alt=\"\" width=\"700\" height=\"171\" /></p>",
            "more_info": "",
            "subheading": "",
            "title": "Island Eight"
        },
        "assets": {
            "cover_photo": "https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2FyzCi9S1G3GFJCyYJya4iV%2FUntitled%20design%20-%202022-08-21T092016.942_1661093064624.png?alt=media&token=ca940042-3190-4339-a840-53009d6a2709"
        },
        "purchase": {
            "amazon_link": "",
            "barnesandnoble_link": "https://www.barnesandnoble.com/w/island-eight-m-z-medenciy/1141993927",
            "custombookstore1_link": "https://www.banksquarebooks.com/book/9781639885343",
            "custombookstore1_name": "Savoy Bookstore - Westerly, RI",
            "custombookstore2_link": "https://amzn.to/3crfcFy",
            "custombookstore2_name": "Amazon - Affiliate Link",
            "indiebound_link": "https://www.indiebound.org/book/9781639885343"
        },
        "reviews": {
            "content": "<p><img src=\"https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2FyzCi9S1G3GFJCyYJya4iV%2Fkindpng_7105732_1665955313103.png?alt=media&amp;token=a371d665-e3ed-4ceb-a171-72645a9250f2\" alt=\"\" width=\"212\" height=\"36\" /></p>\n<p>- <a href=\"https://readersfavorite.com/book-review/island-eight\">Readers' Favorite Review</a></p>"
        },
        "schedule": {
            "publish_date": "2022-10-20T16:00:00.000Z"
        }
    };

    return book;
}
