import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { ReactstrapInputBlurChangeDetector } from '../../form/input';
import { updateCustomHtmlPage } from '@wip/common/event-store/website';
import { getAllCustomHtmlPagesForUser } from "@wip/common/app/page";
import { useState } from "react";

const Home = (props) => {
    const { register, getValues, handleSubmit, setValue, watch, formState, formState: { isValid, isDirty, errors, touchedFields, dirtyFields } } = useForm({
        mode: 'onBlur',
        defaultValues: props.websiteData,
    });

    const [state, setState] = useState({
        customHtmlPage: null,
    });

    const handleStateChange = (name, value) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    // how to watch data
    // 1) https://github.com/react-hook-form/documentation/issues/74
    // 2) https://github.com/react-hook-form/react-hook-form/discussions/2494
    // 3) https://stackoverflow.com/questions/38022618/how-do-i-auto-save-with-multiple-form-fields-in-react
    // 4) https://github.com/react-hook-form/react-hook-form/discussions/3620
    // 5) https://github.com/react-hook-form/react-hook-form/issues/283
    // 6) https://github.com/react-hook-form/react-hook-form/issues/3698#issuecomment-752261747
    // 7) https://github.com/react-hook-form/react-hook-form/issues/1905
    // https://react-hook-form.com/api/useform/watch
    // https://github.com/tiaanduplessis/react-hook-form-persist/blob/master/src/index.js
    watch((_, changedData) => {
        // debounce https://www.synthace.com/autosave-with-react-hooks/
        console.log(changedData.name, changedData.type, ':', changedData.value);
    });

    // There is a bug where the first page (Home) will not show pending changes (e.g. customHtml) until you go to 1 tab then back.
    // kind of annoying - https://github.com/react-hook-form/react-hook-form/issues/4414
    const { ref: slugRef, ...slugRest } = register('meta.slug', { required: true });
    const { ref: customDomainRef, ...customDomainRest } = register('meta.customDomain');
    const { ref: siteIdRef, ...siteIdRest } = register('id');
    // customHtmlRef is treated different due to its dynamism. see https://stackoverflow.com/questions/62242657/how-to-change-react-hook-form-defaultvalue-with-useeffect
    const { ref: customHtmlRef, ...customHtmlRest } = register('customHtmlPage');

    // // https://github.com/react-hook-form/react-hook-form/discussions/2494
    // const formChanged = () => {
    //     const { isValid } = formState;
    //     const formValues = getValues();
    //     console.log("isValid = ", isValid);
    //     console.log("formValues = ", formValues);
    //     if (isValid) {
    //         console.log("sending values to server");
    //     }
    // };

    // const historyList = props.websiteHistory.map(h => (
    //     <option key={h.id} value={h.id}>{h.comment}</option>
    // ));
    // const user = props.userData;
    // const isAdmin = user.roles.includes('admin');

    const customHtmlPages = getAllCustomHtmlPagesForUser(props.websiteData);
    const customHtmlPagesOptions = customHtmlPages.map(p => (
        <option key={p.key} value={p.key}>{p.value}</option>
    ));

    const dispatch = useDispatch();

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                key = event.target.name;
                value = event.target.value;
            }

            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateAbout({ key: newKey, value }));
            } else if (path == 'customHtmlPage') {
                const key = state.customHtmlPage;
                dispatch(updateCustomHtmlPage({ key, value }));
            } else if (path == 'meta') {
                dispatch(updateMeta({ key: newKey, value }));
            }
        }
    };

    const selectPageCustomHtml = evt => {
        const page = evt.target.value;
        handleStateChange('customHtmlPage', page);

        const customHtmlPageValue = props.websiteData.customHtml.pages[page];
        // https://stackoverflow.com/questions/62242657/how-to-change-react-hook-form-defaultvalue-with-useeffect
        setValue('customHtmlPage', customHtmlPageValue);
    };

    return (
        <Form>
            <FormGroup>
                <Label for="meta.slug">Website URL</Label>
                <Input disabled type="text" id="meta.slug" {...slugRest} innerRef={slugRef} />
                {errors.slug && <span>This field is required</span>}
            </FormGroup>
            <FormGroup>
                <Label for="id">Website Id</Label>
                <Input disabled type="text" id="id" {...siteIdRest} innerRef={siteIdRef} />
            </FormGroup>
            <FormGroup>
                <Label for="meta.customDomain">Custom Domain</Label>
                <Input disabled type="text" id="meta.customDomain" {...customDomainRest} innerRef={customDomainRef} />
            </FormGroup>
            <FormGroup>
                <Label for="customHtml">Custom HTML</Label>
                <Input type="select" name="customHtml" id="customHtml" size={5} onClick={selectPageCustomHtml}>
                    {customHtmlPagesOptions}
                </Input>
                {state.customHtmlPage}
                <ReactstrapInputBlurChangeDetector id="customHtmlPage" type="textarea" {...customHtmlRest} innerRef={customHtmlRef} onDiff={handleChangeEvent} />
            </FormGroup>
            {/* temporarily disabling history visibility. */}
            {/* <FormGroup>
                <Label for="history">Website History</Label>
                <Input type="select" name="history" id="history" size={5}>
                    {historyList}
                </Input>
            </FormGroup> */}
            {/* 
            <FormGroup>
                <Label for="customHtml">Custom HTML</Label>
                <ReactstrapInputBlurChangeDetector type="textarea" id="customHtml" {...customHtmlRest} innerRef={customHtmlRef} onDiff={handleChangeEvent} />
            </FormGroup>

            <FormGroup check>
                <Label check>
                    <Input disabled type="checkbox" checked={props.websiteData.meta.purchased} name="meta.purchased" id="meta.purchased" />{' '}
                    Purchased?
              </Label>
            </FormGroup> */}
        </Form>
    );
}

export default Home;
