import React, { useRef } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
// TODO -- convert to jsx
// https://stackoverflow.com/questions/57761818/create-react-app-transpile-jsx-of-external-package-in-node-modules
// https://stackoverflow.com/questions/58323916/how-to-config-webpack-to-transpile-files-from-other-lerna-packages-ejected-from/58603207#58603207
// was getting error SyntaxError: /Users/scottc/Documents/GitHub/author-site-builder/apps/common/components/blur-change-detector.js: Support for the experimental syntax 'jsx' isn't currently enabled (24:13):

// todo why cannot use change event in html? https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/change_event
const defaultValueRetriever = (e) => defaultElementRetriever(e).target.value;
const defaultElementRetriever = (e) => e[0];

function withBlurChangeDetection(WrappedInput, inputValRetriever = defaultValueRetriever, inputElementRetriever = defaultElementRetriever) {
    return function BlurChangeDetector(props) {
        // useing setState causes re-render but refs don't.
        // state is more appropirate for determining the appearance of the screen. ref is good for this internal vlidations stuff.
        const prevVal = useRef();

        async function inputFocus(...e) {
            const elem = inputElementRetriever(e);

            props.onFocus && await props.onFocus(elem);

            prevVal.current = inputValRetriever(e);
        };

        const inputBlur = async (...e) => {
            const elem = inputElementRetriever(e);
            props.onBlur && await props.onBlur(elem);

            const newVal = inputValRetriever(e);
            if (newVal != prevVal.current) {
                props.onDiff && props.onDiff(elem);
            }
        };

        // react will otherwise complain about unknown event handlers (e.g. onDiff)
        const { onDiff, ...newProps } = props;

        // TODO - enable jsx compilation in common project
        // return (
        //     <WrappedInput {...newProps} onFocus={inputFocus} onBlur={inputBlur} />
        // )

        return React.createElement(
            WrappedInput,
            {
                ...newProps,
                onFocus: inputFocus,
                onBlur: inputBlur,
            }
        );
    }
}

export default withBlurChangeDetection;
