import humps from 'humps';
import React from 'react';
import _ from 'lodash';

import { renderChildren_v1_0_0 } from '../render-components';
import { cloneReactChildren_v1_0_0 } from '../render-components';
import {
    getInferredComponentDefaultType_v1_0_0
} from '../../compiler/components';
import { getListItemsSiteDataArray_v1_0_0 } from '../../../site-data/retrieval';
import { getComponentClassName_v1_0_0 } from '../render-components';
import { getChildComponentPropertyValueSiteData_v1_0_0 } from '../../../site-data/retrieval';
import { dateUtils } from '../../../../../../../..';
import { slugify } from '../../../../../../../../lib/text-utils';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { Slider_v1_0_0 } from './list-slider';
import { sortListItems } from '../../../../../../../../domain/list-sort';

const DEFAULT_LIST_KEY = 'default';

export function List_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    let retVal: React.ReactNode = null;
    // lists are always page-level but it's possible they'll be nested and get a dataRetrievalListId but we need to remove that
    const { dataRetrievalListComponenyKey: parentListComponentKey, dataRetrievalListId: parentListId, dataRetrievalListItemId: parentListItemId, ...listCleansedProps } = props;
    if (parentListComponentKey) {
        listCleansedProps.componentKey = parentListComponentKey + "." + listCleansedProps.componentKey
    }

    const potentiallyNestedListId = humps.camelize(listCleansedProps.listId || listCleansedProps.componentKey || DEFAULT_LIST_KEY);
    const listId = potentiallyNestedListId.split('.').pop() || potentiallyNestedListId;

    const filter = getChildComponentPropertyValueSiteData_v1_0_0(listCleansedProps, 'filter', getInferredComponentDefaultType_v1_0_0('List'));
    const sort = getChildComponentPropertyValueSiteData_v1_0_0(listCleansedProps, 'sort', getInferredComponentDefaultType_v1_0_0('List'));
    const limit = getChildComponentPropertyValueSiteData_v1_0_0(listCleansedProps, 'limit', getInferredComponentDefaultType_v1_0_0('List'));

    const itemsArray = getListItemsSiteDataArray_v1_0_0(listCleansedProps, listId);

    let filteredArray = itemsArray;

    if (filter?.toString()?.startsWith(`{{slugify(current_item.components.items.heading.content.data.value) == current_page.request.query.`) && filter?.toString()?.endsWith(`}}`)) {
        const queryParam = filter.toString().replace('{{slugify(current_item.components.items.heading.content.data.value) == current_page.request.query.', '').replace('}}', '').trim();
        const bookHeadingFilter = listCleansedProps.currentPage.request.query[queryParam];
        filteredArray = itemsArray.filter((item) => {
            let retVal = false;
            const headingValue = item.components?.items?.heading?.content?.data?.value;
            if (headingValue) {
                const heading = slugify(headingValue);
                retVal = heading == bookHeadingFilter;
            }
            return retVal;
        });
    } else if (filter == "{{isPast(current_item.components.items.date.date.data.value)}}") {
        filteredArray = itemsArray
            .filter((item) => item.components?.items?.date?.date?.data?.value)
            .filter((item) => {
                // todo cast types ahead of time, not here
                const isPast = dateUtils.isPast(new Date(item.components.items.date.date.data.value as string));
                return isPast;
            });
    } else if (filter == "{{isFuture(current_item.components.items.date.date.data.value)}}") {
        filteredArray = itemsArray
            .filter((item) => item.components?.items?.date?.date?.data?.value)
            .filter((item) => {
                // todo cast types ahead of time, not here
                const isFuture = dateUtils.isFuture(new Date(item.components.items.date.date.data.value as string));
                return isFuture;
            });
    } else if (filter?.toString()?.startsWith(`{{current_item.id == "li_`) && filter?.toString()?.endsWith(`"}}`)) {
        // regex /"li_([^"]+)"/
        const id = filter.toString().replace('{{current_item.id == "', '').replace('"}}', '').trim();
        filteredArray = itemsArray
            .filter((item) => item.id == id);
    } else if (filter == "{{current_item.relationships.lists.items[render_props.parent_list_id].ids.includes(render_props.parent_list_item_id)}}") {
        filteredArray = itemsArray.filter((item) => {
            const isRelated = item?.relationships?.lists?.items?.[parentListId]?.ids?.includes(parentListItemId)
            return isRelated;
        });
    } else if (filter == "{{current_item.relationships.lists.ids.length == 0}}") {
        filteredArray = itemsArray.filter((item) => {
            // loop through relationships and if there are none, return true
            const hasAnyRelationships = (Object.keys(item.relationships?.lists?.items || {}).some((key) => {
                const relationship = item.relationships.lists.items[key];
                return relationship.ids.length > 0;
            }));
            return hasAnyRelationships == false;
        });
    } else if (filter == "{{current_item.id == current_page.request.query.product}}") {
        const id = listCleansedProps.currentPage.request.query['thank-you']; // todo this should probably not be thank-you but product instead
        // eventually we should make orders -> line items -> products via list relationships. until then, treat like navigation - its own thing.
        filteredArray = itemsArray
            .filter((item) => item.id == id);
    }

    const sortedArray = sortListItems(sort as string, filteredArray);

    let limitedArray = sortedArray;
    if (limit) {
        limitedArray = _limitListItemsArray(sortedArray, limit as number);
    }

    const finalArray = limitedArray;

    const className = getComponentClassName_v1_0_0(listCleansedProps, 'list-item');
    const topLevelListComponentKey = humps.decamelize(listCleansedProps.componentKey, { separator: '-' });
    const topLevelProps = { className, topLevelListComponentKey };

    const itemComponents = finalArray.map((item) => {
        const children = listCleansedProps.children;
        // todo why is dataRetrievalListId appearing in the dom?
        const propsForAllChildren = { dataRetrievalListComponenyKey: listCleansedProps.componentKey, dataRetrievalListId: listId, dataRetrievalListItemId: item.id };
        const cloned = cloneReactChildren_v1_0_0(children, topLevelProps, propsForAllChildren);
        const renderedChildren = renderChildren_v1_0_0(cloned);

        const itemComponent = <React.Fragment key={item.id}>{renderedChildren}</React.Fragment>;
        return itemComponent;
    });

    if (listCleansedProps.slider != null) {
        retVal = (
            <Slider_v1_0_0 {...listCleansedProps}>
                {itemComponents}
            </Slider_v1_0_0>
        );
    } else {
        retVal = itemComponents;
    }
    return retVal;
}
List_v1_0_0.listProps = {
    isList: true,
};
List_v1_0_0.inputProps = {
    limit: {
        type: 'number',
        label: 'Number of items to show',
        description: 'The number of items to show in the list. If not specified, all items will be shown.',
    },
    filter: {
        type: 'short_text',
        label: 'Filter for list',
        description: 'Determine which items to show in the list. If not specified, all items will be shown.',
    },
    sort: {
        type: 'short_text',
        label: 'Sorting logic for list',
        description: 'Determine which items to show first.',
    },
};

function _limitListItemsArray(itemsArray: any, limit: number) {
    const retVal = _.take(itemsArray, limit || itemsArray.length);

    return retVal;
}
