// todo v1.4 refer to connie on how dark(a yellow #f7e21c) is not dark. instead change the variables
// color-text
// color-primary
// color-secondary
// color-accent
export const cssGlobalVariables_v1_2_0 = `/* Colors */
--global-color-primary: #07031A; /* text */
--global-color-light: #fff; /* bg */
--global-color-dark: var(--global-color-primary); /* bg container, footer */
--global-color-accent: var(--global-color-light);

/* BG */
--global-bg-color: var(--global-color-light);

/* Header */
--global-header-bg-color: var(--global-color-dark);
--global-header-text-color: var(--global-color-light);
--global-header-link-color: var(--global-color-light);

/* Footer */
--global-footer-bg-color: var(--global-color-dark);
--global-footer-text-color: var(--global-color-light);
--global-footer-link-color: var(--global-color-light);

/* Text */
--global-text-color: var(--global-color-primary);
--global-text-accent-color: var(--global-color-accent);

--global-text-body-font: "Open Sans", sans-serif;
--global-text-body-color: var(--global-text-color);
--global-text-body-accent-color: var(--global-text-accent-color);

--global-text-heading-font: "Playfair Display", serif;
--global-text-heading-color: var(--global-text-color);
--global-text-heading-accent-color: var(--global-text-accent-color);

--global-text-sub-heading-font: var(--global-text-heading-font);
--global-text-sub-heading-color: var(--global-text-color);
--global-text-sub-heading-accent-color: var(--global-text-accent-color);

/* Buttons */
--global-button-bg-color: var(--global-color-accent);
--global-button-text-color: var(--global-text-color);

/* Icons */
--global-icon-color: var(--global-color-accent);

/* Spacing */
--global-hr-line-color: var(--global-color-accent);
`;
