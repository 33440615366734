import React, { useContext, createContext, useState, useRef } from "react";

export const StyleContext_V1_2_0 = createContext({ registerGlobalStyles: null });

export const StyleProvider_V1_2_0 = (props) => {
    // const [styles, setStyles] = useState<string[]>([]);

    // const registerGlobalStyles = (styles: string[]) => {
    //     setStyles((prevStyles) => [...prevStyles, ...styles]);
    // };

    // const globalStlyes = React.useMemo(() => {
    //     const dedupedClassNames = [...new Set(styles.filter(Boolean))];

    //     return dedupedClassNames.join('\n');
    // }, [styles]);

    const [styleString, setStyleString] = useState<string>();

    const styleStringArray = useRef<string[]>([]);

    const registerGlobalStyles = (stylesToRegister: string[]) => {

        setStyleString((prevStyleString) => {
            const uniqueStyles = [... new Set(styleStringArray.current.concat(stylesToRegister).filter(Boolean))];
            const newString = uniqueStyles.join('\n');
            styleStringArray.current = uniqueStyles;
            return newString;
        });
    };



    return (
        <StyleContext_V1_2_0.Provider value={{ registerGlobalStyles: registerGlobalStyles }}>
            {/* <pre>{styleString}</pre> */}
            {props.children}
            {/* DO NOT use styled-jsx or global prop here on stle because only the first result gets rendered. */}
            <style>{styleString}</style>
        </StyleContext_V1_2_0.Provider>
    );
};
export const useStyles_V1_2_0 = () => useContext(StyleContext_V1_2_0);
