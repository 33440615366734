import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import React from "react";
import { getComponentClassName_v1_1_0 } from "../../compiler/components";
import { getComponentHtmlAttrs_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/render-components';
import { renderChildren_v1_0_0 } from "../../../../v1.0.0/theme-data/renderer/render-components";
// unit in pixels, not rem

export function Row_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    const className = getComponentClassName_v1_1_0(props, 'row', 'wiml-row');

    const extraAttrs = getComponentHtmlAttrs_v1_0_0(props);
    const renderedChildren = renderChildren_v1_0_0(props.children);

    return <div className={className} {...extraAttrs}>{renderedChildren}</div>;
}
