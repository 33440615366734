import type { MarkerSeverity, editor, IPosition, Range } from 'monaco-editor'
import XsdManager from './XsdManager'
import xmldom from 'xmldom'
// import validateSchema, { ValidationError } from 'xsd-validator'
import { ErrorType, XmlDomError, XmlValidtorFunc } from './types'
import { SimpleParser } from './SimpleParser'
import { XsdNamespaces } from './XsdNamespaces';
import { convertMarkupToXml_v1_3_0 } from '@wip/common/app/wiml/versions/v1/v1.3.0/theme-data/compiler/transform';
// import xmllint from 'xmllint';
// import xmllint from '../../../../../../../../../public/xmllint';
// import xmllint from 'xmllint';

type IModelDeltaDecoration = editor.IModelDeltaDecoration
type ITextModel = editor.ITextModel

export default class XsdValidation {
    private xsdManager: XsdManager
    private dom: xmldom.DOMParser
    private errors: XmlDomError[] = []
    private model: editor.ITextModel | null = null
    private validateXML: XmlValidtorFunc

    constructor(xsdManager: XsdManager, validateXML: XmlValidtorFunc) {
        this.xsdManager = xsdManager
        this.dom = new xmldom.DOMParser({
            locator: {},
            errorHandler: {
                warning: (message: string) =>
                    this.errors.push(new XmlDomError(message, ErrorType.warning)),
                error: (message: string) =>
                    this.errors.push(new XmlDomError(message, ErrorType.error)),
                fatalError: (message: string) =>
                    this.errors.push(new XmlDomError(message, ErrorType.fetalError)),
            },
        })
        this.validateXML = validateXML;
    }

    decorations = (model: ITextModel | null): IModelDeltaDecoration[] => {
        this.errors = []
        this.model = model
        const xml = model ? SimpleParser.getFullText(model) : undefined

        if (xml) {
            this.dom.parseFromString(xml)
            const xsdNamespaces = XsdNamespaces.getXsdNamespaces(xml)
            const xsdWorkers = XsdNamespaces.getXsdWorkersForNamespace(
                xsdNamespaces,
                this.xsdManager,
            )

            const validationResults = xsdWorkers
                .map((xsdWorker) =>
                    this.validateXML({
                        xml,
                        schema: xsdWorker.xsd.value,
                        arguments: ["--noout", "--schema", 'wiml.xml', 'wiml.xsd'],
                    }),
                ).flatMap(x => x.split('\n'))
                .filter(x => /validates?$/.test(x) == false)
                .filter(Boolean);

            validationResults.forEach((validationResult) => {
                this.errors.push(new XmlDomError(validationResult, ErrorType.error))
            });
        }

        return this.getDecorationsFromErrors()
    }

    getDecorationsFromErrors = (): IModelDeltaDecoration[] =>
        this.errors.map(this.getDecorationsFromError)

    getDecorationsFromError = (error: XmlDomError): IModelDeltaDecoration => ({
        range: this.getWordRangeFromErrorPosition(error.position),
        options: {
            className: error.classNames,
            hoverMessage: [
                {
                    value: error.errorTypeString,
                },
                {
                    value: `${error.errorMessage}`,
                },
                {
                    value: '*XML DOM Parser*',
                },
            ],
        },
    })

    markers = (model: ITextModel | null): editor.IMarkerData[] => {
        this.errors = []
        this.model = model
        const xml = model ? SimpleParser.getFullText(model) : undefined

        if (xml) {
            this.dom.parseFromString(xml)
            const xsdNamespaces = XsdNamespaces.getXsdNamespaces(xml)
            const xsdWorkers = XsdNamespaces.getXsdWorkersForNamespace(
                xsdNamespaces,
                this.xsdManager,
            )

            const validationResults = xsdWorkers
                .map((xsdWorker) =>
                    this.validateXML({
                        xml,
                        schema: xsdWorker.xsd.value,
                        arguments: ["--noout", "--schema", 'wiml.xsd', 'wiml.xml'],
                    }),
                )
                .flatMap(x => x.split('\n'))
                .filter(x => /validates?$/.test(x) == false)
                .filter(Boolean)

            validationResults.forEach((validationResult) => {
                this.errors.push(new XmlDomError(validationResult, ErrorType.error))
            });
        }

        return this.getMarkersFromErrors()
    }

    getMarkersFromErrors = (): editor.IMarkerData[] =>
        this.errors.map(this.getMarkerFromError)



    getMarkerFromError = (error: XmlDomError): editor.IMarkerData => {
        const message = error.errorMessage;
        const wordsInSingleQuotes = message.match(/'[^']*'/g) as string[];
        if (!wordsInSingleQuotes) {
            return ({
                startLineNumber: error.position.lineNumber,
                startColumn: error.position.column,
                endLineNumber: error.position.lineNumber,
                endColumn: error.position.column + 1,
                message: error.errorMessage,
                severity: error.errorTypeMarkerSeverity,
            })
        }

        let startLineNumber: number = 0;
        let endLineNumber: number = 0;

        let lastWordInSingleQuotes: string = '';
        let lastErrorWordIndexInLine: number = 0;
        for (const word of wordsInSingleQuotes) {
            const wordCleansed = word.replaceAll("'", '');

            const position = error.position;

            const lineContent = this.model?.getLineContent(position.lineNumber);
            const errorWordIndexInLine = (lineContent?.lastIndexOf(wordCleansed) as number) || -1;
            if (errorWordIndexInLine > -1) {
                startLineNumber = position.lineNumber;
                endLineNumber = position.lineNumber;

                lastWordInSingleQuotes = wordCleansed;
                lastErrorWordIndexInLine = errorWordIndexInLine + 1;
                break;
            }

            const previousLine = position.lineNumber > 1 ? this.model?.getLineContent(position.lineNumber - 1) : '';
            if (previousLine!.includes(wordCleansed)) {
                const errorWordIndexInLine = (previousLine?.lastIndexOf(wordCleansed) as number) || -1;
                if (errorWordIndexInLine > -1) {
                    startLineNumber = position.lineNumber - 1;
                    endLineNumber = position.lineNumber - 1;

                    lastWordInSingleQuotes = wordCleansed;
                    lastErrorWordIndexInLine = errorWordIndexInLine + 1;
                    break;
                }
            }

            const nextLine = position.lineNumber < this.model!.getLineCount() ? this.model!.getLineContent(position.lineNumber + 1) : '';
            if (nextLine.includes(wordCleansed)) {
                const errorWordIndexInLine = (nextLine?.lastIndexOf(wordCleansed) as number) || -1;
                if (errorWordIndexInLine > -1) {
                    startLineNumber = position.lineNumber + 1;
                    endLineNumber = position.lineNumber + 1;

                    lastWordInSingleQuotes = wordCleansed;
                    lastErrorWordIndexInLine = errorWordIndexInLine + 1;
                    break;
                }
            }
        }

        return ({
            startLineNumber: startLineNumber,
            startColumn: lastErrorWordIndexInLine,
            endLineNumber: endLineNumber,
            endColumn: lastErrorWordIndexInLine + lastWordInSingleQuotes.length,
            message: error.errorMessage,
            severity: error.errorTypeMarkerSeverity,
        })
    }

    getWordRangeFromErrorPosition = (position: IPosition): Range => {
        const length = this.model?.getWordAtPosition(position)?.word.length
        if (length)
            return new Range(
                position.lineNumber,
                position.column,
                position.lineNumber,
                position.column + length,
            )
        else
            return new Range(
                position.lineNumber,
                position.column - 1,
                position.lineNumber,
                position.column + 2,
            )
    }
}
