const mozAccessId = process.env.MOZ_ACCESS_ID;
const mozSecretKey = process.env.MOZ_SECRET_KEY;

const domainAnalysisApiUrl = 'https://lsapi.seomoz.com/v2/url_metrics';

// Not sure if this is needed, seems to work just fine without…
const GETOptions = {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    headers: { "Content-Type": "application/json" },
};

export const fetcher = (url, options = GETOptions, { rawResponse = false, getJson = true }) =>
    rawResponse
        ? (fetch(url, options))
        : (
            fetch(url, options)
                .then(async res => {
                    if (res.ok) {
                        return res;
                    } else {
                        const message = await res.text();
                        throw new Error('Error requesting: ' + url + '. Status: ' + res.status + ". Message: " + message);
                    }
                })
                .then(res => getJson
                    ? res.json().catch(e => ({ status: res.status }))
                    : res
                )
        );

export function getDomain(url) {
    if (!url) throw new Error('url is empty.');

    if (!url.startsWith('http')) {
        url = 'http://' + url;
    }

    const retVal = new URL(url).hostname;

    return retVal;
}


export async function getDomainAnalysis(domain) {
    if (!domain) throw new Error('url is empty.');
    // return Promise.resolve(mockResponse);

    // https://moz.com/help/links-api/making-calls/url-metrics#example-http-request
    const POSTWebhookData = {
        "targets": [domain]
    };
    const encodedBasicAuth = Buffer.from(`${mozAccessId}:${mozSecretKey}`).toString('base64');
    const POSTOptions = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Basic " + encodedBasicAuth,
            'User-Agent':
                'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/50.0.2661.102 Safari/537.36',
        },
        body: JSON.stringify(POSTWebhookData)
    };

    let retVal;
    try {
        retVal = await fetcher(domainAnalysisApiUrl, POSTOptions, { getJson: true });
    } catch (e) {
        console.log("Error saving order data:", e);
        const rethrow = new Error('Error saving order data: ' + e);
        rethrow.stack = e.stack;
        throw rethrow;
    }
    return retVal;
}


const mockResponse = {
    "results": [
        {
            "page": "moz.com/",
            "subdomain": "moz.com",
            "root_domain": "moz.com",
            "title": "Moz - SEO Software for Smarter Marketing",
            "last_crawled": "2021-03-30",
            "http_code": 200,
            "pages_to_page": 15246482,
            "nofollow_pages_to_page": 10023551,
            "redirect_pages_to_page": 3640198,
            "external_pages_to_page": 15085290,
            "external_nofollow_pages_to_page": 10023551,
            "external_redirect_pages_to_page": 3639773,
            "deleted_pages_to_page": 1474443,
            "root_domains_to_page": 29655,
            "indirect_root_domains_to_page": 10027,
            "deleted_root_domains_to_page": 5382,
            "nofollow_root_domains_to_page": 5571,
            "pages_to_subdomain": 59964114,
            "nofollow_pages_to_subdomain": 19606689,
            "redirect_pages_to_subdomain": 19207735,
            "external_pages_to_subdomain": 40395969,
            "external_nofollow_pages_to_subdomain": 19556717,
            "external_redirect_pages_to_subdomain": 13073680,
            "deleted_pages_to_subdomain": 21013500,
            "root_domains_to_subdomain": 133072,
            "deleted_root_domains_to_subdomain": 23289,
            "nofollow_root_domains_to_subdomain": 26715,
            "pages_to_root_domain": 61070607,
            "nofollow_pages_to_root_domain": 19682997,
            "redirect_pages_to_root_domain": 19211678,
            "external_pages_to_root_domain": 40562873,
            "external_indirect_pages_to_root_domain": 20724873,
            "external_nofollow_pages_to_root_domain": 19632560,
            "external_redirect_pages_to_root_domain": 13076500,
            "deleted_pages_to_root_domain": 21083773,
            "root_domains_to_root_domain": 133693,
            "indirect_root_domains_to_root_domain": 26483,
            "deleted_root_domains_to_root_domain": 23442,
            "nofollow_root_domains_to_root_domain": 26794,
            "page_authority": 72,
            "domain_authority": 91,
            "link_propensity": 0.009834184311,
            "spam_score": 3,
            "root_domains_from_page": 1,
            "nofollow_root_domains_from_page": 0,
            "pages_from_page": 1,
            "nofollow_pages_from_page": 0,
            "root_domains_from_root_domain": 67837,
            "nofollow_root_domains_from_root_domain": 55026,
            "pages_from_root_domain": 328690,
            "nofollow_pages_from_root_domain": 197495,
            "pages_crawled_from_root_domain": 6898081
        }
    ]
};
