import Website from "../../../../domain/website";
import { ComponentThemeData_v1_0_0, WimlNode_v1_0_0 } from "./v1.0.0/theme-data/compiler/types";
import { JSONObject, JSONValue } from "../../../../lib/object-types";
import { ComponentThemeData_v1_1_0 } from "./v1.1.0/theme-data/compiler/types";
import { ComponentThemeData_v1_2_0 } from "./v1.2.0/theme-data/compiler/types";
import { Wiml_v1 } from ".";

export type WimlNode_v1 = {
    text?: string;
    tag?: string; // 'div'
    node?: string; // 'element' //todo make this NON-optional
    attr?: { [key: string]: string }; // { 'data-wiml-page': 'home' }
    child?: Array<WimlNode_v1>;
};

export type WimlVersion_v1 =
    'v1.0.0' |
    'v1.1.0' |
    'v1.2.0';
// todo make some part of this global amongst version

export type WimlRootNode_v1 = WimlNode_v1 & {
    version: WimlVersion_v1;
};

export type ComponentThemeData_v1 = ComponentThemeData_v1_0_0 | ComponentThemeData_v1_1_0 | ComponentThemeData_v1_2_0;
export type RenderThemeData_v1 = Record<string, React.ReactNode>;
export type ThemeDataWimlComponent_v1 = {
    type: string;
};
type UrlQueryProps_v1 = Record<string, string | string[]>;
type CurrentPage = {
    pageKey: string;
    request: {
        extraQuery: UrlQueryProps_v1;
        query: {
            [key: string]: string;
        };
    };
};

export type RuntimeWimlComponentProps_v1 = {
    display?: "currency" | "percent" | "progress";
    topLevelListComponentKey: string;
    speed?: any;
    slider?: boolean;
    colorThemeConfig: any;
    baseThemeConfig: any;
    circle: boolean;
    square: boolean;
    button: string;
    alignLg: any;
    pageDefinitionId: string;
    currentPage: CurrentPage;
    children: React.ReactNode;
    className?: string;
    componentKey?: string;
    componentId?: string; // todo remove and put in v1.2
    displayLayout?: boolean;
    fluid?: boolean;
    listId?: string;
    listItemId?: string;
    pageKey?: string;
    size?: string;
    class?: string;
    websiteData: Website;
    dataRetrievalListId?: string;
    dataRetrievalListItemId?: string;
    [key: string]: any; // todo remove me
};

export type RuntimeWimlComponenttPropKeys_v1 = keyof RuntimeWimlComponentProps_v1;

export type ThemeDataPageListComponentsTopLevelCloneProps_v1 = {
    className?: string;
    topLevelListComponentKey: string;
};

// THEME DATA -- PAGE
export type ThemeDataPageWithComponents_v1 = {
    name: string;
    url: string;
    components: ThemeDataListComponentCollection_v1;
};
// todo v1.2 rename

export type ThemeDataPageComponent_v1 = ThemeDataWimlComponent_v1 & {
    // unique identifier for the component within the page or list
    key: string;
    themeProps?: JSONObject;
};

export type ThemeDataPageChildComponent_v1 = ThemeDataPageComponent_v1 & {
    // id is the type + key
    // e.g. listBooks
    // however if type and key are the same, it's just the key e.g. aboutAbout -> about
    id: string;
    isListInstanceComponent?: boolean;
    parentListInstanceComponentId?: string;
};

export type ThemeDataPageChildComponentListInstanceChildComponent_v1 = ThemeDataPageChildComponent_v1 & {
    listId: string;
    parentListInstanceComponentId: string;
};

export type ThemeDataPageListComponent_v1 = ThemeDataPageChildComponent_v1 & {
    // unique identifier for the component within the page or list
    components: {
        ids: string[];
    };
};

export type ThemeDataPageComponentCollection_v1 = WimlCollection_v1<ThemeDataPageWithComponents_v1>;

export type ThemeDataReactComponentOptions = {
    layout?: WimlNode_v1_0_0;
    pageDefinitionId?: string; // todo rename to componentDefinitoinId -- because "header" and "footer" (part of <Laytou>) are not pages
    pageOccurrenceId?: string;
};
// THEME DATA -- LIST

export type ThemeDataList_v1 = ThemeDataWimlComponent_v1 & {
    type: 'List';
};

export type ThemeDataListQualifiedAndMergedWithComponents_v1 = ThemeDataListQualifiedAndMerged_v1 & {
    components: ThemeDataListComponentCollection_v1;
};


export type ThemeDataListCollection_v1 = WimlCollection_v1<ThemeDataListQualifiedAndMergedWithComponents_v1>;

export type ListInstanceComponentThemeData_v1 = ThemeDataPageChildComponent_v1 & {
    listId: string;
};
type ThemeDataListComponentCollection_v1 = WimlCollection_v1<ThemeDataPageChildComponent_v1>;


export type ThemeDataListRenderOptions_v1 = {
    dataRetrievalListId?: string;
    dataRetrievalListItemId?: string;
};

export type ThemeDataPageList_v1 = ThemeDataList_v1 & ListInstanceComponentThemeData_v1 & {
    pageOccurrenceId: string;
};

export type ThemeDataListQualifiedAndMerged_v1 = ThemeDataList_v1 & ThemeDataPageChildComponent_v1 & ListInstanceComponentThemeData_v1 & {
    pageOccurrenceIds: string[];
};

export type ThemeDataPageListQualifiedComponent_v1 = ThemeDataPageQualifiedListComponent_v1 & {
    lqId: string;
};

export type ThemeDataPageQualifiedListComponent_v1 = ThemeDataPageChildComponent_v1 & ListInstanceComponentThemeData_v1 & {};
// SITE DATA
// SITE DATA -- PAGE
type SiteDataPageComponent_v1 = {
    [property: string]: SiteDataPageComponentProperty_v1;
};
type SiteDataPageComponentProperty_v1 = {
    data: {
        expression: string;
        value: JSONValue;
    };
};
type SiteDataPageComponentCollection_v1 = WimlCollection_v1<SiteDataPageComponent_v1>;
type SiteDataPage_v1 = {
    components: SiteDataPageComponentCollection_v1;
};
type SiteDataPageCollection_v1 = WimlCollection_v1<SiteDataPage_v1>;
// SITE DATA -- LIST

export type SiteDataListItem_v1 = {
    meta: {
        editedDate: Date;
    };
    components: WimlCollection_v1<SiteDataPageComponent_v1>;
    relationships: { lists: WimlCollection_v1<ListItemRelationshipCollection_v1>; };
};
type ListItemRelationshipCollection_v1 = WimlCollection_v1<ListItemRelationship_v1>;
type SiteDataList_v1 = {
    ids: string[];
    items: WimlCollection_v1<SiteDataListItem_v1>;
};
type ListItemRelationship_v1 = {
    data: JSONObject;
};
// SiteDataList e.g. Books
// -- Ids - IGNORE ME
// -- Items aka List Items
// -- -- IDs - ids of the list items
// -- -- ITEMS 
// -- -- -- LI_123 -- SiteDataListItem
// -- -- -- -- Components
// -- -- -- -- -- IDS
// -- -- -- -- -- ITEMS
// -- -- -- -- Meta
type SiteDataListCollection_v1 = WimlCollection_v1<SiteDataList_v1>;
// todo migrate pages -> pages.items, same for lists
// rootNodeJson -> rootNode.jsonString


export class ThemeData_v1 {
    components: {
        ids: string[];
        items: {
            pages: ThemeDataPageComponentCollection_v1;
            lists: ThemeDataListCollection_v1;
            rootNodeJson: string;
        };
    };
    meta: {
        version: string; // todo make enum global
    };
    markup: string;
    reactComponents: {
        ids: string[];
        items: {
            pages: WimlCollection_v1<{ [page: string]: string; }>;
        };
    };

    constructor() {
        this.components = {
            ids: [],
            items: {
                pages: { ids: [], items: {} },
                lists: { ids: [], items: {} },
                rootNodeJson: ''
            }
        };
        this.meta = {
            version: 'v1.0.0'
        };
        this.markup = '';
        this.reactComponents = {
            ids: [],
            items: {
                pages: { ids: [], items: {} }
            }
        };
    }
}

export class SiteData_v1 {
    components: {
        ids: string[];
        items: {
            pages: SiteDataPageCollection_v1;
            lists: SiteDataListCollection_v1;
        };
    };

    constructor() {
        this.components = {
            ids: [],
            items: {
                pages: { ids: [], items: {} },
                lists: { ids: [], items: {} }
            }
        };
    }
}

export type WimlData_v1 = Wiml_v1;



type WimlCollection_v1<T> = {
    ids: string[];
    items: { [item: string]: T; };
};
