const prefix = 'fab';
const iconName = 'clapper';
const width = 512;
const height = 512;
const ligatures = [];
const unicode = null;
const svgPathData = 'M 560 280 l 0 -280 l -280 0 l -280 0 l 0 280 l 0 280 l 280 0 l 280 0 l 0 -280 z m -363.5 176.75 l -2.5 -6.75 l 7 -36.75 c 3.75 -20.5 7.5 -39.5 8.25 -42.5 l 1.25 -5.75 l -34.5 0 l -34.75 0 l -8.25 -5 c -9 -5.5 -18 -18.5 -18 -26.5 c 0 -10 26.75 -113.25 31 -119.75 c 2.25 -3.5 7.75 -8.75 12.25 -11.25 l 8 -5 l 46.25 0 l 46.25 0 l 13.75 6.5 c 7.5 3.5 18 10.5 23.5 15.5 c 5.25 5 12.75 16 16.75 24.25 l 7.25 15 l 0 17.5 l 0 17.5 l -6 13.75 c -4.75 11.75 -80.5 138.5 -94.5 158.25 l -5 7 l -7.75 -4.75 c -4.5 -2.5 -9 -7.5 -10.25 -11.25 z m 127.25 -102.25 c 12 -19.5 21.25 -40.75 24 -55.75 l 2.75 -15 l -1.5 -18 c -3 -37.5 -19.25 -63 -53.75 -85 c -1.5 -1 53.25 -88.75 56.25 -90 c 5 -1.75 13.25 3.75 17.25 11.75 l 4 7.5 l -7.75 39.25 c -4 21.5 -7.5 41.25 -7.5 43.75 l 0 4.5 l 36.25 0 l 36 0 l 8.75 7.75 l 9 7.75 l 2.25 8 l 2.25 8.25 l -13.75 55.75 l -13.5 55.75 l -5.25 7.5 c -2.75 4 -9.75 9.5 -15.5 12 l -10.25 4.75 l -38.25 0 l -38.25 0 l 6.5 -10.5 z';


export const faClapper = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};
