import { getListInstanceComponentThemeDataId_v1_0_0, getPageComponentId_v1_0_0, isListChildComponentType_v1_0_0, listItemComponentCheckoutRules_v1_3_0 } from '../theme-data/renderer/render-components';

import { ListInstanceComponentThemeData_v1, RuntimeWimlComponentProps_v1, SiteDataListItem_v1, Wiml_v1 } from "../../types";
import { listItemComponentCheckoutRules_v1_0_0, ComponentKey } from '../theme-data/renderer/render-components';
import Website from '../../../../../../domain/website';
import { convertObjectToArray } from '../../../../../../lib/object-utils';
import { objectUtils } from '../../../../../..';
import { getPageComponentThemeData_v1 } from '../..';


export function getListChildComponentPropertyValueSiteData_v1_0_0(props: RuntimeWimlComponentProps_v1, propertyName: string, componentType: string, listId: string, listItemId: string) {
    const camlizedComponentId = getListInstanceComponentThemeDataId_v1_0_0(props, componentType);

    const listItemData = getListItemSiteData_v1_0_0(props.websiteData, listId, listItemId);
    const propertyValue = listItemData?.components?.items?.[camlizedComponentId]?.[propertyName]?.data?.value;

    return propertyValue;
}

export function getPageChildComponentPropertyValueSiteData_v1_0_0(props: RuntimeWimlComponentProps_v1, propertyName: string, componentType: string) {
    if (!props.pageDefinitionId) throw new Error('pageDefinitionId is required for getPageComponentPropertyValue: ' + propertyName);
    const { pageId, camlizedComponentId } = getPageComponentId_v1_0_0(props, componentType);

    const propertyValue = props.websiteData.style.wiml.siteData?.components.items?.pages?.items?.[pageId]?.components?.items?.[camlizedComponentId]?.[propertyName]?.data?.value;

    return propertyValue;
}

// ignore: each version should have its own version of this function - even if they it just calls the previous version.
// on second thought, it's better to explicitly refer to old versions, this lets us know immediately if there has been a new version of a dependent function
export function getChildComponentPropertyValueSiteData_v1_0_0(props: RuntimeWimlComponentProps_v1, propertyName: string, componentType: string) {
    // if (componentType) {
    //     // todo remove assertion
    //     throw new Error('defaultComponentKey is not supported');
    // } else {
    //     componentType = defaultComponentKey;
    // }
    // if (!props.listItemId && propertyName == 'date') throw new Error('date property is only available for list items');
    let retVal = isListChildComponentType_v1_0_0(props)
        ? getListChildComponentPropertyValueSiteData_v1_0_0(props, propertyName, componentType, props.dataRetrievalListId, props.dataRetrievalListItemId)
        : getPageChildComponentPropertyValueSiteData_v1_0_0(props, propertyName, componentType);

    retVal = postProcessPropertyValue_v1_0_0(retVal);
    return retVal;
}
export function postProcessPropertyValue_v1_0_0(retVal: string | number | boolean | import("/Users/scottc/Code/author-site-builder/apps/common/lib/object-types").JSONObject | import("/Users/scottc/Code/author-site-builder/apps/common/lib/object-types").JSONArray) {
    if (typeof retVal == 'string') {
        if (retVal.startsWith?.("{{checkout")) {
            retVal = { checkout: "stripe" };
        }
    }
    return retVal;
} export function convertListItemToCheckoutItemSiteData_v1_0_0(websiteData: Website, listId: string, listItemId: string) {
    const retVal = { errors: {} as Record<string, string>, valid: true, data: {} as Record<string, any>, meta: { location: { listId, listItemId } } };

    const listItemData = getListItemSiteData_v1_0_0(websiteData, listId, listItemId);

    // todo remove and put into own v1.3.0
    const rulesByWimlVer = (websiteData.style.wiml.themeData.meta.version == '1.3.0' ? listItemComponentCheckoutRules_v1_3_0 : listItemComponentCheckoutRules_v1_0_0) as Record<string, any>;
    const checkoutData = (Object.keys(rulesByWimlVer) as string[]).reduce((acc, componentKey) => {
        const rules = rulesByWimlVer[componentKey];
        const fieldValues = Object.keys(rules).reduce((acc2, fieldName) => {
            const fieldValue = listItemData?.components?.items?.[componentKey]?.[fieldName]?.data?.value;
            if (fieldValue) {
                acc2[fieldName] = fieldValue;
            } else {
                retVal.valid = false;
                retVal.errors[fieldName] = `${componentKey}.${fieldName} is required`;
            }
            return acc2;
        }, {} as Record<string, any>);

        acc[componentKey] = fieldValues;

        return acc;
    }, {} as Record<ComponentKey, any>);

    const checkoutOptionsData = {} as Record<string, any>;
    // without this explicit type cast, nextjs build todo remove this

    if (websiteData.style.wiml.themeData.meta.version == '1.3.0') {
        const physicalTypeProductOptionListItemId = Object.entries(websiteData.style.wiml.siteData.components.items.lists.items.list__product_options?.items?.items || {} as Record<string, SiteDataListItem_v1>)
            .find(([k, v]) => /physical/i.test(v.components?.items?.heading__title?.content?.data?.value as string))?.[0];

        if (physicalTypeProductOptionListItemId && listItemData.relationships?.lists?.items?.list__product_options?.ids?.includes(physicalTypeProductOptionListItemId)) {
            checkoutOptionsData.type = 'physical';
        } else {
            checkoutOptionsData.type = 'digital';
        }
    } else {
        const physicalTypeProductOptionListItemId = Object.entries(websiteData.style.wiml.siteData.components.items.lists.items.productOptions?.items?.items || {} as Record<string, SiteDataListItem_v1>)
            .find(([k, v]) => /physical/i.test(v.components?.items?.heading?.content?.data?.value as string))?.[0];

        if (physicalTypeProductOptionListItemId && listItemData.relationships?.lists?.items?.productOptions?.ids?.includes(physicalTypeProductOptionListItemId)) {
            checkoutOptionsData.type = 'physical';
        } else {
            checkoutOptionsData.type = 'digital';
        }
    }

    // extra validation
    const accountActive = websiteData.ecommerce.accountActive;
    if (!accountActive) {
        retVal['valid'] = false;
        retVal['errors']['accountActive'] = 'account is not active';
    }


    retVal.data = checkoutData;
    retVal.data.options = checkoutOptionsData;

    return retVal;
}


export function getListItemSiteData_v1_0_0(websiteData: Website, listId: string, listItemId: string) {
    const retVal = websiteData.style.wiml.siteData?.components.items?.lists?.items?.[listId]?.items?.items?.[listItemId];

    return retVal;
}
export function getListItemsSiteDataArray_v1_0_0(props: RuntimeWimlComponentProps_v1, listId: string) {
    const propertyValue = props.websiteData.style.wiml.siteData?.components.items?.lists?.items?.[listId]?.items?.items;
    const itemsArray = convertObjectToArray(propertyValue);

    const retVal = itemsArray;

    return retVal;
}


export function getPageComponentThemeData_v1_0_0(pageId: string, wimlData: Wiml_v1) {
    return wimlData.themeData?.components.items?.pages?.items?.[pageId];
}

export function getPageChildComponentThemeData_v1_0_0(pageId: string, componentId: string, wimlData: Wiml_v1) {
    return getPageComponentThemeData_v1(pageId, wimlData)?.components?.items?.[componentId];
}

export function getListComponentThemeData_v1_0_0(listId: string, wimlData: Wiml_v1) {
    return wimlData.themeData?.components.items?.lists?.items?.[listId];
}

export function _getListChildComponentsThemeData_v1_0_0(listId: string, wimlData: Wiml_v1) {
    return getListComponentThemeData_v1_0_0(listId, wimlData)?.components?.items;
}

export function _getListChildComponentThemeData_v1_0_0(listId: string, componentId: string, wimlData: Wiml_v1) {
    return _getListChildComponentsThemeData_v1_0_0(listId, wimlData)?.[componentId];
}

export function getPageChildListInstanceComponentThemeData_v1_0_0(pageId: string, listInstanceComponentId: string, wimlData: Wiml_v1) {
    const component = getPageChildComponentThemeData_v1_0_0(pageId, listInstanceComponentId, wimlData);
    if (component.type != 'List') {
        throw new Error(`component ${listInstanceComponentId} on page ${pageId} is not a list`);
    }
    return component as ListInstanceComponentThemeData_v1;
}

export function getPageChildListInstanceChildComponentThemeData_v1_0_0(pageId: string, listInstanceComponentId: string, componentId: string, wimlData: Wiml_v1) {
    const component = getPageChildComponentThemeData_v1_0_0(pageId, listInstanceComponentId, wimlData);
    if (component.type != 'List') {
        throw new Error(`component ${listInstanceComponentId} on page ${pageId} is not a list`);
    }
    return component as ListInstanceComponentThemeData_v1;
}

