import { RuntimeWimlComponentProps_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/types';
import { _renderLink_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/link';
import { Link_v1_1_0 } from '../../../../v1.1.0/theme-data/renderer/components/link';
import { getChildComponentPropertyValueSiteData_v1_2_0 } from '../../../site-data/retrieval';
import { getComponentClassName_v1_2_0 } from '../../compiler/components';

// todo v1.4 make props consistent - is link href or url?
export function Link_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const componentClassName = getComponentClassName_v1_2_0(props, 'wiml-link');
    const urlJSONValue = getChildComponentPropertyValueSiteData_v1_2_0(props, 'url') || props.url;

    // todo replace with next link
    // https://nextjs.org/docs/api-reference/next/link
    const retVal = _renderLink_v1_0_0(props, componentClassName, urlJSONValue);

    return retVal
}

Link_v1_2_0.inputProps = Link_v1_1_0.inputProps;
