// https://dev.to/thatgalnatalie/how-to-get-started-with-redux-toolkit-41e
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchThemeData } from '@wip/common';
import { fetchThemeData_v2 } from '../app/themes';

const initialState = {
  themes: [],
  isLoading: false,
  hasErrors: false,
};

const theme = createSlice({
  name: "themes_v2",
  initialState,
  reducers: {
    getData: (state) => {
      state.isLoading = true;
    },

    getDataSuccess: (state, action) => {
      state.isLoading = false;
      state.themes = action.payload;
    },

    getDataFailure: (state) => {
      state.isLoading = false;
      state.hasErrors = true;
    },
  }
});

export const reducer = theme.reducer;

export const {
  getData, getDataSuccess, getDataFailure
} = theme.actions;

export const fetchThemes_v2 = createAsyncThunk(
  "theme/fetchThemes_v2",
  async (_, { dispatch }) => {
    // Set the loading state to true
    dispatch(getData());

    try {
      const themes = await fetchThemeData_v2();

      const dispatchData = themes;
      dispatch(getDataSuccess(dispatchData));
    } catch (error) {
      console.error('error', error)
      // Set any erros while trying to fetch
      dispatch(getDataFailure(error));
    }
  }
);
