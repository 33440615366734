import { Container_v1_2_0 } from './components/container';
import { Navigation_v1_2_0 } from './components/navigation';
import { RuntimeWimlComponentCollection_v1_0_0, RuntimeWimlComponent_v1_0_0 } from '../../../v1.0.0/theme-data/renderer/types';
import { Footer_v1_2_0 } from './components/footer';
import { Heading_v1_2_0 } from './components/heading';
import { Hr_v1_2_0 } from './components/hr';
import { Spacer_v1_2_0 } from './components/spacer';
import { Col_v1_2_0 } from './components/col';
import { Row_v1_2_0 } from './components/row';
import { Image_v1_2_0 } from './components/image';
import { List_v1_2_0 } from './components/list';
import { Link_v1_2_0 } from './components/link';
import { Text_v1_2_0 } from './components/text';
import { wimlComponents_v1_1_0 } from '../../../v1.1.0/theme-data/renderer/wiml-components-v1.1.0';
import { Embed_v1_2_0 } from './components/embed';
import { Icon_v1_2_0 } from './components/icon';
import { Number_v1_2_0 } from './components/number';
import { Date_v1_2_0 } from './components/date';
// names MUST BE strings, not types, types are minified,etc at build time.
// todo replace and inherit v1.0.0 components
export const wimlComponents_v1_2_0: RuntimeWimlComponentCollection_v1_0_0 = {
    ...wimlComponents_v1_1_0,
    "Col": Col_v1_2_0,
    "Container": Container_v1_2_0,
    "Date": Date_v1_2_0,
    "Embed": Embed_v1_2_0,
    "Footer": Footer_v1_2_0,
    "Heading": Heading_v1_2_0,
    "Hr": Hr_v1_2_0,
    "Icon": Icon_v1_2_0,
    "Image": Image_v1_2_0,
    "Link": Link_v1_2_0,
    "List": List_v1_2_0,
    "Navigation": Navigation_v1_2_0,
    "Number": Number_v1_2_0,
    "Row": Row_v1_2_0,
    "Spacer": Spacer_v1_2_0,
    "Text": Text_v1_2_0,
};
