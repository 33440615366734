import React from 'react';

export default function WIML_v1_0_0(props: React.PropsWithChildren<any>): React.ReactElement {
    return (
        props.children
    );
}

// const WIML2: React.FC = (props: React.PropsWithChildren<any>) => {
//     return (
//         props.children
//     );
// }

// const a = WIML2({ children: null });

// function WIML3(props: React.PropsWithChildren<any>): React.ReactElement {
//     return (
//         props.children
//     );
// }
