import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from "react-redux";
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../form/input';
import { dateUtils, arrayUtils } from '@wip/common';
import { saveFile, updatePostAbout, updatePostAssets, updatePostSchedule } from '@wip/common/event-store/website';

const PostDetail = (props) => {
    const dispatch = useDispatch();

    function uploadImage(file) {
        const workPromise = dispatch(saveFile(file)).then(action => action.payload);

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: uploadImage };

        return retVal;
    }, [dispatch]);

    const x = props.post.schedule.publishDate && dateUtils.dateYYYYMMDD(new Date(props.post.schedule.publishDate));
    const schedule = { ...props.post.schedule, publishDate: x };
    const abc = { ...props.post, schedule };
    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: abc
    });

    const { ref: titleRef, ...titleRest } = register('about.title', { required: true });
    const { ref: tagsRef, ...tagsRest } = register('about.tags', { required: true });
    const { ref: publishDateRef, ...publishDateRest } = register('schedule.publishDate');

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, id, key, value) => {

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                id = event.target.dataset.id;
                key = event.target.name;

                if (event.target.type == 'checkbox') {
                    value = event.target.checked;

                } else {
                    value = event.target.value;
                }
            }

            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updatePostAbout({ id, key: newKey, value }));
            } else if (path == 'schedule') {
                dispatch(updatePostSchedule({ id, key: newKey, value }));
            }
        }
    };

    const handlePublishDateChangeEvent = async (e) => {
        // todo use onChange in regsiter handler - react hook form new version has been added to project but this needs to be fixed.
        // note onChange is handled in react-hook-form 7.18
        publishDateRest.onChange && await publishDateRest.onChange(e);

        const id = e.target.dataset.id;
        const key = e.target.name;
        const value = dateUtils.parseDate(e.target.value);
        handleChangeEvent(null, id, key, value);
    };

    const onCoverPhotoChange = e => {
        const id = e.target.dataset.id;
        const coverPhotoFile = e.target.files[0];
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(saveFile(coverPhotoFile)).then(action => {
            const downloadUrl = action.payload;

            dispatch(updatePostAssets({ id, key: 'coverPhoto', value: downloadUrl }));
            e.target.value = "";
        });
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleRichEditorChangeEvent = (id, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updatePostAbout({ id, key: newKey, value }));
            }
        }
    };

    const bookViewElelement = (
        <Form>
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" defaultChecked={props.post.schedule.draft} name="schedule.draft" id="schedule.draft" data-id={props.post.id} onChange={handleChangeEvent} /> {' '}
                    Draft?
                </Label>
            </FormGroup>
            <FormGroup>
                <Label for="about.title">Post Name</Label>
                <ReactstrapInputBlurChangeDetector id="about.title" invalid={!!errors.firstName} type="text" data-id={props.post.id} {...titleRest} innerRef={titleRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="schedule.publishDate">Post Date</Label>
                <ReactstrapInputBlurChangeDetector id="schedule.publishDate" type="date" data-id={props.post.id} {...publishDateRest} innerRef={publishDateRef} onChange={handlePublishDateChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="assets.coverPhoto">Post Photo</Label>
                <Input id="assets.coverPhoto" type="file" accept="image/*" data-id={props.post.id} onChange={onCoverPhotoChange} />
                <img src={props.post.assets.coverPhoto} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="about.content">Post Content</Label>
                <RichTextEditor defaultValue={props.post.about.content} onDiff={handleRichEditorChangeEvent.bind(null, props.post.id, 'about.content')} {...richTextConfig} />
            </FormGroup>
            <FormGroup>
                <Label for="about.tags">Tags (one per line)</Label>
                <ReactstrapInputBlurChangeDetector id="about.tags" invalid={!!errors.tags} type="textarea" data-id={props.post.id} {...tagsRest} innerRef={tagsRef} onDiff={handleChangeEvent} />
            </FormGroup>
        </Form>);

    return bookViewElelement;
}

export default PostDetail;
