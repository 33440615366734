import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createGallery, deleteGallery } from '@wip/common/event-store/website';

const GalleryList = (props) => {
    const dispatch = useDispatch();

    function addGallery(e) {
        // 
        // const x = createGallery;
        // window.x = x;
        // const b = x();
        // const c = b;
        // dispatch(c);

        dispatch(createGallery());
    }

    function removeGallery(id, e) {
        dispatch(deleteGallery({ id }));
    }

    function selectGallery(id, e) {
        props.onSelectGallery(id);
    }

    const productList = props.websiteData.galleries.map(g => (
        <ListGroupItem key={g.id}>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="link" onClick={selectGallery.bind(null, g.id)}>{g.about.title}</Button>

                <Button color="link" onClick={removeGallery.bind(null, g.id)}><FontAwesomeIcon icon={faTrash} /> Remove</Button>
            </div>

        </ListGroupItem>
    ));
    return (
        <Form>
            <Button outline color="primary" onClick={addGallery}>
                <FontAwesomeIcon icon={faPlus} /> Add New Gallery
            </Button>
            <ListGroup>
                {productList}
            </ListGroup>
        </Form>
    );
}

export default GalleryList;
