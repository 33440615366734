import { DataClass } from "../lib/object-utils";

export default class Event extends DataClass {
    constructor(customPageId, data) {
        super(data);

        this.id = customPageId;

        this.about = this.about || {};
        this.schedule = this.schedule || {};
        this.location = this.location || {};

        const eventDate = this.schedule.date;
        if(eventDate) {
            this.schedule.date = new Date(eventDate);
        }
    }
}
