// from help crunch dashboard: The current version of your widget is outdated and is soon going to lose functionality and support. Please update to the most recent version as soon as possible. See how to update.
export function getHelpCrunchScript_deprecated(helpCrunchId) {
  const retVal = `
    (function(w,d){
        w.HelpCrunch=function(){w.HelpCrunch.q.push(arguments)};w.HelpCrunch.q=[];
        function r(){var s=document.createElement('script');s.async=1;s.type='text/javascript';s.src='https://widget.helpcrunch.com/';(d.body||d.head).appendChild(s);}
        // scott changed this because document.readyState is already complete // if(w.attachEvent){w.attachEvent('DOMContentLoaded',r)}else{w.addEventListener('DOMContentLoaded',r,false)}
        r();
      })(window, document);
      
    HelpCrunch('init', 'wildink', {
        applicationId: 1,
        applicationSecret: '${helpCrunchId}'
    });

    HelpCrunch('showChatWidget');`;

  return retVal;
}


export function getHelpCrunchScript(props) {
  const user = props.userData;
  if (!user) throw new Error('user is required');
  const phone = user.phoneNumber ? `'${user.phoneNumber}'` : 'null';
  const wimlVersion = props.websiteData.style.wiml?.themeData?.meta?.version ? `'${props.websiteData.style.wiml.themeData.meta.version}'` : 'null';

  // https://docs.helpcrunch.com/en/developers/user-authentication-mode
  const retVal = `
  window.helpcrunchSettings = {
    organization: 'wildink',
    appId: '0e5d9fe2-a6ff-4bbb-857d-889f7d8bd70a',
    user: {
      user_id: '${user.uid}',
      name: '${user.displayName}',
      email: '${user.email}',
      phone: ${phone},
      custom_data: {
        admin_url: 'https://app.wildinkpages.com/manage/${props.websiteData.meta.slug}',
        website_version: '${props.websiteData.meta.appVersion}',
        wiml_version: ${wimlVersion},
      }
    },
  };

  (function(w,d){var hS=w.helpcrunchSettings;if(!hS||!hS.organization){return;}var widgetSrc='https://'+hS.organization+'.widget.helpcrunch.com/';w.HelpCrunch=function(){w.HelpCrunch.q.push(arguments)};w.HelpCrunch.q=[];function r(){if (d.querySelector('script[src="' + widgetSrc + '"')) { return; }var s=d.createElement('script');s.async=1;s.type='text/javascript';s.src=widgetSrc;(d.body||d.head).appendChild(s);}if(d.readyState === 'complete'||hS.loadImmediately){r();} else if(w.attachEvent){w.attachEvent('onload',r)}else{w.addEventListener('load',r,false)}})(window, document)
`;

  return retVal;
}

export function getFreshchatWidgetLoaderScript(props) {
  // https://wildink-org.myfreshworks.com/crm/messaging/a/898423032106057/settings/messenger/all/web/widget/default?activeTabName=custom-deploy
  const retVal = <script
    src='//fw-cdn.com/11880845/4462830.js'
    chat='true'>
  </script>

  return retVal;
}



export function getFreshchatWidgetUserScript(props) {
  // https://developers.freshchat.com/web-sdk/v2/#user-identification
  const user = props.userData;
  if (!user) throw new Error('user is required');
  const phone = user.phoneNumber ? `'${user.phoneNumber}'` : 'null';
  const wimlVersion = props.websiteData.style.wiml?.themeData?.meta?.version ? `'${props.websiteData.style.wiml.themeData.meta.version}'` : 'null';
  const name = user.displayName || user.email;
  const firstName = name.split(' ')[0];
  const lastName = name.split(' ').slice(1).join(' ');

  const loaderScript = `
window.fcSettings = {
  onInit: function() {
  debugger;
    // defined in apps/admin/src/components/layout.js  
    window.__wip_fs_init?.();

    window.fcWidget.setExternalId("${user.uid}");
    window.fcWidget.user.setFirstName("${firstName}");
    window.fcWidget.user.setLastName("${lastName}");
    window.fcWidget.user.setEmail("${user.email}");
    window.fcWidget.user.setPhone(${phone});
  }
}`

  /* consider adding above
  window.fcWidget.user.setProperties({
          admin_url: 'https://app.wildinkpages.com/manage/${props.websiteData.meta.slug}',
          website_version: '${props.websiteData.meta.appVersion}',
          wiml_version: ${wimlVersion},
      });
       */
  const retVal = <script>{loaderScript}</script>;

  return retVal;
}

export function getHelppierScript() {
  const retVal = `
window.help_company_key = '892273e50e38dbfa91b1e749';
window.helppier_app_id = 'xJ3NzM9Whb4qAgP3p';
var script = document.createElement('script');
script.setAttribute('id', 'helppierEmbed');
script.setAttribute('defer', '');
script.setAttribute('src', 'https://km.helppier.com/widget/js/start.js?help_company_key=' + window.help_company_key);
document.head.appendChild(script);
`;

  return retVal;
}
