import React, { } from 'react';
import { getComponentClassName_v1_2_0 } from "../../compiler/components";
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { V1_2_0_ComponentWrapper } from '../utils/component-wrapper';
import _ from 'lodash';
import { getChildComponentPropertyValueSiteData_v1_2_0 } from '../../../site-data/retrieval';
import { WimlNumber_v1_0_0, _renderWimlNumber_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/number';
import { WimlDate_v1_0_0, _renderWimlDate_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/date';

// note: before versionion, we called Number_*** WimlNumber to avoid conflict with the built-in Number type
// but now that we version, we can drop the Wiml prefix
export function Date_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    let content = getChildComponentPropertyValueSiteData_v1_2_0(props, 'date') || props.children as string;
    const className = getComponentClassName_v1_2_0(props, 'wiml-date');

    const dateValContent = _renderWimlDate_v1_0_0(content);

    const retVal = <span className={className}>
        <V1_2_0_ComponentWrapper {...props} >
            {dateValContent}
        </V1_2_0_ComponentWrapper>
    </span>

    return retVal;
}

Date_v1_2_0.inputProps = {
    ...WimlDate_v1_0_0.inputProps
};
