import _ from 'lodash';
import Settings_v1_0_0 from '../v1.0.0/settings-v1.0.0';
import Style_v1_3_0 from './style-v1.3.0';
import { ThemeModificationImageInspiration_v1_3_0 } from './image-style-inspiration-v1.3.0';

export default function Settings_v1_3_0(props: React.PropsWithChildren<any>) {
  return <>
    {/* <ThemeModificationImageInspiration_v1_3_0 {...props} /> */}
    <Settings_v1_0_0 {...props} />
    <StyleSelector_v1_3_0 {...props} />
  </>;
}
Settings_v1_3_0.sidebarTab = { id: 'dashboard', name: 'Dashboard' };



function StyleSelector_v1_3_0(props: React.PropsWithChildren<any>) {
  return <Style_v1_3_0 {...props} />;
  const styleOptions = [
    { value: 'light', label: 'Light' },
    { value: 'dark', label: 'Dark' },
  ];
  const selectedStyle = props.websiteData.style || 'light';
  function handleStyleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange('style', event.target.value);
  }
  return (
    <section className="style-selector mb-5">
      <h6 className="border-bottom mt-3">Style Selector</h6>
      <label htmlFor="style">Select a style:</label>
      <select id="style" value={selectedStyle} onChange={handleStyleChange}>
        {styleOptions.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </section>
  );
}
