import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createLandingPage, deleteLandingPage } from '@wip/common/event-store/website';

const LandingPageList = (props) => {
    const dispatch = useDispatch();

    function addLandingPage(e) {
        dispatch(createLandingPage());
    }

    function removeLandingPage(id, e) {
        dispatch(deleteLandingPage({ id }));
    }

    function selectLandingPage(id, e) {
        props.onSelectLandingPage(id);
    }

    const landingPageList = props.websiteData.landingPages.map(landingPage => (
        <ListGroupItem key={landingPage.id}>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="link" onClick={selectLandingPage.bind(null, landingPage.id)}>{landingPage.about.title}</Button>

                <Button color="link" onClick={removeLandingPage.bind(null, landingPage.id)}><FontAwesomeIcon icon={faTrash} /> Remove</Button>
            </div>

        </ListGroupItem>
    ));
    return (
        <Form>
            <Button outline color="primary" onClick={addLandingPage}>
                <FontAwesomeIcon icon={faPlus} /> Add New Landing Page
            </Button>
            <ListGroup>
                {landingPageList}
            </ListGroup>
        </Form>
    );
}

export default LandingPageList;
