import React from 'react';
import humps from "humps";

import { getComponentClassName_v1_0_0 } from '../render-components';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";

const responsizeAlignmentLookupClassNames = {
    'xl': {
        'xl': 'py-xl-5',
        'lg': 'py-xl-4',
        'md': 'py-xl-3',
        'sm': 'py-xl-2',
        'xs': 'py-xl-1'
    },
    'lg': {
        'xl': 'py-lg-5',
        'lg': 'py-lg-4',
        'md': 'py-lg-3',
        'sm': 'py-lg-2',
        'xs': 'py-lg-1'
    },
    'md': {
        'xl': 'py-md-5',
        'lg': 'py-md-4',
        'md': 'py-md-3',
        'sm': 'py-md-2',
        'xs': 'py-md-1'
    },
    'sm': {
        'xl': 'py-sm-5',
        'lg': 'py-sm-4',
        'md': 'py-sm-3',
        'sm': 'py-sm-2',
        'xs': 'py-sm-1'
    },
    '': {
        'xl': 'py-5',
        'lg': 'py-4',
        'md': 'py-3',
        'sm': 'py-2',
        'xs': 'py-1'
    }
} as const;


type SpacerSizeLookupClassNamesKey = keyof typeof responsizeAlignmentLookupClassNames;
type PositionKey = keyof typeof responsizeAlignmentLookupClassNames['']


const defaultSpacerClass = responsizeAlignmentLookupClassNames['']['md'];

const alignmentLookupClassNames = Object.keys(responsizeAlignmentLookupClassNames) as SpacerSizeLookupClassNamesKey[];
const camelizedPropNames = alignmentLookupClassNames.reduce((acc, size) => {
    const alignPropName = size ? `size-${size}` : 'size';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[size] = camelizedPropName;
    return acc;
}, {} as Record<SpacerSizeLookupClassNamesKey, string>);

export function Spacer_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    const classNames = alignmentLookupClassNames.map((size) => {
        const camelizedPropName = camelizedPropNames[size];
        const propValue = props[camelizedPropName] as PositionKey;
        if (propValue) {
            return [
                'spacer',
                responsizeAlignmentLookupClassNames[size][propValue]
            ];
        }
    }).filter(Boolean);

    if (classNames.length === 0) {
        classNames.push(['spacer', defaultSpacerClass]);
    }

    const className = classNames.flat().filter(Boolean).join(' ');
    // const className = getComponentClassName(props, 'spacer', props.size != null && `spacer-${props.size}`);

    return <br className={className} />;
}
