import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { css } from "cheerio/lib/api/css";
import humps from "humps";
import React from "react";
import { breakpoints_v1_1_0 } from "../utils/breakpoints";
// unit in pixels, not rem
const responsizeHorizontalSizeLookupClassNames = {
    '': {
        '5': 'wiml-spacer-x-5',
        '4': 'wiml-spacer-x-4',
        '3': 'wiml-spacer-x-3',
        '2': 'wiml-spacer-x-2',
        '1': 'wiml-spacer-x-1',
    },
    'lg': {
        '5': 'wiml-lg-spacer-x-5',
        '4': 'wiml-lg-spacer-x-4',
        '3': 'wiml-lg-spacer-x-3',
        '2': 'wiml-lg-spacer-x-2',
        '1': 'wiml-lg-spacer-x-1',
    },
    'md': {
        '5': 'wiml-md-spacer-x-5',
        '4': 'wiml-md-spacer-x-4',
        '3': 'wiml-md-spacer-x-3',
        '2': 'wiml-md-spacer-x-2',
        '1': 'wiml-md-spacer-x-1',
    },
    'sm': {
        '5': 'wiml-sm-spacer-x-5',
        '4': 'wiml-sm-spacer-x-4',
        '3': 'wiml-sm-spacer-x-3',
        '2': 'wiml-sm-spacer-x-2',
        '1': 'wiml-sm-spacer-x-1',
    },
    'xs': {
        '5': 'wiml-xs-spacer-x-5',
        '4': 'wiml-xs-spacer-x-4',
        '3': 'wiml-xs-spacer-x-3',
        '2': 'wiml-xs-spacer-x-2',
        '1': 'wiml-xs-spacer-x-1',

    }
} as const;


type HorizontalSizeLookupClassNamesKey = keyof typeof responsizeHorizontalSizeLookupClassNames;
type HorizontalSizePositionKey = keyof typeof responsizeHorizontalSizeLookupClassNames['']


const defaultHorizontalSizeClass = responsizeHorizontalSizeLookupClassNames['']['3'];

const horizontalSizeLookupClassNames = Object.keys(responsizeHorizontalSizeLookupClassNames) as HorizontalSizeLookupClassNamesKey[];
const horizontalSizeCamelizedPropNames = horizontalSizeLookupClassNames.reduce((acc, size) => {
    const alignPropName = size ? `size-${size}` : 'size';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[size] = camelizedPropName;
    return acc;
}, {} as Record<HorizontalSizeLookupClassNamesKey, string>);


const responsizeVerticalSizeLookupClassNames = {
    '': {
        '5': 'wiml-spacer-y-5',
        '4': 'wiml-spacer-y-4',
        '3': 'wiml-spacer-y-3',
        '2': 'wiml-spacer-y-2',
        '1': 'wiml-spacer-y-1',
    },
    'lg': {
        '5': 'wiml-lg-spacer-y-5',
        '4': 'wiml-lg-spacer-y-4',
        '3': 'wiml-lg-spacer-y-3',
        '2': 'wiml-lg-spacer-y-2',
        '1': 'wiml-lg-spacer-y-1',
    },
    'md': {
        '5': 'wiml-md-spacer-y-5',
        '4': 'wiml-md-spacer-y-4',
        '3': 'wiml-md-spacer-y-3',
        '2': 'wiml-md-spacer-y-2',
        '1': 'wiml-md-spacer-y-1',
    },
    'sm': {
        '5': 'wiml-sm-spacer-y-5',
        '4': 'wiml-sm-spacer-y-4',
        '3': 'wiml-sm-spacer-y-3',
        '2': 'wiml-sm-spacer-y-2',
        '1': 'wiml-sm-spacer-y-1',
    },
    'xs': {
        '5': 'wiml-xs-spacer-y-5',
        '4': 'wiml-xs-spacer-y-4',
        '3': 'wiml-xs-spacer-y-3',
        '2': 'wiml-xs-spacer-y-2',
        '1': 'wiml-xs-spacer-y-1',

    }
} as const;



const defaultVerticalSizeClass = responsizeVerticalSizeLookupClassNames['']['3'];

const verticalSizeLookupClassNames = Object.keys(responsizeVerticalSizeLookupClassNames) as HorizontalSizeLookupClassNamesKey[];
const verticalSizeCamelizedPropNames = verticalSizeLookupClassNames.reduce((acc, size) => {
    const alignPropName = size ? `size-${size}` : 'size';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[size] = camelizedPropName;
    return acc;
}, {} as Record<HorizontalSizeLookupClassNamesKey, string>);


export function Spacer_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    const className = _getSpacerClassNames_v1_1_0(props);
    // const className = getComponentClassName(props, 'hr', props.size != null && `hr-${props.size}`);

    return <br className={className} />;
}
export function _getSpacerClassNames_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    if (props.horizontal != null) {
        const classNames = horizontalSizeLookupClassNames.map((size) => {
            const camelizedPropName = horizontalSizeCamelizedPropNames[size];
            const propValue = props[camelizedPropName] as HorizontalSizePositionKey;
            if (propValue) {
                return [
                    // size ? `wiml-${size}-spacer-x` : 'wiml-spacer-x',
                    'wiml-spacer-x',
                    responsizeHorizontalSizeLookupClassNames[size][propValue]
                ];
            }
        }).filter(Boolean);

        if (classNames.length === 0) {
            classNames.push(['wiml-spacer-x', defaultHorizontalSizeClass]);
        }

        const dedupedClassNames = [...new Set(classNames.flat().filter(Boolean))];
        const className = dedupedClassNames.join(' ');
        return className;
    } else {
        const classNames = verticalSizeLookupClassNames.map((size) => {
            const camelizedPropName = verticalSizeCamelizedPropNames[size];
            const propValue = props[camelizedPropName] as HorizontalSizePositionKey;
            if (propValue) {
                return [
                    // size ? `wiml-${size}-spacer-y` : 'wiml-spacer-y', // todo make this specific size e.g. alignment or wrap -- update no: given it's a spacer, no need
                    'wiml-spacer-y',
                    responsizeVerticalSizeLookupClassNames[size][propValue]
                ];
            }
        }).filter(Boolean);

        if (classNames.length === 0) {
            classNames.push(['wiml-spacer-y', defaultVerticalSizeClass]);
        }

        const dedupedClassNames = [...new Set(classNames.flat().filter(Boolean))];
        const className = dedupedClassNames.join(' ');
        return className;
    }
}
