import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createPost, deletePost, saveFile } from '@wip/common/event-store/website';
import { extractPostContent, fetchPostAsset, fetchBlog } from '@wip/common/lib/website-import';
import { dateYYYYMMDD } from "@wip/common/lib/date-utils";
import { getFilenameFromUrl, getExtensionFromFilename } from "@wip/common/lib/file-utils";
import { fetcher } from "@wip/common/lib/data-utils";
import { convertToSlug, onlyAlphaNumeric } from "@wip/common/lib/text-utils";
import TurndownService from 'turndown';

const apiUrl = process.env.REACT_APP_API_URL;
const commentApiUrl = process.env.REACT_APP_COMMENT_API;
const currentEnv = process.env.NODE_ENV;

let isDebugging = false;
if (currentEnv == 'development') {
    isDebugging = true;
}

const sliceVal = isDebugging ? 1 : undefined;

const PostList = (props) => {
    const dispatch = useDispatch();

    const initialState = {
        isImportingBlog: false,
        isImportComplete: false,
        importingBlogStatus: '',
    };
    const [state, setState] = useState(initialState);

    const handleStateChange = (name, value) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    function addPost(e) {
        dispatch(createPost());
    }

    function removePost(id, e) {
        dispatch(deletePost({ id }));
    }

    function selectPost(id, e) {
        props.onSelectPost(id);
    }

    async function test() {
        const feedUrl = "https://rmnewman.wordpress.com/2021/04/27/2021-a-progressive-poem/feed";
        // FOR SOME REASON I HAVE NO IDEA WHY, THE WWW. VERSION ONLY RETURNS 10 ITEMS WTH?!
        // i noticed with the www. version, postman was hanging although the chrome browser was not
        // i have sinced removed www.authorsite.com from hosts file
        // const proxyFeedUrl = "http://www.authorsite.com:3000/api/proxy?url=https://rmnewman.wordpress.com/2021/04/27/2021-a-progressive-poem/feed";
        const proxyFeedUrl = "http://authorsite.com:3000/api/proxy?url=https://rmnewman.wordpress.com/2021/04/27/2021-a-progressive-poem/feed";

        const res = await fetcher(feedUrl, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.

        }, { getJson: false });
        const text = await res.text();
        const proxyRes = await fetcher(proxyFeedUrl, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.

        }, { getJson: false });
        const proxyText = await proxyRes.text();

        console.log('regular')
        console.log(text.match(/<item>/g))
        console.log()
        console.log()
        console.log()
        console.log()
        console.log('proxyText')
        console.log(proxyText.match(/<item>/g))
    }

    async function startBlogImport(e) {
        const turndownService = new TurndownService()

        if (!props.websiteData.meta.customDomain) {
            if (!confirm('Your custom domain has not been setup yet. Are you sure you want to import now? Some blog data may be lost when you upgrade.')) {
                // todo move a conditional var up the scope isntead of a return here.
                return;
            }
        }

        let formattedUrl;
        const url = prompt('What is your website URL?');

        if (url) {
            // if someone tyhpes in "https://rmnewman.wordpress.com/wp-admin/options-reading.php" it will just be https://rmnewman.wordpress.com
            formattedUrl = new URL(url).origin;
            // things i've learned about cors.
            // posting works if not json and if just simple form
            // ending slash is important - a 200 must be returned but if 301 or 308 etc, that will fail
            // https and http must be same
            // adding a custom header will trigger a preflight. e.g. Request header field content-type is not allowed by Access-Control-Allow-Headers in preflight response.

            // was getting this error:
            // Response for preflight is invalid (redirect)
            // https://stackoverflow.com/questions/42168773/how-to-resolve-preflight-is-invalid-redirect-or-redirect-is-not-allowed-for
            // MAKE SURE URL ENDS WITH /  ---- OR DOES NOT END WITH SLASH?? 
            // removing ending slash from proxy/ fixes CORS.

            //  const proxiedUrl = `${apiUrl}/proxy/?url=${url}/`;
            const urlNoWww = formattedUrl.replace('www.', '');
            const srcDomain = new URL(urlNoWww).hostname.split('.')[0];

            // const proxiedUrl  = 'http://authorsite.com:3000/api/proxy?url=https%3A%2F%2Fwww.wildinkmarketing.com%2F';
            // const proxiedUrl  = 'http://authorsite.com:3000/api/proxy?url=https://www.wildinkmarketing.com';
            // const proxiedUrl2  = '//www.authorsite.com:3000/api/proxy?url=https://www.wildinkmarketing.com//';

            try {
                let exportedPosts;
                let exportedComments;
                let exportedAssets;
                let websiteMetadata;
                let websiteType;

                const fetchBlogWork = new Promise((resolve, reject) => {

                    let counter = 1;

                    try {
                        const onUpdate = news => {

                            if (news.message === 'blog:import:begin') {
                                handleStateChange('isImportingBlog', true);
                                handleStateChange('importingBlogStatus', `Beginning ${formattedUrl}.`);
                            } else if (news.message === 'blog:import:complete') {
                                handleStateChange('importingBlogStatus', `${news.data.length} posts ready for import.`);
                                exportedPosts = news.data.posts.slice(0, sliceVal);
                                exportedComments = news.data.comments;
                                exportedAssets = news.data.assets.slice(0, sliceVal);
                                websiteMetadata = news.data.meta;
                                websiteType = websiteMetadata.websiteType;

                                resolve();
                            } else if (news.message === 'blog:post:found') {
                                const { title } = news.data;
                                const pubDate = dateYYYYMMDD(new Date(news.data.pubDate));

                                handleStateChange('importingBlogStatus', `Found post #${counter}: ${title}. Publish date: ${pubDate}.`);

                                counter++;
                            } else if (news.message === 'blog:type:identified') {
                                handleStateChange('importingBlogStatus', `Identifying website type: ${news.data}.`);
                            } else {
                                handleStateChange('importingBlogStatus', news.message);
                            }
                        };

                        fetchBlog(formattedUrl, srcDomain, onUpdate, true, isDebugging);
                    } catch (e) {
                        reject(e);
                    }
                });

                try {
                    await fetchBlogWork;
                } catch (e) {
                    console.error(`Error fetching posts: ${e}`);
                    handleStateChange('importingBlogStatus', "Error importing. Please email us at support@wildinkmarketing.com.");
                    handleStateChange('isImportComplete', true);
                }

                handleStateChange('importingBlogStatus', "Importing Comments…");
                const commentsByPost = Object.keys(exportedComments);
                for (let postGuid of commentsByPost) {
                    const post = exportedPosts.find(p => p.guid == postGuid);

                    if (!post) {
                        if (isDebugging) {
                            // during debug mode, contineu
                            continue;
                        }
                        else {
                            throw new Error(`post does not exist for post: ${postGuid}.`);
                        }
                    }

                    handleStateChange('importingBlogStatus', `Importing comments for ${post.link}.`);

                    const slugTitle = convertToSlug(post.title);
                    const blogUrl = `/blog/${slugTitle}`;

                    // sorting comments by oldest to newest ensures that parent comments exist before child comments.
                    const postComments = exportedComments[postGuid];
                    const sortedComments = postComments.sort((a, b) => a.isoDate ? new Date(a.isoDate) - new Date(b.isoDate) : -1)

                    const commentToHexLookup = {};

                    const commentsLength = sortedComments.length;
                    const counter = 1;

                    for (let comment of sortedComments.slice(0, sliceVal)) {

                        handleStateChange('importingBlogStatus', `Importing ${post.link} comments. \n\ncomment #${counter} of ${commentsLength}.`);

                        const commentId = comment.guid.substring(comment.guid.lastIndexOf('-') + 1);

                        let parentId = 'root';

                        const commentContent = comment['content:encoded'] || comment['content'];
                        let content = turndownService.turndown(commentContent);

                        const pubDate = comment.pubDate.slice(0, -6); // remove the " +0000"

                        if (comment.contentSnippet.toLowerCase().startsWith('in reply to <a href=')) {
                            // remove in reply to
                            const extractedParentId = comment.contentSnippet.match(/#comment-(\d+)/gi)[0].split('-')[1];
                            parentId = commentToHexLookup[extractedParentId];
                            // remove newline
                            content = comment.content.substring(comment.content.indexOf('\n') + 2);
                        }

                        // the 4 spaces make the makrdown generate a <pre> tag. so it looks system-generated.
                        content = `${content}\n\n    Originally posted by ${comment.creator} on ${pubDate}.`;

                        const data = {
                            "commenterToken": "anonymous",
                            "domain": `www.${props.websiteData.meta.customDomain}`,
                            "path": blogUrl,
                            "parentHex": parentId,

                            // "html": comment['content:encoded']
                            // "html": comment.content
                            // NOTE: markdown is required -- https://gitlab.com/commento/commento/-/blob/master/api/comment_new.go#L13
                            // "markdown": content,
                            "markdown": content,

                            creationDate: comment.isoDate
                        }

                        // const POSTWebhookData = Object.entries(data).map(([key, value]) => {
                        //     return encodeURIComponent(key) + '=' + encodeURIComponent(value);
                        // }).join('&');

                        // const POSTWebhookData = encodeURIComponent(JSON.stringify(data));
                        const POSTWebhookData = (JSON.stringify(data));

                        // commento sends a 403 on OPTIONS - their frontend does not preflight with form encoding
                        // this content type prevents preflight problem to zapier:
                        // https://zapier.com/help/doc/common-problems-with-webhooks#posting-json-from-web-browser-access-control-allow-headers-in-preflight-response-error
                        // why? https://stackoverflow.com/questions/39725955/why-is-there-no-preflight-in-cors-for-post-requests-with-standard-content-type
                        // setting the body: https://github.com/github/fetch/issues/263#issuecomment-209548790
                        const POSTOptions = {
                            method: 'POST', // *GET, POST, PUT, DELETE, etc.
                            headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8;" },
                            body: POSTWebhookData
                        };

                        try {
                            const newCommentApiUrl = `${commentApiUrl}/api/comment/new`;
                            const res = await fetcher(newCommentApiUrl, POSTOptions, { getJson: true });

                            if (!res.success) {
                                throw new Error(`Error importing comment: ${res.message}.`);
                            }

                            const commentHex = res.commentHex;

                            commentToHexLookup[commentId] = commentHex
                        } catch (e) {
                            console.log("Error saving order data:", e);
                            const rethrow = new Error('Error saving order data: ' + e);
                            rethrow.stack = e.stack;
                            throw rethrow;
                        }

                        counter++;
                    }
                }



                handleStateChange('importingBlogStatus', "Importing Media…");

                // create kv store of {originalAssetUrl: importedAssetUrl};
                const importedAssetMapping = {};
debugger
                if (!isDebugging) {
                    let assetCounter = 1;

                    for (let assetUrl of exportedAssets.slice(0, sliceVal)) {
                        const filename = getFilenameFromUrl(assetUrl);
                        const extension = getExtensionFromFilename(filename);
                        const contentType = { type: `image/${extension}` };

                        handleStateChange('importingBlogStatus', `Importing media #${assetCounter} of ${exportedAssets.length}: ${filename}.`);

                        // MAKE SURE URL ENDS WITH /  ---- OR DOES NOT END WITH SLASH?? 
                        // removing ending slash from proxy/ fixes CORS.
                        // https://mly50ysjldnr.i.optimole.com/X3ALMos-yJ5mmz5l/w:1024/h:768/q:auto/https://www.wildinkmarketing.com/wp-content/uploads/2018/10/IMG_4828-e1539643020913.jpg --> IF THIS HAS ENDING SLASH, IT WILL NOT WORK
                        const proxiedUrl = `${apiUrl}/proxy?url=${assetUrl}`; // IMPORTATN - DO NOT HAVE ENDING SLASH - THAT CAUSES FAILURES AS MENTIONED ABOVE
                        const asset = await fetchPostAsset(proxiedUrl);
                        const file = new File([asset], filename, contentType);

                        // upload asset 
                        // dispatch async thunks are promises
                        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
                        const action = await dispatch(saveFile(file));
                        const downloadUrl = action.payload;
                        importedAssetMapping[assetUrl] = downloadUrl;

                        assetCounter++;
                    }
                }



                handleStateChange('importingBlogStatus', "Creating Posts…");
                // replace them
                // iterate through new posts. search/replace original with new imported asset url.
                let postCounter = 1;

                for (let post of exportedPosts.slice(0, sliceVal)) {
                    // TODO - CONVERT THIS TO A STANDARDIZED SCHEMA.
                    // E.G. WORDPRESS HAS CONTENT:ENCODED, OTHERS HAVE JUST `CONTENT`, ETC.
                    const postContent = extractPostContent(post['content:encoded'] || post['content']);
                    const postContentWithNewAssets = replaceOldAssetUrl(postContent, importedAssetMapping);

                    handleStateChange('importingBlogStatus', `Creating new post #${postCounter} of ${exportedPosts.length}: ${post.title}.`);

                    // humps will lowercase all keys as in tags: {"Website":true} --> becomes {"website":true}
                    // this just makes it explicit.
                    // const postCategories = post.categories.map(String.toLowerCase);
                    // https://stackoverflow.com/questions/23280974/why-doesnt-a-b-c-mapstring-prototype-tolowercase-call-work
                    const postCategories = post
                        ?.categories
                        // ?.map(c => c.toLowerCase())
                        // firebase Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"
                        // do not do -_ as that causes Range out of order in character class
                        // https://stackoverflow.com/questions/17727884/range-out-of-order-in-character-class-in-javascript

                        //TODO
                        // this person has 1 tag with mulitple commas in it https://issaflower.com/2020/05/26/109/
                        // INSPIRATION, POETRY. REBIRTH, MIND, SELF-LOVE, UNCATEGORIZED
                        // split on commas
                        // .map(c => onlyAlphaNumeric(c, '_\\s-'))
                        .filter(c => c != 'uncategorized')
                        // TODO - we are passing tags in via a comma-delimited string cause … yeah
                        .join('\n');

                    const pubDate = post.pubDate.slice(0, -6); // remove the " +0000"
debugger
                    // handle cases where no blog title
                    const postData = {
                        about: { title: post.title || pubDate, content: postContentWithNewAssets, tags: postCategories },
                        schedule: { publishDate: post.isoDate },
                        // tags: postCategories
                    };

                    dispatch(createPost(postData));

                    postCounter++;
                }

                if (websiteType == 'wix') {
                    handleStateChange('importingBlogStatus', `Done! ${exportedPosts.length} posts successfully imported! This page will now refresh. NOTE: Wix only allows us to import 20 posts at a time. Go to your Wix blog and mark the most recent 20 posts as drafts and re-import your blog.`);
                } else {
                    handleStateChange('importingBlogStatus', `Done! ${exportedPosts.length} posts successfully imported! This page will now refresh.`);
                }

                handleStateChange('isImportComplete', true);

            } catch (e) {
                console.error(`Error import blog: ${e}`);
                handleStateChange('importingBlogStatus', "Error importing.  Please email us at support@wildinkmarketing.com.");
                handleStateChange('isImportComplete', true);
            }
        }
    }

    function close(e) {
        // todo - for some reason, duplicates of posts and tags show up as integers like 0,1,2,3,4 instead of tag name. 
        // a refresh seems to fix it.
        window.location.reload();
        // setState(initialState);
    }

    const postList = props.websiteData.posts.map(p => (
        <ListGroupItem key={p.id}>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="link" onClick={selectPost.bind(null, p.id)}>{p.about.title}</Button>

                <Button color="link" onClick={removePost.bind(null, p.id)}><FontAwesomeIcon icon={faTrash} /> Remove</Button>
            </div>

        </ListGroupItem>
    ));

    const modalHeaderProps = {};
    if (state.isImportComplete) {
        modalHeaderProps.toggle = close;
    }

    return (
        <Form>
            <div className="d-flex">
                <Button outline color="primary" onClick={addPost}>
                    <FontAwesomeIcon icon={faPlus} /> Add New Post
                </Button>
                <Button outline color="secondary" onClick={startBlogImport}>
                    <FontAwesomeIcon icon={faUpload} /> Import Blog
                </Button>
            </div>
            <ListGroup>
                {postList}
            </ListGroup>

            <Modal isOpen={state.isImportingBlog} backdrop={false}>
                <ModalHeader {...modalHeaderProps}>Blog Post Import</ModalHeader>
                <ModalBody>
                    {state.importingBlogStatus}
                </ModalBody>
                <ModalFooter>
                    {state.isImportComplete && <Button color="primary" onClick={close}>Ok</Button>}
                </ModalFooter>
            </Modal>
        </Form>
    );
}

export default PostList;

function replaceOldAssetUrl(postContent, importedAssetMapping) {
    let retVal = postContent;

    Object.entries(importedAssetMapping).forEach(([key, value]) => {
        retVal = retVal.replace(key, value);
    });

    return retVal;
}
