import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createNavigationGroup, deleteNavigationGroup } from '@wip/common/event-store/website';

const NavigationGroupList = (props) => {
    const dispatch = useDispatch();

    function addNavigationGroup(e) {
        // 
        // const x = createNavigationGroup;
        // window.x = x;
        // const b = x();
        // const c = b;
        // dispatch(c);

        dispatch(createNavigationGroup());
    }

    function removeNavigationGroup(id, e) {
        dispatch(deleteNavigationGroup({ id }));
    }

    function selectNavigationGroup(id, e) {
        props.onSelectNavigationGroup(id);
    }

    const pgList = props.websiteData.navigationGroups.map(pg => (
        <ListGroupItem key={pg.id}>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="link" onClick={selectNavigationGroup.bind(null, pg.id)}>{pg.about.title}</Button>

                <Button color="link" onClick={removeNavigationGroup.bind(null, pg.id)}><FontAwesomeIcon icon={faTrash} /> Remove</Button>
            </div>

        </ListGroupItem>
    ));

    return (
        <Form>
            <Button outline color="primary" onClick={addNavigationGroup}>
                <FontAwesomeIcon icon={faPlus} /> Add New Navigation Group
            </Button>
            <ListGroup>
                {pgList}
            </ListGroup>
        </Form>
    );
}

export default NavigationGroupList;
