import { DataClass } from "../lib/object-utils";

export default class Slide extends DataClass {
    constructor(slideId, data) {
        super(data);

        this.id = slideId;

        this.about = this.about || {};
        this.meta = this.meta || {};
        this.navigation = this.navigation || {};
        this.assets = this.assets || {};
    }
}
