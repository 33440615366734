import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBackward, faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createBook } from '@wip/common/event-store/website';;
import BookList from './book-list';
import BookDetail from './book-detail';
import Book from "@wip/common/domain/book";
import BookSeries from "@wip/common/domain/book-series";
import BookSeriesList from "./book-series-list";
import BookSeriesDetail from "./book-series-detail";

const CustomPages = (props) => {

    const [pageDetail, setPageDetail] = useState(null);

    let pageViewElelement;

    function doThing() {
        setPageDetail(null);
    }

    function setBookDetail(bookId) {
        setPageDetail({ type: 'book', id: bookId });
    }

    function setSeriesDetail(seriesId) {
        setPageDetail({ type: 'series', id: seriesId });
    }

    const pageDetailType = pageDetail?.type;
    const pageDetailId = pageDetail?.id;

    if (pageDetailType == 'book') {
        const bookData = props.websiteData.books.find(b => b.id == pageDetailId);
        const book = new Book(bookData.id, bookData);

        pageViewElelement = (
            <>
                <Button color="link" onClick={doThing}><FontAwesomeIcon icon={faArrowLeft} /> Back</Button>
                <BookDetail book={book} {...props} />
            </>
        );
    } else if (pageDetailType == 'series') {
        const bookData = props.websiteData.bookSeries.find(bs => bs.id == pageDetailId);
        const bookSeries = new BookSeries(bookData.id, bookData);

        pageViewElelement = (
            <>
                <Button color="link" onClick={doThing}><FontAwesomeIcon icon={faArrowLeft} /> Back</Button>
                <BookSeriesDetail bookSeries={bookSeries} onBookSelect={setBookDetail} {...props} />
            </>
        );
    }
    else {
        pageViewElelement = (
            <>
                <BookList {...props} onSelect={setBookDetail} />
                <div className="mt-5">
                    <h3>Book Series</h3>
                    <BookSeriesList {...props} onSelect={setSeriesDetail} />
                </div>
            </>
        );
    }

    return pageViewElelement;
}

export default CustomPages;
