import { DataClass } from "../lib/object-utils";


 export default class GalleryItem extends DataClass {
    constructor(galleryItemId, data) {
        super(data);

        this.id = galleryItemId;

        this.about = this.about || {};
        this.assets = this.assets || {};
        this.meta = this.meta || {};
    }
}
