export function getAllPagesForUser(customPages) {
    const defaultPages = [
        { key: '_about', value: 'About' },
        { key: '_books', value: 'Books' },
        { key: '_events', value: 'Events' },
        // { key: '_gallery', value: 'Gallery' },
    ];

    const customPagesMapped = customPages.map(cp => ({ key: cp.id, value: cp.about.title }));

    const pages = [...defaultPages, ...customPagesMapped];

    return pages;
}

// these pages do not start with _ because in firebase we treat them as keys and keys must satisfy certain requirements like no _ or -.
export function getAllCustomHtmlPagesForUser(websiteData) {
    const defaultPages = [
        { key: '*', value: 'All Pages' },
        { key: 'about', value: "About" },
        { key: 'blog', value: "Blog" },
        { key: 'books', value: "Books" },
        { key: 'contact', value: "Contact" },
        { key: 'footer', value: "Footer" },
        { key: 'home', value: "Home" },
    ];

    const customPages = websiteData.customPages;
    const customPagesMapped = customPages.map(cp => ({ key: cp.id, value: `Custom Page: ${cp.about.title}` }));

    const landingPages = websiteData.landingPages;
    const landingPagesMapped = landingPages.map(cp => ({ key: cp.id, value: `Landing Page: ${cp.about.title}` }));

    const books = websiteData.books;
    const booksMapped = books.map(cp => ({ key: cp.id, value: `Book: ${cp.about.title}` }));

    const pages = [...defaultPages, ...booksMapped, ...customPagesMapped, ...landingPagesMapped];

    return pages;
}
