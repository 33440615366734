import { _isTextNode_v1_0_0, _isListNode_v1_0_0, _isPageNode_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/nodes';
import { _getPageUrl_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/page-components';
import { Wiml_v1 } from '../../..';
import { __convertHtmlToCheerio_v1_1_0 } from '../../../v1.1.0/theme-data/compiler/nodes';

export function convertMarkupToXml_v1_3_0(markup: string) {
    let retVal = markup;

    retVal = _addXmlRootAttrsToWiml_v1_3_0(retVal);
    retVal = _addXmlIncompleteAttrsToWiml_v1_3_0(retVal);
    retVal = _escapeXmlCharsInWiml_v1_3_0(retVal);
    console.log('transformed', retVal)

    return retVal;
}

export function _addXmlRootAttrsToWiml_v1_3_0(markup: string) {
    // add xml attrs
    // root wiml tag regex
    const rootRegex = /^\s*<WIML\s*version\s*=\s*".*"/;
    const rootAttrs = `xmlns="http://www.example.com/wiml" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.example.com/wiml wiml.xsd"`
    const markupWithXmlRootAttrs = markup.replace(rootRegex, `$& ${rootAttrs}`);
    return markupWithXmlRootAttrs;
}

export function _addXmlIncompleteAttrsToWiml_v1_3_0(markup: string) {
    // add xml attrs
    // root wiml tag regex
    //@ts-ignore
    // const rootRegex = /<(?:Container|Heading|Link|List|Image).+?(bg-fixed|button|circle|slider|square|sub)(?!\s*=)/gs;
    // const rootRegex = /<(?:Container|Heading|Link|List|Image)[^>]*?(?<!=")(bg-fixed|button|circle|slider|square|sub)/g;
    // const rootRegex = /<(?:Container|Heading|Link|List|Image)[^>]*?(?<!=")(bg-fixed|button|circle|slider|square|sub)(?!\s*=|\s*")/g

    // const rootAttrs = `="true"`;
    // const markupWithXmlRootAttrs = markup.replace(rootRegex, (match, p1) => {
    //     return match.replace(p1, `${p1}${rootAttrs}`);
    // });
    // const markupWithXmlRootAttrstext = markup.matchAll(rootRegex).map(m => m[1]))
    const rootRegex = /\s(bg-fixed|button|circle|fluid|slider|square|sub)(?!\s*=\s*["'])(?=\s|>)/g;
    const rootAttrs = ` $1="true"`;
    const markupWithXmlRootAttrs = markup.replace(rootRegex, rootAttrs);
    return markupWithXmlRootAttrs;
}

export function _removeEmptyQuoteAttrsInWiml_v1_3_0(markup: string) {
    const emptyAttrsRegex = /\s(bg-fixed|button|circle|fluid|slider|square|sub)=["']"?["'](?=[\s>])/g;
    const emptyAttrsReplacement = ` $1`;
    const markupWithEmptyAttrsRemoved = markup.replace(emptyAttrsRegex, emptyAttrsReplacement);
    return markupWithEmptyAttrsRemoved;
}

export function getTagChildContents_v1_3_0(tag: string, markup: string) {
    const regex = new RegExp(`(?<=<${tag}[^>]*>\s*)(.|\n)*?(?=\s*<\/${tag}>)`, 'g');
    const contents = markup.match(regex)[0]?.trim();
    return contents;
}

export function _escapeXmlCharsInWiml_v1_3_0(markup: string) {
    // add xml attrs
    // root wiml tag regex
    // const rootRegex = /&(?!amp;|lt;|gt;|quot;|apos;)/g;

    // const escapedChars = markup.replace(rootRegex, match => {
    //     switch (match) {
    //         case '&': return '&amp;';
    //         case '<': return '&lt;';
    //         case '>': return '&gt;';
    //         case '"': return '&quot;';
    //         case "'": return '&apos;';
    //         default: return match;
    //     }
    // });

    // return escapedChars;


    const escapedChars = markup
        .replace(/&(?!amp;)/g, '&amp;')
        .replace(/—/g, '#8212;')
        .replace(/’/g, '#8217;');
    return escapedChars;
}



