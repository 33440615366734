import { DataClass } from "../lib/object-utils";
import GalleryItem from "./gallery-item";
import _ from 'lodash';


export default class Gallery extends DataClass {
    constructor(galleryId, data) {
        super(data);

        this.id = galleryId;

        this.about = this.about || {};
        this.meta = this.meta || {};

        this.items = (this.items || [])
            .map(item => {
                const { id, ...rest } = item;
                const gallery = new GalleryItem(id, rest);
                return gallery;
            });
        // .sort((a, b) => a.meta.order ? a.meta.order - b.meta.order : -1);
        this._sortItems();
    }

    addItem(galleryItem) {
        this.items.push(galleryItem);

        this._sortItems();
    }

    removeItem(galleryItemId) {
        this.items = this.items.filter(gi => gi.id !== galleryItemId);
        
        this._sortItems();
    }

    updateItemAbout(galleryItemId, key, value) {
        const item = this.items.find(gi => gi.id == galleryItemId);

        item.about[key] = value;
    }

    updateItemAssets(galleryItemId, key, value) {
        const item = this.items.find(gi => gi.id == galleryItemId);

        item.assets[key] = value;
    }

    updateItemMeta(galleryItemId, key, value) {
        const item = this.items.find(gi => gi.id == galleryItemId);

        item.meta[key] = value;
        this._sortItems();
    }

    _sortItems() {
        this.items = _.orderBy(this.items, ['meta.order'], ['asc']);
    }
}
