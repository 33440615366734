import { extractManualListItemOrder } from "./utils";

export function sortListItemsByDate(listItemArray: any[], key = 'date') {
    const retVal = listItemArray.sort((a, b) => {
        const aDate = a.components?.items?.[key]?.date?.data?.value;
        const bDate = b.components?.items?.[key]?.date?.data?.value;
        if (aDate && bDate) {
            return new Date(bDate).getTime() - new Date(aDate).getTime();
        } else if (aDate) {
            return -1;
        } else if (bDate) {
            return 1;
        } else {
            return 0;
        }
    });
    return retVal;
}

export function sortListItemsByManualOrder(filteredArray: any[], orderedIdsObj: any): any[] {
    const sortedArray = filteredArray.sort((a, b) => {
        const aIndex = orderedIdsObj[a.id];
        const bIndex = orderedIdsObj[b.id];
        if (aIndex == null) {
            return 1;
        } else if (bIndex == null) {
            return -1;
        } else {
            return aIndex - bIndex;
        }
    });
    return sortedArray;
}

// put into own v1.3.0
export function sortListItems(sortExpr: string, filteredArray: any[]) {
    let sortedArray = filteredArray;
    if (sortExpr == "{{current_item.components.items.date.date.data.value}}") {
        // sort array by checking if field is date and if so, sorting by date, otherwise set to end of array
        sortedArray = sortListItemsByDate(filteredArray);
    } else if (sortExpr == "{{current_item.components.items.date__published.date.data.value}}") {
        // sort array by checking if field is date and if so, sorting by date, otherwise set to end of array
        sortedArray = sortListItemsByDate(filteredArray, 'date__published');
    } else if (sortExpr == "{{current_item.components.items.date__start.date.data.value}}") {
        // sort array by checking if field is date and if so, sorting by date, otherwise set to end of array
        sortedArray = sortListItemsByDate(filteredArray, 'date__start');
    }
    /*BEGIN reversal */
    else if (sortExpr == "{{reverse(current_item.components.items.date.date.data.value)}}") {
        // sort array by checking if field is date and if so, sorting by date, otherwise set to end of array
        sortedArray = sortListItemsByDate(filteredArray).reverse();
    } else if (sortExpr == "{{reverse(current_item.components.items.date__published.date.data.value)}}") {
        // sort array by checking if field is date and if so, sorting by date, otherwise set to end of array
        sortedArray = sortListItemsByDate(filteredArray, 'date__published').reverse();
    } else if (sortExpr == "{{reverse(current_item.components.items.date__start.date.data.value)}}") {
        // sort array by checking if field is date and if so, sorting by date, otherwise set to end of array
        sortedArray = sortListItemsByDate(filteredArray, 'date__start').reverse();
    }
    /*BEGIN Manual */
    else if (sortExpr?.startsWith("{{manualOrder(current_item.id,")) {
        const orderedIdsObj = extractManualListItemOrder(sortExpr as string);

        sortedArray = sortListItemsByManualOrder(filteredArray, orderedIdsObj);
    }
    return sortedArray;
}
