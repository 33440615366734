import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createGalleryItem, deleteGalleryItem } from '@wip/common/event-store/website';

const GalleryItemList = (props) => {
    const dispatch = useDispatch();

    function addGalleryItem(galleryId, e) {
        dispatch(createGalleryItem({ galleryId }));
    }

    function removeGalleryItem(galleryId, id, e) {
        dispatch(deleteGalleryItem({ galleryId, id }));
    }

    function selectGalleryItem(id, e) {
        props.onSelectGalleryItem(id);
    }

    const productList = props.gallery.items.map(gi => (
        <ListGroupItem key={gi.id}>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="link" onClick={selectGalleryItem.bind(null, gi.id)}>{gi.about.title}</Button>

                <Button color="link" onClick={removeGalleryItem.bind(null, props.gallery.id, gi.id)}><FontAwesomeIcon icon={faTrash} /> Remove</Button>
            </div>

        </ListGroupItem>
    ));
    return (
        <Form>
            <Button outline color="primary" onClick={addGalleryItem.bind(null, props.gallery.id)}>
                <FontAwesomeIcon icon={faPlus} /> Add New Item to Gallery
            </Button>
            <ListGroup>
                {productList}
            </ListGroup>
        </Form>
    );
}

export default GalleryItemList;
