import React from 'react';
import { getChildComponentPropertyValueSiteData_v1_0_0 } from '../../../site-data/retrieval';
import { getInferredComponentDefaultType_v1_0_0 } from '../../compiler/components';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
export function Heading_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    const content = getChildComponentPropertyValueSiteData_v1_0_0(props, 'content', getInferredComponentDefaultType_v1_0_0('Heading'));

    const retVal = content ? <div>{content}</div> : null;

    return retVal;
}
Heading_v1_0_0.inputProps = {
    content: {
        type: 'medium_text',
        label: 'Content',
        description: 'The content of the heading',
    },
};
