import { fetchSpreadsheet } from '../lib/gdrive-utils';
import { splitStringOnComma, stringToBool } from '../lib/text-utils';

const themesSpreadsheetId = process.env.THEME_SPREADSHEET_ID || process.env.REACT_APP_THEME_SPREADSHEET_ID || process.env.NEXT_PUBLIC_THEME_SPREADSHEET_ID;

export default async function fetchThemeData(allowHidden = false) {
  if (!themesSpreadsheetId) throw new Error('themesSpreadsheetId is not valid');

  const data = await fetchSpreadsheet(themesSpreadsheetId, "themes");

  const sheetDataConverted = data
    .map(theme => ({
      ...theme,
      genres: splitStringOnComma(theme.genres),
      displayTitleSeparator: stringToBool(theme.displayTitleSeparator),
      hidden: stringToBool(theme.hidden),
      exclusive: stringToBool(theme.exclusive),
      homepageFlashName: stringToBool(theme.homepageFlashName),
    }))
    // note this is kind of weired - and exclusive themes are not filtered here but in admin/demo
    .filter(theme => allowHidden || !theme.hidden)
    .sort((a, b) => a.themeId.localeCompare(b.themeId));

  return sheetDataConverted;
}


export async function fetchThemeData_v2(sortByThemeIdAsc = true) {
  if (!themesSpreadsheetId) throw new Error('themesSpreadsheetId is not valid');

  const data = await fetchSpreadsheet(themesSpreadsheetId, "themes_v2");

  const sheetDataConverted = data
    .map(theme => {
      const { wIML, ...rest } = theme;
      return {
        ...rest,
        tags: splitStringOnComma(theme.tags),
        wiml: wIML,
      };

      // genres: splitStringOnComma(theme.genres),
      // displayTitleSeparator: stringToBool(theme.displayTitleSeparator),
      // hidden: stringToBool(theme.hidden),
      // exclusive: stringToBool(theme.exclusive),
      // homepageFlashName: stringToBool(theme.homepageFlashName),
    });
  const sheetDataSorted = sortByThemeIdAsc
    ? sheetDataConverted.sort((a, b) => a.themeId.localeCompare(b.themeId))
    : sheetDataConverted;
  // note this is kind of weired - and exclusive themes are not filtered here but in admin/demo
  // .filter(theme => allowHidden || !theme.hidden)

  return sheetDataSorted;
}

export async function fetchDefaultThemeData_v2() {
  const themes = await fetchThemeData_v2(false);

  const defaultTheme = themes[0];

  return defaultTheme;
}
