import {
    ComponentThemeData_v1_2_0,
} from './types';
import { _wimlToThemeData_v1_1_0 } from '../../v1.1.0/theme-data/compiler';
import { convertWimlToNodes_v1_1_0 } from '../../../v1.1.0/theme-data/compiler/nodes';
import { wimlComponents_v1_2_0 } from '../renderer/wiml-components-v1.2.0';
import { getThemeDataPageComponentCollectionFromNode_v1_2_0 } from './page-components';
import { getThemeDataListComponentCollectionFromNode_v1_2_0 } from './list-components';
const defaultValidVersions = ['1.2', '1.2.0'];
const defaultVersion = '1.2.0';
// todo split this out somewhere - so it's seperate from theme data
export function _markupToComponentThemeData_v1_2_0(wimlString: string, version: string = defaultVersion, validVersions: string[] = defaultValidVersions) {
    const retVal: ComponentThemeData_v1_2_0 = {
        rootNode: null,
        pages: null,
        lists: null,
        version: version
    };

    const rootNode = convertWimlToNodes_v1_1_0(wimlString, validVersions);
    retVal.rootNode = rootNode;

    const themeDataPageComponentCollection = getThemeDataPageComponentCollectionFromNode_v1_2_0(wimlComponents_v1_2_0, rootNode);
    retVal.pages = themeDataPageComponentCollection;

    // todo put header, footer, etc components in layout separate from pages
    // otherwise make header/footer not eligible for page names

    const lists = getThemeDataListComponentCollectionFromNode_v1_2_0(wimlComponents_v1_2_0, rootNode);
    retVal.lists = lists;

    return retVal;
}
