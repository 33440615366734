import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { css } from "cheerio/lib/api/css";
import humps from "humps";
import React from "react";
import { breakpoints_v1_1_0 } from "../../../v1.1.0/theme-data/renderer/utils/breakpoints";
import { convertPropsToClassNames_v1_2_0, convertPropsToDynamicCssCode_v1_2_0, getStyleCollectionString_v1_2_0, ResponsiveClassTemplate_v1_2_0 } from "../utils/styles";

const responfontSizeLookupClassNames = {
    '': {
        '5': 'wiml-font-size-5',
        '4': 'wiml-font-size-4',
        '3': 'wiml-font-size-3',
        '2': 'wiml-font-size-2',
        '1': 'wiml-font-size-1',
    },
    'lg': {
        '5': 'wiml-lg-font-size-5',
        '4': 'wiml-lg-font-size-4',
        '3': 'wiml-lg-font-size-3',
        '2': 'wiml-lg-font-size-2',
        '1': 'wiml-lg-font-size-1',
    },
    'md': {
        '5': 'wiml-md-font-size-5',
        '4': 'wiml-md-font-size-4',
        '3': 'wiml-md-font-size-3',
        '2': 'wiml-md-font-size-2',
        '1': 'wiml-md-font-size-1',
    },
    'sm': {
        '5': 'wiml-sm-font-size-5',
        '4': 'wiml-sm-font-size-4',
        '3': 'wiml-sm-font-size-3',
        '2': 'wiml-sm-font-size-2',
        '1': 'wiml-sm-font-size-1',
    },
    'xs': {
        '5': 'wiml-xs-font-size-5',
        '4': 'wiml-xs-font-size-4',
        '3': 'wiml-xs-font-size-3',
        '2': 'wiml-xs-font-size-2',
        '1': 'wiml-xs-font-size-1',
    }
} as const;
type ResponsiveFontSizeLookupClassNames = typeof responfontSizeLookupClassNames;
type ResponfontSizeLookupClassNamesKey = keyof ResponsiveFontSizeLookupClassNames;

type SizePositionKeyNames = typeof responfontSizeLookupClassNames[''];
type SizePositionKey = keyof SizePositionKeyNames

const fontSizeLookupClassNames = Object.keys(responfontSizeLookupClassNames) as ResponfontSizeLookupClassNamesKey[];
const sizeCamelizedPropNames = fontSizeLookupClassNames.reduce((acc, size) => {
    const alignPropName = size ? `font-size-${size}` : 'font-size';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[size] = camelizedPropName;
    return acc;
}, {} as Record<ResponfontSizeLookupClassNamesKey, string>);

// this is a HOC react component that wraps any WIML component with an optional flex parent that provides for fontsize
export default function withFont_v1_2_0(WrappedComponent: React.ComponentType) {
    return function (props: RuntimeWimlComponentProps_v1) {
        const fontSizeClassName = _getFontSizeClassNames_v1_2_0(props);
        const fontWeightClassName = _getFontWeightClassNames_v1_2_0(props);
        const fontColorClassName = _getFontColorClassNames_v1_2_0(props);
        const wimlFontClassName = [fontSizeClassName, fontWeightClassName,].filter(Boolean).join(' ');
        const wimlFontColorClassName = [fontColorClassName].filter(Boolean).join(' ');

        return (
            <WrappedComponent {...props} wimlFontClassName={wimlFontClassName} wimlFontColorClassName={wimlFontColorClassName} />
        );
    }
}

export function _getFontSizeClassNames_v1_2_0__old(props: RuntimeWimlComponentProps_v1) {
    const sizeClassNames = fontSizeLookupClassNames.map((sizeRepsoniveSizeProp) => {
        const camelizedPropName = sizeCamelizedPropNames[sizeRepsoniveSizeProp];
        const propValue = props[camelizedPropName] as SizePositionKey;
        if (propValue) {
            return [
                responfontSizeLookupClassNames[sizeRepsoniveSizeProp][propValue]
            ];
        }
    });


    const className = [...sizeClassNames].flat().filter(Boolean).join(' ');
    return className;
}




export const V1_2_0_FontComponentWrapper = (props: RuntimeWimlComponentProps_v1) => {
    const fontSizeClassName = _getFontSizeClassNames_v1_2_0(props);
    const fontWeightClassName = _getFontWeightClassNames_v1_2_0(props);
    const fontColorClassName = _getFontColorClassNames_v1_2_0(props);
    const wimlFontClassName = [fontSizeClassName, fontWeightClassName,].filter(Boolean).join(' ');
    const wimlFontColorClassName = [fontColorClassName].filter(Boolean).join(' ');

    return (
        <WrappedComponent {...props} wimlFontClassName={wimlFontClassName} />
    );
}







// lg = bold
// xl = bolder
const responfontWeightLookupClassNames = {
    '': {
        '5': 'wiml-font-weight-5',
        '4': 'wiml-font-weight-4',
        '3': 'wiml-font-weight-3',
        '2': 'wiml-font-weight-2',
        '1': 'wiml-font-weight-1',
    },
    'lg': {
        '5': 'wiml-lg-font-weight-5',
        '4': 'wiml-lg-font-weight-4',
        '3': 'wiml-lg-font-weight-3',
        '2': 'wiml-lg-font-weight-2',
        '1': 'wiml-lg-font-weight-1',
    },
    'md': {
        '5': 'wiml-md-font-weight-5',
        '4': 'wiml-md-font-weight-4',
        '3': 'wiml-md-font-weight-3',
        '2': 'wiml-md-font-weight-2',
        '1': 'wiml-md-font-weight-1',
    },
    'sm': {
        '5': 'wiml-xs-font-weight-5',
        '4': 'wiml-xs-font-weight-4',
        '3': 'wiml-xs-font-weight-3',
        '2': 'wiml-xs-font-weight-2',
        '1': 'wiml-xs-font-weight-1',
    },
    'xs': {
        '5': 'wiml-xs-font-weight-5',
        '4': 'wiml-xs-font-weight-4',
        '3': 'wiml-xs-font-weight-3',
        '2': 'wiml-xs-font-weight-2',
        '1': 'wiml-xs-font-weight-1',
    }
} as const;
type ResponsiveFontWeightLookupClassNames = typeof responfontWeightLookupClassNames;
type ResponfontWeightLookupClassNamesKey = keyof ResponsiveFontWeightLookupClassNames;

type WeightPositionKeyNames = typeof responfontWeightLookupClassNames[''];
type WeightPositionKey = keyof WeightPositionKeyNames

const fontWeightLookupClassNames = Object.keys(responfontWeightLookupClassNames) as ResponfontWeightLookupClassNamesKey[];
const weightCamelizedPropNames = fontWeightLookupClassNames.reduce((acc, weight) => {
    const alignPropName = weight ? `font-weight-${weight}` : 'font-weight';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[weight] = camelizedPropName;
    return acc;
}, {} as Record<ResponfontWeightLookupClassNamesKey, string>);


export function _getFontWeightClassNames_v1_2_0__old(props: RuntimeWimlComponentProps_v1) {
    const weightClassNames = fontWeightLookupClassNames.map((weightRepsoniveWeightProp) => {
        const camelizedPropName = weightCamelizedPropNames[weightRepsoniveWeightProp];
        const propValue = props[camelizedPropName] as SizePositionKey;
        if (propValue) {
            return [
                responfontWeightLookupClassNames[weightRepsoniveWeightProp][propValue]
            ];
        }
    });


    const className = [...weightClassNames].flat().filter(Boolean).join(' ');
    return className;
}






const responfontColorLookupClassNames = {
    '': {
        'xl': '',
        'lg': '',
        'md': '',
        'sm': '',
        'xs': '',
    },
    'lg': {
        'xl': '',
        'lg': '',
        'md': '',
        'sm': '',
        'xs': '',
    },
    'md': {
        'xl': '',
        'lg': '',
        'md': '',
        'sm': '',
        'xs': '',
    },
    'sm': {
        'xl': '',
        'lg': '',
        'md': '',
        'sm': '',
        'xs': '',
    },
    'xs': {
        'xl': '',
        'lg': '',
        'md': '',
        'sm': '',
        'xs': '',
    }
} as const;



const responfontColorLookupClassNames2 = {
    '': 'wiml-font-color',
    'lg': 'wiml-lg-font-color',
    'md': 'wiml-md-font-color',
    'sm': 'wiml-sm-font-color',
    'xs': 'wiml-xs-font-color',
} as const;
type ResponsiveFontColorLookupClassNames = typeof responfontColorLookupClassNames;
type ResponfontColorLookupClassNamesKey = keyof ResponsiveFontColorLookupClassNames;

type ColorPositionKeyNames = typeof responfontColorLookupClassNames[''];
type ColorPositionKey = keyof ColorPositionKeyNames

const fontColorLookupClassNames = Object.keys(responfontColorLookupClassNames) as ResponfontColorLookupClassNamesKey[];
const colorCamelizedPropNames = fontColorLookupClassNames.reduce((acc, color) => {
    const alignPropName = color ? `font-color-${color}` : 'font-color';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[color] = camelizedPropName;
    return acc;
}, {} as Record<ResponfontColorLookupClassNamesKey, string>);

// this is a HOC react component that wraps any WIML component with an optional flex parent that provides for fontcolor
export function withFontColor(WrappedComponent: React.ComponentType) {
    return function (props: RuntimeWimlComponentProps_v1) {
        let Tag: React.ElementType = React.Fragment;
        const tagProps = {} as Record<string, string>;

        const className = _getFontColorStyles_v1_2_0(props);
        if (className) {
            Tag = 'div';
            tagProps.className = className;
        }

        const cssColorVariable = '--global-color-accent';
        return (
            <Tag {...tagProps}>
                <WrappedComponent {...props} />
                <style jsx global>{`
                    .wiml-font-color-dynamic__${cssColorVariable} {
                        color: var(--global-color-accent);
                    }
                `}</style>
            </Tag>
        );
    }
}


export function _getFontColorClassNames_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const colorClassNames = fontColorLookupClassNames.map((sizeRepsoniveColorProp) => {
        const camelizedPropName = colorCamelizedPropNames[sizeRepsoniveColorProp];
        const propValue = props[camelizedPropName] as ColorPositionKey;
        if (propValue) {
            return [
                responfontColorLookupClassNames2[sizeRepsoniveColorProp] + '-dynamic__' + propValue
                // responfontColorLookupClassNames[sizeRepsoniveColorProp][propValue]
            ];
        }
    });


    const className = [...colorClassNames].flat().filter(Boolean).join(' ');
    return className;
}

export function _getFontColorStyles_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const styles = convertPropsToDynamicCssCode_v1_2_0(props, 'font-color', 'color', 'font-color');
    return styles;
}

export function _getFontStyleCollectionString_v1_2_0() {
    const fontSizeTemplate: ResponsiveClassTemplate_v1_2_0 = {
        'wiml-{size}-font-size-12': `font-size: 6.0rem`,
        'wiml-{size}-font-size-11': `font-size: 5.0rem`,
        'wiml-{size}-font-size-10': `font-size: 4.5rem`,
        'wiml-{size}-font-size-9': `font-size: 4.0rem`,
        'wiml-{size}-font-size-8': `font-size: 3.5rem`,
        'wiml-{size}-font-size-7': `font-size: 3.0rem`,
        'wiml-{size}-font-size-6': `font-size: 2.5rem`,
        'wiml-{size}-font-size-5': `font-size: 2.0rem`,
        'wiml-{size}-font-size-4': `font-size: 1.5rem`,
        'wiml-{size}-font-size-3': `font-size: 1.0rem`,
        'wiml-{size}-font-size-2': `font-size: .75rem`,
        'wiml-{size}-font-size-1': `font-size: .50rem`,
    };

    const fontWeightTemplate: ResponsiveClassTemplate_v1_2_0 = {
        'wiml-{size}-font-weight-9': `font-weight: 900`,
        'wiml-{size}-font-weight-8': `font-weight: 800`,
        'wiml-{size}-font-weight-7': `font-weight: 700`,
        'wiml-{size}-font-weight-6': `font-weight: 600`,
        'wiml-{size}-font-weight-5': `font-weight: 500`,
        'wiml-{size}-font-weight-4': `font-weight: 400`,
        'wiml-{size}-font-weight-3': `font-weight: 300`,
        'wiml-{size}-font-weight-2': `font-weight: 200`,
        'wiml-{size}-font-weight-1': `font-weight: 100`,
    };

    const styleCollectionString = getStyleCollectionString_v1_2_0([fontSizeTemplate, fontWeightTemplate]);
    return styleCollectionString;
}

const fontSizeClassNamesTemplate = {
    '12': 'wiml-font-size-12',
    '11': 'wiml-font-size-11',
    '10': 'wiml-font-size-10',
    '9': 'wiml-font-size-9',
    '8': 'wiml-font-size-8',
    '7': 'wiml-font-size-7',
    '6': 'wiml-font-size-6',
    '5': 'wiml-font-size-5',
    '4': 'wiml-font-size-4',
    '3': 'wiml-font-size-3',
    '2': 'wiml-font-size-2',
    '1': 'wiml-font-size-1',
};
export function _getFontSizeClassNames_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const classNames = convertPropsToClassNames_v1_2_0(props, fontSizeClassNamesTemplate, 'font-size');
    return classNames;
}




const fontWeightClassNamesTemplate = {
    '12': 'wiml-font-weight-12',
    '11': 'wiml-font-weight-11',
    '10': 'wiml-font-weight-10',
    '9': 'wiml-font-weight-9',
    '8': 'wiml-font-weight-8',
    '7': 'wiml-font-weight-7',
    '6': 'wiml-font-weight-6',
    '5': 'wiml-font-weight-5',
    '4': 'wiml-font-weight-4',
    '3': 'wiml-font-weight-3',
    '2': 'wiml-font-weight-2',
    '1': 'wiml-font-weight-1',
};

export function _getFontWeightClassNames_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const classNames = convertPropsToClassNames_v1_2_0(props, fontWeightClassNamesTemplate, 'font-weight');
    return classNames;
}
