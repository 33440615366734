// todo replace and just use re-resizable (we use react-beautiful-dnd elsewhere), so RND is redundant.
import { Rnd } from "react-rnd";
import { useDispatch } from "react-redux";
import { useAuth } from "@wip/common/lib/auth";
import { saveWebsiteData, clearPendingChanges } from '@wip/common/event-store/website';

import {
    Button,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltH, faBook, faCalendar, faCog, faHome, faImages, faLayerGroup, faPaintBrush, faPencilAlt, faPhone, faQuestionCircle, faSign, faSitemap, faStore } from '@fortawesome/free-solid-svg-icons';

import Home from './home';
import About from './about';
import Settings from './settings';
import Style from './style';
import Books from './books';
import Blog from './blog';
import Galleries from './galleries';
import Events from './events';
import Contact from './contact';
import CustomPages from './custom-pages';
import LandingPages from './landing-pages';
import Products from './products';
import Slides from './slides';
import NavigationGroups from "./navigation-groups";

function HomeEditor(props) {
    return <Home {...props} />
}

function StyleEditor(props) {
    return <Style {...props} />
}

function BooksEditor(props) {

    return <Books {...props} />
}

function AboutEditor(props) {
    return <About {...props} />
}

function BlogEditor(props) {
    return <Blog {...props} />
}

function GalleriesEditor(props) {
    return <Galleries {...props} />
}

function EventsEditor(props) {
    return <Events {...props} />
}

function ContactEditor(props) {
    return <Contact {...props} />
}

function CustomPagesEditor(props) {
    return <CustomPages {...props} />
}

function LandingPagesEditor(props) {
    return <LandingPages {...props} />
}

function SlidesEditor(props) {
    return <Slides {...props} />
}

function Slides2Editor(props) {
    return <Slides {...props} secondary />
}

function SettingsEditor(props) {
    return <Settings {...props} />
}

function ProductsEditor(props) {
    return <Products {...props} />
}

function NavigationGroupsEditor(props) {
    return <NavigationGroups {...props} />
}

const sidebarTabs = [
    ['home', 'Home', HomeEditor, faHome],
    ['style', 'Style', StyleEditor, faPaintBrush],
    ['books', 'Books', BooksEditor, faBook],
    ['about', 'About', AboutEditor, faQuestionCircle],
    ['blog', 'Blog', BlogEditor, faPencilAlt],
    ['gallery', 'Gallery', GalleriesEditor, faImages],
    ['events', 'Events', EventsEditor, faCalendar],
    ['contact', 'Contact', ContactEditor, faPhone],
    ['custom-pages', 'Custom Pages', CustomPagesEditor, faLayerGroup],
    ['landing-pages', 'Landing Pages', LandingPagesEditor, faSign],
    ['slides', 'Slides', SlidesEditor, faArrowsAltH],
    ['slides2', 'Slides 2', Slides2Editor, faArrowsAltH],
    ['products', 'Products', ProductsEditor, faStore],
    ['navigation', 'Navigation', NavigationGroupsEditor, faSitemap],
    ['settings', 'My Settings', SettingsEditor, faCog],
];

export function Sidebar(props) {
    const sideBarTabs = sidebarTabs
        .filter(t => {
            if (t[0] == 'landing-pages') {
                return true;

                if (props.websiteData.meta.slug == 'cmriordan') {
                    return true;
                }
                else {
                    return false;
                }
            } else {
                return true;
            }
        })
        .map(tab => (
            <NavItem key={tab[0]}>
                <NavLink
          /*href={`/${tab[0]}/`}*/ href='#'
                    active={tab[0] == props.selectedTab}
                    onClick={props.onChange.bind(this, 'selectedTab', tab[0])}
                >
                    <FontAwesomeIcon icon={tab[3]} />
                    {tab[1]}
                </NavLink>
            </NavItem>
        ));

    const sideBar = (
        <aside className="d-flex flex-row">
            <section className="sidebar-nav border-right">
                <Nav vertical tabs>
                    {sideBarTabs}
                </Nav>
            </section>
            <Rnd
                default={{
                    width: 260,
                }}
                minWidth={160}
                maxWidth={960}
                disableDragging={true}
                className="sidebar-editor-wrapper border-right"
            >
                <SidebarEditor {...props} />
            </Rnd>
            <style jsx>{`
          aside {
            font-size: 14px;
          }
  
          .sidebar-nav {
            width: 100px;
            border-color: ${props.themeConfig.borderColor};
            height: auto;
            overflow-y: scroll;
          }
  
          .sidebar-nav :global(li.nav-item) {
            min-height: 75px;
            display: flex;
            flex-direction: column;
            text-align: center;
          }
  
          .sidebar-nav :global(a.nav-link) {
            padding: 0;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
  
          aside :global(.sidebar-editor-wrapper) {
            overflow-y: scroll;
            overflow-x: hidden;
            position: relative !important;
            transform: none !important;
          }
        `}</style>
        </aside>
    );

    return sideBar;
}


function SidebarEditor(props) {
    const dispatch = useDispatch();
    const { user, } = useAuth();

    function cancel(e) {
        if (confirm('Are you sure you want to cancel your changes?')) {
            dispatch(clearPendingChanges({ isCancelled: true }))
                // https://stackoverflow.com/questions/63439021/handling-errors-with-redux-toolkit 
                // there is NO CATCH for thunks
                .then(action => {
                    if (action.error) {
                        console.error(error);
                    } else {
                        window.location.reload();
                    }
                });
        }
    }

    function save(e) {
        // const comment = prompt("Leave a note about the changes you've made.");
        const date = new Date().toISOString();

        const comment = `${user.email} saved website on ${date}.`;

        if (comment) {
            // dispatch(commitPendingChanges());
            dispatch(saveWebsiteData(comment))
                .then(() => dispatch(clearPendingChanges({ isCancelled: false })))
                .then(() => {
                    // alert('Your changes have been saved.');
                    // todo - make the ui reflect new state
                    // e.g. website history should be updated.
                    props.onSave && props.onSave();
                });
        }
    }

    const { selectedTab } = props;

    const sidebarTabObj = sidebarTabs.find(tab => tab[0] == selectedTab);
    if (!sidebarTabObj) throw new Error(`editor for tab ${selectedTab} doesn't exist.`);

    const SidebarEditorElement = sidebarTabObj[2];
    const pendingChanges = props.pendingChanges;

    return (
        <>
            <div className="p-3 pb-5">
                <h3>{sidebarTabObj[1]}</h3>
                <div className="sidebar-editor mt-2">
                    <SidebarEditorElement {...props} />
                </div>
            </div>
            <div className="sidebar-editor-controls d-flex">
                <Button className="flex-grow-1 rounded-0" disabled={pendingChanges.length == 0} color={pendingChanges.length == 0 ? "secondary" : "danger"} onClick={cancel}>cancel</Button>
                <Button className="flex-grow-1 rounded-0" disabled={pendingChanges.length == 0} color={pendingChanges.length == 0 ? "secondary" : "primary"} onClick={save}>save</Button>
            </div>
            <style jsx>{`
          .sidebar-editor-controls {
            /* TODO this should be sticky but I do'nt know if it's supported yet:
            https://stackoverflow.com/questions/34252589/position-fixed-100-of-parent
            https://stackoverflow.com/questions/5873565/set-width-of-a-position-fixed-div-relative-to-parent-div
            position: sticky;
            */ 
            position: fixed;
            bottom: 0;
            width: inherit;
            z-index: 1;
          }
  
          .preview-wrapper :global(.preview-header) {
            background-color: ${props.themeConfig.backgroundPrimaryColor};
            height: 40px;
          }
  
          .preview-wrapper :global(.preview-body) {
            padding:0;
          }
      `}</style>
        </>
    );
}
