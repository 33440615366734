import React from 'react';
import { useRef, useEffect, useState, } from "react";
import Typed from 'typed.js';
import { getInferredComponentDefaultType_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/components';
import { getChildComponentPropertyValueSiteData_v1_0_0 } from '../../../../v1.0.0/site-data/retrieval';
import { convertNewLineToBr } from '../../../../../../../../lib/text-utils';
import { getComponentClassName_v1_1_0 } from '../../compiler/components';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { Heading_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/heading';

const DEFAULT_TAG = 'h1';
const COMPONENT_TYPE = getInferredComponentDefaultType_v1_0_0("Heading");

export function Heading_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    let retVal = null;

    const content = getChildComponentPropertyValueSiteData_v1_0_0(props, 'content', COMPONENT_TYPE) || props.children;

    if (content) {
        const formattedContent = convertNewLineToBr(content);

        const pageOccurrenceId = props.pageOccurrenceId; // e.g. "home"
        const shouldType = checkIfPropContainsValue(props, 'typeOnPage', pageOccurrenceId);
        const [contentLoaded, setContentLoaded] = useState(false);
        const contentRef = useRef(null);

        typeContent(shouldType, contentRef, formattedContent, setContentLoaded);

        const defaultClass = props.sub != null ? 'sub-heading' : 'heading';
        const className = getComponentClassName_v1_1_0(props, defaultClass);
        const Tag = props.tag || DEFAULT_TAG;
        const headingTag = <Tag onClick={props.onClick} ref={contentRef} className={className} dangerouslySetInnerHTML={{ __html: formattedContent }} />;

        const titleElement = (
            <>
                {headingTag}
                <style jsx global>{`
                    h1.heading:empty::before,
                    h2.heading:empty::before,
                    h3.heading:empty::before,
                    h4.heading:empty::before,
                    h5.heading:empty::before,
                    h6.heading:empty::before,
                    h1.sub-heading:empty::before,
                    h2.sub-heading:empty::before,
                    h3.sub-heading:empty::before,
                    h4.sub-heading:empty::before,
                    h5.sub-heading:empty::before,
                    h6.sub-heading:empty::before {
                        /*
                        That would add one &nbsp; before <span> contents if empty only and would not affect the layout of elements that have some text or children.
                        https://stackoverflow.com/questions/7770444/copyright-symbol-in-css-after-pseudo-element
                        https://stackoverflow.com/questions/2212861/css-debugging-help-empty-div-collapsing
                        https://stackoverflow.com/questions/36878850/octal-literals-are-not-allowed-in-strict-mode
                        */
                        content: "";
                        display: inline-block;
                    }
                `}</style>
            </>
        );
        retVal = titleElement;
    }
    return retVal;
}

function checkIfPropContainsValue(props, propName, valueToCheck) {
    const propValue = props[propName];

    const retVal = propValue == valueToCheck || propValue?.includes(valueToCheck);

    return retVal;
}

function typeContent(shouldType, elementRef, content, setContentLoaded) {
    useEffect(() => {
        if (shouldType) {
            // we want SSR to show name on load but in order to use typed.js we must firrst
            // clear out the text contents, otherwise it goes bonkers.
            elementRef.current.textContent = '';

            let contentTyped;
            const typedOptions = {
                typeSpeed: 100,
                showCursor: false,
                loop: false
            };

            contentTyped = new Typed(elementRef.current, {
                strings: [content],
                ...typedOptions,
                onComplete: () => {
                    setContentLoaded(true);
                }
            });

            return () => {
                contentTyped && contentTyped.destroy();
            };
        }
    }, []); // [] prevents the re-render on ALL state changes - which is what we want for typed.js
}
Heading_v1_1_0.inputProps = Heading_v1_0_0.inputProps;
