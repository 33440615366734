import { WimlManage_v1_0_0 } from "./wiml/versions/v1/v1.0.0/wiml-manage-v1.0.0";
import { WimlManage_v1_1_0 } from "./wiml/versions/v1/v1.1.0/wiml-manage-v1.1.0";
import { WimlManage_v1_2_0 } from "./wiml/versions/v1/v1.2.0/wiml-manage-v1.2.0";
import { WimlManage_v1_3_0 } from "./wiml/versions/v1/v1.3.0/wiml-manage-v1.3.0";


export function ManageV4Editor(props) {
    // todo v1.2 remove this ||. keep 1.0.0 consistent throughout
    const wimlVersion = props.websiteData.style?.wiml?.themeData?.meta?.version || "1.0.0";
    const versionMetaLookup = {
        "1.0.0": WimlManage_v1_0_0,
        "1.1.0": WimlManage_v1_1_0,
        "1.2.0": WimlManage_v1_2_0,
        "1.3.0": WimlManage_v1_3_0,
    };

    const Editor = versionMetaLookup[wimlVersion];
    return <Editor {...props} />;
}
