import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import React, { useEffect, useRef, useState } from "react";
import { fetcher } from "@wip/common/lib/data-utils";
import * as websiteService from '@wip/common/domain/website-service';
import firebaseClient, { getFirebaseKeyV3 } from '@wip/common/lib/firebase';
import 'nprogress/nprogress.css'
import NProgress from 'nprogress';

const websiteApi = websiteService.getClient(firebaseClient);

export default function WebsiteSearcher(props) {

  const queryRef = useRef(null);
  const logicalOperatorAndRef = useRef(null);
  const logicalOperatorOrRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [entries, setEntries] = useState([]);

  const formSubmit = async e => {
    e.preventDefault();

    setEntries([]);
    setIsLoading(true);
    const queryInput = queryRef.current.value.trim();
    const logicalOperatorAndInput = logicalOperatorAndRef.current.value.trim();
    const logicalOperatorOrInput = logicalOperatorOrRef.current.value.trim();

    const queries = queryInput.split('\n').map((query) => new RegExp(query));
    const logicalOperator = logicalOperatorAndInput ? 'and' : 'or';
    const filterFunc = logicalOperator === 'and' ? 'every' : 'some';

    try {
      const websiteIds = await websiteApi.getAllIds();
      for (const websiteId of websiteIds) {
        try {
          const metadata = await websiteApi.getMetadata(websiteId);
          if (metadata.appVersion == 'v4') {
            const markup = await websiteApi.getWimlMarkup(websiteId);
            const slug = metadata.slug;

            const queryMatches = queries[filterFunc](asadfasfd => asadfasfd.test(markup));
            if (queryMatches) {
              setEntries(entries => [...entries, `${slug}: ${websiteId}`]);
            }
          }

        } catch (e) {
          errors[websiteId] = e.stack;
        }
      }
      setIsLoading(false);

    } catch (e) {
      alert("Error: " + e);
      const rethrow = new Error('Error searching websites: ' + e);
      rethrow.stack = e.stack;
      throw rethrow;
    }
  };
  useEffect(() => {
    if (isLoading) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [isLoading]);

  const entriesComponent = entries.map((entry, index) => {
    return (
      <tr key={index}>
        <td>
          <a target='_blank' href={`/manage/${entry.split(':')[0]}`}>{entry}</a></td>
      </tr>
    );
  });
  const entriesTable = (
    <table>
      <thead>
        <tr>
          <th>Websites found</th>
        </tr>
      </thead>
      <tbody>
        {entriesComponent}
      </tbody>
    </table>
  );
  return (
    <div className="layout container-fluid d-flex flex-column ">
      <div className="inner-wrapper d-flex flex-grow-1 h-100">
        <div className="row">
          <div className="col">
            <Form onSubmit={formSubmit}>
              <FormGroup>
                <Label for="query">Search Queries</Label>
                <Input id="query" type="textarea" rows={5} innerRef={queryRef} required autoComplete="off" />
              </FormGroup>
              <FormGroup check inline>
                <Label check for="logical-operator-and">
                  <Input name="logical-operator" type="radio" defaultChecked={true} id="logical-operator-and" value="and" innerRef={logicalOperatorAndRef} /> {' '}
                  And
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check for="logical-operator-or">
                  <Input name="logical-operator" type="radio" id="logical-operator-or" value="or" innerRef={logicalOperatorOrRef} /> {' '}
                  Or
                </Label>
              </FormGroup>
              <Button>Search Websites</Button>
            </Form>
          </div>
          {entriesTable}
        </div>
      </div>
      <style jsx>{`
                .layout {
                  height: calc(100vh - 69px);
                  padding: 0;
                  background- color: ${props.themeConfig.backgroundPrimaryColor};
            }

        .layout: global(h1) {
    text - transform: uppercase;
    letter - spacing: .1rem;
    padding: .5rem;
  }

        .layout: global(h2) {
    font - weight: bold;
  }

        .layout: global(h3) {
  }

        .layout: global(.ql - editor, .rich - text - editor p) {
    margin - bottom: 1rem;
  }
  `}</style>
    </div>

  );
}
