import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createNavigationGroupItem, deleteNavigationGroupItem } from '@wip/common/event-store/website';
import { getAllPagesForUser } from "@wip/common/app/page";

const NavigationGroupItemList = (props) => {
    const dispatch = useDispatch();

    function addNavigationGroupItem(navigationGroupId, e) {
        dispatch(createNavigationGroupItem({ navigationGroupId }));
    }

    function removeNavigationGroupItem(navigationGroupId, id, e) {
        dispatch(deleteNavigationGroupItem({ navigationGroupId, id }));
    }

    function selectNavigationGroupItem(id, e) {
        props.onSelectNavigationGroupItem(id);
    }

    const pages = getAllPagesForUser(props.websiteData.customPages);

    const getNavItemTitle = pageId => {
        const nav = pages.find(p => p.key == pageId);
        return nav.value;
    };

    const productList = props.navigationGroup.items.map(pgi => (
        <ListGroupItem key={pgi.id}>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="link" onClick={selectNavigationGroupItem.bind(null, pgi.id)}>{getNavItemTitle(pgi.page.id)}</Button>

                <Button color="link" onClick={removeNavigationGroupItem.bind(null, props.navigationGroup.id, pgi.id)}><FontAwesomeIcon icon={faTrash} /> Remove</Button>
            </div>

        </ListGroupItem>
    ));
    return (
        <Form>
            <Button outline color="primary" onClick={addNavigationGroupItem.bind(null, props.navigationGroup.id)}>
                <FontAwesomeIcon icon={faPlus} /> Add Page to Navigation Group
            </Button>
            <ListGroup>
                {productList}
            </ListGroup>
        </Form>
    );
}

export default NavigationGroupItemList;
