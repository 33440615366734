import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createCustomPage, deleteCustomPage } from '@wip/common/event-store/website';

const CustomPageList = (props) => {
    const dispatch = useDispatch();

    function addCustomPage(e) {
        dispatch(createCustomPage());
    }

    function removeCustomPage(id, e) {
        dispatch(deleteCustomPage({ id }));
    }

    function selectCustomPage(id, e) {
        props.onSelectCustomPage(id);
    }

    const customPageList = props.websiteData.customPages.map(customPage => (
        <ListGroupItem key={customPage.id}>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="link" onClick={selectCustomPage.bind(null, customPage.id)}>{customPage.about.title}</Button>

                <Button color="link" onClick={removeCustomPage.bind(null, customPage.id)}><FontAwesomeIcon icon={faTrash} /> Remove</Button>
            </div>

        </ListGroupItem>
    ));
    return (
        <Form>
            <Button outline color="primary" onClick={addCustomPage}>
                <FontAwesomeIcon icon={faPlus} /> Add New Custom Page
            </Button>
            <ListGroup>
                {customPageList}
            </ListGroup>
        </Form>
    );
}

export default CustomPageList;
