// https://dev.to/thatgalnatalie/how-to-get-started-with-redux-toolkit-41e
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { reducer as website } from '@wip/common/event-store/website';
import { pendingChangesMiddleware } from '@wip/common/event-store/pending-changes';
import { reducer as themes } from '@wip/common/event-store/themes';
import { reducer as themes_v2 } from '@wip/common/event-store/themes-v2';
import { reducer as user } from '@wip/common/event-store/user';

// TODO: add validation logic here: https://redux-toolkit.js.org/api/createSlice#customizing-generated-action-creators
// https://redux-toolkit.js.org/api/createAction#using-prepare-callbacks-to-customize-action-contents
// const todosSlice = createSlice({
//     name: 'todos',
//     initialState: [],
//     reducers: {
//       addTodo: {
//         reducer: (state, action) => {
//           state.push(action.payload)
//         },
//         prepare: (text) => {
//           const id = nanoid()
//           return { payload: { id, text } }
//         },
//       },
//     },
//   })


const reducer = combineReducers({
    website,
    themes,
    themes_v2,
    user,
});

const store = configureStore({
    reducer,
    middleware: [pendingChangesMiddleware, ...getDefaultMiddleware()],
});

export default store;

