import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Label } from 'reactstrap';
import { updateContact } from '@wip/common/event-store/website';
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../form/input';
import { useMemo } from "react";

const Contact = (props) => {
    const dispatch = useDispatch();

    function uploadImage(file) {
        const workPromise = dispatch(saveFile(file)).then(action => action.payload);

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: uploadImage };

        return retVal;
    }, [dispatch]);

    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: props.websiteData.contact,
    });

    // kind of annoying - https://github.com/react-hook-form/react-hook-form/issues/4414
    const { ref: emailRef, ...emailRest } = register('email', { required: true });

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                key = event.target.name;
                value = event.target.value;
            }

            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            dispatch(updateContact({ key, value }));
        }
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleRichEditorChangeEvent = (key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            dispatch(updateContact({ key, value }));
        }
    };

    return (
        <Form>
            <FormGroup>
                <Label for="email">Contact Email Address</Label>
                <ReactstrapInputBlurChangeDetector type="email" {...emailRest} innerRef={emailRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="content">Contact Information</Label>
                <RichTextEditor defaultValue={props.websiteData.contact.content} onDiff={handleRichEditorChangeEvent.bind(null, 'content')} {...richTextConfig} />
            </FormGroup>
        </Form>
    );
}

export default Contact;

//  // how to watch data
//     // 1) https://github.com/react-hook-form/documentation/issues/74
//     // 2) https://github.com/react-hook-form/react-hook-form/discussions/2494
//     // 3) https://stackoverflow.com/questions/38022618/how-do-i-auto-save-with-multiple-form-fields-in-react
//     // 4) https://github.com/react-hook-form/react-hook-form/discussions/3620
//     // 5) https://github.com/react-hook-form/react-hook-form/issues/283
//     // 6) https://github.com/react-hook-form/react-hook-form/issues/3698#issuecomment-752261747
//     // 7) https://github.com/react-hook-form/react-hook-form/issues/1905
//     // https://react-hook-form.com/api/useform/watch
//     // https://github.com/tiaanduplessis/react-hook-form-persist/blob/master/src/index.js
//     watch((_, changedData) => {
//         // debounce https://www.synthace.com/autosave-with-react-hooks/
//         console.log(changedData.name, changedData.type, ':', changedData.value);
//     });

//        // https://github.com/react-hook-form/react-hook-form/discussions/2494
//        const formChanged = (e, e2) => {
//         const { isValid } = formState;
//         const formValues = getValues();
//         console.log("isValid = ", isValid);
//         console.log("formValues = ", formValues);
//         if (isValid) {
//             console.log("sending values to server");
//         }
//     };

