import { Input } from 'reactstrap';
import { useRef } from 'react';
import { Editor as MceEditor } from '@tinymce/tinymce-react';
import { withBlurChangeDetection } from '@wip/common';

const tinyMceApiKey = process.env.REACT_APP_TINY_MCE_API_KEY;

export const ReactstrapInputBlurChangeDetector = withBlurChangeDetection(Input);

export function RichTextEditor(props) {
    // if (process.env.NODE_ENV !== 'production') {
    //     // todo fix after tiny mce hosting is fixed
    //     return "EMPTY - NOT production";
    // }

    if (!props.onImageUpload) {
        throw new Error(`onImageUpload is required.`);
    }

    // todo remove along w/ env vars
    if (!tinyMceApiKey) {
        throw new Error(`tinyMceApiKey is required.`);
    }

    const editorRef = useRef(null);
    const prevVal = useRef();

    const inputBlur = () => {
        const newVal = getHTML();
        if (newVal != prevVal.current) {
            props.onDiff && props.onDiff(newVal);
        }
    };


    function getHTML() {
        const editorHtml = editorRef.current.getContent();

        return editorHtml
    }

    function inputFocus() {
        prevVal.current = getHTML();
    };

    // https://www.tiny.cloud/docs/configure/file-image-upload/#exampleusingimages_upload_handler
    async function uploadImageCallBack(blobInfo, success, failure, progress) {
        const file = blobInfo.blob();

        const fileUrl = await props.onImageUpload(file);


        success(fileUrl);
    }

    // todo self host tinymce not rely on cdn, see tiny-mce-v6 branch
    // https://www.tiny.cloud/docs/tinymce/6/react-pm-host/
    return <>
        <MceEditor
            apiKey={tinyMceApiKey}
            onInit={setRef}
            /*
            MUST USE initialValue
            https://stackoverflow.com/questions/65024237/why-editor-field-not-rendering-recent-data-in-react-hook-form
            https://app.clickup.com/25740756/docs/rhhem-14790/rhhem-5390
            value={props.defaultValue || ""}
            */
            initialValue={props.defaultValue || ""}
            init={{
                body_class: 'rich-text-editor', /*todo does not work, have to use height*/
                height: 500,
                //    menubar: false,
                plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'print',
                    'preview',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'paste',
                    'help',
                    'wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | code | help',
                images_upload_handler: uploadImageCallBack,
                resize_img_proportional: true,
                extended_valid_elements: 'script[src|async|defer|type|charset]',
            }}
            onFocus={inputFocus}
            onBlur={inputBlur}
        />
        <style jsx global>{`
        .tox-promotion{
            display: none;
        }
        `}</style>
    </>;

    function setRef(evt, editor) {
        editorRef.current = editor;
    }
}
