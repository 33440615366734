import React from 'react';

import { renderChildren_v1_0_0 } from '../render-components';
import { getComponentClassName_v1_0_0 } from '../render-components';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";

export function Page_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    const className = getComponentClassName_v1_0_0(props, 'page');

    const renderedChildren = renderChildren_v1_0_0(props.children);

    return <div className={className}>{renderedChildren}</div>;
}
Page_v1_0_0.inputProps = {
    displayLayout: {
        type: 'boolean',
        label: 'Show Master Page?',
        description: 'Should the page contain a header/footer? This is commonly used for landing pages.',
    },
};
