import Sidebar_v1_2_0 from './sidebar-v1.2.0';
import Preview_v1_0_0 from '../v1.0.0/preview-v1.0.0';

export function WimlManage_v1_2_0(props) {
    const onPreviewChange = (previewData) => {
        if (previewData.listItemId) {
            // this controls the 'view options' dropdown in the list editor - e.g. all components or just the page-specific ones.
            const stateChanges = {
                selectedTab: previewData.pageDefinitionId,
                listId: previewData.listId,
                listItemId: previewData.listItemId,
                listInstanceComponentId: previewData.listInstanceComponentId,
                componentId: previewData.componentId,
                meta: previewData.meta,
            };

            props.onChange(stateChanges);
        } else {
            const stateChanges = {
                selectedTab: previewData.pageDefinitionId,
                listId: null,
                listItemId: null,
                listInstanceComponentId: null,
                componentId: previewData.componentId,
                meta: previewData.meta,
            };

            props.onChange(stateChanges);
        }
    };

    return <>
        <Sidebar_v1_2_0  {...props} />
        <Preview_v1_0_0 {...props} onPreviewChange={onPreviewChange} />
    </>;
}
