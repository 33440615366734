import _ from 'lodash';
import WimlEditor_v1_3_0 from './wiml-editor-v1.3.0';
import { __Sidebar_v1_0_0 } from '../v1.0.0/sidebar-v1.0.0';
import Settings_v1_3_0 from './settings-v1.3.0';
import { useDispatch } from 'react-redux';
import { createPageComponent, } from '@wip/common/event-store/website';
import { formatNodeKey_v1_2_0 } from '@wip/common/app/wiml/versions/v1/v1.2.0/theme-data/compiler/components';

export default function Sidebar(props) {
  const selectedTab = props.selectedTab == 'home' ? 'page__home' : props.selectedTab;
  const newProps = { ...props, selectedTab };
  return __Sidebar_v1_0_0(newProps, WimlEditor_v1_3_0, Settings_v1_3_0, <AddNewPage {...props} />);
}

function AddNewPage(props) {
  const isAdmin = props.userData.roles.includes('admin');
  if (!isAdmin) return null;

  const dispatch = useDispatch();

  const handleNewPageClick = async e => {
    e.preventDefault();

    const pageKey = window.prompt('Enter page key, e.g. books or help-categories.');
    if (!pageKey) return;

    try {
      dispatch(createPageComponent({ pageKey }));

      const formattedPageKey = formatNodeKey_v1_2_0(pageKey);
      props?.onChange('selectedTab', `page__${formattedPageKey}`)
      // newContainerKeyRef.current.value = '';
    } catch (e) {
      alert("Error: " + e);
      const rethrow = new Error('Error searching websites: ' + e);
      rethrow.stack = e.stack;
      throw rethrow;
    }
  };

  return <button className="btn btn-info btn-sm" onClick={handleNewPageClick}>Add New Page</button>;;
}
