export function range(n) {
  const retVal = [...Array(n).keys()];

  return retVal;
};

// https://stackoverflow.com/questions/22015684/how-do-i-zip-two-arrays-in-javascript
export function zip(arr1, arr2) {
  const retVal = arr1.map(function (e, i) {
    return [e, arr2[i]];
  });

  return retVal;
}


export function move(arr, fromIndex, toIndex) {
  const element = arr[fromIndex];
  const retVal = arr.slice(0, fromIndex).concat(arr.slice(fromIndex + 1, arr.length));
  retVal.splice(toIndex, 0, element);

  return retVal;
}
