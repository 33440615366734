// https://dev.to/thatgalnatalie/how-to-get-started-with-redux-toolkit-41e
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchThemeData } from '@wip/common';

const initialState = {
  themes: [],
  isLoading: false,
  hasErrors: false,
};

const theme = createSlice({
  name: "themes",
  initialState,
  reducers: {
    getData: (state) => {
      state.isLoading = true;
    },

    getDataSuccess: (state, action) => {
      state.isLoading = false;
      state.themes = action.payload;
    },

    getDataFailure: (state) => {
      state.isLoading = false;
      state.hasErrors = true;
    },
  }
});

export const reducer = theme.reducer;

export const {
  getData, getDataSuccess, getDataFailure
} = theme.actions;

export const fetchThemes = createAsyncThunk(
  "theme/fetchThemes",
  async (_, { dispatch }) => {
    // Set the loading state to true
    dispatch(getData());

    try {
      const allThemes = await fetchThemeData();
      const themes = allThemes.filter(t => !t.exclusive);

      // const themes = [
      //   { 'theme_id': 'rainbow' },
      //   { 'theme_id': 'rainbow2' },
      //   { 'theme_id': 'rolling-waves:default' }
      // ];
      const dispatchData = themes;
      dispatch(getDataSuccess(dispatchData));
    } catch (error) {
      console.error('error', error)
      // Set any erros while trying to fetch
      dispatch(getDataFailure(error));
    }
  }
);
