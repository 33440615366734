const prefix = 'fab';
const iconName = 'bookbub';
const width = 512;
const height = 480;
const ligatures = [];
const unicode = null;
const svgPathData = 'm0 440v-400h135c40 0 70 7.5 95 25 22.5 17.5 35 40 35 67.5 0 20-7.5 40-20 57.5-15 17.5-32.5 30-55 35 27.5 2.5 50 15 67.5 32.5 17.5 17.5 25 40 25 65 0 35-12.5 65-37.5 85-25 22.5-60 32.5-102.5 32.5h-142.5zm75-340v105h42.5c20 0 37.5-5 47.5-15 10-10 17.5-25 17.5-42.5 0-32.5-22.5-47.5-67.5-47.5h-40zm0 165v117.5h52.5c22.5 0 40-5 52.5-15 12.5-12.5 20-27.5 20-47.5 0-37.5-25-55-75-55h-50zm242.5 175v-400h135c40 0 70 7.5 95 25 22.5 17.5 35 40 35 67.5 0 20-7.5 40-20 57.5-15 17.5-32.5 30-55 35 27.5 2.5 50 15 67.5 32.5 17.5 17.5 25 40 25 65 0 35-12.5 65-37.5 85-25 22.5-60 32.5-102.5 32.5h-142.5zm75-340v105h42.5c20 0 37.5-5 47.5-15s17.5-25 17.5-42.5c0-32.5-22.5-47.5-67.5-47.5h-40zm0 165v117.5h52.5c22.5 0 40-5 52.5-15 12.5-10 17.5-25 17.5-45 0-37.5-25-57.5-75-57.5h-47.5z';


export const faBookbub = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};
