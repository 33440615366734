import { useRef, useEffect } from 'react';

const getThumbnailList = (props, handleChange, activeRef) => props.themes
    .filter(t => props.themeTag == "all" || t.tags.includes(props.themeTag))
    .map(t =>
        <li ref={t.themeId == props.themeId ? activeRef : undefined} className={["py-1 mb-3", t.themeId == props.themeId ? "active" : ""].join(' ')} key={t.themeId} onClick={handleChange.bind(this, null, t.themeId)}>
            <div className="mt-0 mb-3 text-center font-weight-light">{t.themeName}</div>
            <section className="image-section mx-auto d-flex align-items-center">
                <img src={t.thumbnail} className="mx-auto h-100 img-thumbnail" />
                <div className="overlay-div"></div>
            </section>
            <style jsx>{`
                li {
                    cursor: pointer;
                    background-color: WhiteSmoke;
                }
                
                li.active, li:hover {
                    background-color: firebrick;
                    color: #fff;
                }

                section.image-section {
                    position: relative;
                    width: 166px;
                    height: 116px;
                }

                section.image-section img {
                    object-fit: cover;
                }

                .overlay-div {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    background-color: ${t.primaryColor};
                    opacity: .25;
                  }
            `}</style>
        </li>
    );

export default function ThemeList_v1_3_0(props) {
    const themesListRef = useRef(null);
    const didMountThemesListRef = useRef(false);

    const activeThemeItemElement = useRef(null);

    useEffect(() => {
        if (didMountThemesListRef.current) {
            themesListRef.current.scrollTo(0, 0);
        } else {
            didMountThemesListRef.current = true;
        }

    }, [props.themeTag]);

    useEffect(() => {
        // https://stackoverflow.com/questions/14622963/javascript-css-window-scrollto0-0-not-working
        // i noticed that when clicking on 'scroll into view' with chrome dev tools, it DID work.
        // this this might be empty if we're creating a brand new user who has no style yet.
        activeThemeItemElement.current?.scrollIntoView();
    }, []);

    // TODO - THIS HANDLE CHANGE IS DUPLICATED IN DEMO AND OTHER PLACES.
    const handleChangeGenreEvent = (event, key, value) => {

        if (event) {
            key = event.target.name;
            value = event.target.value;
        }

        if (!key) throw new Error('Handle change event `key` is missing.')
        if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

        props.onGenreChange(key, value);
    };

    // TODO - THIS HANDLE CHANGE IS DUPLICATED IN DEMO AND OTHER PLACES.
    const handleChangeThemeEvent = (event, key, value) => {
        if (event) {
            key = event.target.name;
            value = event.target.value;
        }

        if (!key) throw new Error('Handle change event `key` is missing.')
        if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

        props.onThemeChange(key, value);
    };

    const themeThumbnailList = getThumbnailList(props, handleChangeThemeEvent, activeThemeItemElement);

    return (
        <div className="theme-list">
            {/* <Form>
                <FormGroup>
                    <Label for="exampleSelect">Theme Category</Label>
                    <Input type="select" name="genre" id="genre" value={props.genre} onChange={handleChangeGenreEvent}>
                        <GenreList/>

                    </Input>
                </FormGroup>
            </Form> */}

            <ul className="list-unstyled" ref={themesListRef}>
                {themeThumbnailList}
            </ul>
            <style jsx>{`
                .theme-list {
                }

                ul {
                    scrollbar-color: pink red; /*#0A4C95 #C2D2E4;*/
                    scrollbar-width: 20px !important;
                }

                ul::-webkit-scrollbar {
                    width: 10px;
                }

                ul::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: grey;
                }
            `}
            </style>
        </div>
    );
}
