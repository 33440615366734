import { DataClass } from "../lib/object-utils";
import NavigationGroupItem from "./navigation-group-item";
import _ from 'lodash';

export default class NavigationGroup extends DataClass {
    constructor(navigationGroupId, data) {
        super(data);

        this.id = navigationGroupId;

        this.about = this.about || {};
        this.meta = this.meta || {};

        this.items = (this.items || [])
            .map(item => {
                const { id, ...rest } = item;
                const pgItem = new NavigationGroupItem(id, rest);
                return pgItem;
            });
        // .sort((a, b) => a.meta.order ? a.meta.order - b.meta.order : -1);

        this._sortItems();
    }

    addItem(navigationGroupItem) {
        this.items.push(navigationGroupItem);

        this._sortItems();
    }

    removeItem(navigationGroupItemId) {
        this.items = this.items.filter(gi => gi.id !== navigationGroupItemId);

        this._sortItems();
    }

    updateItemAbout(navigationGroupItemId, key, value) {
        const item = this.items.find(gi => gi.id == navigationGroupItemId);

        item.about[key] = value;
    }

    updateItemAssets(navigationGroupItemId, key, value) {
        const item = this.items.find(gi => gi.id == navigationGroupItemId);

        item.assets[key] = value;
    }

    updateItemMeta(navigationGroupItemId, key, value) {
        const item = this.items.find(gi => gi.id == navigationGroupItemId);

        item.meta[key] = value;
        this._sortItems();
    }
    
    updateItemPage(navigationGroupItemId, key, value) {
        const item = this.items.find(gi => gi.id == navigationGroupItemId);

        item.page[key] = value;
    }

    _sortItems() {
        this.items = _.orderBy(this.items, ['meta.order'], ['asc']);
    }
}

