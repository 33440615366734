import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { convertPropsToDynamicCssCode_v1_2_0, convertPropsToDynamicClassNames_v1_2_0 } from "../utils/styles";


export function _getBgColorClassNames_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const className = convertPropsToDynamicClassNames_v1_2_0(props, 'bg-color', 'background-color');
    return className;
}

export function _getBackgroundColorStyles_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const styles = convertPropsToDynamicCssCode_v1_2_0(props, 'bg-color', 'background-color');
    return styles;
}

