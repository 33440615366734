//https://github.com/zasource-dev/React-Serialize
import React from "react"
import { isFirstCharUpper } from "../text-utils"

/**
 * Serialize React element to JSON string
 *
 * @param {ReactNode} element
 * @returns {string}
 */
export function serialize(element) {
  const replacer = (key, value) => {
    switch (key) {
      case "_owner":
      case "_store":
      case "ref":
      case "key":
        return
      case "type":
        return typeof(value) == "string" ? value : value.name
      default:
        return value
    }
  }

  return JSON.stringify(element, replacer)
}

/**
 * Deserialize JSON string to React element
 *
 * @param {string|object} data
 * @param {object?} options
 * @param {object?} options.components
 * @param {function?} options.reviver
 * @returns {ReactNode}
 */
export function deserialize(data, options) {
  if (typeof data === "string") {
    data = JSON.parse(data)
  }
  if (data instanceof Object) {
    return deserializeElement(data, options)
  }
  throw new Error("Deserialization error: incorrect data type")
}

function deserializeElement(element, options = {}, key) {
  let { components = {}, reviver } = options

  if (typeof element !== "object") {
    return element
  }

  if (element === null) {
    return element
  }

  if (element instanceof Array) {
    return element.map((el, i) => deserializeElement(el, options, i))
  }

  // Now element has following shape { type: string, props: object }

  let { type, props } = element

  if (typeof type !== "string") {
    throw new Error("Deserialization error: element type must be string")
  }

  const isCustomComponent =  isFirstCharUpper(type)
  if(isCustomComponent) {
    const customType = components[type]
    if(!customType) throw new Error(`Custom Type: ${type} was not provided in the "components" object.`)
    type = customType
  }

  if (props.children) {
    props = { ...props, children: deserializeElement(props.children, options) }
  }

  if (reviver) {
    ;({ type, props, key, components } = reviver(type, props, key, components))
  }

  // throw error if any intersection between props and options.props
  const intersection = Object.keys(props).filter(key => !!options.props?.[key])
  if(intersection.length > 0) {
    throw new Error(`Deserialization error: props ${intersection.join(", ")} are reserved for the deserializer`)
  }

  const finalProps = {
    ...props,
    ...(isCustomComponent && options.props),
    key
  };
  // pass in options props
  return React.createElement(type, finalProps)
}
