import React from 'react';
import { RuntimeWimlComponentProps_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/types';
import { getChildComponentPropertyValueSiteData_v1_2_0 } from '../../../site-data/retrieval';
import InnerHTML from 'dangerously-set-html-content';
import { Embed_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/embed';

export function Embed_v1_2_0(props: RuntimeWimlComponentProps_v1_0_0) {
    let retVal = null;

    let content = getChildComponentPropertyValueSiteData_v1_2_0(props, 'content');

    if (content) {
        const element = <InnerHTML html={content} />;
        retVal = element;
    }
    return retVal;
}
Embed_v1_2_0.inputProps = Embed_v1_0_0.inputProps;
