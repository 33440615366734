import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import humps from "humps";
import React from "react";
import { ResponsiveClassTemplate_v1_2_0, convertPropsToClassNames_v1_2_0, convertPropsToDynamicCssCode_v1_2_0, convertPropsToDynamicClassNames_v1_2_0, getStyleCollectionString_v1_2_0 } from "../utils/styles";
import { getComponentClassName_v1_2_0 } from "../../compiler/components";
import { V1_2_0_ComponentWrapper } from "../utils/component-wrapper";
import { getInferredComponentDefaultType_v1_0_0 } from "../../../../v1.0.0/theme-data/compiler/components";
// unit in pixels, not rem
const hrXClassNamesTemplate = {
    '5': ['wiml-hr-x', 'wiml-hr-x-5'],
    '4': ['wiml-hr-x', 'wiml-hr-x-4'],
    '3': ['wiml-hr-x', 'wiml-hr-x-3'],
    '2': ['wiml-hr-x', 'wiml-hr-x-2'],
    '1': ['wiml-hr-x', 'wiml-hr-x-1'],
};

const hrYClassNamesTemplate = {
    '5': ['wiml-hr-y', 'wiml-hr-y-5'],
    '4': ['wiml-hr-y', 'wiml-hr-y-4'],
    '3': ['wiml-hr-y', 'wiml-hr-y-3'],
    '2': ['wiml-hr-y', 'wiml-hr-y-2'],
    '1': ['wiml-hr-y', 'wiml-hr-y-1'],
};


const responsizeHrSizeLookupClassNames = {
    '': {
        '5': 'wiml-hr-5',
        '4': 'wiml-hr-4',
        '3': 'wiml-hr-3',
        '2': 'wiml-hr-2',
        '1': 'wiml-hr-1',
    },
    'lg': {
        '5': 'wiml-lg-hr-5',
        '4': 'wiml-lg-hr-4',
        '3': 'wiml-lg-hr-3',
        '2': 'wiml-lg-hr-2',
        '1': 'wiml-lg-hr-1',
    },
    'md': {
        '5': 'wiml-md-hr-5',
        '4': 'wiml-md-hr-4',
        '3': 'wiml-md-hr-3',
        '2': 'wiml-md-hr-2',
        '1': 'wiml-md-hr-1',
    },
    'sm': {
        '5': 'wiml-sm-hr-5',
        '4': 'wiml-sm-hr-4',
        '3': 'wiml-sm-hr-3',
        '2': 'wiml-sm-hr-2',
        '1': 'wiml-sm-hr-1',
    },
    'xs': {
        '5': 'wiml-xs-hr-5',
        '4': 'wiml-xs-hr-4',
        '3': 'wiml-xs-hr-3',
        '2': 'wiml-xs-hr-2',
        '1': 'wiml-xs-hr-1',

    }
} as const;


type HrSizeLookupClassNamesKey = keyof typeof responsizeHrSizeLookupClassNames;
type HrSizePositionKey = keyof typeof responsizeHrSizeLookupClassNames['']


const defaultHrSizeClass = responsizeHrSizeLookupClassNames['']['3'];

const hrSizeLookupClassNames = Object.keys(responsizeHrSizeLookupClassNames) as HrSizeLookupClassNamesKey[];
const hrSizeCamelizedPropNames = hrSizeLookupClassNames.reduce((acc, size) => {
    const alignPropName = size ? `size-${size}` : 'size';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[size] = camelizedPropName;
    return acc;
}, {} as Record<HrSizeLookupClassNamesKey, string>);

const COMPONENT_TYPE = getInferredComponentDefaultType_v1_0_0('Hr');

export function Hr_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const hrClassName = _getHrClassNames_v1_2_0(props);
    const className = getComponentClassName_v1_2_0(props, hrClassName);
    const retVal = (
        <div className={className}>
            <V1_2_0_ComponentWrapper {...props}>
                <hr />
            </V1_2_0_ComponentWrapper>
        </div >
    );

    return retVal;
}

export function _getHrClassNames_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const spacerXClassNames = convertPropsToClassNames_v1_2_0(props, hrXClassNamesTemplate, 'size-x', 'wiml-hr', ['wiml-hr', 'wiml-hr-x-10']);
    const spacerYClassNames = convertPropsToClassNames_v1_2_0(props, hrYClassNamesTemplate, 'size-y', 'wiml-hr', ['wiml-hr', 'wiml-hr-y-3']);

    const dynamicColorClassNames = convertPropsToDynamicClassNames_v1_2_0(props, 'color', 'hr-line-color');

    const classNames = [spacerXClassNames, spacerYClassNames, dynamicColorClassNames].filter(Boolean).join(' ').split(' ');
    const retVal = [...new Set(classNames)].join(' ');

    return retVal;
}






export function _getHrColorStyles_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const styles = convertPropsToDynamicCssCode_v1_2_0(props, 'color', 'border-color', 'hr-line-color', '> hr');
    return styles;
}


export function _getHrStyleCollectionString_v1_2_0() {
    const hrXTemplate: ResponsiveClassTemplate_v1_2_0 = {
        'wiml-{size}-hr-x-10': `width: 100%`,
        'wiml-{size}-hr-x-9': `width: 90%`,
        'wiml-{size}-hr-x-8': `width: 80%`,
        'wiml-{size}-hr-x-7': `width: 70%`,
        'wiml-{size}-hr-x-6': `width: 60%`,
        'wiml-{size}-hr-x-5': `width: 50%`,
        'wiml-{size}-hr-x-4': `width: 40%`,
        'wiml-{size}-hr-x-3': `width: 30%`,
        'wiml-{size}-hr-x-2': `width: 20%`,
        'wiml-{size}-hr-x-1': `width: 10%`,
    };

    const hrYTemplate: ResponsiveClassTemplate_v1_2_0 = {
        'wiml-{size}-hr-y-10': `border-top-width: 10px`,
        'wiml-{size}-hr-y-9': `border-top-width: 9px`,
        'wiml-{size}-hr-y-8': `border-top-width: 8px`,
        'wiml-{size}-hr-y-7': `border-top-width: 7px`,
        'wiml-{size}-hr-y-6': `border-top-width: 6px`,
        'wiml-{size}-hr-y-5': `border-top-width: 5px`,
        'wiml-{size}-hr-y-4': `border-top-width: 4px`,
        'wiml-{size}-hr-y-3': `border-top-width: 3px`,
        'wiml-{size}-hr-y-2': `border-top-width: 2px`,
        'wiml-{size}-hr-y-1': `border-top-width: 1px`,
    };

    const styleCollectionString = getStyleCollectionString_v1_2_0([hrXTemplate, hrYTemplate]);
    return styleCollectionString;
}
