import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from "react-redux";
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../form/input';
import { updateSlideAbout, updateSlideMeta, updateSlideAssets, updateSlideNavigation } from '@wip/common/event-store/website';
import { updateSlide2About, updateSlide2Meta, updateSlide2Assets, updateSlide2Navigation } from '@wip/common/event-store/website';
import { saveFile } from '@wip/common/event-store/website';

const SlideDetail = (props) => {
    const dispatch = useDispatch();

    function uploadImage(file) {
        const workPromise = dispatch(saveFile(file)).then(action => action.payload);

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: uploadImage };

        return retVal;
    }, [dispatch]);

    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: props.slide
    });

    const { ref: titleRef, ...titleRest } = register('about.title', { required: true });
    const { ref: pageOrderRef, ...pageOrderRest } = register('meta.order');
    const { ref: linkRef, ...linkRest } = register('navigation.link');
    const { ref: contentRef, ...contentRest } = register('navigation.content');

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, id, key, value) => {

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                id = event.target.dataset.id;
                key = event.target.name;
                value = event.target.value;
            }

            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (props.secondary) {
                if (path == 'about') {
                    dispatch(updateSlide2About({ id, key: newKey, value }));
                } else if (path == 'meta') {
                    dispatch(updateSlide2Meta({ id, key: newKey, value }));
                } else if (path == 'navigation') {
                    dispatch(updateSlide2Navigation({ id, key: newKey, value }));
                }
            } else {
                if (path == 'about') {
                    dispatch(updateSlideAbout({ id, key: newKey, value }));
                } else if (path == 'meta') {
                    dispatch(updateSlideMeta({ id, key: newKey, value }));
                } else if (path == 'navigation') {
                    dispatch(updateSlideNavigation({ id, key: newKey, value }));
                }
            }
        }
    };

    const handlePublishDateChangeEvent = async (e) => {
        publishDateRest.onChange && await publishDateRest.onChange(e);
        handleChangeEvent(e);
    };

    const onCoverPhotoChange = e => {
        const id = e.target.dataset.id;
        const coverPhotoFile = e.target.files[0];
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(saveFile(coverPhotoFile)).then(action => {
            const downloadUrl = action.payload;

            if (props.secondary) {
                dispatch(updateSlide2Assets({ id, key: 'coverPhoto', value: downloadUrl }));
            } else {
                dispatch(updateSlideAssets({ id, key: 'coverPhoto', value: downloadUrl }));
            }

            e.target.value = "";
        });
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleRichEditorChangeEvent = (id, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (props.secondary) {
                if (path == 'about') {
                    dispatch(updateSlide2About({ id, key: newKey, value }));
                }
            } else {
                if (path == 'about') {
                    dispatch(updateSlideAbout({ id, key: newKey, value }));
                }
            }
        }
    };

    const bookViewElelement = (
        <Form>
            <FormGroup>
                <Label for="about.title">Slide Name</Label>
                <ReactstrapInputBlurChangeDetector id="about.title" type="text" data-id={props.slide.id} {...titleRest} innerRef={titleRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="meta.order">Slide Order</Label>
                <ReactstrapInputBlurChangeDetector id="meta.order" type="number" data-id={props.slide.id} {...pageOrderRest} innerRef={pageOrderRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="navigation.link">Slide Link</Label>
                <ReactstrapInputBlurChangeDetector id="navigation.link" type="text" data-id={props.slide.id} {...linkRest} innerRef={linkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="navigation.content">Button Text</Label>
                <ReactstrapInputBlurChangeDetector id="navigation.content" type="text" data-id={props.slide.id} {...contentRest} innerRef={contentRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="assets.coverPhoto">Cover Photo</Label>
                <Input id="assets.coverPhoto" type="file" accept="image/*" data-id={props.slide.id} onChange={onCoverPhotoChange} />
                <img src={props.slide.assets.coverPhoto} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="reviews.content">Slide Content</Label>
                <RichTextEditor defaultValue={props.slide.about.content} onDiff={handleRichEditorChangeEvent.bind(null, props.slide.id, 'about.content')} {...richTextConfig} />
            </FormGroup>
        </Form>);

    return bookViewElelement;
}

export default SlideDetail;
