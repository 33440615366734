import React from 'react';
import { useRef, useEffect, useState, } from "react";
import Typed from 'typed.js';
import { getInferredComponentDefaultType_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/components';
import { getChildComponentPropertyValueSiteData_v1_0_0 } from '../../../../v1.0.0/site-data/retrieval';
import { convertNewLineToBr } from '../../../../../../../../lib/text-utils';
import { getComponentClassName_v1_2_0 } from '../../compiler/components';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { Heading_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/heading';
import { _getFontSizeClassNames_v1_2_0 } from '../attributes/font';
import { V1_2_0_ComponentWrapper } from '../utils/component-wrapper';
import { breakpointsMin_v1_1_0 } from '../../../../v1.1.0/theme-data/renderer/utils/breakpoints';
import { getChildComponentPropertyValueSiteData_v1_2_0 } from '../../../site-data/retrieval';

const DEFAULT_TAG = 'h1';
const COMPONENT_TYPE = getInferredComponentDefaultType_v1_0_0("Heading");

export function Heading_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    let retVal = null;

    const content = getChildComponentPropertyValueSiteData_v1_2_0(props, 'content') || props.children;

    if (content) {
        const formattedContent = convertNewLineToBr(content);

        const pageOccurrenceId = props.pageOccurrenceId; // e.g. "home"
        const shouldType = checkIfPropContainsValue(props, 'typeOnPage', pageOccurrenceId);
        const [contentLoaded, setContentLoaded] = useState(false);
        const contentRef = useRef(null);

        typeContent(shouldType, contentRef, formattedContent, setContentLoaded);

        // todo rename to wiml-heading
        const defaultClass = props.sub != null ? 'sub-heading' : 'heading';
        const className = getComponentClassName_v1_2_0(props, defaultClass, 'wiml-heading');
        const Tag = props.tag || DEFAULT_TAG;
        const headingTag = <Tag onClick={props.onClick} ref={contentRef} className={className} >
            {/* <pre style={{ fontSize: '10px' }}>{JSON.stringify({ ...props, websiteData: null, baseThemeConfig: null, themes: null }, null, 2)}</pre> */}
            <V1_2_0_ComponentWrapper {...props} >
                <span className="inner-heading-content" dangerouslySetInnerHTML={{ __html: formattedContent }} />
            </V1_2_0_ComponentWrapper>
        </Tag>;

        // .heading:has(+ *:not(.wiml-hr)) {
        //     margin-bottom: .5rem; 
        // }
        const titleElement = (
            <>
                {headingTag}
                <style jsx global>{`                   
                    .wiml-heading {
                        margin: initial;
                    }

                    .wiml-heading:empty::before {
                        /*
                        That would add one &nbsp; before <span> contents if empty only and would not affect the layout of elements that have some text or children.
                        https://stackoverflow.com/questions/7770444/copyright-symbol-in-css-after-pseudo-element
                        https://stackoverflow.com/questions/2212861/css-debugging-help-empty-div-collapsing
                        https://stackoverflow.com/questions/36878850/octal-literals-are-not-allowed-in-strict-mode
                        */
                        content: "";
                        display: inline-block;
                    }

                    /* .heading .inner-heading-content, .sub-heading .inner-heading-content {
                        position: relative;
                    } */

                    /* todo: apply this when n columns beside each other, with heading and image at top */
                    /*:not(header) .wiml-col > .heading:first-child .inner-heading-content, :not(header) .wiml-col > .sub-heading:first-child .inner-heading-content {*/
                        /* line-height: .8;
                        min-height: 1.2em; */
                        /*top: -.2em;*/
                    /* } */

                    /* this makes image and text flush to the top of one another when in 2 diff columns */
                    @media ${breakpointsMin_v1_1_0.md} { 
                        .wiml-col:has(> .wiml-img:first-child) + .wiml-col > .wiml-heading:first-child,
                        .wiml-col:has(> .wiml-img:first-child) + .wiml-col > .wiml-link:first-child > .wiml-heading:first-child,
                        .wiml-col:has(> .wiml-link:first-child > .wiml-heading:first-child) + .wiml-col > .wiml-heading:first-child,
                        .wiml-col:has(> .wiml-link:first-child > .wiml-heading:first-child) + .wiml-col > .wiml-link:first-child > .wiml-heading:first-child,
                        .wiml-col:has(+ .wiml-col > .wiml-img:first-child) > .wiml-heading:first-child,
                        .wiml-col:has(+ .wiml-col > .wiml-img:first-child) > .wiml-link:first-child > .wiml-heading:first-child,
                        .wiml-col:has(+ .wiml-col > .wiml-link:first-child > .wiml-img:first-child) > .wiml-heading:first-child,
                        .wiml-col:has(+ .wiml-col > .wiml-link:first-child > .wiml-img:first-child) > .wiml-link:first-child > .wiml-heading:first-child {
                            top: -.2em;
                        }
                    }
                `}</style>
            </ >
        );
        retVal = titleElement;
    }
    return retVal;
}

function checkIfPropContainsValue(props, propName, valueToCheck) {
    const propValue = props[propName];

    const retVal = propValue == valueToCheck || propValue?.includes(valueToCheck);

    return retVal;
}

function typeContent(shouldType, elementRef, content, setContentLoaded) {
    useEffect(() => {
        if (shouldType) {
            // we want SSR to show name on load but in order to use typed.js we must firrst
            // clear out the text contents, otherwise it goes bonkers.
            elementRef.current.textContent = '';

            let contentTyped;
            const typedOptions = {
                typeSpeed: 100,
                showCursor: false,
                loop: false
            };

            contentTyped = new Typed(elementRef.current, {
                strings: [content],
                ...typedOptions,
                onComplete: () => {
                    setContentLoaded(true);
                }
            });

            return () => {
                contentTyped && contentTyped.destroy();
            };
        }
    }, []); // [] prevents the re-render on ALL state changes - which is what we want for typed.js
}
Heading_v1_2_0.inputProps = Heading_v1_0_0.inputProps;

const V1_2_0_FontComponentWrapper = (props: RuntimeWimlComponentProps_v1) => {
    const fontSizeClassName = _getFontSizeClassNames_v1_2_0(props);
    const fontWeightClassName = _getFontWeightClassNames_v1_2_0(props);
    const fontColorClassName = _getFontColorClassNames_v1_1_0(props);
    const wimlFontClassName = [fontSizeClassName, fontWeightClassName,].filter(Boolean).join(' ');
    const wimlFontColorClassName = [fontColorClassName].filter(Boolean).join(' ');

    return (
        <WrappedComponent {...props} wimlFontClassName={wimlFontClassName} />
    );
}
