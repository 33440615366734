import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBackward, faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createBook } from '@wip/common/event-store/website';
import PostList from './post-list';
import NavBlogTags from './nav-blog-tags';
import PostDetail from './post-detail';
import Post from "@wip/common/domain/post";

const Blog = (props) => {

    const [postDetail, setPostDetail] = useState(null);

    let blogViewElelement;

    function doThing() {
        setPostDetail(null);
    }

    if (postDetail) {
        const postData = props.websiteData.posts.find(b => b.id == postDetail);
        const post = new Post(postData.id, postData);

        blogViewElelement = (
            <>
                <Button color="link" onClick={doThing}><FontAwesomeIcon icon={faArrowLeft} /> Back</Button>
                <PostDetail post={post} {...props} />
            </>
        );
    } else {
        blogViewElelement = (
            <>
                <NavBlogTags {...props} />
                <PostList {...props} onSelectPost={setPostDetail} />
            </>
        );
    }

    return blogViewElelement;
}

export default Blog;
