import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import humps from "humps";
import React from "react";
import { ResponsiveClassTemplate_v1_2_0, convertPropsToClassNames_v1_2_0, getStyleCollectionString_v1_2_0 } from "../utils/styles";
const responsizeMaxWidthLookupClassNames = {
    '': {
        '5': 'wiml-max-width-5',
        '4': 'wiml-max-width-4',
        '3': 'wiml-max-width-3',
        '2': 'wiml-max-width-2',
        '1': 'wiml-max-width-1',
    },
    'lg': {
        '5': 'wiml-lg-max-width-5',
        '4': 'wiml-lg-max-width-4',
        '3': 'wiml-lg-max-width-3',
        '2': 'wiml-lg-max-width-2',
        '1': 'wiml-lg-max-width-1',
    },
    'md': {
        '5': 'wiml-md-max-width-5',
        '4': 'wiml-md-max-width-4',
        '3': 'wiml-md-max-width-3',
        '2': 'wiml-md-max-width-2',
        '1': 'wiml-md-max-width-1',
    },
    'sm': {
        '5': 'wiml-sm-max-width-5',
        '4': 'wiml-sm-max-width-4',
        '3': 'wiml-sm-max-width-3',
        '2': 'wiml-sm-max-width-2',
        '1': 'wiml-sm-max-width-1',
    },
    'xs': {
        '5': 'wiml-xs-max-width-5',
        '4': 'wiml-xs-max-width-4',
        '3': 'wiml-xs-max-width-3',
        '2': 'wiml-xs-max-width-2',
        '1': 'wiml-xs-max-width-1',
    }
} as const;


// 2 = 200px
// 3 = 300
// 4 = 400px
// 5 = 560
const responsizeMaxHeightLookupClassNames = {
    '': {
        '5': 'wiml-max-height-5',
        '4': 'wiml-max-height-4',
        '3': 'wiml-max-height-3',
        '2': 'wiml-max-height-2',
        '1': 'wiml-max-height-1',
    },
    'lg': {
        '5': 'wiml-lg-max-height-5',
        '4': 'wiml-lg-max-height-4',
        '3': 'wiml-lg-max-height-3',
        '2': 'wiml-lg-max-height-2',
        '1': 'wiml-lg-max-height-1',
    },
    'md': {
        '5': 'wiml-md-max-height-5',
        '4': 'wiml-md-max-height-4',
        '3': 'wiml-md-max-height-3',
        '2': 'wiml-md-max-height-2',
        '1': 'wiml-md-max-height-1',
    },
    'sm': {
        '5': 'wiml-xs-max-height-5',
        '4': 'wiml-xs-max-height-4',
        '3': 'wiml-xs-max-height-3',
        '2': 'wiml-xs-max-height-2',
        '1': 'wiml-xs-max-height-1',
    },
    'xs': {
        '5': 'wiml-xs-max-height-5',
        '4': 'wiml-xs-max-height-4',
        '3': 'wiml-xs-max-height-3',
        '2': 'wiml-xs-max-height-2',
        '1': 'wiml-xs-max-height-1',
    }
} as const;
type ResponsizeMaxWidthLookupClassNames = typeof responsizeMaxWidthLookupClassNames;
type ResponsizeMaxHeightLookupClassNames = typeof responsizeMaxHeightLookupClassNames
type ResponsizeAlignmentLookupClassNames = ResponsizeMaxWidthLookupClassNames & ResponsizeMaxHeightLookupClassNames;
type ResponsizeAlignmentLookupClassNamesKey = keyof ResponsizeAlignmentLookupClassNames;

type HorizontalPositionKeyNames = typeof responsizeMaxWidthLookupClassNames[''];
type VerticalPositionKeyNames = typeof responsizeMaxHeightLookupClassNames[''];
type PositionKeyNames = HorizontalPositionKeyNames & VerticalPositionKeyNames;
type PositionKey = keyof PositionKeyNames


const maxWidthLookupClassNames = Object.keys(responsizeMaxWidthLookupClassNames) as ResponsizeAlignmentLookupClassNamesKey[];
const horizontalCamelizedPropNames = maxWidthLookupClassNames.reduce((acc, size) => {
    const alignPropName = size ? `max-width-${size}` : 'max-width';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[size] = camelizedPropName;
    return acc;
}, {} as Record<ResponsizeAlignmentLookupClassNamesKey, string>);

const maxHeightLookupClassNames = Object.keys(responsizeMaxHeightLookupClassNames) as ResponsizeAlignmentLookupClassNamesKey[];
const verticalCamelizedPropNames = maxHeightLookupClassNames.reduce((acc, size) => {
    const alignPropName = size ? `max-height-${size}` : 'max-height';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[size] = camelizedPropName;
    return acc;
}, {} as Record<ResponsizeAlignmentLookupClassNamesKey, string>);

// this is a HOC react component that wraps any WIML component with an optional flex parent that provides for alignment
export default function withDimensions_v1_1_0(WrappedComponent: React.ComponentType) {
    return function (props: RuntimeWimlComponentProps_v1) {
        const className = _getDimensionsClassNames_v1_1_0(props);

        return <WrappedComponent {...props} wimlDimensionsClassName={className} />;
    }
}

export function _getDimensionsClassNames_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    const horizontalClassNames = maxWidthLookupClassNames.map((horizontalRepsoniveSizeProp) => {
        const camelizedPropName = horizontalCamelizedPropNames[horizontalRepsoniveSizeProp];
        const propValue = props[camelizedPropName] as PositionKey;
        if (propValue) {
            return [
                responsizeMaxWidthLookupClassNames[horizontalRepsoniveSizeProp][propValue]
            ];
        }
    });

    const verticalClassNames = maxHeightLookupClassNames.map((verticalRepsoniveSizeProp) => {
        const camelizedPropName = verticalCamelizedPropNames[verticalRepsoniveSizeProp];
        const propValue = props[camelizedPropName] as PositionKey;
        if (propValue) {
            return [
                responsizeMaxHeightLookupClassNames[verticalRepsoniveSizeProp][propValue]
            ];
        }
    });

    const className = [...horizontalClassNames, ...verticalClassNames].flat().filter(Boolean).join(' ');
    return className;
}

const maxWidthClassNamesTemplate = {
    '12': 'wiml-max-width-12',
    '11': 'wiml-max-width-11',
    '10': 'wiml-max-width-10',
    '9': 'wiml-max-width-9',
    '8': 'wiml-max-width-8',
    '7': 'wiml-max-width-7',
    '6': 'wiml-max-width-6',
    '5': 'wiml-max-width-5',
    '4': 'wiml-max-width-4',
    '3': 'wiml-max-width-3',
    '2': 'wiml-max-width-2',
    '1': 'wiml-max-width-1',
};

export function _getMaxWidthClassNames_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const classNames = convertPropsToClassNames_v1_2_0(props, maxWidthClassNamesTemplate, 'max-width');
    return classNames;
}

const maxHeightClassNamesTemplate = {
    '12': 'wiml-max-height-12',
    '11': 'wiml-max-height-11',
    '10': 'wiml-max-height-10',
    '9': 'wiml-max-height-9',
    '8': 'wiml-max-height-8',
    '7': 'wiml-max-height-7',
    '6': 'wiml-max-height-6',
    '5': 'wiml-max-height-5',
    '4': 'wiml-max-height-4',
    '3': 'wiml-max-height-3',
    '2': 'wiml-max-height-2',
    '1': 'wiml-max-height-1',
};

export function _getMaxHeightClassNames_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const classNames = convertPropsToClassNames_v1_2_0(props, maxHeightClassNamesTemplate, 'max-height');
    return classNames;
}


export function _getDimensionsStyleCollectionString_v1_2_0() {
    const maxWidthTemplate: ResponsiveClassTemplate_v1_2_0 = {
        'wiml-{size}-max-width-12': `max-width: 1100px`,
        'wiml-{size}-max-width-11': `max-width: 1000px`,
        'wiml-{size}-max-width-10': `max-width: 900px`,
        'wiml-{size}-max-width-9': `max-width: 800px`,
        'wiml-{size}-max-width-8': `max-width: 700px`,
        'wiml-{size}-max-width-7': `max-width: 600px`,
        'wiml-{size}-max-width-6': `max-width: 500px`,
        'wiml-{size}-max-width-5': `max-width: 400px`,
        'wiml-{size}-max-width-4': `max-width: 300px`,
        'wiml-{size}-max-width-3': `max-width: 200px`,
        'wiml-{size}-max-width-2': `max-width: 100px`,
        'wiml-{size}-max-width-1': `max-width: 50px`,
    };

    const maxHeightTemplate: ResponsiveClassTemplate_v1_2_0 = {
        'wiml-{size}-max-height-12': `max-height: 1100px`,
        'wiml-{size}-max-height-11': `max-height: 1000px`,
        'wiml-{size}-max-height-10': `max-height: 900px`,
        'wiml-{size}-max-height-9': `max-height: 800px`,
        'wiml-{size}-max-height-8': `max-height: 700px`,
        'wiml-{size}-max-height-7': `max-height: 600px`,
        'wiml-{size}-max-height-6': `max-height: 500px`,
        'wiml-{size}-max-height-5': `max-height: 400px`,
        'wiml-{size}-max-height-4': `max-height: 300px`,
        'wiml-{size}-max-height-3': `max-height: 200px`,
        'wiml-{size}-max-height-2': `max-height: 100px`,
        'wiml-{size}-max-height-1': `max-height: 50px`,
    };

    const styleCollectionString = getStyleCollectionString_v1_2_0([maxWidthTemplate, maxHeightTemplate]);
    return styleCollectionString;
}
