import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import React, { useRef, useState, useEffect } from "react";
import { fetcher } from "@wip/common/lib/data-utils";
import { getClient as getWebsiteClient } from "@wip/common/domain/website-service";
import fbClient, { firebase } from '@wip/common/lib/firebase';

const websiteApi = getWebsiteClient(fbClient);

export default function Manage(props) {
  const [state, setState] = useState({
    data: {},
    isLoaded: false
  });

  const handleStateChange = (name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  useEffect(async () => {
    const websiteIds = await websiteApi.getAllIds();

    const websites = [];
    for (const websiteId of websiteIds) {
      const metadata = await websiteApi.getMetadata(websiteId);
      websites.push({
        id: websiteId,
        ...metadata
      });
    }

    handleStateChange('data', websites);
    handleStateChange('isLoaded', true);
  }, []);
  let websites;
  if (state.isLoaded) {
    console.log('state.data', state.data);

    websites = state.data
      .filter((website) => website.appVersion === 'v4')
      .map((website) => {
        return (
          <div className="card" key={website.id}>
            <div className="card-body">
              <h5 className="card-title">{website.slug}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{website.id} {website.appVersion}</h6>
              <p className="card-text">{website.description}</p>
              <a target='__blank' href={"/manage/" + website.slug} className="card-link">Manage</a>
            </div>
          </div>
        );
      });
  }
  const retVal = (
    <div className="d-flex flex-column align-items-center">
      <h1>📛 Admin Dashboard 📛</h1>
      <div className="container-fluid mt-5">
        {websites}
      </div>
      <style jsx>{`
    `}</style>
    </div>
  );

  return retVal;
}
