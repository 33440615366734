import { establishPendingChangesListener, fetchPendingChanges, recordChanges, replayChanges, replayChangesSuccess } from "@wip/common/event-store/website";

const shouldRecordRegex = /^website\/(update|create|delete|add|remove|move|replace)/;

export const pendingChangesMiddleware = store => next => action => {
    const result = next(action);

    const state = store.getState();
    const { isReplaying } = state.website;

    if (!isReplaying) {
        if (typeof action == 'object') {
            if (shouldRecordRegex.test(action.type)) {
                store.dispatch(recordChanges(action));
            }
            else if (action.type == ('website/getDataSuccess')) {
                store.dispatch(establishPendingChangesListener()).then(action => {
                    // store.dispatch(fetchWebsiteHistory());
                    // todo: we are temporariyl or permanently not displaying history. still capturing it on each save tho.
                    // this also causes the manage/index page to re-render the layout based on isLoading.
                });
            }
            else if (action.type == ('website/establishPendingChangesListenerSuccess')) {
                throw new Error('deprecated');
                store.dispatch(replayChanges());

                action.payload.forEach(change => {
                    store.dispatch(change.action);
                });

                store.dispatch(replayChangesSuccess());
            } else {
                console.warn('pendingChangesMiddleware: unhandled action', action);
            }
            // else if (action.type == ('website/clearPendingChanges/fulfilled')) {
            //     window.location.reload();
            // }

            // TODO not moving this logic to middleware -- it's too complex. we need to use
            // redux saga or or redux rxjs
            // else if (action.type.startsWith('pendingChanges/commitPendingChanges/pending')) {
            //     store.dispatch(saveWebsiteData());
            // }
        }
    }


    return result
}
