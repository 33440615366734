import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormGroup, Label, Input, Button } from 'reactstrap';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';

import { updateAssets, updateStyle, saveFile } from '@wip/common/event-store/website';
import ThemeList from './theme-list-v1.3.0'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Style_v1_3_0 = (props) => {
    // eventually update inital state at apps/admin/src/components/manage/index.js:40

    // const [props, props.onChange] = useState({
    //     themeTag: "all",
    //     styleSection: null,
    // });
    const themeTag = props.themeTag || "all";
    const styleSection = props.styleSection || null;

    const dispatch = useDispatch();
    const { themes } = useSelector(
        (state) => state.themes_v2
    );

    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: props.websiteData.style,
    });

    // kind of annoying - https://github.com/react-hook-form/react-hook-form/issues/4414
    const { ref: baseThemeIdRef, onChange: baseThemeIdChange, ...baseThemeIdRest } = register('baseThemeId', { required: true });

    const onThemeIdChange = e => {
        baseThemeIdChange(e);
        handleChangeThemeEvent1(e);
    };

    const onLogoChange = e => {
        const logoFile = e.target.files[0];
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(saveFile(logoFile)).then(action => {
            const downloadUrl = action.payload;
            dispatch(updateAssets({ key: 'logoImage', value: downloadUrl }));
            e.target.value = "";
        });
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeThemeEvent1 = (event, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {

            if (event) {
                key = event.target.name;
                value = event.target.value;
            }

            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')
            const selectedTheme = themes.find(t => t.themeId == value);
            if (!selectedTheme) throw new Error('Selected theme not found.');
            dispatch(updateStyle({ key: 'baseThemeId', value: selectedTheme.themeId }));
            dispatch(updateStyle({ key: 'wiml', value: selectedTheme.wiml }));
        }
    };


    // todo - this is duplicated throughout the app -- combine.
    const handleChangeThemeEvent2 = (event, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {

            if (event) {
                value = event.target.name;
                value = event.target.value;
            }

            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            dispatch(updateStyle({ key: 'backgroundThemeId', value }));
        }
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeThemeEvent3 = (event, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {

            if (event) {
                key = event.target.name;
                value = event.target.value;
            }

            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            dispatch(updateStyle({ key: 'fontThemeId', value }));
        }
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeThemeEvent4 = (event, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {

            if (event) {
                key = event.target.name;
                value = event.target.value;
            }

            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            dispatch(updateStyle({ key: 'colorThemeId', value }));
        }
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeThemeEvent5 = (event, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {

            if (event) {
                key = event.target.name;
                value = event.target.value;
            }

            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            dispatch(updateStyle({ key: 'iconThemeId', value }));
        }
    };

    const themeList = themes.map(t => (
        <option key={t.themeId} value={t.themeId}>{t.themeName}</option>
    ));

    // remember, order matteres
    const newProps = { ...props, ...{ themeTag, styleSection }, };
    const baseThemeProps = { themeId: props.websiteData.style.baseThemeId, ...newProps, themes, onThemeChange: handleChangeThemeEvent1.bind(null, null), onGenreChange: props.onChange };
    const backgroundProps = { themeId: props.websiteData.style.backgroundThemeId, ...newProps, themes, onThemeChange: handleChangeThemeEvent2.bind(null, null), onGenreChange: props.onChange };
    const fontThemeProps = { themeId: props.websiteData.style.fontThemeId, ...newProps, themes, onThemeChange: handleChangeThemeEvent3.bind(null, null), onGenreChange: props.onChange };
    const colorThemeProps = { themeId: props.websiteData.style.colorThemeId, ...newProps, themes, onThemeChange: handleChangeThemeEvent4.bind(null, null), onGenreChange: props.onChange };
    const iconThemeProps = { themeId: props.websiteData.style.iconThemeId, ...newProps, themes, onThemeChange: handleChangeThemeEvent5.bind(null, null), onGenreChange: props.onChange };

    const toggle = e => {
        let section = e.target.dataset.section;

        const currentSection = props.styleSection;

        if (section == currentSection) {
            section = null;
        }

        props.onChange('styleSection', section);
    }


    const onBackgroundImageChange = e => {
        const key = e.target.dataset.key;
        const bgImage = e.target.files[0];
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(saveFile(bgImage)).then(action => {
            const downloadUrl = action.payload;

            dispatch(updateStyle({ key: 'backgroundThemeId', value: null }));
            dispatch(updateStyle({ key: 'backgroundImage', value: downloadUrl }));
            e.target.value = "";
        });
    };

    const onBackgroundImageRemove = e => {
        const key = e.target.dataset.key;
        dispatch(updateStyle({ key: 'backgroundImage', value: null }));
    };

    return (
        <div className="style">
            <div className="user-info">
                {/* <Form>
                    <FormGroup>
                        <Label for="logo">Logo</Label>
                        <Input type="file" accept="image/*" onChange={onLogoChange} />
                        <img src={props.websiteData.assets.logo} className="img-fluid shadow mt-4" />
                    </FormGroup>
                </Form> */}
            </div>
            <Card className="mb-3 theme-selector-wrapper">
                <CardHeader onClick={toggle} data-section='baseTheme' role='button'>Template</CardHeader>
                <Collapse isOpen={props.styleSection === 'baseTheme'}>
                    <CardBody>
                        <ThemeList {...baseThemeProps} />
                    </CardBody>
                </Collapse>
            </Card>
            <div className='d-none'>
                <Card className="mb-3">
                    <CardHeader onClick={toggle} data-section='background' role='button'>Background</CardHeader>
                    <Collapse isOpen={props.styleSection === 'background'}>
                        <CardBody>
                            <FormGroup>
                                <Label for="style.backgroundImage">Upload Your Own Background Image</Label>
                                <Input id="style.backgroundImage" type="file" accept="image/*" data-key='backgroundImage' onChange={onBackgroundImageChange} />
                                {
                                    props.websiteData.style.backgroundImage &&
                                    <Button outline color="primary" data-key='backgroundImage' onClick={onBackgroundImageRemove}>
                                        <FontAwesomeIcon icon={faTrash} /> Remove
                                    </Button>
                                }
                                <img src={props.websiteData.style.backgroundImage} className="img-fluid shadow mt-4" />
                            </FormGroup>
                            <ThemeList {...backgroundProps} />
                        </CardBody>
                    </Collapse>
                </Card>
                <Card className="mb-3">
                    <CardHeader onClick={toggle} data-section='font' role='button'>Font</CardHeader>
                    <Collapse isOpen={props.styleSection === 'font'}>
                        <CardBody>
                            {/* <FontList {...fontThemeProps} /> */}
                        </CardBody>
                    </Collapse>
                </Card>
                <Card className="mb-3">
                    <CardHeader onClick={toggle} data-section='color' role='button'>Color</CardHeader>
                    <Collapse isOpen={props.styleSection === 'color'}>
                        <CardBody>
                            {/* <ColorList {...colorThemeProps} /> */}
                        </CardBody>
                    </Collapse>
                </Card>
                <Card className="mb-3">
                    <CardHeader onClick={toggle} data-section='icons' role='button'>Icons</CardHeader>
                    <Collapse isOpen={props.styleSection === 'icons'}>
                        <CardBody>
                            {/* <IconList {...iconThemeProps} /> */}
                        </CardBody>
                    </Collapse>
                </Card>
            </div>
            <style jsx>{`
                .style {
                    display: flex;
                    flex-direction: column;
                    height: 80vh;
                }
                
                .style :global(.baseTheme-list) {
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                }

                .style :global(.baseTheme-list ul) {
                    overflow-y: scroll;
                }
            `}
            </style>
        </div>
    );
}

// <Form>
//     <FormGroup>
//         <Label for="baseTheme">Select</Label>
//         <Input type="select" innerRef={baseThemeIdRef} onChange={onThemeIdChange} {...baseThemeIdRest}>
//             {baseThemeList}
//         </Input>
//     </FormGroup>

// </Form>


export default Style_v1_3_0;

//  // how to watch data
//     // 1) https://github.com/react-hook-form/documentation/issues/74
//     // 2) https://github.com/react-hook-form/react-hook-form/discussions/2494
//     // 3) https://stackoverflow.com/questions/38022618/how-do-i-auto-save-with-multiple-form-fields-in-react
//     // 4) https://github.com/react-hook-form/react-hook-form/discussions/3620
//     // 5) https://github.com/react-hook-form/react-hook-form/issues/283
//     // 6) https://github.com/react-hook-form/react-hook-form/issues/3698#issuecomment-752261747
//     // 7) https://github.com/react-hook-form/react-hook-form/issues/1905
//     // https://react-hook-form.com/api/useform/watch
//     // https://github.com/tiaanduplessis/react-hook-form-persist/blob/master/src/index.js
//     watch((_, changedData) => {
//         // debounce https://www.synthace.com/autosave-with-react-hooks/
//         console.log(changedData.name, changedData.type, ':', changedData.value);
//     });

//        // https://github.com/react-hook-form/react-hook-form/discussions/2494
//        const formChanged = (e, e2) => {
//         const { isValid } = formState;
//         const formValues = getValues();
//         console.log("isValid = ", isValid);
//         console.log("formValues = ", formValues);
//         if (isValid) {
//             console.log("sending values to server");
//         }
//     };
