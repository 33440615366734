import { RuntimeWimlComponentProps_v1_0_0 } from "../../v1.0.0/theme-data/compiler/types";

export function getComponentClassName_v1_1_0(props: RuntimeWimlComponentProps_v1_0_0, ...defaultClasses: string[]) {
    // const alignClass = _getAlignClass(props);
    const classNames = [props.className,
    props.wimlFontClassName,
    props.wimlAlignChildrenClassName,
    props.wimlDimensionsClassName,
    props.wimlBoundaryClassName,
    props.wimlWrapClassName,
    ...defaultClasses /* , alignClass */];
    const className = classNames.filter(Boolean).join(' ');
    return className;
}
