import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createProduct, deleteProduct, } from '@wip/common/event-store/website';

const ProductList = (props) => {
    const dispatch = useDispatch();

    function addProduct(e) {
        dispatch(createProduct());
    }

    function removeProduct(id, e) {
        dispatch(deleteProduct({ id }));
    }

    function selectProduct(id, e) {
        props.onSelectProduct(id);
    }

    const productList = props.websiteData.products.map(p => (
        <ListGroupItem key={p.id}>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="link" onClick={selectProduct.bind(null, p.id)}>{p.about.title}</Button>

                <Button color="link" onClick={removeProduct.bind(null, p.id)}><FontAwesomeIcon icon={faTrash} /> Remove</Button>
            </div>

        </ListGroupItem>
    ));
    return (
        <Form>
            <Button outline color="primary" onClick={addProduct}>
                <FontAwesomeIcon icon={faPlus} /> Add New Product
            </Button>
            <ListGroup>
                {productList}
            </ListGroup>
        </Form>
    );
}

export default ProductList;
