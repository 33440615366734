import { useDispatch } from "react-redux";
import Settings from '../../../../../v3/settings';
import { updateAssets, saveFile, } from '@wip/common/event-store/website';
import { manageFavicon } from "../../../../../v3/about";

export default function Settings_v1_0_0(props) {
  const dispatch = useDispatch();

  const onProfileImageRemove = e => {
    const key = e.target.dataset.key;
    dispatch(updateAssets({ key, value: null }));
  };
  const onProfileImageChange = e => {
    const key = e.target.dataset.key;
    const profileImage = e.target.files[0];
    // dispatch async thunks are promises
    // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
    dispatch(saveFile(profileImage)).then(action => {
      const downloadUrl = action.payload;

      dispatch(updateAssets({ key, value: downloadUrl }));
      e.target.value = "";
    });
  };

  return <>
    <Settings {...props} />
    {manageFavicon(onProfileImageChange, props, onProfileImageRemove)}
  </>;
}
