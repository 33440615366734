import React from 'react';
// todo move this out of common into theme-list
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import SlickSlider from "react-slick";
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { renderChildren_v1_0_0 } from '../render-components';
const size = {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px'
};

const breakpoints = {
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
    xl: `(min-width: ${size.xl})`,
};


export function Slider_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    let autoPlaySpeed = 5000;
    if (props.speed) {
        const speedNum = parseInt(props.speed);
        autoPlaySpeed = speedNum;
    }

    let slidesToShow = 1;
    if (props.size) {
        // todo - this is confusing, because the admin has a "limit" input for list.
        // perhaps the slider should interpret the size based on columns, eg. if col size=4, then 12/4 = 3 slides
        const sizeNum = parseInt(props.size);
        slidesToShow = sizeNum;
    }

    const settings = {
        infinite: true,
        autoplaySpeed: autoPlaySpeed,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        // fade: true,
        autoplay: true,
    };

    const renderedChildren = renderChildren_v1_0_0(props.children);

    return (
        <div className="slick-wrapper">
            <SlickSlider {...settings}>
                {renderedChildren}
            </SlickSlider>
            <style jsx global>{`
                /*This handles overflow clipping. in some big headings, it gets clipped from slick-list */
                .slick-wrapper {
                    overflow-x: hidden;
                    overflow-y: visible;
                    padding-top: 5px;  /* Adjust based on how much overflow you need */
                }
                .slick-list{
                    overflow: visible;
                }
                /* https://stackoverflow.com/questions/49028877/slick-carousel-force-slides-to-have-the-same-height */
                .slick-track {
                    display: flex !important;
                }
                .slick-slide {
                    height: inherit !important;
                    display: flex;
                    align-items: center;
                }

                /*bg has same height */
                .slick-slide > div, .slick-slide > div > .list-item {
                    height: 100% !important;
                }

                /* https://github.com/akiran/react-slick/issues/2076 */
                .slick-slide:not(.slick-active) {
                    pointer-events: none;
                }

                .slick-prev {
                    left: 0;
                    z-index: 1;
                }

                .slick-next {
                    right: 0;
                    z-index: 1;
                }

                /*NOTE: in v1.0 we use global-button-color-bg */
                /*NOTE: in v1.1 we use global-button-bg-color */
                .slick-prev:before {
                    color: var(--global-button-color-bg, var(--global-button-bg-color));
                    content: '‹';
                    font-size: 3rem;
                    /*keeps the slider image middle aligned;*/
                    line-height: 0;
                }

                .slick-next:before {
                    color: var(--global-button-color-bg, var(--global-button-bg-color));
                    content: '›';
                    font-size: 3rem;
                    /*keeps the slider image middle aligned;*/
                    line-height: 0;
                }

                button.btn {
                    background-color: red;
                }

                button.slick-arrow  {
                    background: none !important;
                }

                @media ${breakpoints.lg} {
                    .slick-prev {
                        left: 1rem;
                    }

                    .slick-next {
                        right: 1.75rem;
                    }

                    .slick-prev:before {
                        font-size: 6rem;
                    }

                    .slick-next:before {
                        font-size: 6rem;
                    }
                }
            `}</style>
        </div>
    );
}
