import Stripe from 'stripe';

const stripeSecretKey = process.env.STRIPE_SECRET_KEY || process.env.REACT_APP_STRIPE_SECRET_KEY || process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY;
const stripe = new Stripe(stripeSecretKey);

export default stripe;

// https://gist.github.com/jylopez/ba16be2ae55282d5cff07de65128de83
export const currencyCodes = [
  { currency: "Afghan Afghani", code: "afn" },
  { currency: "Albanian Lek", code: "all" },
  { currency: "Algerian Dinar", code: "dzd" },
  { currency: "Angolan Kwanza", code: "aoa" },
  { currency: "Argentine Peso", code: "ars" },
  { currency: "Armenian Dram", code: "amd" },
  { currency: "Aruban Florin", code: "awg" },
  { currency: "Australian Dollar", code: "aud" },
  { currency: "Azerbaijani Manat", code: "azn" },
  { currency: "Bahamian Dollar", code: "bsd" },
  { currency: "Bangladeshi Taka", code: "bdt" },
  { currency: "Barbadian Dollar", code: "bbd" },
  { currency: "Belize Dollar", code: "bzd" },
  { currency: "Bermudian Dollar", code: "bmd" },
  { currency: "Bolivian Boliviano", code: "bob" },
  { currency: "Bosnia & Herzegovina Convertible Mark", code: "bam" },
  { currency: "Botswana Pula", code: "bwp" },
  { currency: "Brazilian Real", code: "brl" },
  { currency: "British Pound", code: "gbp" },
  { currency: "Brunei Dollar", code: "bnd" },
  { currency: "Bulgarian Lev", code: "bgn" },
  { currency: "Burundian Franc", code: "bif" },
  { currency: "Cambodian Riel", code: "khr" },
  { currency: "Canadian Dollar", code: "cad" },
  { currency: "Cape Verdean Escudo", code: "cve" },
  { currency: "Cayman Islands Dollar", code: "kyd" },
  { currency: "Central African Cfa Franc", code: "xaf" },
  { currency: "Cfp Franc", code: "xpf" },
  { currency: "Chilean Peso", code: "clp" },
  { currency: "Chinese Renminbi Yuan", code: "cny" },
  { currency: "Colombian Peso", code: "cop" },
  { currency: "Comorian Franc", code: "kmf" },
  { currency: "Congolese Franc", code: "cdf" },
  { currency: "Costa Rican Colón", code: "crc" },
  { currency: "Croatian Kuna", code: "hrk" },
  { currency: "Czech Koruna", code: "czk" },
  { currency: "Danish Krone", code: "dkk" },
  { currency: "Djiboutian Franc", code: "djf" },
  { currency: "Dominican Peso", code: "dop" },
  { currency: "East Caribbean Dollar", code: "xcd" },
  { currency: "Egyptian Pound", code: "egp" },
  { currency: "Ethiopian Birr", code: "etb" },
  { currency: "Euro", code: "eur" },
  { currency: "Falkland Islands Pound", code: "fkp" },
  { currency: "Fijian Dollar", code: "fjd" },
  { currency: "Gambian Dalasi", code: "gmd" },
  { currency: "Georgian Lari", code: "gel" },
  { currency: "Gibraltar Pound", code: "gip" },
  { currency: "Guatemalan Quetzal", code: "gtq" },
  { currency: "Guinean Franc", code: "gnf" },
  { currency: "Guyanese Dollar", code: "gyd" },
  { currency: "Haitian Gourde", code: "htg" },
  { currency: "Honduran Lempira", code: "hnl" },
  { currency: "Hong Kong Dollar", code: "hkd" },
  { currency: "Hungarian Forint", code: "huf" },
  { currency: "Icelandic Króna", code: "isk" },
  { currency: "Indian Rupee", code: "inr" },
  { currency: "Indonesian Rupiah", code: "idr" },
  { currency: "Israeli New Sheqel", code: "ils" },
  { currency: "Jamaican Dollar", code: "jmd" },
  { currency: "Japanese Yen", code: "jpy" },
  { currency: "Kazakhstani Tenge", code: "kzt" },
  { currency: "Kenyan Shilling", code: "kes" },
  { currency: "Kyrgyzstani Som", code: "kgs" },
  { currency: "Lao Kip", code: "lak" },
  { currency: "Lebanese Pound", code: "lbp" },
  { currency: "Lesotho Loti", code: "lsl" },
  { currency: "Liberian Dollar", code: "lrd" },
  { currency: "Macanese Pataca", code: "mop" },
  { currency: "Macedonian Denar", code: "mkd" },
  { currency: "Malagasy Ariary", code: "mga" },
  { currency: "Malawian Kwacha", code: "mwk" },
  { currency: "Malaysian Ringgit", code: "myr" },
  { currency: "Maldivian Rufiyaa", code: "mvr" },
  { currency: "Mauritanian Ouguiya", code: "mro" },
  { currency: "Mauritian Rupee", code: "mur" },
  { currency: "Mexican Peso", code: "mxn" },
  { currency: "Moldovan Leu", code: "mdl" },
  { currency: "Mongolian Tögrög", code: "mnt" },
  { currency: "Moroccan Dirham", code: "mad" },
  { currency: "Mozambican Metical", code: "mzn" },
  { currency: "Myanmar Kyat", code: "mmk" },
  { currency: "Namibian Dollar", code: "nad" },
  { currency: "Nepalese Rupee", code: "npr" },
  { currency: "Netherlands Antillean Gulden", code: "ang" },
  { currency: "New Taiwan Dollar", code: "twd" },
  { currency: "New Zealand Dollar", code: "nzd" },
  { currency: "Nicaraguan Córdoba", code: "nio" },
  { currency: "Nigerian Naira", code: "ngn" },
  { currency: "Norwegian Krone", code: "nok" },
  { currency: "Pakistani Rupee", code: "pkr" },
  { currency: "Panamanian Balboa", code: "pab" },
  { currency: "Papua New Guinean Kina", code: "pgk" },
  { currency: "Paraguayan Guaraní", code: "pyg" },
  { currency: "Peruvian Nuevo Sol", code: "pen" },
  { currency: "Philippine Peso", code: "php" },
  { currency: "Polish Złoty", code: "pln" },
  { currency: "Qatari Riyal", code: "qar" },
  { currency: "Romanian Leu", code: "ron" },
  { currency: "Russian Ruble", code: "rub" }, ,
  { currency: "Rwandan Franc", code: "rwf" },
  { currency: "São Tomé and Príncipe Dobra", code: "std" },
  { currency: "Saint Helenian Pound", code: "shp" },
  { currency: "Salvadoran Colón", code: "svc" },
  { currency: "Samoan Tala", code: "wst" },
  { currency: "Saudi Riyal", code: "sar" },
  { currency: "Serbian Dinar", code: "rsd" },
  { currency: "Seychellois Rupee", code: "scr" },
  { currency: "Sierra Leonean Leone", code: "sll" },
  { currency: "Singapore Dollar", code: "sgd" },
  { currency: "Solomon Islands Dollar", code: "sbd" },
  { currency: "Somali Shilling", code: "sos" },
  { currency: "South African Rand", code: "zar" },
  { currency: "South Korean Won", code: "krw" },
  { currency: "Sri Lankan Rupee", code: "lkr" },
  { currency: "Surinamese Dollar", code: "srd" },
  { currency: "Swazi Lilangeni", code: "szl" },
  { currency: "Swedish Krona", code: "sek" },
  { currency: "Swiss Franc", code: "chf" },
  { currency: "Tajikistani Somoni", code: "tjs" },
  { currency: "Tanzanian Shilling", code: "tzs" },
  { currency: "Thai Baht", code: "thb" },
  { currency: "Tongan Paʻanga", code: "top" },
  { currency: "Trinidad and Tobago Dollar", code: "ttd" },
  { currency: "Turkish Lira", code: "try" },
  { currency: "Ugandan Shilling", code: "ugx" },
  { currency: "Ukrainian Hryvnia", code: "uah" },
  { currency: "United Arab Emirates Dirham", code: "aed" },
  { currency: "United States Dollar", code: "usd" },
  { currency: "Uruguayan Peso", code: "uyu" },
  { currency: "Uzbekistani Som", code: "uzs" },
  { currency: "Vanuatu Vatu", code: "vuv" },
  { currency: "Vietnamese Đồng", code: "vnd" },
  { currency: "West African Cfa Franc", code: "xof" },
  { currency: "Yemeni Rial", code: "yer" },
  { currency: "Zambian Kwacha", code: "zmw" }
];
