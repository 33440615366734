import _ from 'lodash';
import { nanoid, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { decode, encode } from 'firebase-encode';
import humps from 'humps';
import getFirebaseClient, { prepareFirebaseObjectV3 } from '../lib/firebase';
import * as objectUtils from '../lib/object-utils';
import Website from './website';
import { fetcher } from '@wip/common/lib/data-utils'
import { convertToSlug, truncate } from '../lib/text-utils';
import { daysDiff } from '../lib/date-utils';

const ANALYTICAL_PATH = 'analytical';

export function getClient(firebaseClient) {
    async function _getTrialsInProgressData() {
        let retVal = null;

        try {
            const snapshot = await firebaseClient.database().ref(ANALYTICAL_PATH).child(`/trials_in_progress/`).once('value');
            let data = snapshot.val();

            if (data) {
                let camelizedData = humps.camelizeKeys(data);

                const trialsInProgress = _
                    .chain(objectUtils.convertObjectToArray(camelizedData))
                    .map(t => ({ ...t, createdDate: new Date(t.createdDate) }))
                    .sortBy(t => t.createdDate)
                    .reverse()
                    .value();

                retVal = trialsInProgress;
            }

            return retVal;
        } catch (e) {
            console.error('error', e)
            const rethrow = new Error('error getting analytics data: ' + e + '\n\n');
            rethrow.stack = e.stack;
            throw rethrow;
        }
    }

    async function _getSupportMessagesData() {
        let retVal = null;

        try {
            const snapshot = await firebaseClient.database().ref(ANALYTICAL_PATH).child(`/support_messages/`).once('value');
            let data = snapshot.val();

            if (data) {
                let camelizedData = humps.camelizeKeys(data);

                const supportMessages = _
                    .chain(objectUtils.convertObjectToArray(camelizedData))
                    .map(t => ({ ...t, date: new Date(t.date) }))
                    .sortBy(t => t.date)
                    .reverse()
                    .value();

                retVal = supportMessages;
            }

            return retVal;
        } catch (e) {
            console.error('error', e)
            const rethrow = new Error('error getting analytics data: ' + e + '\n\n');
            rethrow.stack = e.stack;
            throw rethrow;
        }
    }

    async function _getWebsiteFilters(websiteId) {
        let retVal = null;

        try {
            const snapshot = await firebaseClient.database().ref(ANALYTICAL_PATH).child(`/filter/websites/${websiteId}/`).once('value');
            let data = snapshot.val();

            if (data) {
                let camelizedData = humps.camelizeKeys(data);

                let ipFilterList = [];

                if (camelizedData.byIp) {
                    ipFilterList = objectUtils.convertObjectToArray(camelizedData.byIp).map(ip => decode(ip.id));
                }

                retVal = {
                    byIp: ipFilterList,
                };
            }

            return retVal;
        } catch (e) {
            console.error('error', e)
            const rethrow = new Error('error getting filter ip data: ' + e + '\n\n' + ': ' + websiteId);
            rethrow.stack = e.stack;
            throw rethrow;
        }
    }

    const client = {

        getDashboardData: async function () {
            const retVal = {
                trialsInProgress: null,
                supportMessages: null,
            };

            retVal.trialsInProgress = await _getTrialsInProgressData();
            retVal.supportMessages = await _getSupportMessagesData();

            return retVal;
        },


        getWebsiteFilters: async function (websiteId) {
            const websiteFilters = await _getWebsiteFilters(websiteId) ?? {};
            const websiteIpFilters = websiteFilters.byIp ??= [];

            const allWebsiteFilters = await _getWebsiteFilters('*') ?? {};
            const allWwebsiteIpFilters = allWebsiteFilters.byIp ??= [];

            const retVal = {
                byIp: [
                    ...websiteIpFilters,
                    ...allWwebsiteIpFilters,
                ],
            }

            return retVal;
        },

        saveWebsiteTrialData: async function (websites, users) {
            let retVal;
            let data;

            try {
                // if purchased is false
                const sitesNotBought = websites
                    .filter(w => w.meta.purchased == false)
                    .filter(w => w.meta.websiteType == 'standard');

                const today = new Date();
                const recentSites = sitesNotBought.filter(w => daysDiff(today, w.meta.createdDate) <= 60);

                // in last 60 days

                // get start date
                //
                data = recentSites
                    .map(w => getWebsiteWithUser(w, users))
                    .map(w => getTrialDataFromWebsite(w));

            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error getting saving website changes: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }

            try {
                // const decamelizedKeys = humps.decamelizeKeys(data);
                // const arrayToObj = objectUtils.convertArrayToObject(decamelizedKeys);
                // const firebaseWebsiteData = prepareFirebaseObject(arrayToObj);

                const arrayToObj = objectUtils.convertArrayToObject(data);

                const firebaseWebsiteData = prepareFirebaseObjectV3(arrayToObj);

                // todo this solves the problem we run into with customHtml.pages 
                // each page key is the id, this causes a problem when we getData. it decamlizes everything upon save.
                // we should update the save website data behavior to do this too. that way the db will store 
                // the correctly formtaed ids.
                const dataWithCamlizedKeys = objectUtils.camelizeFirstLayer(firebaseWebsiteData);

                let status = await firebaseClient.database().ref(ANALYTICAL_PATH).child(`/trials_in_progress/`).set(dataWithCamlizedKeys);

                retVal = status;

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error getting saving website changes: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        saveSupportMessageData: async function (supportMessage) {
            let retVal;
            // const { conversationId, message, messageid, date, name, channel, link, ...rest } = supportMessage;

            if (Object.keys(supportMessage).length < 8) {
                throw new Error('conversationId, message, messageid, date, name, email, channel, link required');
            }

            try {
                const { message, ...rest } = supportMessage;
                const truncatedMessage = truncate(message, 100);
                const data = { ...rest, message: truncatedMessage };
                const firebaseWebsiteData = prepareFirebaseObjectV3(data);

                let status = await firebaseClient.database().ref(ANALYTICAL_PATH).child(`/support_messages/`).push().set(firebaseWebsiteData);

                retVal = status;

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error getting saving website changes: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },


        saveWebsiteIpFilter: async function (websiteId, ipString, isAdmin) {
            let retVal;

            let websiteKey;
            if (isAdmin) {
                websiteKey = '*';
            } else {
                websiteKey = websiteI;
            }

            try {
                // let status = await firebaseClient.database().ref(ANALYTICAL_PATH).child(`/support_messages/`).push().set(firebaseWebsiteData);
                const ipKey = encode(ipString);
                const ipObj = { [ipKey]: true }
                let status = await firebaseClient.database().ref(ANALYTICAL_PATH).child(`/filter/websites/${websiteKey}/by_ip/`).set(ipObj);

                retVal = status;

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error saving ip changes: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

    };

    return client;
}

function getTrialDataFromWebsite({ website, user }) {
    const retVal = {
        id: website.id,
        name: website.about.firstName + ' ' + website.about.lastName,
        progress: getTrialProgressFromWebsite(website),
        createdDate: website.meta.createdDate,
    };

    return retVal
}

function getWebsiteWithUser(website, users) {
    const user = users.find(u => u.websites.includes(website.id));

    const retVal = {
        website: { ...website },
        user: { id: user.id }
    };

    return retVal
}

function getTrialProgressFromWebsite(website) {
    let progress = 0;

    if (website.books.length) {
        progress += 10;
    }

    if (website.about.bio) {
        progress += 10;

        if (website.about.bio.length > 240) {
            progress += 10;
        }
    }

    if (website.posts.length) {
        progress += 10;
    }

    if (website.products.length) {
        progress += 10;
    }

    if (Object.keys(website.social).length) {
        progress += 10;
    }

    if (website.customPages.length || website.landingPages.length) {
        progress += 10;
    }

    if (Object.keys(website.customHtml.pages || {}).length) {
        progress += 10;
    }

    return progress;
}
