import { objectUtils } from '../../../../../../../';
import _ from "lodash";
import dot from 'dot-object';

import { _getComponentId_v1_0_0, isThemeDataListComponent_v1_0_0 } from "../../../v1.0.0/theme-data/compiler/components";
import { _isElementNode_v1_0_0, _isListNode_v1_0_0, getNodeAttr_v1_0_0, getWimlPageNodeCollectionFromNode_v1_0_0 } from "../../../v1.0.0/theme-data/compiler/nodes";
import { WimlNode_v1_0_0 } from "../../../v1.0.0/theme-data/compiler/types";
import { ThemeDataPageList_v1, ListInstanceComponentThemeData_v1, ThemeDataPageQualifiedListComponent_v1, ThemeDataListQualifiedAndMerged_v1, ThemeDataListCollection_v1, ThemeDataPageListQualifiedComponent_v1, ThemeDataPageComponent_v1, ThemeDataPageChildComponent_v1 } from "../../../types";
import { RuntimeWimlComponentCollection_v1_0_0 } from "../../../v1.0.0/theme-data/renderer/types";
import { wimlComponents_v1_0_0 } from "../../../v1.0.0/theme-data/renderer/wiml-components-v1.0.0";
import { _getThemeDataComponentId_v1_2_0, formatNodeKey_v1_2_0 } from "./components";
import { getListIdFromNodeAttrs_v1_2_0, processListQualifiedNodeChildren_v1_2_0 } from "./nodes";
import { mergeListInstanceComponents_v1_0_0 } from "../../../v1.0.0/theme-data/compiler/list-components";
import { convertArrayToObject, convertObjectToArray } from '../../../../../../../lib/object-utils';

export function getThemeDataListComponentCollectionFromNode_v1_2_0(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, node: WimlNode_v1_0_0) {
    // extract all top-level pages including header and footer
    const nodesByPage = getWimlPageNodeCollectionFromNode_v1_0_0(node);

    // copy key names from allComponents to new variable
    const themeDataListComponents = Object.keys(nodesByPage)
        .map((pageKey) => {
            const page = nodesByPage[pageKey];
            const listComponentsArray = _getThemeDataListInstanceAndChildrenComponentsFromPage_v1_2_0(runtimeComponents, page);

            const listComponentsArrayPageOccurrence = listComponentsArray.map((c) => {
                if (c.type === 'List') {
                    const retVal = { ...c, pageOccurrenceId: 'page__' + formatNodeKey_v1_2_0(pageKey) } as ThemeDataPageList_v1;
                    return retVal;
                } else {
                    const retVal = c;
                    return retVal;
                }
            });
            return listComponentsArrayPageOccurrence;
        })
        .flat() as ListInstanceComponentThemeData_v1[];

    // group by list id and deep merge
    // this "resets" the id to remove the list prefix
    const fullyQualifiedListComponents: ListInstanceComponentThemeData_v1[] = themeDataListComponents.map((c) => ({ ...c, id: _getThemeDataComponentId_v1_2_0(c) }));
    // const fullyQualifiedListComponents: ListInstanceComponentThemeData_v1_0_0[] = themeDataListComponents.map((c) => ({ ...c, }));
    // console.log({ fullyQualifiedListComponents, fullyQualifiedListComponents2 });
    // todo v1.2 ideally just use this: const fullyQualifiedListComponents: ListInstanceComponentThemeData_v1_0_0[] = themeDataListComponents.map((c) => ({ ...c }));
    const themeDataListQualifiedAndMergedComponents = mergeListInstanceComponents_v1_0_0(fullyQualifiedListComponents);

    // this ensures list and its components get same namespace. remove the qualified id.
    const nonListComponents = fullyQualifiedListComponents.filter((c) => c.type != 'List');

    const mergedListsAndNonListComponents = [...themeDataListQualifiedAndMergedComponents, ...nonListComponents];
    const listQualifiedComponentsObject = mergedListsAndNonListComponents.map((c) => getThemeDataPageListQualifiedComponent_v1_2_0(c));

    // remove id from child components
    const cleansedListQualifiedComponentsObject = listQualifiedComponentsObject.map((c) => {
        if (isThemeDataListComponent_v1_0_0(c)) {
            const { id, ...rest } = c;
            return rest;
        }
        else {
            const { id, listId, ...rest } = c;
            return rest;
        }
    });

    const listComponentsObject = objectUtils.convertArrayToObject(cleansedListQualifiedComponentsObject, 'lqId');

    // split keys on . and create nested objects
    const nestedPageComponents = dot.object({ ...listComponentsObject }) as Record<string, ThemeDataListQualifiedAndMerged_v1>;

    // const camelizedPageComponents3 = pipe(
    //     nestedPageComponents,
    //     R.map(listObj => ({
    //         ...listObj,
    //         components: {
    //             ...listObj.components,
    //             ids: Object.keys(listObj.components.items),
    //         },
    //     }))
    // ) as Record<string, ThemeDataListQualifiedAndMergedWithComponents_v1_0_0>;
    const camelizedPageComponents4 = convertObjectToArray(nestedPageComponents).map((c) => {
        return {
            ...c,
            id: `${c.id}`,
            listId: `${c.id}`,
            // id: `_getThemeDataComponentId_v1_2_0(c)`,
            components: {
                ...c.components,
                ids: Object.keys(c.components.items),
            }
        };
    });

    const camelizedPageComponents5 = convertArrayToObject(camelizedPageComponents4, 'id');
    // const camelizedPageComponents5 = camelizedPageComponents3

    // console.log({ a: camelizedPageComponents3, b: camelizedPageComponents4, c: camelizedPageComponents5 });
    const retVal = { ids: Object.keys(camelizedPageComponents5), items: camelizedPageComponents5 } as ThemeDataListCollection_v1;

    return retVal;
}

export function getThemeDataPageListQualifiedComponent_v1_2_0(c: ThemeDataPageQualifiedListComponent_v1): ThemeDataPageListQualifiedComponent_v1 {
    //eg if user types book-list, becomes book_list
    const list222 = formatNodeKey_v1_2_0(c.listId);

    if (isThemeDataListComponent_v1_0_0(c)) {
        const retVal = { ...c, lqId: list222 };
        return retVal;

    } else {
        const retVal = { ...c, lqId: `${list222}.components.items.${c.id}` };
        return retVal;
    }
}

export function _getThemeDataListInstanceAndChildrenComponentsFromPage_v1_2_0(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, node: WimlNode_v1_0_0, parentListId: string = '', parentListInstanceComponentId: string = ''): ThemeDataPageComponent_v1[] {
    // todo fix this because checing if is text node is not enough, what if <div> or <img> -- the answer: check ifIWmlComponent

    if (_isElementNode_v1_0_0(node)) {
        // console.group();
        BeginLog(node, parentListId, parentListInstanceComponentId);
        const abc = processListQualifiedNodeChildren_v1_2_0(runtimeComponents, node, parentListId, parentListInstanceComponentId, false, _getThemeDataListInstanceAndChildrenComponentsFromPage_v1_2_0);

        const isList = _isListNode_v1_0_0(node);
        const currentListId = isList ? getListIdFromNodeAttrs_v1_2_0(node) : parentListId;
        const lookup = wimlComponents_v1_0_0[node.tag];

        if (currentListId && lookup?.inputProps) {
            const { ...rest } = createThemeDataListInstanceAndChildComponent_v1_2_0(node, currentListId, parentListInstanceComponentId);
            const component = { ...rest };

            EndLog(node, parentListId, parentListInstanceComponentId);
            // console.groupEnd();
            return [...abc, component];
        } else {
            EndLog(node, parentListId, parentListInstanceComponentId);
            // console.groupEnd();
            return abc;
        }
    } else {
        EndLog(node, parentListId, parentListInstanceComponentId);
        // console.groupEnd();
        return [];
    }
}
function BeginLog(node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string) {
    return;
    // const beginString = `Beginning processing of NODE:${node.tag} KEY:${node.attr?.key}     LISTID:${options.listId} parentListId:${parentListId} LISTCOMPONENTID:${options.listComponentId} parentListComponentId: ${parentListComponentId}`;
    // if (options.listId != parentListId || options.listComponentId != parentListComponentId) {
    //     console.warn(beginString);
    // } else {
    //     console.log(beginString);
    // }
}


function EndLog(node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string) {
    return;
    // const endString = `End processing of NODE:${node.tag} KEY:${node.attr?.key}   LISTID:${options.listId} parentListId:${parentListId} LISTCOMPONENTID:${options.listComponentId} parentListComponentId: ${parentListComponentId}`;
    // if (options.listId != parentListId || options.listComponentId != parentListComponentId) {
    //     console.warn(endString);
    // } else {
    //     console.log(endString);
    // }
}// todo move to list-components

export function createThemeDataListInstanceAndChildComponent_v1_2_0(node: WimlNode_v1_0_0, parentListId: string, parentListInstanceComponentId: string): ThemeDataPageChildComponent_v1 {
    const { key, ...themeProps } = getNodeAttr_v1_0_0(node);
    const type = node.tag;

    const componentId = _getThemeDataComponentId_v1_2_0({
        type,
        key,
        parentListInstanceComponentId,
    }, true);
    return {
        type: node.tag,
        ...({ listId: themeProps.listId ? 'list__' + formatNodeKey_v1_2_0(themeProps.listId) : parentListId }),
        ...(key && { key: formatNodeKey_v1_2_0(key) }),
        id: componentId,
    };
}

