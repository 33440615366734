import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { ResponsiveClassTemplate_v1_2_0, convertPropsToClassNames_v1_2_0, getStyleCollectionString_v1_2_0 } from "../utils/styles";

const justifyContentXClassNamesTemplate = {
    'left': ['wiml-align-x', 'wiml-align-x-left'],
    'center': ['wiml-align-x', 'wiml-align-x-center'],
    'right': ['wiml-align-x', 'wiml-align-x-right'],
};

const justifyContentYClassNamesTemplate = {
    'top': ['wiml-align-y', 'wiml-align-y-top'],
    'center': ['wiml-align-y', 'wiml-align-y-center'],
    'bottom': ['wiml-align-y', 'wiml-align-y-bottom'],
};

export function _getAlignmentClassNames_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const alignXClassNames = convertPropsToClassNames_v1_2_0(props, /*this gets turned into responsive class names*/justifyContentXClassNamesTemplate, 'align-x', /*this DOES NOT get turned into responsive class names*/'wiml-alignment-x');
    const alignYClassNames = convertPropsToClassNames_v1_2_0(props, justifyContentYClassNamesTemplate, 'align-y', 'wiml-alignment-y');

    return [alignXClassNames, alignYClassNames].filter(Boolean).join(' ');
}

export function _getAlignmentChildrenClassNames_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const alignXClassNames = convertPropsToClassNames_v1_2_0(props, justifyContentXClassNamesTemplate, 'align-items-x', 'wiml-alignment-x');
    const alignYClassNames = convertPropsToClassNames_v1_2_0(props, justifyContentYClassNamesTemplate, 'align-items-y', 'wiml-alignment-y');

    return [alignXClassNames, alignYClassNames].filter(Boolean).join(' ');
}

export function _getAlignmentStyleCollectionString_v1_2_0() {
    const alignXTemplate: ResponsiveClassTemplate_v1_2_0 = {
        'wiml-{size}-align-x-left': `justify-content: start`,
        'wiml-{size}-align-x-center': [`justify-content: center`, `text-align: center`],
        'wiml-{size}-align-x-right': `justify-content: end`,
        'wiml-col.wiml-{size}-align-x-left': `align-items: start`,
        'wiml-col.wiml-{size}-align-x-center': [`margin-left: auto`, `margin-right: auto`],
        'wiml-col.wiml-{size}-align-x-right': `align-items: end`,
        'wiml-col > .wiml-{size}-align-x-left': `margin-right: auto`,
        'wiml-col > .wiml-{size}-align-x-center': [`margin-right: auto`, `margin-left: auto`],
        'wiml-col > .wiml-{size}-align-x-right': `margin-left: auto`,
    };

    const alignYTemplate: ResponsiveClassTemplate_v1_2_0 = {
        'wiml-{size}-align-y-top': `align-items: start`,
        'wiml-{size}-align-y-center': `align-items: center`,
        'wiml-{size}-align-y-bottom': `align-items: end`,
        'wiml-col.wiml-{size}-align-y-top': `justify-content: start`,
        'wiml-col.wiml-{size}-align-y-center': `justify-content: center`,
        'wiml-col.wiml-{size}-align-y-bottom': `justify-content: end`,
        'wiml-col > .wiml-{size}-align-y-top': `margin-bottom: auto`,
        'wiml-col > .wiml-{size}-align-y-center': [`margin-bottom: auto`, `margin-top: auto`],
        'wiml-col > .wiml-{size}-align-y-bottom': `margin-top: auto`,
    };

    const styleCollectionString = getStyleCollectionString_v1_2_0([alignXTemplate, alignYTemplate]);
    return styleCollectionString;
}


