import { DataClass } from "../lib/object-utils";


 export default class NavigationGroupItem extends DataClass {
    constructor(navigationGroupItemId, data) {
        super(data);

        this.id = navigationGroupItemId;

        this.page = this.page || {};
        this.meta = this.meta || {};
    }
}
