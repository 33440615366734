import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";

import humps from "humps";
import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getChildComponentPropertyValueSiteData_v1_2_0 } from "../../../site-data/retrieval";
import { iconOptions_v1_0_0 } from "../../../../v1.0.0/theme-data/renderer/components/icon";
import { Icon_v1_1_0, _getIconClassNames_v1_1_0 } from "../../../../v1.1.0/theme-data/renderer/components/icon";

export function Icon_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    let retVal = null;

    const iconType = getChildComponentPropertyValueSiteData_v1_2_0(props, 'type');

    if (iconType) {
        const icon = iconOptions_v1_0_0.find((option) => option.key === iconType);
        const className = _getIconClassNames_v1_1_0(props);

        retVal = <FontAwesomeIcon icon={icon.icon} className={className} />;
    }

    return retVal;
}
Icon_v1_2_0.inputProps = Icon_v1_1_0.inputProps;
