import XsdManager from './XsdManager'
import XsdCompletion from './XsdCompletion'
import XsdValidation from './XsdValidation'
import { debounce } from 'ts-debounce'
import xsdGenerateTemplate from './XsdGenerateTemplate'
import prettier from 'prettier'
import parserHTML from 'prettier/parser-html'
import type { Monaco } from '@monaco-editor/react'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

import type { editor } from 'monaco-editor'
import { XmlValidtorFunc } from './types'
// import { type editor } from 'monaco-editor'
// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
// ?import IStandaloneCodeEditor = editor.IStandaloneCodeEditor
type IStandaloneCodeEditor = monaco.editor.IStandaloneCodeEditor;
export default class XsdFeatures {
    private readonly xsdCollection: XsdManager
    private monaco: Monaco
    private oldDecorations: string[] = []
    private editor: IStandaloneCodeEditor;
    private xsdValidation: XsdValidation | undefined
    private xsdGenerateTemplate: xsdGenerateTemplate | undefined
    private validateXML: XmlValidtorFunc

    constructor(xsdCollection: XsdManager, monaco: any, editor: IStandaloneCodeEditor, validateXML: XmlValidtorFunc) {
        this.xsdCollection = xsdCollection
        this.monaco = monaco
        this.editor = editor

        if (!validateXML) throw new Error('validateXML is not defined. Make sure to include the validateXML script in your html file.')
        this.validateXML = validateXML;

        this.editor.updateOptions({
            wordSeparators: '`~!@#$%^&*()-=+[{]}\\|;\'",.<>/?',
        })
    }

    public addCompletion = (): void => {
        ;
        const xsdCompletion = new XsdCompletion(this.xsdCollection)
        this.monaco.languages.registerCompletionItemProvider('html', xsdCompletion.provider())
    }

    public doValidation = (): void => {
        this.xsdValidation = this.xsdValidation ?? new XsdValidation(this.xsdCollection, this.validateXML)
        const model = this.editor.getModel()

        // decorations not showing up in the editor
        // const newDecorations = this.xsdValidation.decorations(model)
        // this.editor.createDecorationsCollection(newDecorations)
        // console.log({ newDecorations })

        const newMarkers = this.xsdValidation.markers(model)
        this.monaco.editor.setModelMarkers(model!, 'xsd', newMarkers)
        console.log({ newMarkers })
    }

    public addValidation = (): void => {
        this.xsdValidation = new XsdValidation(this.xsdCollection, this.validateXML)
        const debouncedDoValidation = debounce(this.doValidation, 1000)
        this.editor.onKeyDown(debouncedDoValidation)
        this.doValidation()
    }

    public doReformatCode = () => {
        const model = this.editor.getModel()
        if (model) model.setValue(this.prettier(model.getValue()))
    }

    public prettier = (xml: string): string =>
        prettier.format(xml, {
            parser: 'html',
            plugins: [parserHTML],
            tabWidth: 4,
        })

    public doGenerate = (levels: number, withAttributes: boolean): void => {
        this.xsdGenerateTemplate =
            this.xsdGenerateTemplate ?? new xsdGenerateTemplate(this.xsdCollection)
        const model = this.editor.getModel()
        const template = this.xsdGenerateTemplate.getTemplate(model, levels, withAttributes)
        if (template) model?.setValue(this.prettier(template))
    }

    public addGenerateAction = (): void => {
        this.xsdGenerateTemplate = new xsdGenerateTemplate(this.xsdCollection)
        this.editor.addAction({
            id: 'xsd-template-generate-without-attributes',
            label: 'Generate Template from XSD',
            keybindings: [
                this.monaco.KeyMod.CtrlCmd | this.monaco.KeyMod.Shift | this.monaco.KeyCode.KEY_G,
            ],
            contextMenuGroupId: '1_modification',
            contextMenuOrder: 2,
            run: () => this.doGenerate(parseInt(window.prompt('levels', '1') ?? ''), false),
        })
        this.editor.addAction({
            id: 'xsd-template-generate-with-attributes',
            label: 'Generate Template from XSD with requires attributes',
            keybindings: [
                this.monaco.KeyMod.CtrlCmd |
                this.monaco.KeyMod.Alt |
                this.monaco.KeyMod.Shift |
                this.monaco.KeyCode.KEY_G,
            ],
            contextMenuGroupId: '1_modification',
            contextMenuOrder: 3,
            run: () => this.doGenerate(parseInt(window.prompt('levels', '1') ?? ''), true),
        })
    }

    public addReformatAction = (): void => {
        this.editor.addAction({
            id: 'xsd-reformat-code',
            label: 'Reformat code',
            keybindings: [
                this.monaco.KeyMod.CtrlCmd | this.monaco.KeyMod.Shift | this.monaco.KeyCode.KEY_R,
            ],
            contextMenuGroupId: '1_modification',
            contextMenuOrder: 1.5,
            run: this.doReformatCode,
        })
    }
}
