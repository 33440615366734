import * as chrono from 'chrono-node';

export function formatStringAsDate(str) {
    if (!str) throw new Error('str is empty');

    const date = getDateFromString(str);
    const retVal = localeDateString(date);

    return retVal;
}

export function localeDateString(date) {
    if (!date) throw new Error('Date is empty');

    const retVal = date.toLocaleDateString();

    return retVal;
}

export function dateYYYYMMDD(date) {
    if (!date) throw new Error('Date is empty');

    const retVal = date.toISOString().split('T')[0];

    return retVal;
}

export function formatDate(date) {
    if (!date) throw new Error('Date is empty');

    // const retVal = date.toString().split(' ').splice(1, 3).join(' ');
    const retVal = date.toUTCString().split(' ').splice(1, 3).join(' ');

    return retVal;
}

export function daysDiff(date1, date2) {
    if (!date1) throw new Error('Date1 is empty');
    if (!date2) throw new Error('Date2 is empty');

    const daysDiff = Math.ceil((date1 - date2) / (1000 * 60 * 60 * 24));

    return daysDiff;
}

export function isPast(date) {
    if (!date) throw new Error('Date is empty');

    const retVal = date < new Date();

    return retVal;
}

export function isFuture(date) {
    if (!date) throw new Error('Date is empty');

    const retVal = date > new Date();

    return retVal;
}

// todo - fix getDateFromString and findDateInText
export function getDateFromString(str) {
    let retVal = null;
    const temp = new Date(str);

    if (/invalid/i.test(temp)) {
        retVal = chrono.parseDate(str);
    } else {
        retVal = temp;
    }

    return retVal
}

export function findDateInText(str) {
    // if (!str) throw new Error('Date is empty');
    let retVal = null;

    if (str) {
        retVal = getDateFromString(str);
    }

    return retVal;
}

// TODO consider replacing with dayjs
export function parseDate(str) {
    if (!str) throw new Error('Date is empty');

    const retVal = chrono.parseDate(str);

    return retVal;
}
