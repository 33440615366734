import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBackward, faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createBook } from '@wip/common/event-store/website';
import SlideList from './slide-list';
import SlideDetail from './slide-detail';
import Slide from "@wip/common/domain/slide";

const Slides = (props) => {

    const [slideDetail, setSlideDetail] = useState(null);

    let pageViewElelement;

    function doThing() {
        setSlideDetail(null);
    }

    if (slideDetail) {
        const slides = props.secondary ? props.websiteData.slides2 : props.websiteData.slides;
        const slideData = slides.find(s => s.id == slideDetail);
        const slide = new Slide(slideData.id, slideData);

        pageViewElelement = (
            <>
                <Button color="link" onClick={doThing}><FontAwesomeIcon icon={faArrowLeft} /> Back</Button>
                <SlideDetail slide={slide} {...props} />
            </>
        );
    } else {
        pageViewElelement = (<SlideList {...props} onSelectSlide={setSlideDetail} />);
    }

    return pageViewElelement;
}

export default Slides;
