import React, { useRef, useEffect, useCallback } from 'react';
import { Image_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/image';
import { getComponentClassName_v1_2_0 } from "../../compiler/components";
import { getInferredComponentDefaultType_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/components';
import { getChildComponentPropertyValueSiteData_v1_0_0 } from '../../../../v1.0.0/site-data/retrieval';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { V1_2_0_ComponentWrapper } from '../utils/component-wrapper';
import _ from 'lodash';
import { getChildComponentPropertyValueSiteData_v1_2_0 } from '../../../site-data/retrieval';

const COMPONENT_TYPE = getInferredComponentDefaultType_v1_0_0('Image');

// todo v1.4 make props consistent - is img src or url?
export function Image_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    // note: this debouncer must be scoped to the instance - not the module, or only the first instance will work
    // todo v1.2 memoize with useCallback
    const resizeDebouncer = _.debounce((resizeFunc) => {
        resizeFunc();
    }, 500);
    const imgRef = useRef<HTMLImageElement>(null);
    const minWidthImgIdRef = useRef<string>(null);
    const minHeightImgIdRef = useRef<string>(null);
    const targetImgLoadedRef = useRef<boolean>(false);
    let retVal = null;

    // d-inline-block is for debugger box
    // rouded-circle is bootstrap
    const openGallery = props.openGallery;
    const className = getComponentClassName_v1_2_0(props, 'd-inline-block', props.circle != null && 'circle rounded-circle', props.square != null && 'square', 'wiml-img');

    const url = getChildComponentPropertyValueSiteData_v1_2_0(props, 'url') || props.src;

    if (url) {
        const alt = getChildComponentPropertyValueSiteData_v1_2_0(props, 'alt');

        retVal = <span className={className}>
            <V1_2_0_ComponentWrapper {...props} >
                <img ref={imgRef} src={url} alt={alt} className={className} onClick={openGallery?.bind(null, url)} />
            </V1_2_0_ComponentWrapper>
        </span>
    }

    // useEffect(() => {
    //     if (props.dataRetrievalListItemId == imgIdRef.current) {
    //         const listItemId = props.dataRetrievalListItemId;
    //         const handleResize = () => {
    //             if (imgRef.current && imgRef.current.complete) {
    //                 props.onTargetImageLoad(imgRef.current);

    //             }
    //         };
    //         imgRef.current.onload = () => {
    //             props.onTargetImageLoad(imgRef.current);
    //             window.addEventListener('resize', handleResize);
    //             window.addEventListener('orientationchange', handleResize);

    //             return () => {
    //                 window.removeEventListener('resize', handleResize);
    //                 window.removeEventListener('orientationchange', handleResize);
    //             };
    //         }
    //     }

    // }, [props.dataRetrievalListItemId, imgIdRef.current]);

    useEffect(() => {
        if (url) {
            if (props.dataRetrievalListItemId) {
                if (props.minImageWidthRatioIds) {
                    const listItemId = props.dataRetrievalListItemId;
                    const minImageWidthRatioIds = props.minImageWidthRatioIds;
                    if (minImageWidthRatioIds[0] == listItemId) {
                        minWidthImgIdRef.current = listItemId;
                    }
                }

                if (props.minImageHeightRatioIds) {
                    const listItemId = props.dataRetrievalListItemId;
                    const minImageHeightRatioIds = props.minImageHeightRatioIds;
                    if (minImageHeightRatioIds[0] == listItemId) {
                        minHeightImgIdRef.current = listItemId;
                    }
                }
            }

            if (!targetImgLoadedRef.current) {
                if (minWidthImgIdRef.current && minHeightImgIdRef.current) {
                    broadcastTargetImageLoaded(['minWidthImg', 'minHeightImg']);
                } else if (minWidthImgIdRef.current) {
                    broadcastTargetImageLoaded('minWidthImg');
                } else if (minHeightImgIdRef.current) {
                    broadcastTargetImageLoaded('minHeightImg');
                }
            }
        }
    }, [props.dataRetrievalListItemId, props.minImageWidthRatioIds, props.minImageHeightRatioIds, url]);

    return retVal;

    function broadcastTargetImageLoaded(key: string | string[]) {
        const keyArray = Array.isArray(key) ? key : [key];
        const listItemId = props.dataRetrievalListItemId;

        const imageRef = imgRef.current;
        if (imageRef) {
            const additionalProps = keyArray.reduce((acc, key) => {
                acc[key] = imageRef;
                return acc;
            }, {} as Record<string, HTMLImageElement>);

            const doBroadcast = () => {
                // const doBroadcast = _.debounce(() => {
                props.onTargetImageLoad({ listItemId, ...additionalProps });
                // }, 5000);
            }
            if (imageRef.complete) {
                doBroadcast();
            } else {
                imageRef.onload = () => {
                    doBroadcast();
                };
            }

            const debounceBroadcast = () => {
                resizeDebouncer(doBroadcast);
            };

            const resizeHandler = () => {

                debounceBroadcast();
            }

            // window.addEventListener('resize', debounceBroadcast);

            targetImgLoadedRef.current = true;

            // Cleanup on unmount
            return () => {
                // window.removeEventListener('resize', debounceBroadcast);
            };
        } else {
            throw new Error('Image ref is null');
        }
    }
}
Image_v1_2_0.inputProps = {
    ...Image_v1_0_0.inputProps,
    width: {
        type: 'number',
        label: 'Width (px)',
        description: 'The width of the image',
        readonly: true, // note this doesn't actuallly do anything, refer to getComponentProperties_v1 in apps/admin/src/components/manage/wiml.js
    },
    height: {
        type: 'number',
        label: 'Height (px)',
        description: 'The height of the image',
        readonly: true,
    },
}
