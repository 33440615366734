import React, { useState } from 'react';
import { getComponentStyles_v1_2_0 } from "../../renderer/render-components";
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { useStyles_V1_2_0 } from './style-provider';
import { isListChildComponentType_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/render-components';
import { useHotkeys } from 'react-hotkeys-hook'
import { set } from 'dot-object';
import { prev } from 'cheerio/lib/api/traversing';

export const ExampleComponent = () => {
    const [count, setCount] = useState(0)
    useHotkeys('ctrl+k', () => setCount(count + 1), [count])

    return (
        <p>
            Pressed {count} times.
        </p>
    )
}
export const V1_2_0_ComponentWrapper = (props: RuntimeWimlComponentProps_v1_0_0) => {
    const { registerGlobalStyles } = useStyles_V1_2_0();
    const componentStyles = getComponentStyles_v1_2_0(props);
    React.useEffect(() => {
        registerGlobalStyles(componentStyles);
    }, [componentStyles]);

    const [debugEnabled, setDebugEnabled] = useState(true)

    useHotkeys(['ctrl', 'meta'], () => newFunction(setDebugEnabled, debugEnabled), {
        keydown: true,
        keyup: true
    }, [debugEnabled]);

    if (props.mode == 'preview' && debugEnabled) {
        const handleDebugBoxClick = (e: React.MouseEvent<HTMLElement>) => {

            const goToLine = e.altKey;
            // Command key was down during the click
            e.preventDefault();

            const componentId = props.componentId;
            let listInstanceComponentId;

            // todo v1.2 simplify this, provide this info furhter up the chain in compiler, renderer.
            // e.g. dataRetrievalListComponenyKey can be captured in compiler
            // but the list item instance itself (li_123) won't be known until render.
            if (isListChildComponentType_v1_0_0(props)) {
                listInstanceComponentId = props.listInstanceComponentId;
            }

            const payload = {
                pageOccurrenceId: props.pageOccurrenceId,
                pageDefinitionId: props.pageDefinitionId,
                listId: props.dataRetrievalListId,
                listItemId: props.dataRetrievalListItemId,
                listInstanceComponentId: listInstanceComponentId,
                componentId: componentId,
                meta: {
                    goToLine,
                    lineNumber: props.meta.lineNumber,
                }
            };

            window.parent?.postMessage({ name: 'preview_update', payload }, '*');
        };

        return (
            <>
                {/* <pre>props.componentKey: {props.componentKey}</pre> */}
                <span className="debugger-box" onClick={handleDebugBoxClick} />
                {/* <span className={`debugger-box ${isHovered ? 'active' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}></span> */}
                {props.children}
                <style jsx global>{`
                .debugger-box {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    /* accounts for any padding or negative padding */
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    /* accounts for any component who has a relative position, e.g. Navigation */
                    z-index: 10;
                }
                .debugger-box:hover {
                    border: ${debugEnabled ? '1px solid red' : '1px solid green'};
                }

            `}</style>
            </>
        );
    } else {
        /*
        In JSX, the root element of a component's return statement must be a valid JSX element. This means it must be either a React component (like <MyComponent />), a DOM element (like <div>), or null.
        However, ReactNode is a broader type that includes string, number, boolean, null, undefined, ReactElement, ReactFragment, ReactPortal, and arrays of these types. While string, number, boolean, null, undefined, and arrays are valid as children of a JSX element, they are not valid as the root JSX element. For example, you can't return a string or a number directly from a component's render method. */
        return <>{props.children}</>;
    }
};
function newFunction(setDebugEnabled: React.Dispatch<React.SetStateAction<boolean>>, debugEnabled: boolean): void {
    return setDebugEnabled(!debugEnabled);
}

