import { Form, FormGroup, Label, Input } from 'reactstrap';
import { useRef, useEffect } from 'react';
import GenreList from './genre-list';

const getIconList = (props, handleChange, activeRef) => props.themes
    .filter(t => props.genre == "all" || t.genres.includes(props.genre))
    .map(t =>
        <li ref={t.themeId == props.themeId ? activeRef : undefined} className={["py-1 mb-3", t.themeId == props.themeId ? "active" : ""].join(' ')} key={t.themeId} onClick={handleChange.bind(this, null, t.themeId)}>
            <div className="mt-0 mb-3 text-center font-weight-light">{t.themeName}</div>
            <section className="icon-section">
                {t.homeIcon && <p className="preview-heading p-3">Home Icon: <img src={t.homeIcon} className="icon-preview" /></p>}
                {t.booksIcon && <p className="preview-heading p-3">Books Icon: <img src={t.booksIcon} className="icon-preview" /></p>}
                {t.aboutIcon && <p className="preview-heading p-3">About Icon: <img src={t.aboutIcon} className="icon-preview" /></p>}
                {t.blogIcon && <p className="preview-heading p-3">Blog Icon: <img src={t.blogIcon} className="icon-preview" /></p>}
                {t.contactIcon && <p className="preview-heading p-3">Contact Icon: <img src={t.contactIcon} className="icon-preview" /></p>}
            </section>
            {/* todo move style to outer scope - each iteratio gets own style */}
            <style jsx>{`
                li {
                    cursor: pointer;
                    background-color: WhiteSmoke;
                }
                
                li.active, li:hover {
                    background-color: firebrick;
                    color: #fff;
                }

                section.icon-section {
                    min-height: 116px;
                }

                section.icon-section p.preview-heading .icon-preview {
                    margin: 0 auto;
                    width: 45px;
                    height: 45px;
                    margin-left: 5px;
                }
            `}</style>
        </li>
    );

export default function IconList(props) {
    const fontListRef = useRef(null);
    const didMountFontsListRef = useRef(false);

    const activeFontItemElement = useRef(null);

    useEffect(() => {
        if (didMountFontsListRef.current) {
            fontListRef.current.scrollTo(0, 0);
        } else {
            didMountFontsListRef.current = true;
        }

    }, [props.genre]);

    useEffect(() => {
        // https://stackoverflow.com/questions/14622963/javascript-css-window-scrollto0-0-not-working
        // i noticed that when clicking on 'scroll into view' with chrome dev tools, it DID work.
        // this this might be empty if we're creating a brand new user who has no style yet.
        activeFontItemElement.current?.scrollIntoView();
    }, []);

    // TODO - THIS HANDLE CHANGE IS DUPLICATED IN DEMO AND OTHER PLACES.
    const handleChangeGenreEvent = (event, key, value) => {
        if (event) {
            key = event.target.name;
            value = event.target.value;
        }

        if (!key) throw new Error('Handle change event `key` is missing.')
        if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

        props.onGenreChange(key, value);
    };

    // TODO - THIS HANDLE CHANGE IS DUPLICATED IN DEMO AND OTHER PLACES.
    const handleChangeThemeEvent = (event, key, value) => {
        if (event) {
            key = event.target.name;
            value = event.target.value;
        }

        if (!key) throw new Error('Handle change event `key` is missing.')
        if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

        props.onThemeChange(key, value);
    };

    const themeThumbnailList = getIconList(props, handleChangeThemeEvent, activeFontItemElement);

    return (
        <div className="theme-list">
            <Form>
                <FormGroup>
                    <Label for="exampleSelect">Theme Category</Label>
                    <Input type="select" name="genre" id="genre" value={props.genre} onChange={handleChangeGenreEvent}>
                        <GenreList />
                    </Input>
                </FormGroup>
            </Form>

            <ul className="list-unstyled" ref={fontListRef}>
                {themeThumbnailList}
            </ul>
            <style jsx>{`
                .theme-list {
                }

                ul {
                    scrollbar-color: pink red; /*#0A4C95 #C2D2E4;*/
                    scrollbar-width: 20px !important;
                }

                ul::-webkit-scrollbar {
                    width: 10px;
                }

                ul::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: grey;
                }
            `}
            </style>
        </div>
    );
}
