import { useForm } from "react-hook-form";
import { Form, FormGroup, Label } from 'reactstrap';
import { useDispatch } from "react-redux";
import { ReactstrapInputBlurChangeDetector } from '../../form/input';
import { updateNavigation } from '@wip/common/event-store/website';

const blogTags = (props) => {
    const dispatch = useDispatch();

    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: props.websiteData
    });

    const { ref: blogTagsRef, ...blogTagsRest } = register('navigation.blogTags');

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                key = event.target.name;
                value = event.target.value;
            }

            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'navigation') {
                dispatch(updateNavigation({ key: newKey, value }));
            }
        }
    };

    const bookViewElelement = (
        <Form>
            <FormGroup>
                <Label for="navigation.blogTags">Blog Tags (one per line)</Label>
                <ReactstrapInputBlurChangeDetector id="navigation.blogTags" invalid={!!errors.tags} type="textarea" rows={5} {...blogTagsRest} innerRef={blogTagsRef} onDiff={handleChangeEvent} />
            </FormGroup>
        </Form>
    );

    return bookViewElelement;
}

export default blogTags;
