import _ from 'lodash';


import {
    ComponentThemeData_v1_1_0,
} from './types';
import { convertWimlToNodes_v1_1_0 } from './nodes';
import { getThemeDataListCollectionFromNode_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/list-components';
import { getThemeDataPageCollectionFromNode_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/page-components';
import { wimlComponents_v1_1_0 } from '../renderer/wiml-components-v1.1.0';

const defaultValidVersions = ['1.1', '1.1.0'];

export function _markupToComponentThemeData_v1_1_0(wimlString: string) {
    const retVal: ComponentThemeData_v1_1_0 = {
        rootNode: null,
        pages: null,
        lists: null,
        version: '1.1.0'
    };

    const rootNode = convertWimlToNodes_v1_1_0(wimlString, defaultValidVersions);
    retVal.rootNode = rootNode;

    // there is an error with this. it will call _getThemeDataPageQualifiedComponentsFromNode_v1_0_0 which only looks at wimlComponents_v1_0_0
    // so if there was a case where v1.1 adds a new component, but it's not in 1.0, this will fail
    const pages = getThemeDataPageCollectionFromNode_v1_0_0(wimlComponents_v1_1_0, rootNode);
    retVal.pages = pages;

    // todo put header, footer, etc components in layout separate from pages
    // otherwise make header/footer not eligible for page names

    const lists = getThemeDataListCollectionFromNode_v1_0_0(wimlComponents_v1_1_0, rootNode);
    retVal.lists = lists;

    return retVal;
}
