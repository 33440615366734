import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBackward, faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createBook } from '@wip/common/event-store/website';
import CustomPageList from './custom-page-list';
import CustomPageDetail from './custom-page-detail';
import CustomPage from "@wip/common/domain/custom-page";

const CustomPages = (props) => {

    const [pageDetail, setPageDetail] = useState(null);

    let pageViewElelement;

    function doThing() {
        setPageDetail(null);
    }

    if (pageDetail) {
        const customPageData = props.websiteData.customPages.find(customPage => customPage.id == pageDetail);
        const customPage = new CustomPage(customPageData.id, customPageData);

        pageViewElelement = (
            <>
                <Button color="link" onClick={doThing}><FontAwesomeIcon icon={faArrowLeft} /> Back</Button>
                <CustomPageDetail customPage={customPage} {...props} />
            </>
        );
    } else {
        pageViewElelement = (<CustomPageList {...props} onSelectCustomPage={setPageDetail} />);
    }

    return pageViewElelement;
}

export default CustomPages;
  