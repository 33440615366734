import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { css } from "cheerio/lib/api/css";
import humps from "humps";
import React from "react";
import { breakpoints_v1_1_0 } from "../utils/breakpoints";
// unit in pixels, not rem
const responsizeHrSizeLookupClassNames = {
    '': {
        '5': 'wiml-hr-5',
        '4': 'wiml-hr-4',
        '3': 'wiml-hr-3',
        '2': 'wiml-hr-2',
        '1': 'wiml-hr-1',
    },
    'lg': {
        '5': 'wiml-lg-hr-5',
        '4': 'wiml-lg-hr-4',
        '3': 'wiml-lg-hr-3',
        '2': 'wiml-lg-hr-2',
        '1': 'wiml-lg-hr-1',
    },
    'md': {
        '5': 'wiml-md-hr-5',
        '4': 'wiml-md-hr-4',
        '3': 'wiml-md-hr-3',
        '2': 'wiml-md-hr-2',
        '1': 'wiml-md-hr-1',
    },
    'sm': {
        '5': 'wiml-sm-hr-5',
        '4': 'wiml-sm-hr-4',
        '3': 'wiml-sm-hr-3',
        '2': 'wiml-sm-hr-2',
        '1': 'wiml-sm-hr-1',
    },
    'xs': {
        '5': 'wiml-xs-hr-5',
        '4': 'wiml-xs-hr-4',
        '3': 'wiml-xs-hr-3',
        '2': 'wiml-xs-hr-2',
        '1': 'wiml-xs-hr-1',

    }
} as const;


type HrSizeLookupClassNamesKey = keyof typeof responsizeHrSizeLookupClassNames;
type HrSizePositionKey = keyof typeof responsizeHrSizeLookupClassNames['']


const defaultHrSizeClass = responsizeHrSizeLookupClassNames['']['3'];

const hrSizeLookupClassNames = Object.keys(responsizeHrSizeLookupClassNames) as HrSizeLookupClassNamesKey[];
const hrSizeCamelizedPropNames = hrSizeLookupClassNames.reduce((acc, size) => {
    const alignPropName = size ? `size-${size}` : 'size';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[size] = camelizedPropName;
    return acc;
}, {} as Record<HrSizeLookupClassNamesKey, string>);

export function Hr_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    const className = _getHrClassNames_v1_1_0(props);
    // const className = getComponentClassName(props, 'hr', props.size != null && `hr-${props.size}`);

    return <hr className={className} />;
}
export function _getHrClassNames_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    const classNames = hrSizeLookupClassNames.map((size) => {
        const camelizedPropName = hrSizeCamelizedPropNames[size];
        const propValue = props[camelizedPropName] as HrSizePositionKey;
        if (propValue) {
            return [
                // 'hr',
                responsizeHrSizeLookupClassNames[size][propValue]
            ];
        }
    }).filter(Boolean);

    if (classNames.length === 0) {
        classNames.push([defaultHrSizeClass]);
    }

    const className = classNames.flat().filter(Boolean).join(' ');
    return className;
}











// todo these are placeholders for some color keys
const responhrHrColorLookupClassNames = {
    '': {
        '5': '',
        '4': '',
        '3': '',
        '2': '',
        '1': '',
    },
    'lg': {
        '5': '',
        '4': '',
        '3': '',
        '2': '',
        '1': '',
    },
    'md': {
        '5': '',
        '4': '',
        '3': '',
        '2': '',
        '1': '',
    },
    'sm': {
        '5': '',
        '4': '',
        '3': '',
        '2': '',
        '1': '',
    },
    'xs': {
        '5': '',
        '4': '',
        '3': '',
        '2': '',
        '1': '',
    }
} as const;
type ResponsiveHrColorLookupClassNames = typeof responhrHrColorLookupClassNames;
type ResponiveHrColorLookupClassNamesKey = keyof ResponsiveHrColorLookupClassNames;

type HrColorPositionKeyNames = typeof responhrHrColorLookupClassNames[''];
type HrColorPositionKey = keyof HrColorPositionKeyNames

const hrColorLookupClassNames = Object.keys(responhrHrColorLookupClassNames) as ResponiveHrColorLookupClassNamesKey[];
const hrColorCamelizedPropNames = hrColorLookupClassNames.reduce((acc, hr) => {
    const alignPropName = hr ? `color-${hr}` : 'color';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[hr] = camelizedPropName;
    return acc;
}, {} as Record<ResponiveHrColorLookupClassNamesKey, string>);

export function _getHrStyles_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    const hrStyles = hrSizeLookupClassNames.map((hrRepsoniveHrProp) => {
        const camelizedPropName = hrColorCamelizedPropNames[hrRepsoniveHrProp];
        const propValue = props[camelizedPropName] as HrColorPositionKey;
        if (propValue) {
            const cssHrValue = propValue;
            const cssRuleValue = cssHrValue.startsWith('--') ? `var(${cssHrValue})` : cssHrValue;
            let style;
            if (hrRepsoniveHrProp) {
                style = `.wiml-hr-line-color-dynamic__${cssHrValue} {
      border-color: ${cssRuleValue};
    }`;
                style = `@media ${breakpoints_v1_1_0[hrRepsoniveHrProp]} {
    ${style}
}`;
            } else {
                style = `.wiml-hr-line-color-dynamic__${cssHrValue} {
    border-color: ${cssRuleValue};
}`;
            }
            return [
                style
            ];
        }
    });


    const className = hrStyles.flat().filter(Boolean).join('\n\n');
    return className;
}
