import { useForm } from "react-hook-form";
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from "react-redux";
import { updateProductConfirmation, updateProductStripe, updateProductSubscription, } from '@wip/common/event-store/website';
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../form/input';
import { updateProductAbout, updateProductAssets, updateProductAttribute, updateProductMeta, updateProductPrice, updateProductSocial, saveFile } from '@wip/common/event-store/website';
import { currencyCodes } from '@wip/common/lib/stripe';
import { useMemo } from "react";

const ProductDetail = (props) => {
    const dispatch = useDispatch();

    function uploadImage(file) {
        const workPromise = dispatch(saveFile(file)).then(action => action.payload);

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: uploadImage };

        return retVal;
    }, [dispatch]);

    const total = props.product.price.total;
    const totalConverted = total / 100;
    const price = { ...props.product.price, total: totalConverted };
    const abc = { ...props.product, price };
    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: abc
    });

    const { ref: titleRef, ...titleRest } = register('about.title', { required: true });
    // kind of annoying - https://github.com/react-hook-form/react-hook-form/issues/4414
    const { ref: typeRef, onChange: typeChange, ...typeRest } = register('about.type', { required: true });
    const { ref: sizesRef, ...sizesRest } = register('attribute.sizes');
    const { ref: colorsRef, ...colorsRest } = register('attribute.colors');
    const { ref: pageOrderRef, ...pageOrderRest } = register('meta.order');
    const { ref: priceTotalRef, ...priceTotalRest } = register('price.total', { required: true });
    // currencyChange will ovveride the register() onChange - but will call it later in currencyChange
    const { ref: priceCurrencyRef, onChange: currencyChange, ...priceCurrencyRest } = register('price.currency');
    const { ref: shareLinkRef, ...shareLinkRest } = register('social.shareLink');
    const { ref: priceIdRef, ...priceIdRest } = register('stripe.priceId');
    // subscriptionIntervalChange will ovveride the register() onChange - but will call it later in currencyChange
    const { ref: subscriptionIntervalRef, onChange: subscriptionIntervalChange, ...subscriptionIntervalRest } = register('subscription.interval');

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, id, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                id = event.target.dataset.id;
                key = event.target.name;
                value = event.target.value;
            }

            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];
            if (path == 'about') {
                dispatch(updateProductAbout({ id, key: newKey, value }));
            } else if (path == 'meta') {
                dispatch(updateProductMeta({ id, key: newKey, value }));
            } else if (path == 'assets') {
                dispatch(updateProductAssets({ id, key: newKey, value }));
            } else if (path == 'attribute') {
                dispatch(updateProductAttribute({ id, key: newKey, value }));
            } else if (path == 'price') {
                dispatch(updateProductPrice({ id, key: newKey, value }));
            } else if (path == 'social') {
                dispatch(updateProductSocial({ id, key: newKey, value }));
            } else if (path == 'stripe') {
                dispatch(updateProductStripe({ id, key: newKey, value }));
            } else if (path == 'subscription') {
                // todo - check if empty string, if so pass null. firebase now stroes empty strings?
                dispatch(updateProductSubscription({ id, key: newKey, value }));
            }
        }
    };

    // https://github.com/scottccoates/author-site-builder/blob/97d1f076526c424746c51c7f21a9858a5d57e2c8/apps/admin/src/components/manage/style.js#L19
    const handlProdctTypeChangeEvent = async (e) => {
        typeChange && await typeChange(e);
        handleChangeEvent(e);
    };


    // https://github.com/scottccoates/author-site-builder/blob/97d1f076526c424746c51c7f21a9858a5d57e2c8/apps/admin/src/components/manage/style.js#L19
    const handlCurrencyChangeEvent = async (e) => {
        currencyChange && await currencyChange(e);
        handleChangeEvent(e);
    };

    // https://github.com/scottccoates/author-site-builder/blob/97d1f076526c424746c51c7f21a9858a5d57e2c8/apps/admin/src/components/manage/style.js#L19
    const handlIntervalChangeEvent = async (e) => {
        subscriptionIntervalChange && await subscriptionIntervalChange(e);
        handleChangeEvent(e);
    };


    const onCoverPhotoChange = e => {
        const id = e.target.dataset.id;
        const coverPhotoFile = e.target.files[0];
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(saveFile(coverPhotoFile)).then(action => {
            const downloadUrl = action.payload;

            dispatch(updateProductAssets({ id, key: 'coverPhoto', value: downloadUrl }));
            e.target.value = "";
        });
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleRichEditorChangeEvent = (id, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateProductAbout({ id, key: newKey, value }));
            } else if (path == 'assets') {
                dispatch(updateProductAssets({ id, key: newKey, value }));
            } else if (path == 'confirmation') {
                dispatch(updateProductConfirmation({ id, key: newKey, value }));
            }
        }
    };

    const typeList = (
        <>
            <option value='digital'>Digital</option>
            <option value='physical'>Physical</option>
        </>
    );

    const currencyCodeOptions = currencyCodes.map(c =>
        (<option key={c.code} value={c.code}>{c.currency}</option>)
    );

    const subscriptionOptions = (
        <>
            <option value="">One Time</option>
            <option value='month'>Monthly</option>
            <option value='year'>Yearly</option>
        </>
    );

    const bookViewElelement = (
        <Form>
            <FormGroup>
                <Label for="about.title">Product Name</Label>
                <ReactstrapInputBlurChangeDetector id="about.title" type="text" data-id={props.product.id} {...titleRest} innerRef={titleRef} onDiff={handleChangeEvent} />
            </FormGroup>
            {/* https://github.com/scottccoates/author-site-builder/blob/97d1f076526c424746c51c7f21a9858a5d57e2c8/apps/admin/src/components/manage/style.js#L19 */}
            <FormGroup>
                <Label for="about.type">Product Type</Label>
                <Input type="select" innerRef={typeRef} data-id={props.product.id} onChange={handlProdctTypeChangeEvent} {...typeRest}>
                    {typeList}
                </Input>
            </FormGroup>
            {
                props.product.about.type == 'physical' &&
                <FormGroup>
                    <Label for="attribute.sizes">Sizes</Label>
                    <ReactstrapInputBlurChangeDetector id="attribute.sizes" type="textarea" data-id={props.product.id} rows={5} {...sizesRest} innerRef={sizesRef} onDiff={handleChangeEvent} />
                </FormGroup>
            }
            {
                props.product.about.type == 'physical' &&
                <FormGroup>
                    <Label for="attribute.colors">Colors</Label>
                    <ReactstrapInputBlurChangeDetector id="attribute.colors" type="textarea" data-id={props.product.id} rows={5} {...colorsRest} innerRef={colorsRef} onDiff={handleChangeEvent} />
                </FormGroup>
            }
            <FormGroup>
                <Label for="price.total">Product Price</Label>
                <ReactstrapInputBlurChangeDetector id="price.total" type="number" data-id={props.product.id} {...priceTotalRest} innerRef={priceTotalRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="price.currency">Product Price (Currency)</Label>
                <Input type="select" innerRef={priceCurrencyRef} data-id={props.product.id} onChange={handlCurrencyChangeEvent} {...priceCurrencyRest}>
                    {currencyCodeOptions}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="price.currency">Payment Frequency (Subscription)</Label>
                <Input type="select" innerRef={subscriptionIntervalRef} data-id={props.product.id} onChange={handlIntervalChangeEvent} {...subscriptionIntervalRest}>
                    {subscriptionOptions}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="meta.order">Product Order</Label>
                <ReactstrapInputBlurChangeDetector id="meta.order" type="number" data-id={props.product.id} {...pageOrderRest} innerRef={pageOrderRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="assets.coverPhoto">Cover Photo</Label>
                <Input id="assets.coverPhoto" type="file" accept="image/*" data-id={props.product.id} onChange={onCoverPhotoChange} />
                <img src={props.product.assets.coverPhoto} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="about.content">Product Description</Label>
                <RichTextEditor defaultValue={props.product.about.content} onDiff={handleRichEditorChangeEvent.bind(null, props.product.id, 'about.content')} {...richTextConfig} />
            </FormGroup>
            <FormGroup>
                <Label for="confirmation.pageContent">Thank-You Page Confirmation Message</Label>
                <RichTextEditor defaultValue={props.product.confirmation.pageContent} onDiff={handleRichEditorChangeEvent.bind(null, props.product.id, 'confirmation.pageContent')} {...richTextConfig} />
            </FormGroup>
            <FormGroup>
                <Label for="confirmation.emailContent">Email Confirmation Message</Label>
                <RichTextEditor defaultValue={props.product.confirmation.emailContent} onDiff={handleRichEditorChangeEvent.bind(null, props.product.id, 'confirmation.emailContent')} {...richTextConfig} />
            </FormGroup>
            <FormGroup>
                <Label for="social.shareLink">Social Share Link</Label>
                <ReactstrapInputBlurChangeDetector id="social.shareLink" type="text" data-id={props.product.id} {...shareLinkRest} innerRef={shareLinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            {/* 
            stripe price id is not working  - it causes state.data.displayItems to be empty (used in thank you page)

            <FormGroup>
                <Label for="stripe.priceId">Stripe Price ID# (Advanced)</Label>
                <ReactstrapInputBlurChangeDetector id="stripe.priceId" type="text" data-id={props.product.id} {...priceIdRest} innerRef={priceIdRef} onDiff={handleChangeEvent} />
            </FormGroup> */}
        </Form>);

    return bookViewElelement;
}

export default ProductDetail;
