import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBackward, faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createBook } from '@wip/common/event-store/website';;
import GalleryList from './gallery-list';
import GalleryDetail from './gallery-detail';
import Gallery from "@wip/common/domain/gallery";

const Galleries = (props) => {

    const [galleryDetail, setGalleryDetail] = useState(null);
    const [galleryItemDetail, setGalleryItemDetail] = useState(null);

    let pageViewElelement;

    function doThing() {
        setGalleryDetail(null);
    }

    if (galleryDetail) {
        const galleryData = props.websiteData.galleries.find(g => g.id == galleryDetail);
        const gallery = new Gallery(galleryData.id, galleryData);

        pageViewElelement = (
            <>
                {
                    galleryItemDetail == null &&
                    <Button color="link" onClick={doThing}><FontAwesomeIcon icon={faArrowLeft} /> Back</Button>
                }
                <GalleryDetail gallery={gallery} {...props} galleryItemDetail={galleryItemDetail} onSelectGalleryItem={setGalleryItemDetail}/>
            </>
        );
    } else {
        pageViewElelement = (<GalleryList {...props} onSelectGallery={setGalleryDetail} />);
    }

    return pageViewElelement;
}

export default Galleries;
