import { Button_v1_0_0 } from './components/button';
import { Col_v1_0_0 } from './components/col';
import Navigation_v1_0_0 from './components/navigation';
import WimlRoot from './components/wiml-root';
import { Container_v1_0_0 } from './components/container';
import { Embed_v1_0_0 } from './components/embed';
import { Footer_v1_0_0 } from './components/footer';
import { Header_v1_0_0 } from './components/header';
import { Heading_v1_0_0 } from './components/heading';
import { Icon_v1_0_0 } from './components/icon';
import { Image_v1_0_0 } from './components/image';
import { Layout_v1_0_0 } from './components/layout';
import { Link_v1_0_0 } from './components/link';
import { List_v1_0_0 } from './components/list';
import { Page_v1_0_0 } from './components/page';
import { Row_v1_0_0 } from './components/row';
import { Spacer_v1_0_0 } from './components/spacer';
import { OrderSummary_v1_0_0 } from './components/order';
import { Text_v1_0_0 } from './components/text';
import { WimlDate_v1_0_0 } from './components/date';
import { WimlNumber_v1_0_0 } from './components/number';
import { NoOp_v1_0_0 } from './components/no-op';
import { RuntimeWimlComponentCollection_v1_0_0, } from './types';

export const wimlComponents_v1_0_0: RuntimeWimlComponentCollection_v1_0_0 = {
    "WIML": WimlRoot,
    "Container": Container_v1_0_0,
    "Text": Text_v1_0_0,
    "Embed": Embed_v1_0_0,
    "Date": WimlDate_v1_0_0,
    "WimlDate": WimlDate_v1_0_0, // todo can we remove this now?
    "Number": WimlNumber_v1_0_0,
    "WimlNumber": WimlNumber_v1_0_0,
    "Button": Button_v1_0_0,
    "List": List_v1_0_0,
    "Image": Image_v1_0_0,
    "Icon": Icon_v1_0_0,
    "Header": Header_v1_0_0,
    "Heading": Heading_v1_0_0,
    "Footer": Footer_v1_0_0,
    "Navigation": Navigation_v1_0_0,
    "Layout": Layout_v1_0_0,
    "Page": Page_v1_0_0,
    "Row": Row_v1_0_0,
    "Col": Col_v1_0_0,
    "Spacer": Spacer_v1_0_0,
    "Link": Link_v1_0_0,
    "ContactForm": NoOp_v1_0_0,
    "OrderSummary": OrderSummary_v1_0_0,
};
