import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import React, { useRef } from "react";
import { fetcher } from "@wip/common/lib/data-utils";

const apiUrl = process.env.REACT_APP_API_URL;

export default function UserCreator(props) {

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const formSubmit = async e => {
    e.preventDefault();

    const firstName = firstNameRef.current.value.trim();
    const lastName = lastNameRef.current.value.trim();
    const email = emailRef.current.value.trim();
    const password = passwordRef.current.value.trim();

    // post server
    // send auth header

    try {

      // ending slash - do not have ending slash or CORS error - will cause redirect from /user/ to /user (no ending slash) == cors error.
      // todo move endpoint from zapier to user
      const newUserApiUrl = `${apiUrl}/zapier`;

      // api uses snake_case
      const POSTWebhookData = {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        event_type: 'user.registration.completed',
      };

      const POSTOptions = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: { "Content-Type": "application/json", "Authorization": 'fill me in with users token' },
        body: JSON.stringify(POSTWebhookData)
      };

      const res = await fetcher(newUserApiUrl, POSTOptions, { getJson: true });

      firstNameRef.current.form.reset();

      alert(`The new site was successfully created! Go to /manage/${res.slug} to edit the site.`);

    } catch (e) {
      alert("Error: " + e);
      const rethrow = new Error('Error creating new user: ' + e);
      rethrow.stack = e.stack;
      throw rethrow;
    }
  };


  return (
    <div className="layout container-fluid d-flex flex-column ">
      <div className="inner-wrapper d-flex flex-grow-1 h-100">
        <div className="row">
          <div className="col">
            <Form onSubmit={formSubmit}>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input id="firstName" type="text" innerRef={firstNameRef} required autoComplete="off" />
              </FormGroup>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input id="lastName" type="text" innerRef={lastNameRef} required autoComplete="off" />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input id="email" type="email" innerRef={emailRef} required autoComplete="off" />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input id="password" type="password" innerRef={passwordRef} required autoComplete="off" />
              </FormGroup>
              <Button>Create New Website w/ User</Button>
            </Form>
          </div>
        </div>
      </div>
      <style jsx>{`
        .layout {
          height: calc(100vh - 69px);
          padding: 0;
          background-color: ${props.themeConfig.backgroundPrimaryColor};
        }

        .layout :global(h1) {
          text-transform: uppercase;
          letter-spacing: .1rem;
          padding: .5rem;
        }

        .layout :global(h2) {
          font-weight: bold;
        }

        .layout :global(h3) {
        }

        .layout :global(.ql-editor, .rich-text-editor p) {
          margin-bottom: 1rem;
        }
    `}</style>
    </div>

  );
}
