// https://dev.to/thatgalnatalie/how-to-get-started-with-redux-toolkit-41e
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const themesUrl = process.env.REACT_APP_THEME_API_URL;

const initialState = {
  data: {},
  isLoading: false,
  hasErrors: false,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    getData: (state) => {
      state.isLoading = true;
    },

    getDataSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    getDataFailure: (state) => {
      state.isLoading = false;
      state.hasErrors = true;
    },
  }
});

export const reducer = user.reducer;

export const {
  getData, getDataSuccess, getDataFailure
} = user.actions;
