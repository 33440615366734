import React from 'react';

import { renderChildren_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/render-components';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { getComponentClassName_v1_2_0 } from '../../compiler/components';
import { getInferredComponentDefaultType_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/components';
import { getChildComponentPropertyValueSiteData_v1_0_0 } from '../../../../v1.0.0/site-data/retrieval';
import { Container_v1_1_0 } from '../../../../v1.1.0/theme-data/renderer/components/container';
import { convertPropsToClassNames_v1_2_0 } from '../utils/styles';
import { getChildComponentPropertyValueSiteData_v1_2_0 } from '../../../site-data/retrieval';

const paddingYClassNamesTemplate = {
    '5': ['py-5'],
    '4': ['py-4'],
    '3': ['py-3'],
    '2': ['py-2'],
    '1': ['py-1'],
};
// todo v1.4 handle svg backgrounds
export function Container_v1_2_0(props: RuntimeWimlComponentProps_v1) {

    const { wrapperClassName, containerClass, } = getContainerTypeClassNames_v1_2_0(props);
    const bgAttachmentFixed = props.bgFixed !== null && props.bgFixed !== undefined && props.bgFixed !== 'false';

    const bgUrl = getChildComponentPropertyValueSiteData_v1_2_0(props, 'bgUrl') || props.bgUrl;

    const bgUrlRules = bgUrl ? `background-image: url('${bgUrl}');
background-repeat: no-repeat;
background-size: cover;
background-position: center;` : '';

    const bgColor = getChildComponentPropertyValueSiteData_v1_2_0(props, 'bgColor') || props.bgColor;
    const bgColorFormatted = bgColor ? bgColor.startsWith('--') ? `var(${bgColor})` : bgColor : null;
    const bgColorRules = bgColorFormatted ? `background-color: ${bgColorFormatted};` : '';
    const className = getContainerClassNames_v1_2_0(props);

    const wrapperClassNameWithUserClass = getComponentClassName_v1_2_0(props, wrapperClassName, bgUrl && 'has-bg-img', bgColor && 'has-bg-color', bgAttachmentFixed && 'bg-fixed', className);
    const renderedChildren = renderChildren_v1_0_0(props.children);

    return (
        <section className={wrapperClassNameWithUserClass}>
            <div className={containerClass}>
                {renderedChildren}
            </div>
            <style jsx>{`
                    section.has-bg-color {
                        ${bgColorRules}
                    }
                    section.has-bg-img {
                        ${bgUrlRules}
                    }
                    :global(section.bg-fixed) {
                        background-attachment: fixed;
                    }
                    :global(footer) section.wiml-default-col-padding {
                        padding-top: 1rem !important;
                        padding-bottom: 1rem !important;
                    }
                `}</style>
        </section>
    );
}
Container_v1_2_0.inputProps = Container_v1_1_0.inputProps;

export function getContainerClassNames_v1_2_0(props: Record<string, string>) {
    const paddingYClassNames = convertPropsToClassNames_v1_2_0(props, paddingYClassNamesTemplate, 'padding-y', undefined, ['wiml-default-col-padding', 'py-5']);

    return paddingYClassNames;
}
export function getContainerTypeClassNames_v1_2_0(props: Record<string, string>) {
    let wrapperClassName;
    let containerClass;

    if (props.stretch != null) {
        wrapperClassName = 'wiml-full-width-container-wrapper';
        containerClass = 'container-fluid';
    } else if (props.narrow != null) {
        wrapperClassName = 'wiml-narrow-container-wrapper';
        containerClass = 'container';
    } else {
        wrapperClassName = 'wiml-full-width-container-wrapper';
        containerClass = 'container';
    }
    return { wrapperClassName, containerClass, };
}

