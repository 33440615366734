import { _isListNode_v1_0_0, getNodeKey_v1_0_0 } from "../../../v1.0.0/theme-data/compiler/nodes";
import { WimlListElement_v1_0_0, WimlNode_v1_0_0 } from "../../../v1.0.0/theme-data/compiler/types";
import { RuntimeWimlComponentCollection_v1_0_0 } from "../../../v1.0.0/theme-data/renderer/types";
import { _getThemeDataComponentId_v1_2_0, formatNodeKey_v1_2_0 } from "./components";

// v1.2 test this as SUT
export function processListQualifiedNodeChildren_v1_2_0<T>(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string, useParent: boolean, recurFunction: (runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string, useParent: boolean) => T[]) {
    const retVal: T[] = [];
    const isList = _isListNode_v1_0_0(node);

    const children = node.child;
    if (children?.length) {
        // set option that this is a list
        if (isList) {
            const currentListId = getListIdFromNodeAttrs_v1_2_0(node);
            if (!currentListId) throw new Error('list must have a id');

            // const currentListComponentId = getComponentIdForListNode_v1_2_0(node);
            const currentListComponentId = _getThemeDataComponentId_v1_2_0({
                type: node.tag,
                key: getNodeKey_v1_2_0(node),
                parentListInstanceComponentId: parentListComponentId,
            }, useParent);

            if (parentListComponentId) {
                // const newListComponentId = `${parentListComponentId}.${currentListComponentId}`;
                const newRetVal = [...retVal, ...children.map((c) => recurFunction(runtimeComponents, c, currentListId, currentListComponentId, useParent)).flat()];

                return newRetVal
            } else {
                const newRetVal = [...retVal, ...children.map((c) => recurFunction(runtimeComponents, c, currentListId, currentListComponentId, useParent)).flat()];

                return newRetVal
            }
        } else {
            const newRetVal = [...retVal, ...children.map((c) => recurFunction(runtimeComponents, c, parentListId, parentListComponentId)).flat()];

            return newRetVal
        }
    } else {
        return [];
    }
}
function getComponentIdForListNode_v1_2_0(node: WimlListElement_v1_0_0) {
    const key = getNodeKey_v1_0_0(node);
    // uppercase first char of key and return key
    const upperKey = key.charAt(0).toUpperCase() + key.slice(1);

    // convert name to camel case e.g. Listblog -> listBlog
    const retVal = humps.camelize(`${node.tag}${upperKey}`);
    return retVal;
}


export function getListIdFromNodeAttrs_v1_2_0(node: WimlListElement_v1_0_0) {
    const retVal = formatNodeKey_v1_2_0(node.attr?.listId || node.attr?.key);
    return retVal.startsWith('list__') ? retVal : `list__${retVal}`;
}

// these are duplicated and being called (or not called) from wrong places
export function getNodeKey_v1_2_0(node: WimlNode_v1_0_0) {
    const retVal = node.attr?.key;

    return retVal;
}
