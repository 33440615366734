import humps from 'humps';
import deepTrim from 'deep-trim';
import { zip } from './array-utils';
import { fetcher } from '@wip/common/lib/data-utils'

const gsheetsApiKey = process.env.GSHEETS_API_KEY || process.env.REACT_APP_GSHEETS_API_KEY || process.env.NEXT_PUBLIC_GSHEETS_API_KEY;

// NOTE in v4, trailing cels are ommitted from the sheet data.
// https://stackoverflow.com/questions/38442634/googlesheet-apiv4-getting-empty-cells
export async function fetchSpreadsheet(spreadsheetId, spreadsheetName) {
  if (!spreadsheetId) throw new Error('themesUrl is not valid');

  const restUrl = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${spreadsheetName}?key=${gsheetsApiKey}`;
  const res = await fetcher(restUrl, undefined, { getJson: true });

  const data = res.values;
  const [headers, ...rows] = data;
  const sheetData = rows.flatMap(row => Object.fromEntries(zip(headers, row)));

  const cleansed = cleanseData(sheetData);
  return cleansed;
}

function cleanseData(data) {
  const humpsSheetData = humps.camelizeKeys(data);
  const trimmedData = deepTrim(humpsSheetData);

  return trimmedData;
}
