import { textUtils } from "..";
import { DataClass } from "../lib/object-utils";

export default class Product extends DataClass {
    constructor(productId, data) {
        super(data);

        this.id = productId;

        this.about = this.about || {};
        this.assets = this.assets || {};
        this.attribute = this.attribute || {};
        this.confirmation = this.confirmation || {};
        this.schedule = this.schedule || {};
        this.meta = this.meta || {};
        this.price = this.price || {};
        this.social = this.social || {};
        this.stripe = this.stripe || {};
        this.subscription = this.subscription || {};
    }

    get sizes() {
        return this._getAttributeCollection('sizes');
    }

    get colors() {
        return this._getAttributeCollection('colors');
    }

    _getAttributeCollection(attribute) {
        const retVal = (this.attribute[attribute] || "")
            .split("\n")
            .filter(Boolean)
            .map(t => t.trim())
            .map(t => ({ key: textUtils.convertToSlug(t), value: t }));

        return retVal;
    }
}
