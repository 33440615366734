import React, { useEffect, useContext, createContext, useState } from "react";
import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'

export const PRODUCT_ROOT = 'product-root-website';

export function getClient(writeKey, endpoint) {
    let analytics;

    if (endpoint) {
        //rudderstack
        analytics = getRudderClient(writeKey, endpoint);
    } else {
        //segment
        analytics = getSegmentClient(writeKey);
    }

    return analytics
}

function getSegmentClient(writeKey) {
    const analytics = Analytics({
        app: 'wip',
        plugins: [
            segmentPlugin({
                writeKey: writeKey
            })
        ]
    });

    return analytics
}


function getRudderClient(writeKey, endpoint) {
    if (!process.browser) return {};
    if (!window.rudderanalytics) {
        !function () {
            var e = window.rudderanalytics = window.rudderanalytics || []; e.methods = ["load", "page", "track", "identify", "alias", "group", "ready", "reset", "getAnonymousId", "setAnonymousId", "getUserId", "getUserTraits", "getGroupId", "getGroupTraits", "startSession", "endSession", "getSessionId"], e.factory = function (t) { return function () { var r = Array.prototype.slice.call(arguments); return r.unshift(t), e.push(r), e } }; for (var t = 0; t < e.methods.length; t++) { var r = e.methods[t]; e[r] = e.factory(r) } e.loadJS = function (e, t) { var r = document.createElement("script"); r.type = "text/javascript", r.async = !0, r.src = "https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js"; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(r, a) }, e.loadJS(),
                e.load(writeKey, endpoint)
            // e.page()
        }();
    }
    // taken from segment plugin https://github.com/DavidWells/analytics/commit/40035c813886eb0bcafd9ba636da18987810f19d
    // https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#page
    return {
        page: (properties) => {
            if (typeof window.rudderanalytics === 'undefined') return
            const name = document.title;
            const category = undefined;

            window.rudderanalytics.page(category, name, properties);
        },
        track: (event, properties) => {
            if (typeof window.rudderanalytics === 'undefined') return

            window.rudderanalytics.track(event, properties)
        },
        identify: (userId, traits) => {
            if (typeof window.rudderanalytics === 'undefined') return

            if (typeof userId === 'string') {
                window.rudderanalytics.identify(userId, traits);
            } else {
                throw new Error('userId must be a string')
            }
        },
    };
    // todo - make this a plguin. i tried but rudderstack this.loaded keeps being set to false.
    // const analytics = Analytics({
    //     app: 'wip',
    //     plugins: [
    //         rudderstackPlugin({
    //             writeKey: writeKey
    //         })
    //     ]
    // });

    // return analytics
}

export const AnalyticsContext = createContext({
    tracker: null,
});

export const AnalyticsProvider = ({ tracker, children }) => {
    // replace jsx because then callers do not need to transpile
    const retVal = React.createElement(
        AnalyticsContext.Provider,
        { value: { tracker } },
        children
    );

    return retVal;

    // return (
    //     <AnalyticsContext.Provider value={{ tracker }}>
    //         {children}
    //     </AnalyticsContext.Provider>
    // );
};
export const useAnalytics = () => useContext(AnalyticsContext);

// const target = analytics;

// //http://2ality.com/2017/11/proxy-method-calls.html
// const handler = {
//     get(target, propKey) {
//         const targetValue = target[propKey];
//         if (typeof targetValue === "function") {
//             return function ({...args}) {
//                 const retryPromise = promiseRetry((retry, number) => {
//                     const logRetry = number > 1;

//                     logRetry && console.debug(`begin retry: ${util.inspect(targetValue)} ${util.inspect(args)}`);

//                     const throttledPromise = promiseThrottle
//                         // add must return a new promise every time. do not re-use!
//                         .add(() => Promise
//                             // ensure a promise is returned even if basic func
//                             .resolve(targetValue.apply(target, args))
//                             .then(v => {
//                                 logRetry && console.debug(`complete retry: ${util.inspect(targetValue)} ${util.inspect(args)}`);
//                                 return v;
//                             }))
//                         .catch(async err => {
//                             if (typeof err.name == "string" && err.name.toLowerCase() == "webapierror") {
//                                 const retryCodes = [401, 429, 500, 503, 504];
//                                 const retryMessages = ['enotfound', 'disconnected', 'enetdown', 'enetunreach', 'bad gateway', 'service unavailable'];
//                                 const { statusCode } = err;
//                                 const message = (err.message || "").toLowerCase();

//                                 if (retryCodes.includes(statusCode) || retryMessages.some(m => message.includes(m))) {
//                                     if (statusCode == 401) {
//                                         await refreshSpotifyClientAuth(target);
//                                     }
//                                     retry(err);
//                                 } else {
//                                     throw new Error(err);
//                                 }
//                             } else {
//                                 throw new Error(err);
//                             }
//                         });
//                     return throttledPromise;
//                 });
//                 return retryPromise;
//             };
//         } else {
//             return targetValue;
//         }
//     }
// };

// const proxy = new Proxy(target, handler);
// return proxy;

// export { proxy as analytics };

export function getHotjarScript(hotjarId) {
    const retVal = `
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${hotjarId},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

    return retVal;
}
