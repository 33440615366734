import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import React from "react";
import { ResponsiveClassTemplate_v1_2_0, convertPropsToClassNames_v1_2_0, getStyleCollectionString_v1_2_0 } from "../utils/styles";
import { V1_2_0_ComponentWrapper } from "../utils/component-wrapper";
import { getInferredComponentDefaultType_v1_0_0 } from "../../../../v1.0.0/theme-data/compiler/components";
import { getComponentClassName_v1_2_0 } from "../../compiler/components";

const spacerXClassNamesTemplate = {
    '12': ['wiml-spacer-x', 'wiml-spacer-x-12'],
    '11': ['wiml-spacer-x', 'wiml-spacer-x-11'],
    '10': ['wiml-spacer-x', 'wiml-spacer-x-10'],
    '9': ['wiml-spacer-x', 'wiml-spacer-x-9'],
    '8': ['wiml-spacer-x', 'wiml-spacer-x-8'],
    '7': ['wiml-spacer-x', 'wiml-spacer-x-7'],
    '6': ['wiml-spacer-x', 'wiml-spacer-x-6'],
    '5': ['wiml-spacer-x', 'wiml-spacer-x-5'],
    '4': ['wiml-spacer-x', 'wiml-spacer-x-4'],
    '3': ['wiml-spacer-x', 'wiml-spacer-x-3'],
    '2': ['wiml-spacer-x', 'wiml-spacer-x-2'],
    '1': ['wiml-spacer-x', 'wiml-spacer-x-1'],
    '0': ['wiml-spacer-x', 'wiml-spacer-x-0'],
};

const spacerYClassNamesTemplate = {
    '12': ['wiml-spacer-y', 'wiml-spacer-y-12'],
    '11': ['wiml-spacer-y', 'wiml-spacer-y-11'],
    '10': ['wiml-spacer-y', 'wiml-spacer-y-10'],
    '9': ['wiml-spacer-y', 'wiml-spacer-y-9'],
    '8': ['wiml-spacer-y', 'wiml-spacer-y-8'],
    '7': ['wiml-spacer-y', 'wiml-spacer-y-7'],
    '6': ['wiml-spacer-y', 'wiml-spacer-y-6'],
    '5': ['wiml-spacer-y', 'wiml-spacer-y-5'],
    '4': ['wiml-spacer-y', 'wiml-spacer-y-4'],
    '3': ['wiml-spacer-y', 'wiml-spacer-y-3'],
    '2': ['wiml-spacer-y', 'wiml-spacer-y-2'],
    '1': ['wiml-spacer-y', 'wiml-spacer-y-1'],
    '0': ['wiml-spacer-y', 'wiml-spacer-y-0'],
};

const COMPONENT_TYPE = getInferredComponentDefaultType_v1_0_0('Spacer');

export function Spacer_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const spacerClassName = _getSpacerClassNames_v1_2_0(props);

    const className = getComponentClassName_v1_2_0(props, spacerClassName);
    const retVal = (
        <div className={className}>
            <V1_2_0_ComponentWrapper {...props}>
                <br />
            </V1_2_0_ComponentWrapper>
        </div >
    );

    return retVal;
}
export function _getSpacerClassNames_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const spacerXClassNames = convertPropsToClassNames_v1_2_0(props, spacerXClassNamesTemplate, 'size-x', 'wiml-spacing-x');
    const potentialDefault = spacerXClassNames ? undefined : 'wiml-spacing-y wiml-spacer-y wiml-spacer-y-5';
    const spacerYClassNames = convertPropsToClassNames_v1_2_0(props, spacerYClassNamesTemplate, 'size-y', 'wiml-spacing-y', potentialDefault);

    const retVal = [spacerXClassNames, spacerYClassNames].filter(Boolean).join(' ');

    return retVal;
}
export function _getSpacerStyleCollectionString_v1_2_0() {
    const spacerXTemplate: ResponsiveClassTemplate_v1_2_0 = {
        'wiml-{size}-spacer-x-10': `padding-left: 5rem`,
        'wiml-{size}-spacer-x-9': `padding-left: 4rem`,
        'wiml-{size}-spacer-x-8': `padding-left: 3rem`,
        'wiml-{size}-spacer-x-7': `padding-left: 2.5rem`,
        'wiml-{size}-spacer-x-6': `padding-left: 2.0rem`,
        'wiml-{size}-spacer-x-5': `padding-left: 1.5rem`,
        'wiml-{size}-spacer-x-4': `padding-left: 1.0rem`,
        'wiml-{size}-spacer-x-3': `padding-left: .75rem`,
        'wiml-{size}-spacer-x-2': `padding-left: .50rem`,
        'wiml-{size}-spacer-x-1': `padding-left: .25rem`,
        'wiml-{size}-spacer-x-0': `padding-left: 0`,
    };

    const spacerYTemplate: ResponsiveClassTemplate_v1_2_0 = {
        'wiml-{size}-spacer-y-10': `padding-top: 5rem`,
        'wiml-{size}-spacer-y-9': `padding-top: 4rem`,
        'wiml-{size}-spacer-y-8': `padding-top: 3rem`,
        'wiml-{size}-spacer-y-7': `padding-top: 2.5rem`,
        'wiml-{size}-spacer-y-6': `padding-top: 2.0rem`,
        'wiml-{size}-spacer-y-5': `padding-top: 1.5rem`,
        'wiml-{size}-spacer-y-4': `padding-top: 1.0rem`,
        'wiml-{size}-spacer-y-3': `padding-top: .75rem`,
        'wiml-{size}-spacer-y-2': `padding-top: .50rem`,
        'wiml-{size}-spacer-y-1': `padding-top: .25rem`,
        'wiml-{size}-spacer-y-0': `padding-top: 0`,
        'wiml-{size}-spacer-y-0 br': `display: none`,
    };

    const styleCollectionString = getStyleCollectionString_v1_2_0([spacerXTemplate, spacerYTemplate]);
    return styleCollectionString;
}
