import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createSlide, deleteSlide } from '@wip/common/event-store/website';
import { createSlide2, deleteSlide2 } from '@wip/common/event-store/website';

const SlideList = (props) => {
    const dispatch = useDispatch();

    function addSlide(e) {
        if (props.secondary) {
            dispatch(createSlide2());
        } else {
            dispatch(createSlide());
        }
    }

    function removeSlide(id, e) {
        if (props.secondary) {
            dispatch(deleteSlide2({ id }));
        } else {
            dispatch(deleteSlide({ id }));
        }
    }

    function selectSlide(id, e) {
        props.onSelectSlide(id);
    }

    const slides = props.secondary ? props.websiteData.slides2 : props.websiteData.slides;

    const slideList = slides.map(s => (
        <ListGroupItem key={s.id}>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="link" onClick={selectSlide.bind(null, s.id)}>{s.about.title}</Button>

                <Button color="link" onClick={removeSlide.bind(null, s.id)}><FontAwesomeIcon icon={faTrash} /> Remove</Button>
            </div>

        </ListGroupItem>
    ));
    return (
        <Form>
            <Button outline color="primary" onClick={addSlide}>
                <FontAwesomeIcon icon={faPlus} /> Add New Slide
            </Button>
            <ListGroup>
                {slideList}
            </ListGroup>
        </Form>
    );
}

export default SlideList;
