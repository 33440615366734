
import { getListItemSiteData_v1_0_0, postProcessPropertyValue_v1_0_0 } from '../../v1.0.0/site-data/retrieval';
import { RuntimeWimlComponentProps_v1 } from "../../types";
import { isListChildComponentType_v1_0_0 } from '../../v1.0.0/theme-data/renderer/render-components';


export function getListChildComponentPropertyValueSiteData_v1_2_0(props: RuntimeWimlComponentProps_v1, propertyName: string, listId: string, listItemId: string) {
    const camlizedComponentId = props.componentId;

    const listItemData = getListItemSiteData_v1_0_0(props.websiteData, listId, listItemId);
    const propertyValue = listItemData?.components?.items?.[camlizedComponentId]?.[propertyName]?.data?.value;

    return propertyValue;
}

export function getPageChildComponentPropertyValueSiteData_v1_2_0(props: RuntimeWimlComponentProps_v1, propertyName: string,) {
    const pageId = props.pageDefinitionId;
    if (!pageId) throw new Error('pageDefinitionId is required for getPageComponentPropertyValue: ' + propertyName);
    const camlizedComponentId = props.componentId;

    const propertyValue = props.websiteData.style.wiml.siteData?.components.items?.pages?.items?.[pageId]?.components?.items?.[camlizedComponentId]?.[propertyName]?.data?.value;

    return propertyValue;
}

export function getChildComponentPropertyValueSiteData_v1_2_0<T = string>(props: RuntimeWimlComponentProps_v1, propertyName: string) {
    // if (componentType) {
    //     // todo remove assertion
    //     throw new Error('defaultComponentKey is not supported');
    // } else {
    //     componentType = defaultComponentKey;
    // }
    // if (!props.listItemId && propertyName == 'date') throw new Error('date property is only available for list items');
    const retVal = isListChildComponentType_v1_0_0(props)
        ? getListChildComponentPropertyValueSiteData_v1_2_0(props, propertyName, props.dataRetrievalListId, props.dataRetrievalListItemId)
        : getPageChildComponentPropertyValueSiteData_v1_2_0(props, propertyName);

    const retVal2 = postProcessPropertyValue_v1_0_0(retVal) as T
    return retVal2;
}
