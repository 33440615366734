import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createEvent, deleteEvent } from '@wip/common/event-store/website';

const EventList = (props) => {
    const dispatch = useDispatch();

    function addEvent(e) {
        dispatch(createEvent());
    }

    function removeEvent(id, e) {
        dispatch(deleteEvent({ id }));
    }

    function selectEvent(id, e) {
        props.onSelectEvent(id);
    }

    const eventList = props.websiteData.events.map(e => (
        <ListGroupItem key={e.id}>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="link" onClick={selectEvent.bind(null, e.id)}>{e.about.title}</Button>

                <Button color="link" onClick={removeEvent.bind(null, e.id)}><FontAwesomeIcon icon={faTrash} /> Remove</Button>
            </div>

        </ListGroupItem>
    ));
    return (
        <Form>
            <Button outline color="primary" onClick={addEvent}>
                <FontAwesomeIcon icon={faPlus} /> Add New Event
            </Button>
            <ListGroup>
                {eventList}
            </ListGroup>
        </Form>
    );
}

export default EventList;
