import { objectUtils } from "..";

const TRANSACTIONAL_PATH = 'transactional';

export function getClient(firebaseClient) {
    function formatUserData(userId, userData) {
        return { id: userId, websites: Object.keys(userData.websites || []) };
    }

    const client = {
        createUser: async function ({ firstName, email, password }) {
            let retVal;

            try {
                // https://firebase.google.com/docs/auth/admin/manage-users#create_a_user
                const userRecord = await firebaseClient
                    .auth()
                    .createUser({
                        displayName: firstName,
                        email: email,
                        emailVerified: true, //should this be false when we go live?
                        password: password,
                    });

                retVal = userRecord.uid;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error creating new user: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }

            return retVal;
        },

        giveUserPermissionToWebsite: async function ({ userId, websiteId }) {
            try {
                const userRef = firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`user/${userId}/websites/${websiteId}`);

                // const firebaseData = { [websiteId]: true };

                // await userRef.update(firebaseData);
                await userRef.set(true);

                return websiteId;
            } catch (e) {
                const rethrow = new Error('error givving user permission to website: ' + e);
                rethrow.stack = e.stack;

                throw rethrow;
            }
        },

        getData: async function (userId) {
            try {
                let retVal = null;

                const snapshot = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/user/${userId}/`).once('value');

                // const data = humps.camelizeKeys(snapshot.val());
                // todo fix this - this is the same problem in website service. calelize keys is breakitng underscores and other acceptable chars as firebase keys.
                const data = (snapshot.val());
                if (data) {
                    retVal = formatUserData(userId, data);
                }

                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error retrieving user data: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },

        getDataByEmail: async function (email) {
            let retVal = null;
            try {
                const fbUser = await firebaseClient
                    .auth()
                    .getUserByEmail(email);

                const userId = fbUser?.uid;
                retVal = this.getData(userId);
            } catch (e) {
                if (/no user/i.test(e) == false) {
                    console.error('error', e)
                    const rethrow = new Error('error retrieving user data: ' + e);
                    rethrow.stack = e.stack;
                    throw rethrow;
                }
            }

            return retVal;
        },

        getAllData: async function () {
            try {
                const snapshot = await firebaseClient.database().ref(TRANSACTIONAL_PATH).child(`/user/`).once('value');
                const data = (snapshot.val());

                const dataAsArray = objectUtils.convertObjectToArray(data);
                const users = dataAsArray.map(formatUserData);

                const retVal = users;
                return retVal;
            } catch (e) {
                console.error('error', e)
                const rethrow = new Error('error retrieving payment info for website: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        },
    };

    return client;
}
