import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createBook, deleteBook } from '@wip/common/event-store/website';

const BookList = (props) => {
    const books = props.books || props.websiteData.books;//.filter(b => !b.series.id);
    const dispatch = useDispatch();

    function addBook(e) {
        dispatch(createBook());
    }

    function removeBook(id, e) {
        dispatch(deleteBook({ id }));
    }

    function selectBook(id, e) {
        props.onSelect(id);
    }

    const bookList = books.map(b => (
        <ListGroupItem key={b.id}>
            <div className="d-flex justify-content-between align-items-center">
                <Button color="link" onClick={selectBook.bind(null, b.id)}>{b.about.title}</Button>

                <Button color="link" onClick={removeBook.bind(null, b.id)}><FontAwesomeIcon icon={faTrash} /> Remove</Button>
            </div>

        </ListGroupItem>
    ));
    return (
        <Form>
            <Button outline color="primary" onClick={addBook}>
                <FontAwesomeIcon icon={faPlus} /> Add New Book
            </Button>
            <ListGroup>
                {bookList}
            </ListGroup>
        </Form>
    );
}

export default BookList;
