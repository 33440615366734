import { customAlphabet } from 'nanoid';
import humps from 'humps';

const alphabet = '23456789abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ';
const length = 10;
const nanoid = customAlphabet(alphabet, length);

export function invertObject(object) {
  const retVal = Object.keys(object).reduce((accum, next) => {
    accum[object[next]] = next;
    return accum;
  }, {});

  return retVal;
}
type InputObject = Record<string, any>;

type ArrayValue<T> = T extends object ? T & { id: string } : { id: string; value: T };

type ConvertedArray<T extends InputObject> = {
  [K in keyof T]: ArrayValue<T[K]>;
}[keyof T][];


// export function convertObjectToArray<T extends InputObject>(obj: T = {} as T): ConvertedArray<T> {
export function convertObjectToArray<T extends InputObject>(obj: T = {} as T): ConvertedArray<T> {

  const retVal = Object.entries(obj).map(([key, value]) => {
    let arrayVal;

    if (typeof value == 'object') {
      arrayVal = { ...value, id: key };
    } else {
      arrayVal = { id: key, value };
    }

    return arrayVal;
  });

  return retVal as ConvertedArray<T>;
}
/*
{
  "books": [{"id": 123, "title": "hello me"}]
  "books": {123: {"title": "hello me"}}
}

{
  "books": [{"id": 123, "title": "hello me", products: [{"id": 456, "title": "my product"}]}]
  "books": {123: {"title": "hello me", "products": {456: {"title": "my product"}}}}
}

{
  "books": [{"id": 123, "title": "hello me", products: [456]}]
  "books": {123: {"title": "hello me", "products": {456: true}}}
}
*/
type ArrayItem = Record<string, any>;

export function convertArrayToObject<T extends ArrayItem, K extends keyof T>(
  array: T[],
  key: K = "id" as K
): Record<string, Omit<T, K>> {
  const retVal = array.reduce((accum, next) => {
    const { [key]: id, ...rest } = next;
    if (id !== undefined) {
      accum[id as string] = rest;
    } else {
      return { ...accum, [next as any]: true };
    }

    return accum;
  }, {} as Record<string, Omit<T, K>>);

  return retVal;
}

export function replaceArraysWithObjects(key, value) {
  let retVal;

  if (Array.isArray(value)) {
    retVal = convertArrayToObject(value);
  } else {
    retVal = value;
  }

  return retVal;
}

const identity = _ => _;
// todo why not just use humps?
export function replaceKeys(obj, replacer = identity) {
  let newObj = {};
  for (let key in obj) {
    let newKey = replacer(key);
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      newObj[newKey] = replaceKeys(obj[key], replacer);
    } else {
      newObj[newKey] = obj[key];
    }
  }
  return newObj;
}

export function makeSerialilzable(obj, replacer) {
  const retVal = JSON.parse(JSON.stringify(obj, replacer));

  return retVal;
}

// https://stackoverflow.com/questions/30339675/how-to-map-json-data-to-a-class
export class DataClass {
  constructor(data) {
    // make a complete copy - no remaining references
    // good for react props - where they make objects locked
    Object.assign(this, makeSerialilzable(data));
  }
}

export function camelizeFirstLayer(obj) {
  // todo this solves the problem we run into with customHtml.pages 
  // each page key is the id, this causes a problem when we getData. it decamlizes everything upon save.
  // we should update the save website data behavior to do this too. that way the db will store 
  // the correctly formtaed ids.
  const dataWithCamlizedKeys = Object.keys(obj).reduce((accum, next) => {
    const decamlziedKey = next;

    const camlizedKey = humps.camelize(decamlziedKey);
    accum[camlizedKey] = obj[decamlziedKey]

    return accum;

  }, {});

  const retVal = dataWithCamlizedKeys;

  return retVal;
}

// todo make sequential
// eliminate confusing chars (lI10Oo)
// prefix uid with obj type a la stripe cu_abcsdf, li_abse
export const generateId = (prefix) => {
  const id = nanoid();
  const retVal = `${prefix}_${id}`;

  return retVal;
};

export function prepareDataTransportV4(data) {
  const preparedKeys = _preapreDataTransportKeys(data);
  const seriailizedData = makeSerialilzable(preparedKeys);
  const retVal = seriailizedData;

  return retVal;
}

export function prepareDataTransportV3(data) {
  const preparedKeys = _preapreDataTransportKeys(data);
  const seriailizedData = makeSerialilzable(preparedKeys, replaceArraysWithObjects);
  const retVal = seriailizedData;

  return retVal;
}

export function _preapreDataTransportKeys(data) {
  const preparedData = humps.decamelizeKeys(data, humpsDomainObjectFormattingOptions());
  const retVal = preparedData;

  return retVal;
}

const humpsDomainObjectFormattingOptions = prefixesArray => {
  const prefixes = prefixesArray ? `(${prefixesArray.map(p => p.toLowerCase()).join('|')})` : `[a-z]{1,6}`;
  const regex = new RegExp(`^${prefixes}_(\\w){10}$`)

  const retVal = {
    process: function (key, convert, options) {
      // test for id pattern, do not format these
      // https://github.com/domchristie/humps#converting-object-keys
      return regex.test(key) ? key : convert(key, options);
    }
  };

  return retVal;
};

const humpsDomainObjectFormattingOptions2 = prefixesArray => {
  const prefixes = prefixesArray ? `(${prefixesArray.map(p => p.toLowerCase()).join('|')})` : `[a-z]{1,6}`;
  const regex = new RegExp(`(^[^_\W]+__[^\W]+$)|(^${prefixes}_(\\w){10}$)`)

  const retVal = {
    process: function (key, convert, options) {
      if (regex.test(key)) {
        key
      }
      // test for id pattern, do not format these
      // https://github.com/domchristie/humps#converting-object-keys
      return regex.test(key) ? key : convert(key, options);
    }
  };

  return retVal;
};

export function rehydrateDomainObject(obj, options = {}) {
  const decamlizedData = humps.camelizeKeys(obj, humpsDomainObjectFormattingOptions(options.prefixesArray));

  const retVal = decamlizedData;

  return retVal;
}

export function rehydrateDomainObject2(obj, options = {}) {
  const decamlizedData = humps.camelizeKeys(obj, humpsDomainObjectFormattingOptions2(options.prefixesArray));

  const retVal = decamlizedData;

  return retVal;
}
