import React from 'react';

import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { getComponentClassName_v1_0_0, renderChildren_v1_0_0 } from '../render-components';

export function Layout_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    const className = getComponentClassName_v1_0_0(props, 'layout');
    const renderedChildren = renderChildren_v1_0_0(props.children);

    return <div className={className}>
        {renderedChildren}
    </div>;
}
