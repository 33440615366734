import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBackward, faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createBook } from '@wip/common/event-store/website';;
import NavigationGroupList from './navigation-group-list';
import NavigationGroupDetail from './navigation-group-detail';
import Gallery from "@wip/common/domain/gallery";
import NavigationGroup from "@wip/common/domain/navigation-group";

const NavigationGroups = (props) => {

    const [navigationGroupDetail, setNavigationGroupDetail] = useState(null);
    const [navigationGroupItemDetail, setNavigationGroupItemDetail] = useState(null);

    let pageViewElelement;

    function doThing() {
        setNavigationGroupDetail(null);
    }

    if (navigationGroupDetail) {
        const pgData = props.websiteData.navigationGroups.find(pg => pg.id == navigationGroupDetail);
        const navigationGroup = new NavigationGroup(pgData.id, pgData);

        pageViewElelement = (
            <>
                {
                    navigationGroupItemDetail == null &&
                    <Button color="link" onClick={doThing}><FontAwesomeIcon icon={faArrowLeft} /> Back</Button>
                }
                <NavigationGroupDetail navigationGroup={navigationGroup} {...props} navigationGroupItemDetail={navigationGroupItemDetail} onSelectNavigationGroupItem={setNavigationGroupItemDetail}/>
            </>
        );
    } else {
        pageViewElelement = (<NavigationGroupList {...props} onSelectNavigationGroup={setNavigationGroupDetail} />);
    }

    return pageViewElelement;
}

export default NavigationGroups;
