import { getChildComponentPropertyValueSiteData_v1_0_0 } from '../../../../v1.0.0/site-data/retrieval';
import { getInferredComponentDefaultType_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/components';
import { RuntimeWimlComponentProps_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/types';
import { Link_v1_0_0, _renderLink_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/link';
import { getComponentClassName_v1_1_0 } from '../../compiler/components';

export function Link_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    const componentClassName = getComponentClassName_v1_1_0(props);
    const urlJSONValue = getChildComponentPropertyValueSiteData_v1_0_0(props, 'url', getInferredComponentDefaultType_v1_0_0('Link')) || props.url;

    // todo replace with next link
    // https://nextjs.org/docs/api-reference/next/link
    const retVal = _renderLink_v1_0_0(props, componentClassName, urlJSONValue);

    return retVal
}

Link_v1_1_0.inputProps = Link_v1_0_0.inputProps;
