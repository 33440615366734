import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import humps from "humps";
import React from "react";
import { convertArrayToObject } from "../../../../../../../../lib/object-utils";
import { getComponentHtmlAttrs_v1_0_0, renderChildren_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/render-components';
import { getComponentClassName_v1_2_0 } from "../../compiler/components";
// unit in pixels, not rem
const responsizeColSizeLookupClassNames = {
    'xs': {
        'auto': 'col-auto',
        '12': 'col-12',
        '11': 'col-11',
        '10': 'col-10',
        '9': 'col-9',
        '8': 'col-8',
        '7': 'col-7',
        '6': 'col-6',
        '5': 'col-5',
        '4': 'col-4',
        '3': 'col-3',
        '2': 'col-2',
        '1': 'col-1',
    },
    'sm': {
        'auto': 'col-sm-auto',
        '12': 'col-sm-12',
        '11': 'col-sm-11',
        '10': 'col-sm-10',
        '9': 'col-sm-9',
        '8': 'col-sm-8',
        '7': 'col-sm-7',
        '6': 'col-sm-6',
        '5': 'col-sm-5',
        '4': 'col-sm-4',
        '3': 'col-sm-3',
        '2': 'col-sm-2',
        '1': 'col-sm-1',
    },
    'md': {
        'auto': 'col-md-auto',
        '12': 'col-md-12',
        '11': 'col-md-11',
        '10': 'col-md-10',
        '9': 'col-md-9',
        '8': 'col-md-8',
        '7': 'col-md-7',
        '6': 'col-md-6',
        '5': 'col-md-5',
        '4': 'col-md-4',
        '3': 'col-md-3',
        '2': 'col-md-2',
        '1': 'col-md-1',
    },
    '': {
        'auto': 'col-lg-auto',
        '12': 'col-lg-12',
        '11': 'col-lg-11',
        '10': 'col-lg-10',
        '9': 'col-lg-9',
        '8': 'col-lg-8',
        '7': 'col-lg-7',
        '6': 'col-lg-6',
        '5': 'col-lg-5',
        '4': 'col-lg-4',
        '3': 'col-lg-3',
        '2': 'col-lg-2',
        '1': 'col-lg-1',
    },
    'lg': {
        'auto': 'col-lg-auto',
        '12': 'col-lg-12',
        '11': 'col-lg-11',
        '10': 'col-lg-10',
        '9': 'col-lg-9',
        '8': 'col-lg-8',
        '7': 'col-lg-7',
        '6': 'col-lg-6',
        '5': 'col-lg-5',
        '4': 'col-lg-4',
        '3': 'col-lg-3',
        '2': 'col-lg-2',
        '1': 'col-lg-1',
    },
    'xl': {
        'auto': 'col-xl-auto',
        '12': 'col-xl-12',
        '11': 'col-xl-11',
        '10': 'col-xl-10',
        '9': 'col-xl-9',
        '8': 'col-xl-8',
        '7': 'col-xl-7',
        '6': 'col-xl-6',
        '5': 'col-xl-5',
        '4': 'col-xl-4',
        '3': 'col-xl-3',
        '2': 'col-xl-2',
        '1': 'col-xl-1',
    },

} as const;


type ColSizeLookupClassNamesKey = keyof typeof responsizeColSizeLookupClassNames;
type ColSizePositionKey = keyof typeof responsizeColSizeLookupClassNames['']


const defaultColLgSizeClass = 'col-lg';
const defaultColXsSizeClass = responsizeColSizeLookupClassNames['xs']['12'];

const colSizeLookupClassNames = Object.keys(responsizeColSizeLookupClassNames) as ColSizeLookupClassNamesKey[];
const colSizeCamelizedPropNames = colSizeLookupClassNames.reduce((acc, size) => {
    const alignPropName = size ? `size-${size}` : 'size';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[size] = camelizedPropName;
    return acc;
}, {} as Record<ColSizeLookupClassNamesKey, string>);

export function Col_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const colClassName = _getColClassNames_v1_2_0(props);
    // const className = getComponentClassName(props, 'col', props.size != null && `col-${props.size}`);
    const className = getComponentClassName_v1_2_0(props, colClassName);
    const extraAttrs = getComponentHtmlAttrs_v1_0_0(props);
    const renderedChildren = renderChildren_v1_0_0(props.children);

    return <div className={className} {...extraAttrs}>
        {renderedChildren}
    </div>;
}
export function _getColClassNames_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const usedSizedArray = colSizeLookupClassNames.map(size => ({ key: size, used: false }));
    const usedSizedObject = convertArrayToObject(usedSizedArray, 'key');

    const classNames = colSizeLookupClassNames.map((size) => {
        const camelizedPropName = colSizeCamelizedPropNames[size];
        const propValue = props[camelizedPropName] as ColSizePositionKey;
        if (propValue) {
            usedSizedObject[size].used = true;
            return [
                // 'col',
                responsizeColSizeLookupClassNames[size][propValue]
            ];
        }
    }).filter(Boolean);

    if (!usedSizedObject.xs.used) {
        classNames.unshift([defaultColXsSizeClass]);
    }

    if (!usedSizedObject[''].used) {
        classNames.push([defaultColLgSizeClass]);
    }

    classNames.unshift(['col', 'wiml-col']);

    const className = classNames.flat().filter(Boolean).join(' ');
    return className;
}




