import { formatNodeKey_v1_2_0 } from "../../../v1.2.0/theme-data/compiler/components";

// todo move these from 1.2 to new-ver-xyz
export function getKeyFromId_v1_3_0(id: string) {
    const typeSeparator = '__';

    const parts = id.split(typeSeparator);
    const key = parts[1];

    const retVal = formatNodeKey_v1_2_0(key);
    return retVal;
}

export function unFormatNodeKey_v1_3_0(key: string) {
    return key && key.replace(/_/g, '-');
}
