import React from 'react';
import { Table, Spinner } from 'reactstrap';

import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { fetcher } from '@wip/common/lib/data-utils'
import { dateUtils, textUtils } from '@wip/common';

import { useEffect, useState } from 'react';


type OrderSummaryData = {
    id: string;
    currency: string;
    amountTotal: number;
    metadata: {
        date: string;
        productData: {
            about: {
                title: string;
            }
        };
    }
}


export function OrderSummary_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    let retVal
    const [state, setState] = useState({
        isLoaded: false,
        data: {} as OrderSummaryData,
    });

    const handleStateChange = (name: string, value: any) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        const orderId = props.currentPage.request.extraQuery.order_id;

        let res;
        const fetchData = async () => {
            try {
                res = await fetcher(`/api/ecommerce/${orderId}`, undefined, { getJson: true });
            } catch {
                // wait n seconds and try again
                await new Promise(resolve => setTimeout(resolve, 5000));
                res = await fetcher(`/api/ecommerce/${orderId}`, undefined, { getJson: true });
            }

            handleStateChange('data', res);
            handleStateChange('isLoaded', true);
        };

        fetchData();
    }, []);

    if (state.isLoaded) {
        // the heading class is defined in globals.
        retVal = (
            <div>
                <h1 className="heading text-center">
                    Order Summary
                </h1>
                <div className="row summary text-center">
                    <div className="col-lg">
                        <div>  <u className="">Order #</u></div>
                        <div>{state.data.id.slice(8, 20)}</div>
                    </div>
                    <div className="col-lg">
                        <div>  <u className="">Date</u></div>
                        <div>{dateUtils.formatStringAsDate(state.data.metadata.date)}</div>
                    </div>
                    <div className="col-lg">
                        <div>  <u className="">Total</u></div>
                        <div>{(state.data.amountTotal / 100).toLocaleString('en-US', { style: 'currency', currency: state.data.currency })}</div>

                    </div>
                </div>
                <div className="mt-5 details">
                    <h3 className="d-block">Order Details</h3>
                    <Table>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/* note: displayItems is deprecated in stripe. this field only exists when youp ass in 1-time purchase products, but it does not exist when you pass in a subscription.*/}
                                {/* <td>{state.data.displayItems[0].custom.name} x{state.data.displayItems[0].quantity}</td> */}
                                <td>{state.data.metadata.productData.about.title} x1</td>

                                <td>{(state.data.amountTotal / 100).toLocaleString('en-US', { style: 'currency', currency: state.data.currency })}</td>
                            </tr>
                            <tr>
                                <td>Subtotal</td>
                                <td>{(state.data.amountTotal / 100).toLocaleString('en-US', { style: 'currency', currency: state.data.currency })}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                {/* todo refer to components/common/orders.js <style jsx>{`
                    .summary {
                        background-color: var(--global-color-bg);
                    }
                `}</style> */}
            </div>
        );
    } else {
        retVal = (
            <div>
                <h4 className="text-center">
                    Loading Order Info… &nbsp;
                    <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    />
                </h4>

            </div>
        );
    }
    return retVal;
}
