import { RuntimeWimlComponentProps_v1_0_0 } from '../../../../../../../app/wiml/versions/v1/v1.0.0/theme-data/compiler/types';
import { NoOp_v1_0_0 } from './no-op';

export default function Navigation_v1_0_0(props: RuntimeWimlComponentProps_v1_0_0) {
    return NoOp_v1_0_0();
}

Navigation_v1_0_0.inputProps = {
    items: {
        type: 'json',
        label: 'Navigation Items',
        description: 'The JSON payload for the navigation items',
    },
};
