import { _isPageDefinitionNode_v1_0_0, _isPageNode_v1_0_0, getNodeAttr_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/nodes';
import { ComponentThemeData_v1_2_0, } from '../compiler/types';


import humps from 'humps';
import React from 'react';
import { _getComponentId_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/components';
import { getNodeKey_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/nodes';
import { RuntimeWimlComponentProps_v1 } from "../../../types";
import { convertPropsToReactFormat_v1_0_0 } from '../../../v1.0.0/theme-data/renderer/render-components';
import { ThemeDataReactComponentOptions_v1_1_0, WimlNode_v1_1_0 } from '../../../v1.1.0/theme-data/compiler/types';
import { _getThemeDataComponentId_v1_2_0, formatNodeKey_v1_2_0 } from '../compiler/components';
import { getListIdFromNodeAttrs_v1_2_0 } from '../compiler/nodes';
import { _getBackgroundColorStyles_v1_2_0 } from './attributes/background';
import { _getFontColorStyles_v1_2_0 } from './attributes/font';
import { _getHrColorStyles_v1_2_0 } from './components/hr';

//todo find way to not copy so much
// todo v1.2 find way to rely on theme data parent/child relationship for rendering
export function convertThemeDataToReact_v1_2_0(themeData: ComponentThemeData_v1_2_0) {
    const wimlRoot = themeData.rootNode;
    const layoutNode = wimlRoot.child.find((node) => node.tag == 'Layout');

    const retVal = wimlRoot.child
        .filter((node) => node != layoutNode)
        .reduce((accum, node) => {
            const key = _getThemeDataComponentId_v1_2_0({
                type: node.tag,
                key: getNodeAttr_v1_0_0(node).key,
            });
            accum[key] = convertJsonNodeToReact_v1_2_0(node, { layout: layoutNode });
            return accum;
        }, {} as Record<string, React.ReactNode>);

    return retVal;
}


// DFS iterative https://medium.com/@jpoechill/iterative-bfs-and-dfs-in-javascript-
// https://blog.bitsrc.io/depth-first-search-of-a-binary-tree-in-javascript-874701d8210a
export function convertJsonNodeToReact_v1_2_0(node: WimlNode_v1_1_0, options: ThemeDataReactComponentOptions_v1_1_0): React.ReactNode {
    let retVal = null;

    if (node.tag == 'div') {
        const b = node;
    }
    if (node.node == 'text') {
        retVal = node.text;
    } else {
        const { layout, ...optionsRest } = options;

        const isPageNode = _isPageNode_v1_0_0(node);
        if (isPageNode) {
            optionsRest.pageOccurrenceId = optionsRest.pageDefinitionId = _getThemeDataComponentId_v1_2_0({
                type: node.tag,
                key: getNodeAttr_v1_0_0(node).key,
            });
        }

        if (_isPageDefinitionNode_v1_0_0(node)) {
            // if top level page component, set the pageDefinitionId
            // if page node or header/footer
            // set optoiins
            optionsRest.pageDefinitionId = _getThemeDataComponentId_v1_2_0({
                type: node.tag,
                key: getNodeAttr_v1_0_0(node).key,
            });
        } else {
            if (optionsRest.pageDefinitionId) {
                node.attr = { ...node.attr, pageDefinitionId: optionsRest.pageDefinitionId };
            }

            if (optionsRest.pageOccurrenceId) {
                node.attr = { ...node.attr, pageOccurrenceId: optionsRest.pageOccurrenceId };
            }

            if (node.tag != 'List') {
                if (optionsRest.listId) {
                    node.attr = { ...node.attr, listId: optionsRest.listId };
                }

                if (optionsRest.listInstanceComponentId) {
                    node.attr = { ...node.attr, listInstanceComponentId: optionsRest.listInstanceComponentId };
                }
            }
        }

        if (layout) {
            // replace <CurrentPage /> child with node
            const layoutChildren = layout.child.map((childNode) => (childNode.tag == 'CurrentPage' ? node : childNode));
            node = { ...layout, child: layoutChildren };
        }

        // do not use this - because the types are minified at build time so they are not the same as the string
        // e.g. List -> At (just a random example)
        // e.g. Header -> Li (just a random example)
        // const component = _getReactComponent(node);
        const component = node.tag;

        const children = node.child;
        // Add logic to wrap image node with "wrap" attribute and its sibling in a div
        const transformedChildren = children.reduce((acc, child, i, arr) => {
            if (child.tag === 'Image' && child.attr && child.attr.wrap && node?.attr?.extra?.existReason !== 'wrap') {
                const sibling = arr[i + 1];
                if (sibling) {
                    acc.push({
                        tag: 'div',
                        attr: {
                            extra: {
                                existReason: 'wrap',
                                existType: 'system',
                            },
                            "class": "wiml-wrap-container",
                        },
                        child: [child, sibling],
                        startIndex: child.startIndex,
                        endIndex: sibling.endIndex,
                        node: 'element',
                    });
                } else {
                    acc.push(child);
                }
            } else {
                acc.push(child);
            }
            return acc;
        }, []);

        // same as above but iterative
        const transformedChildren2 = [];
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.tag === 'Image' && child.attr && child.attr.wrap && node?.attr?.extra?.existReason !== 'wrap') {
                const sibling = children[i + 1];
                if (sibling) {
                    transformedChildren2.push({
                        tag: 'div',
                        attr: {
                            extra: {
                                existReason: 'wrap',
                            }
                        },
                        child: [child, sibling],
                        startIndex: child.startIndex,
                        endIndex: sibling.endIndex,
                        node: 'element',
                    });
                    i++; // Skip the next sibling
                } else {
                    transformedChildren2.push(child);
                }
            } else {
                transformedChildren2.push(child);
            }
        }

        // strip key from node.attr
        const { key, ...rest } = node.attr || {};

        const componentId = _getThemeDataComponentId_v1_2_0({ key: key || '', type: component }, false);

        const restHumps = humps.camelizeKeys(rest) as RuntimeWimlComponentProps_v1;
        // convert any props that need to be in react format e.g. class -> className
        const reactProps = convertPropsToReactFormat_v1_0_0(restHumps);

        let listId: string = null;
        let listInstanceComponentId: string = null;
        if (node.tag == 'List') {
            listId = getListIdFromNodeAttrs_v1_2_0({ attr: { ...restHumps, key } });
            optionsRest.listId = listId;

            const parentListInstanceComponentId = optionsRest.listInstanceComponentId;
            listInstanceComponentId = _getThemeDataComponentId_v1_2_0({ key: key || '', type: component, parentListInstanceComponentId: parentListInstanceComponentId }, true);
            optionsRest.listInstanceComponentId = listInstanceComponentId;
        }

        const componentProps = {
            ...reactProps,
            ...(key && { componentKey: key }),
            ...(listId && { listId }),
            ...(listInstanceComponentId && { listInstanceComponentId }),
            ...{ componentId },
            // todo figure out how to get theme data meta during render (e.g. galleryView)
            // but also consider things like startIndex which may not be in theme data
            meta: {
                startIndex: node.startIndex,
                endIndex: node.endIndex,
                lineNumber: node.lineNumber,
            }
        };
        if (transformedChildren) {
            retVal = React.createElement(component, componentProps, ...transformedChildren.map((c) => convertJsonNodeToReact_v1_2_0(c, optionsRest)));
        } else {
            retVal = React.createElement(component, componentProps);
        }
    }

    return retVal;
}


export function getComponentStyles_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const styles = [
        _getBackgroundColorStyles_v1_2_0(props),
        _getFontColorStyles_v1_2_0(props),
        _getHrColorStyles_v1_2_0(props), // todo this will surely cause a collisionwith 'color'
    ];

    return styles;
}
