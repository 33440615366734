import { PRODUCT_ROOT } from '@wip/common/lib/analytics-utils';
import humps from 'humps';
import { fetcher } from "@wip/common/lib/data-utils";
import Avatar from 'react-avatar';
import { useRef, useState } from 'react';
import {
  Button,
  Collapse,
  Form,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { analyticsUtils, customerSupportUtils } from '@wip/common'
import { AuthProvider, useAuth, } from '@wip/common/lib/auth';
import { AnalyticsProvider, useAnalytics, } from '@wip/common/lib/analytics-utils';

export default function Layout(props) {
  // because we are using helmot, we have to have single elements in the head
  // TypeError: Cannot convert a Symbol value to a string
  const freshchatWidgetLoaderScript = getFreshchatWidgetLoaderScript(props);
  const freshchatWidgetUserScript = getFreshchatWidgetUserScript(props);

  // const helppierScript = getHelppierScript();

  const user = props.userData;

  const nav = getNav(props, user);

  const currentEnv = process.env.NODE_ENV;

  const apiUrl = process.env.REACT_APP_API_URL;
  const { tracker } = useAnalytics();

  useEffect(async () => {
    if (currentEnv == 'production' && props.websiteData.meta.websiteType != PRODUCT_ROOT) {
      const isAdmin = user.roles.includes('admin');

      if (isAdmin) {
        return;
      }

      const websiteId = props.websiteData.id;

      // todo: expose `alias` via plugin
      // `alias` must come first
      // https://segment.com/docs/destinations/mixpanel/#aliasing-from-the-browser
      // hack for calling alias
      // https://www.npmjs.com/package/@analytics/segment
      // https://github.com/DavidWells/analytics/blob/master/packages/analytics-plugin-segment/src/browser.js
      // https://segment.com/docs/connections/spec/best-practices-identify/
      // https://segment.com/docs/connections/spec/alias/
      // i guess this is no longer recommended.
      // window.analytics?.alias(user.uid); 

      const userTrailts = {
        ...user,
      }

      const traitData = humps.decamelizeKeys(userTrailts);
      tracker.identify(user.uid, traitData);

      tracker.page({ website_id: websiteId });
      window.__wip_fs_init = function () {
        // freshsales https://developers.freshworks.com/crm/api/#create_deal
        // https://crmsupport.freshworks.com/support/solutions/articles/50000002578-what-are-unique-fields-why-are-few-fields-marked-as-unique-by-default-
        // https://segment.com/docs/connections/destinations/catalog/freshsales-suite-crm/
        // https://crmsupport.freshworks.com/support/solutions/articles/50000003223-how-to-integrate-segment-with-the-web-application-
        // name is unique so duplicates not created

        // note freshsales segment not working 
        // https://support.freshdesk.com/support/tickets/16569397

        // https://developers.freshworks.com/crm/libraries/spec/identify/
        // const freshsalesTrailts = {
        //   ...user,
        // https://developers.freshworks.com/crm/libraries/spec/identify/#user-id
        // not sure what fs_contact actually does, seems to have effect on whether or not events are tracked.
        // fs_contact: true,

        // Deals cannot be made with direct api - only segment which requires pro plan $40/mo/user
        // deal: {
        //   name: `Website: ${props.websiteData.meta.slug}`,

        //   // this MUST be configured as a unique field in freshsales otherwise each page load creates new deal.
        //   // more at https://crmsupport.freshworks.com/support/solutions/articles/50000002578-what-are-unique-fields-why-are-few-fields-marked-as-unique-by-default-
        //   cf_website_id: websiteId,

        //   cf_website_slug: props.websiteData.meta.slug,
        //   cf_website_version: props.websiteData.meta.appVersion ?? "v3",// todo - get from website - 1 spot for defaults
        //   cf_website_wiml_version: props.websiteData.style.wiml?.themeData?.meta?.version || "1.0.0", // to get from website apps/admin/src/components/manage/v4/wiml/versions/v1/v1.3.0/wiml-editor-v1.3.0.js
        //   cf_website_manage_url: `https://app.wildinkpages.com/manage/${props.websiteData.meta.slug}`,
        // }
        // }
        // NOTE: DO NOT USE user.uid as freshsales id! WHY? BECAUSE I SPENT OVER AN HOUR WITH THEIR SUPPORT TEAM ON A CALL AND THIS WAS THE ONLY WAY
        // window.freshsales?.identify(user.uid, humps.decamelizeKeys(freshsalesTrailts));
        window.freshsales?.identify(user.email, traitData);
        window.freshsales?.trackPageView(location.href);
      }
    }
  }, [currentEnv, user]);

  useEffect(async () => {
    if (currentEnv == 'production') {

      const websiteId = props.websiteData.id;

      const isAdmin = user.roles.includes('admin');

      try {
        // ending slash - do not have ending slash or CORS error - will cause redirect from /user/ to /user (no ending slash) == cors error.
        const filterIpApiUrl = `${apiUrl}/analytics/${websiteId}/filter/ip`;

        // api uses snake_case
        const POSTWebhookData = {
          is_admin: isAdmin,
        };

        const POSTOptions = {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          headers: { "Content-Type": "application/json", "Authorization": 'fill me in with users token' },
          body: JSON.stringify(POSTWebhookData)
        };

        const res = await fetcher(filterIpApiUrl, POSTOptions, { getJson: false });
        console.log('res', res.status);
      } catch (e) {
        const rethrow = new Error('Error saving ip filter: ' + e);
        rethrow.stack = e.stack;
        throw rethrow;
      }

    }
  }, [currentEnv, user]);

  return (
    <>
      <Helmet>
        <link rel="icon" href="/public/favicon.ico" />
        <meta
          name="description"
          content="Learn how to build a personal website using Next.js"
        />
        <title>Wild Ink Pages</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Lato&display=block" rel="stylesheet" />
        {freshchatWidgetLoaderScript}
        {freshchatWidgetUserScript}
        <script dangerouslySetInnerHTML={{
          __html: `
            
          `}} />
      </Helmet>
      <header>
        {nav}
      </header>
      {user && props.children}
    </>
  )
}

function getHelpCrunchScript(props) {
  const currentEnv = process.env.NODE_ENV;
  let helpCrunchScript;

  if (currentEnv == 'production') {
    const user = props.userData;

    // todo remove if condition 
    if (user) {

      const isAdmin = user.roles.includes('admin');
      if (isAdmin) {
        return;
      }
    }
    // deprecated
    // const helpCrunchId = process.env.REACT_APP_HELPCRUNCH_SECRET;
    helpCrunchScript = customerSupportUtils.getHelpCrunchScript(props);
  }

  // do not use dangerouslysetinnerhtml when using helmet, i gues??
  // https://github.com/nfl/react-helmet#reference-guide
  // TypeError: Cannot convert a Symbol value to a string
  // Example: this is not allowed
  /* 
   const retVal = <> <-- this is not allowed
      <script>{helpCrunchScript}</script>
    </>;
   */


  const retVal = (
    <script>{helpCrunchScript}</script>
  );
  return retVal;
}


function getFreshchatWidgetLoaderScript(props) {
  const currentEnv = process.env.NODE_ENV;
  let freshchatWidgetLoaderScript;

  if (currentEnv == 'production') {
    const user = props.userData;

    const isAdmin = user.roles.includes('admin');
    if (isAdmin) {
      return;
    }

    freshchatWidgetLoaderScript = customerSupportUtils.getFreshchatWidgetLoaderScript(props);
  }

  return freshchatWidgetLoaderScript;
}

function getFreshchatWidgetUserScript(props) {
  const currentEnv = process.env.NODE_ENV;
  let freshchatWidgetLoaderScript;

  if (currentEnv == 'production') {
    const user = props.userData;

    const isAdmin = user.roles.includes('admin');
    if (isAdmin) {
      return;
    }

    freshchatWidgetLoaderScript = customerSupportUtils.getFreshchatWidgetUserScript(props);
  }

  return freshchatWidgetLoaderScript;
}


export function MiniLayout(props) {
  const user = props.userData;
  const nav = getNav(props, user);

  const currentEnv = process.env.NODE_ENV;

  const { tracker } = useAnalytics();

  useEffect(async () => {
    if (currentEnv == 'production') {
      const isAdmin = user?.roles?.includes('admin');

      if (isAdmin) {
        return;
      }

      tracker.page();

    }
  }, [currentEnv, user]);

  return (
    <>
      <Helmet>
        <link rel="icon" href="/public/favicon.ico" />
        <meta
          name="description"
          content="Learn how to build a personal website using Next.js"
        />
        <title>Wild Ink Pages</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Lato&display=block" rel="stylesheet" />

      </Helmet>
      <header>
        {nav}
      </header>
      {user && props.children}
    </>
  )
}

function getNav(props, user) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const navTitle = props.navTitle || 'Wild Ink Pages';
  let navProfileItem;
  if (user && user.email) {

    navProfileItem = (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          <Avatar size={45} className="profile-image img-fluid rounded-circle mr-1" email={user.email} />
        </DropdownToggle>
        <DropdownMenu right>
          {/* TODO - use react router to pushState -- https://stackoverflow.com/questions/47105279/how-can-i-add-a-link-within-a-dropdownitem-with-reactstrap */}
          <DropdownItem href="/account/settings/">
            Profile
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem href="/logout">
            Logout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  } else {
    navProfileItem = (
      <NavItem>
        <NavLink href="/login">Sign in</NavLink>
      </NavItem>
    );
  }

  const nav = (
    <div className="nav-wrapper border-bottom">
      <Navbar light expand="lg">
        <NavbarBrand href="/">{navTitle}</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar className="ml-auto">
            <Form inline className="my-2 my-lg-0">
              {/* <Button color="primary" outline>Publish</Button> */}
            </Form>
            {navProfileItem}
          </Nav>
        </Collapse>
      </Navbar>

      <style jsx>{`
        .nav-wrapper :global(nav) {
          font-family: ${props.themeConfig.primaryFontFamily};
          font-size: 21px;
          text-transform: uppercase;
          letter-spacing: 3px;
          padding-top: 10px;
        }

        /* li is replaced by <NavItem/> */
        .nav-wrapper :global(li) {
          padding: 0 11px;
        }

        .nav-wrapper :global(a) {
          color: ${props.themeConfig.primaryColor};
          text-decoration: none;
          white-space: initial; /* https://stackoverflow.com/a/32817857/173957 */
        }
        
        .nav-wrapper :global(.profile-image) {
          width: 25px;
          height: 25px;
        }  
        
        .nav-wrapper :global(svg) {
          width: 5px;
          height: 5px;
          color: ${props.themeConfig.mutedColor};
        }

        @media ${breakPoints.lg} { 
          .nav-wrapper :global(.profile-image) {
            width: 45px;
            height: 45px;
          }

          .nav-wrapper :global(svg) {
            width: 15px;
            height: 15px;
          }
        }
      `}</style>
    </div>
  );

  return nav;
}


const size = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

export const breakPoints = {
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
};
