import humps from "humps";
import { breakpoints_v1_1_0 } from "../../../../v1.1.0/theme-data/renderer/utils/breakpoints";

export function getResponsiveClassSetFromTemplate_v1_2_0(template: ResponsiveClassTemplate_v1_2_0): ResponsiveClassSet_v1_2_0 {
    const responsiveStyleSet: ResponsiveClassSet_v1_2_0 = {
        '': {},
        'lg': {},
        'md': {},
        'sm': {},
        'xs': {}
    };

    for (const [key, value] of Object.entries(template)) {
        const isArray = Array.isArray(value);
        const arrayValue = isArray ? value : [value];

        responsiveStyleSet[''][key] = arrayValue.join(' ');
        responsiveStyleSet['lg'][key] = getClassNameWithPrefix(arrayValue, 'lg');
        responsiveStyleSet['md'][key] = getClassNameWithPrefix(arrayValue, 'md');
        responsiveStyleSet['sm'][key] = getClassNameWithPrefix(arrayValue, 'sm');
        responsiveStyleSet['xs'][key] = getClassNameWithPrefix(arrayValue, 'xs');
    }

    return responsiveStyleSet;
}
export type ResponsiveClassTemplate_v1_2_0 = Record<string, string | string[]>;

export type ResponsiveClassSet_v1_2_0 = {
    '': {
        [key: string]: string;
    };
    lg: {
        [key: string]: string;
    };
    md: {
        [key: string]: string;
    };
    sm: {
        [key: string]: string;
    };
    xs: {
        [key: string]: string;
    };
};
export type ResponsiveSizes_v1_2_0 = keyof ResponsiveClassSet_v1_2_0;
export const responvieSizes_v1_2_0 = ["", "lg", "md", "sm", "xs"] as ResponsiveSizes_v1_2_0[]
function getClassNameWithPrefix(arrayValue: string[], prefix: string) {
    return arrayValue.map((className) => {
        const [first, ...rest] = className.split('-');
        return [first, prefix, ...rest].join('-');
    }).join(' ');
}

export function convertPropsToClassNames_v1_2_0(props: Record<string, string>, styleTemplate: ResponsiveClassTemplate_v1_2_0, className: string, additionalClassesIfNotEmpty: string | string[] = [], additionalClassesIfEmpty: string | string[] = []) {
    const responsiveClassSet = getResponsiveClassSetFromTemplate_v1_2_0(styleTemplate);

    const camelizedPropNames = getCamlizedPropName_v1_2_0(className);

    const classNames = getClassNames_v1_2_0(props, camelizedPropNames, responsiveClassSet);

    const finalClassNames = prepareClassNames_v1_2_0(classNames, undefined, additionalClassesIfNotEmpty, additionalClassesIfEmpty);
    return finalClassNames;
}
export type ResponsiveClassnames_v1_2_0 = Record<ResponsiveSizes_v1_2_0, string>;
export type ResponsiveProps_v1_2_0 = Record<ResponsiveSizes_v1_2_0, string>;

export function getCamlizedPropName_v1_2_0(className: string) {
    return responvieSizes_v1_2_0.reduce((acc, size) => {
        const propName = size ? `${className}-${size}` : className;
        const camelizedPropName = humps.camelize(propName);
        acc[size] = camelizedPropName;
        return acc;
    }, {} as ResponsiveClassnames_v1_2_0);
}

export function getResponsiveClassNames_v1_2_0(propName: string): ResponsiveClassnames_v1_2_0 {
    const classNames = {
        '': `wiml-${propName}`,
        'lg': `wiml-lg-${propName}`,
        'md': `wiml-md-${propName}`,
        'sm': `wiml-sm-${propName}`,
        'xs': `wiml-xs-${propName}`,
    };
    return classNames;
}

export function prepareClassNames_v1_2_0(classNames: string[][], separater: string = ' ', additionalClassesIfNotEmpty: string | string[] = [], additionalClassesIfEmpty: string | string[] = []) {
    const classNameArr = [...classNames].flat().filter(Boolean);

    if (additionalClassesIfNotEmpty && classNameArr.length > 0) {
        const additionalClasses = Array.isArray(additionalClassesIfNotEmpty) ? additionalClassesIfNotEmpty : [additionalClassesIfNotEmpty];
        const allClassNames = [...additionalClasses, ...classNameArr];

        const finalClassNames = allClassNames.join(separater);
        return finalClassNames;
    } else if (additionalClassesIfEmpty) {
        const additionalClasses = Array.isArray(additionalClassesIfEmpty) ? additionalClassesIfEmpty : [additionalClassesIfEmpty];

        const finalClassNames = additionalClasses.join(separater);
        return finalClassNames;
    }
}

export function getClassNames_v1_2_0(props: Record<string, string>, propNames: ResponsiveClassnames_v1_2_0, classSet: ResponsiveClassSet_v1_2_0) {
    return responvieSizes_v1_2_0.map((size) => {
        const camelizedPropName = propNames[size];
        const propValue = props[camelizedPropName];
        if (propValue) {
            return [
                classSet[size][propValue]
            ];
        } else {
            return [];
        }
    });
}

export function getDynamicClassNames_v1_2_0(props: Record<string, string>, responsiveProps: ResponsiveProps_v1_2_0, responsiveClassNames: ResponsiveClassnames_v1_2_0) {
    const retVal = responvieSizes_v1_2_0.map((size) => {
        const camelizedPropName = responsiveProps[size];
        const propValue = props[camelizedPropName] as string
        if (propValue) {
            return [
                responsiveClassNames[size] + '-dynamic__' + propValue
            ];
        } else {
            return [];
        }
    });

    return retVal;
}

export function convertPropsToDynamicClassNames_v1_2_0(props: Record<string, string>, propName: string, className: string = propName) {
    const camelizedPropNames = getCamlizedPropName_v1_2_0(propName);

    const responsiveClassNames = getResponsiveClassNames_v1_2_0(className);
    const dynamicClassNames = getDynamicClassNames_v1_2_0(props, camelizedPropNames, responsiveClassNames);

    const final = prepareClassNames_v1_2_0(dynamicClassNames);
    return final;
}

export function getCssRuleName_v1_2_0(cssColorValue: string) {
    if (cssColorValue.startsWith('#')) {
        return `hex-${cssColorValue.slice(1)}`;
    }
    else {
        return cssColorValue;
    }
}
export function getCssRuleValue_v1_2_0(cssColorValue: string) {
    return cssColorValue.startsWith('--') ? `var(${cssColorValue})` : cssColorValue;
}

export function convertPropsToDynamicCssCode_v1_2_0(props: Record<string, string>, propName: string, cssProp: string = propName, className: string = cssProp, selector: string = undefined) {
    const camelizedPropNames = getCamlizedPropName_v1_2_0(propName);

    const colorStyles = responvieSizes_v1_2_0.map((size) => {
        const camelizedPropName = camelizedPropNames[size];
        const propValue = props[camelizedPropName] as string;
        if (propValue) {

            const potentialSelectorStr = selector ? `${selector} ` : '';

            const cssColorValue = getCssRuleName_v1_2_0(propValue);
            const cssRuleValue = getCssRuleValue_v1_2_0(propValue);
            let style;
            if (size) {
                const innerStyle = `.wiml-${size}-${className}-dynamic__${cssColorValue} ${potentialSelectorStr}{
      ${cssProp}: ${cssRuleValue} !important;
    }`;
                style = `@media ${breakpoints_v1_1_0[size]} {
    ${innerStyle}
}`;
            } else {
                style = `.wiml-${className}-dynamic__${cssColorValue} ${potentialSelectorStr}{
    ${cssProp}: ${cssRuleValue} !important;
}`;
            }
            return [
                style
            ];
        }
    });


    const final = prepareClassNames_v1_2_0(colorStyles, '\n\n');
    return final;
}

export function getStyleCollectionString_v1_2_0(template: ResponsiveClassTemplate_v1_2_0 | ResponsiveClassTemplate_v1_2_0[]) {
    // const responsiveStyleSet: ResponsiveClassSet_v1_2_0 = {
    //     '': {},
    //     'lg': {},
    //     'md': {},
    //     'sm': {},
    //     'xs': {}
    // };

    // for (const [key, value] of Object.entries(template)) {
    //     const isArray = Array.isArray(value);
    //     const arrayValue = isArray ? value : [value];

    //     responsiveStyleSet[''][key] = arrayValue.join(' ');
    //     responsiveStyleSet['lg'][key] = getClassNameWithPrefix(arrayValue, 'lg');
    //     responsiveStyleSet['md'][key] = getClassNameWithPrefix(arrayValue, 'md');
    //     responsiveStyleSet['sm'][key] = getClassNameWithPrefix(arrayValue, 'sm');
    //     responsiveStyleSet['xs'][key] = getClassNameWithPrefix(arrayValue, 'xs');
    // }

    // return responsiveStyleSet;

    const templateArr = Array.isArray(template) ? template : [template];

    // iterate over each template
    // for each template, generate a css rule for each key
    // note that each value may be a string or concat of strings by \n
    // then for each breakpoint type e.g. lg, md, sm, xs, indent the strings wtihin the rule and append that size to the first spot in a hyphen-separated class name string array


    return responvieSizes_v1_2_0.map((size) => {
        // return ['md'].map((size) => {
        const css = getCssBySize(templateArr, size);
        const cssWithMediaQuery = size ? `@media ${breakpoints_v1_1_0[size]} {\n${css}\n}` : css;
        return cssWithMediaQuery;
    }).join('\n\n');
}

function getCssBySize(templateArr: ResponsiveClassTemplate_v1_2_0[], size: string) {
    const classNameIndent = size ? '    ' : '';
    const ruleIndent = size ? '        ' : '    ';
    const css = templateArr.map((template) => {
        const css = Object.entries(template).map(([key, value]) => {
            const isArray = Array.isArray(value);
            const arrayValue = isArray ? value : [value];

            const cssRuleWithIndent = arrayValue.map((value) => {
                const cssRule = `${ruleIndent}${value};`;
                return cssRule;
            }).join('\n');

            // const [first, ...rest] = key.split('-');
            // const sizeClassName = [first, size, ...rest].filter(Boolean).join('-');
            const classNamesWithSize = key.replace(/{size}-/g, size ? `${size}-` : '');
            const cssRuleWithSelector = `${classNameIndent}.${classNamesWithSize} {\n${cssRuleWithIndent}\n${classNameIndent}}`;

            return cssRuleWithSelector;
        }).join('\n');
        return css;
    }).join('\n\n');
    return css;
};
