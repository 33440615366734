import { getFqComponentId_v1_0_0, getNodeAttr_v1_0_0 } from './nodes';
import {
    ThemeDataPageChildComponentListInstanceComponent_v1_2_0,
    WimlNode_v1_0_0,
    WimlPageNodeCollection_v1_0_0,
} from './types';
import {
    ThemeDataPageChildComponent_v1,
    ThemeDataPageChildComponentListInstanceChildComponent_v1
} from "../../../types";
import { getInferredComponentDefaultType_v1_0_0 } from './components';
import { processListQualifiedNodeChildren_v1_0_0 } from './nodes';
import { _isTextNode_v1_0_0, _isListNode_v1_0_0, _isPageNode_v1_0_0 } from './nodes';
import { createComponent_v1_0_0 } from './components';
import { getListIdFromNodeAttrs_v1_0_0 } from './nodes';
import humps from 'humps';
import { getWimlPageNodeCollectionFromNode_v1_0_0 } from './nodes';
import { objectUtils } from '../../../../../../..';
import { WimlPageElementWithUrl_v1_0_0 } from './types';
import { ThemeDataPageComponentCollection_v1, ThemeDataPageListComponent_v1, ThemeDataPageWithComponents_v1 } from "../../../types";
import { RuntimeWimlComponentCollection_v1_0_0 } from '../renderer/types';

// DFS iterative https://medium.com/@jpoechill/iterative-bfs-and-dfs-in-javascript-
// https://blog.bitsrc.io/depth-first-search-of-a-binary-tree-in-javascript-874701d8210a


export function _getThemeDataPageQualifiedComponentsFromNode_v1_0_0(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, node: WimlNode_v1_0_0, parentListId: string = '', parentListComponentId: string = ''): ThemeDataPageChildComponent_v1[] {

    // todo v1.2 it's probably easier to construct id's with a bfs, or provide a `parent` node to get the stuff.
    // but even that latter approach would require a recursive approach to get the parents full path, whereas bfs would provide it by default.
    if (!_isTextNode_v1_0_0(node)) {
        // console.group();
        BeginLog(node, parentListId, parentListComponentId);
        const abc = processListQualifiedNodeChildren_v1_0_0(runtimeComponents, node, parentListId, parentListComponentId, _getThemeDataPageQualifiedComponentsFromNode_v1_0_0);

        const isList = _isListNode_v1_0_0(node);
        const lookup = runtimeComponents[node.tag];

        if ((isList || lookup?.inputProps) && !_isPageNode_v1_0_0(node)) {
            const component = _getThemeDataPageQualifiedComponentFromNode_v1_0_0(node, parentListId, parentListComponentId);

            if (!parentListComponentId || isList) {
                EndLog(node, parentListId, parentListComponentId);
                // console.groupEnd();




                return [...abc, component];

            } else {
                const listItemComponent: ThemeDataPageChildComponentListInstanceChildComponent_v1 = { ...component, isListInstanceComponent: true, listId: parentListId, parentListInstanceComponentId: parentListComponentId };
                // todo should this be renamed to listItemComponentId? ListComponent are those on a page, but items within a list are listItemComponents?
                // no, listComponentId refers to the list id itself, not the list item component id

                EndLog(node, parentListId, parentListComponentId);
                // console.groupEnd();



                return [...abc, listItemComponent];
            }
        } else {
            return abc;
        }
    } else {
        return []
    }
}
function _getThemeDataPageQualifiedComponentFromNode_v1_0_0(node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string) {
    const componentId = node.attr?.key || getInferredComponentDefaultType_v1_0_0(node.tag);

    const fullyQualifiedComponentId = getFqComponentId_v1_0_0(parentListComponentId, componentId);

    // we use `key` not `id` for wiml
    const { key, ...themeProps } = getNodeAttr_v1_0_0(node);
    BeginLog(node, parentListId, parentListComponentId);

    const listId = _isListNode_v1_0_0(node) ? getListIdFromNodeAttrs_v1_0_0(node) : parentListId;
    const component = createComponent_v1_0_0(fullyQualifiedComponentId, node, themeProps, listId);

    if (!component.key) throw new Error('component ' + node.tag + ' must have a key attribute');
    return component;
}
function BeginLog(node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string) {
    return;
    // const beginString = `Beginning processing of NODE:${node.tag} KEY:${node.attr?.key}     LISTID:${options.listId} parentListId:${parentListId} LISTCOMPONENTID:${options.listComponentId} parentListComponentId: ${parentListComponentId}`;
    // if (options.listId != parentListId || options.listComponentId != parentListComponentId) {
    //     console.warn(beginString);
    // } else {
    //     console.log(beginString);
    // }
}


function EndLog(node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string) {
    return;
    // const endString = `End processing of NODE:${node.tag} KEY:${node.attr?.key}   LISTID:${options.listId} parentListId:${parentListId} LISTCOMPONENTID:${options.listComponentId} parentListComponentId: ${parentListComponentId}`;
    // if (options.listId != parentListId || options.listComponentId != parentListComponentId) {
    //     console.warn(endString);
    // } else {
    //     console.log(endString);
    // }
}

export function getThemeDataPageCollectionFromNode_v1_0_0(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, jsonNode: WimlNode_v1_0_0) {
    const nodeCollection = getWimlPageNodeCollectionFromNode_v1_0_0(jsonNode);

    const themeDataPageCollection = getThemeDataPageCollectionFromNodeCollection_v1_0_0(runtimeComponents, nodeCollection);

    // todo remove this v1.2.0?
    // List-book-series -> listBookSeries
    const camelizedPageCollection = humps.camelizeKeys(themeDataPageCollection) as ThemeDataPageComponentCollection_v1;

    const retVal = camelizedPageCollection;

    return retVal;
}

function getThemeDataPageCollectionFromNodeCollection_v1_0_0(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, nodesByPage: WimlPageNodeCollection_v1_0_0) {
    const pageItems = Object.keys(nodesByPage).reduce((acc, key) => {
        const page = nodesByPage[key];
        const pageComponentsArray = _getThemeDataPageQualifiedComponentsFromNode_v1_0_0(runtimeComponents, page);

        const listItemComponents = pageComponentsArray
            .filter((c) => c.isListInstanceComponent)
            .map((c) => {
                // remove the fqlsid from id
                // handle Nested List
                const retVal = { ...c, id: c.id.split('.').slice(-1)[0] };
                return retVal;
            });
        const nonListItemComponents = pageComponentsArray
            .filter((c) => !c.isListInstanceComponent)
            .map((c) => {
                const isList = c.type === "List";
                if (isList) {
                    const currentListItemComponentsArray = listItemComponents.filter((lc) => lc.parentListInstanceComponentId === c.id);
                    const currentListItemComponentsObject = objectUtils.convertArrayToObject(currentListItemComponentsArray);
                    const retVal: ThemeDataPageListComponent_v1 = { ...c, components: { ids: Object.keys(currentListItemComponentsObject) } };
                    return retVal;
                } else {
                    const retVal = c;
                    return retVal;
                }
            });

        const pageComponentsObject = objectUtils.convertArrayToObject(nonListItemComponents, "id");

        // use lodash to titleize the name
        const name = key;//_.startCase(key);
        const components = {
            ids: Object.keys(pageComponentsObject),
            items: pageComponentsObject,
        };
        const url = _getPageUrl_v1_0_0(page);
        const retVal = { ...acc, [key]: { name, components, url } };
        return retVal;
    }, {} as Record<string, ThemeDataPageWithComponents_v1>);

    const retVal = {
        ids: Object.keys(pageItems),
        items: pageItems,
    } as ThemeDataPageComponentCollection_v1;

    return retVal;
}

export function _getPageUrl_v1_0_0(page: WimlNode_v1_0_0) {
    // get url from page key. if the url is specific as an attribute, use that instead
    const pageUrl = page.attr?.url || page.attr?.key;

    const retVal = pageUrl == 'home' ? '/' : `/${pageUrl}`;

    return retVal;
}
