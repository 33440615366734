import { textUtils } from "..";
import { DataClass } from "../lib/object-utils";
import Product from "./product";


export default class Book extends DataClass {
    constructor(bookId, data) {
        super(data);

        this.id = bookId;

        this.about = this.about || {};
        this.schedule = this.schedule || {};
        this.awards = this.awards || {};
        this.assets = this.assets || {};
        this.meta = this.meta || {};
        this.purchase = this.purchase || {};
        this.reviews = this.reviews || {};
        this.products = this.products || [];
        this.series = this.series || {};

        const publishDate = this.schedule.publishDate;
        if (publishDate) {
            this.schedule.publishDate = new Date(publishDate);
        }
    }


    get awardCollection() {
        const retVal = (this.awards.content || "")
            // .split(/,\s?/)
            .split("\n")
            .filter(Boolean)
            .map(t => t.trim());
        // .map(t => ({ key: textUtils.convertToSlug(t), value: t }));

        return retVal;
    }

    get categoryCollection() {
        const retVal = (this.about.categories || "")
            // .split(/,\s?/)
            .split("\n")
            .filter(Boolean)
            .map(t => t.trim())
            .map(t => ({ key: textUtils.convertToSlug(t), value: t }));

        return retVal;
    }
}
