import { DataClass } from "../lib/object-utils";
import Product from "./product";

export default class LandingPage extends DataClass {
    constructor(customPageId, data) {
        super(data);

        this.id = customPageId;

        this.about = this.about || { title: null };
        this.assets = this.assets || {};
        this.meta = this.meta || {};
        this.schedule = this.schedule || {};
        this.navigation = this.navigation || {};
        this.form = this.form || {};

        this.products = this.products || [];
        this.galleries = this.galleries || [];
    }
}
