// import fb doens't work easily with mjs
// https://stackoverflow.com/questions/42090156/firebase-initializeapp-is-not-a-function
// https://github.com/nodejs/node/issues/33410
import { firebase } from '@firebase/app';
// These imports load individual services into the firebase namespace. e.g. import 'firebase/auth';
import '@firebase/database';
import '@firebase/storage';
import '@firebase/auth';
import { makeSerialilzable, prepareDataTransportV3, prepareDataTransportV4, replaceArraysWithObjects, replaceKeys } from './object-utils';
import { customAlphabet } from 'nanoid';
import humps from 'humps';
import { encode, decode } from 'firebase-encode';

const alphabet = '23456789abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ';
const length = 10;
const nanoid = customAlphabet(alphabet, length);

// https://stackoverflow.com/questions/62250482/firebase-admin-cant-read-property-cert-of-undefined
// const admin = require("firebase-admin");
import admin from "firebase-admin";

//const cache = {};

//export default function getFirebaseClient(apiKey, options) {
const firebaseKey = process.env.FIREBASE_API_KEY || process.env.REACT_APP_FIREBASE_API_KEY || process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
const firebaseUrl = process.env.FIREBASE_DATABASE_URL || process.env.REACT_APP_FIREBASE_DATABASE_URL || process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL;
const firebaseStorageBucket = process.env.FIREBASE_STORAGE_BUCKET || process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET;
const firebaseAuthDomain = process.env.FIREBASE_AUTH_DOMAIN || process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || process.env.NEXT_AUTH_FIREBASE_AUTH_DOMAIN;

const options = {
    apiKey: firebaseKey,
    authDomain: firebaseAuthDomain,
    databaseURL: firebaseUrl,
    storageBucket: firebaseStorageBucket
};

// FIX FOR Firebase Namespace Issue
// https://github.com/vercel/next.js/issues/1999
// https://stackoverflow.com/questions/43331011/firebase-app-named-default-already-exists-app-duplicate-app
const db = firebase.apps.length ? firebase.app() : firebase.initializeApp(options);

export default db;
export { firebase };
// if (!apiKey) throw new Error(`Api Key missing.`);
// const cacheKey = apiKey;
// let cached = cache[cacheKey];

// if (!cached) {
// FIX FOR Firebase Namespace Issue
// https://github.com/vercel/next.js/issues/1999
// https://stackoverflow.com/questions/43331011/firebase-app-named-default-already-exists-app-duplicate-app
// 
//     const db = firebase.apps.length ? firebase.app() : firebase.initializeApp({
//         firebaseKey,
//         ...options
//     });

// cached = db;
// cache[cacheKey] = cached;
// }

// return db;
// }

// firebase api will fail if you send anything undefined. this strips that out.
export function prepareFirebaseObjectV4(data: any) {
    // keep in mind, humps will lowercase all keys
    const decamlizedData = prepareDataTransportV4(data);

    // encode expects all keys. E.g. "." becomes "%2E" -- listBookSeries.listBooks
    const encodedKeys = replaceKeys(decamlizedData, _prepareFirebaseKeyV4);

    const retVal = encodedKeys;
    return retVal;
}

// firebase api will fail if you send anything undefined. this strips that out.
export function prepareFirebaseObjectV3(data: any) {
    // keep in mind, humps will lowercase all keys
    const decamlizedData = prepareDataTransportV3(data);
    const retVal = decamlizedData;
    return retVal;
}

export function _prepareFirebaseKeyV3(key: string) {
    // todo - try custom alphabet for nanoid https://github.com/ai/nanoid#custom-alphabet-or-size
    // we call humps.camelizeKeys on firebase data website-service.js
    // this replaces all '-' and '_'
    // var a  = humps.camelize('-abc-123');
    // var a1  = humps.camelize('-abc_123');
    // var a2  = humps.camelize('_abc_123');
    // var a3  = humps.camelize('-_7ddyxdQXD6zByqbS6yK');
    // NOTE #2: I found out that camelize will lowercase first char so CHgV8UPa8IDx1egCELptI becomes cHgV8UPa8IDx1egCELptI.

    const randomInt = Math.round(Math.random() * 10);

    let alphaNumOnly = key.replace(/_/g, '-').replace(/-/g, randomInt.toString());

    const retVal = alphaNumOnly[0].toLowerCase() + alphaNumOnly.slice(1);

    return retVal;
}
export function _prepareFirebaseKeyV4(key: string) {
    // there is no getFirebaseKeyV4() because we use generateId from object-utils.
    const retVal = encode(key);

    return retVal;
}

export function _decodeFirebaseKey(key: string) {
    // there is no getFirebaseKeyV4() because we use generateId from object-utils.
    const retVal = decode(key);

    return retVal;
}

export function getFirebaseKeyV3() {
    // todo - try custom alphabet for nanoid https://github.com/ai/nanoid#custom-alphabet-or-size
    const retVal = _prepareFirebaseKeyV3(nanoid());

    return retVal;
}

export function getAdminClient() {
    // https://stackoverflow.com/questions/50299329/node-js-firebase-service-account-private-key-wont-parse
    // @ts-ignore
    const firebaseServiceAccountPrivateKey = process.env.FIREBASE_SERVICE_ACCOUNT_PRIVATE_KEY.replace(/\\n/g, '\n');
    // @ts-ignore
    const firebaseServiceAccountProjectId = process.env.FIREBASE_SERVICE_ACCOUNT_PROJECT_ID;
    // @ts-ignore
    const firebaseServiceAccountClientEmail = process.env.FIREBASE_SERVICE_ACCOUNT_CLIENT_EMAIL;

    const serviceKey = {
        projectId: firebaseServiceAccountProjectId,
        clientEmail: firebaseServiceAccountClientEmail,
        privateKey: firebaseServiceAccountPrivateKey,
    };

    // cert() requires an obj. if string, it assumes filepath.
    const options = {
        credential: admin.credential.cert(serviceKey),
        databaseURL: firebaseUrl
    };

    const db = admin.apps.length ? admin.app() : admin.initializeApp(options);

    return db;
}
