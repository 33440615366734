import React, { ReactElement } from 'react';
import { html2json } from '../../../../../../../lib/html2json_2/html2json';
import { pipe, flow } from 'fp-ts/lib/function';
import { fromPredicate, left, right, isLeft, chain } from 'fp-ts/lib/Either';

const _validateWimlRootNode = (versionsArr: string[], errMessage: string) => ({ value: wimlNode }: { value: WimlNode_v1_0_0 }): WimlNodeValidationResult_v1_0_0 => {
    const validationResult = pipe(
        { value: wimlNode },
        validate_v1_0_0(wimlNode.node == 'element', 'wiml must be element'),
        chain(validate_v1_0_0(wimlNode.tag === 'WIML', 'wiml must be WIML')),
        chain(validate_v1_0_0(versionsArr.includes(wimlNode.attr.version), errMessage))
    );

    return validationResult;
}

export function _validateTopLevelNodes({ value: wimlNode }: { value: WimlNode_v1_0_0 }): WimlNodeValidationResult_v1_0_0 {
    const validationResult = pipe(
        { value: wimlNode },
        validate_v1_0_0(wimlNode.child.every(_isValidTopLevelNode), 'top-level elements must be a Layout or Page')
    );

    return validationResult;
}

export function _validateLayoutNode({ value: wimlNode }: { value: WimlNode_v1_0_0 }): WimlNodeValidationResult_v1_0_0 {
    const validationResult = pipe(
        { value: wimlNode },
        validate_v1_0_0(
            (
                !!wimlNode.child.find((childNode) => childNode.tag == 'Layout') == false ||
                wimlNode.child.find((childNode) => childNode.tag == 'Layout').child?.filter((childNode) => childNode.tag == 'CurrentPage')?.length == 1
            ),
            'Layout node must contain a CurrentPage node.')
    );

    return validationResult;

}

export function _validateSelfCloseTags({ value: wimlNode }: { value: WimlNode_v1_0_0 }): WimlNodeValidationResult_v1_0_0 {
    const validationResult = pipe(
        wimlNode,
        recursiveValidateSelfCloseTags
    );

    return validationResult;
}

const isSelfClosingTag = (tag: string) => selfClosingTags.includes(tag);
const hasChildren = (node: WimlNode_v1_0_0) => node.child.length > 0;

const recursiveValidateSelfCloseTags = (node: WimlNode_v1_0_0): WimlNodeValidationResult_v1_0_0 => {
    const hasChildren = node.child?.length > 0;
    const isSelfClosingTag = selfClosingTags.includes(node.tag);

    // if self closing tag has children
    if (isSelfClosingTag && hasChildren) {
        return left({ error: `self closing tag ${node.tag} cannot have children.` });
    } else if (hasChildren) {
        // const firstInvalidChild = node.child.find((child) => isLeft(recursiveValidateSelfCloseTags(child)));
        // if (firstInvalidChild) {
        //     return left({ error: `self closing tag ${node.tag} cannot have children` });
        // }

        const firstInvalidChild = node.child.map((child) => recursiveValidateSelfCloseTags(child)).find(isLeft);
        if (firstInvalidChild) {
            return firstInvalidChild;
        }
    }
    return right({ value: node });
};


export function handleResult(result: WimlNodeValidationResult_v1_0_0) {
    if (isLeft(result)) {
        throw new Error(result.left.error);
    } else {
        return result.right.value;
    }
}

export const validateWiml = (versionsArr: string[], errMessage: string) => (wimlNode: WimlNode_v1_0_0) => {
    return pipe(
        right({ value: wimlNode }),
        chain(_validateWimlRootNode(versionsArr, errMessage)),
        chain(_validateTopLevelNodes),
        chain(_validateLayoutNode),
        chain(_validateSelfCloseTags),
    );
}

const reTest = (regex: RegExp) => (str: string) => regex.test(str);
function _isValidTopLevelNode(node: WimlNode_v1_0_0) {
    const retVal = pipe(
        node.tag,
        // this causes Type instantiation is excessively deep and possibly infinite”
        // @ts-ignore
        // i tried using spectacles-ts.get but it caused infitie typescript loading… - see github https://github.com/anthonyjoeseph/spectacles-ts/issues/19
        // i think it'll be too hard to retain types with point free in ts at this time.
        reTest(/(Layout|Page)/),
    );

    return retVal;
}
function _convertHtmlStringToJson(wimlString: string) {
    // todo make point-free
    const trimmed = wimlString.trim();
    // remove ending characters after </WIML> close tag
    // todo remove this when vscode formatter is fixed prettifier xml?
    // const endingCleaned = trimmed.replace(/<\/WIML>.*/m, '</WIML>');
    // todo use d.ts or ts for html2json
    const document = html2json(trimmed) as unknown as WimlNode_v1_0_0

    _validateWimlDocument(document);

    const wimlNode = document.child[0];

    return wimlNode;
}


export function _validateWimlDocument(document: WimlNode_v1_0_0) {
    if (document.node != 'root') throw new Error('invalid config');
    if (document.child.length != 1) throw new Error('wiml must be top level element');
}

// DFS iterative https://medium.com/@jpoechill/iterative-bfs-and-dfs-in-javascript-
// https://blog.bitsrc.io/depth-first-search-of-a-binary-tree-in-javascript-874701d8210a
export function _cleanseJsonNodes(node: WimlNode_v1_0_0) {
    // let retVal = node;

    const retVal = doThing(node)

    if (retVal) {
        return doThing2(retVal);
    } else {
        return retVal;
    }
}

export function doThing2(node: WimlNode_v1_0_0): WimlNode_v1_0_0 {
    const attr = node.attr;
    if (attr) {
        const newAttr = Object.fromEntries(
            Object.entries(attr).map(([key, value]) => {
                if (Array.isArray(value)) {
                    // e.g. text-center, my-2
                    return [key, value.join(' ')];
                    // } else if (value.startsWith('{') && value.endsWith('}')) {
                    //     const jsonEntry = doThing3(value);
                    //     return [key, JSON.parse(jsonEntry)];
                } else {
                    return [key, value];
                }
            })
        );
        return { ...node, attr: newAttr };
    } else {
        return node;
    }
}

export function doThing3(value: string) {
    const jsonEntry = value.substring(1, value.length - 1);
    if (jsonEntry.endsWith('/')) {
        const newJsonEntry = jsonEntry.substring(0, jsonEntry.length - 1);
        return newJsonEntry;
    } else {
        return jsonEntry;
    }
}

export function doThing(node: WimlNode_v1_0_0): WimlNode_v1_0_0 {
    const children = node.child;

    if (children) {
        return {
            ...node,
            child: children.map(_cleanseJsonNodes).filter(Boolean),
        };
    } else {
        if (node.node === 'text') {
            const trimmedStringElement = node.text.trim();
            if (trimmedStringElement.length === 0) {
                return null;
            } else {
                return {
                    ...node,
                    text: trimmedStringElement,
                };
            }
        } else {
            return node;
        }
    }
}


export function convertWimlToNodes_v1_0_0(wimlString: string): WimlRootNode_v1 {
    const wimlJsonRootNode = wimlJsonRootNodePipeline(wimlString);
    const value = handleResult(wimlJsonRootNode);

    return {
        ...value,
        version: 'v1.0.0',
    };
}
export const validate_v1_0_0 = (predicate: boolean, error: string) =>
    fromPredicate(
        () => predicate,
        () => ({ error })
    );




const wimlJsonRootNodePipeline = flow(
    _convertHtmlStringToJson,
    _cleanseJsonNodes,
    validateWiml(['1', '1.0', '1.0.0'], 'wiml must be version 1.0.0'),
);

export function isValidNonFragmentElement_v1_0_0(element: any): element is ReactElement {
    return React.isValidElement(element) && element.type !== React.Fragment;
}
// todo move to html2json utils but it was not working with module resolution, i think.


// export function _isPageNode(node: Html2JsonNode): node is Html2JsonPageNode {
//     const retVal = node.node == 'element' && node.tag == 'Page';

//     return retVal;
// }

export function _isPageDefinitionNode_v1_0_0(node: WimlNode_v1_0_0) {
    return _isPageNode_v1_0_0(node) || node.tag == 'Header' || node.tag == 'Footer';
}
export function _isTextNode_v1_0_0(node: WimlNode_v1_0_0): node is WimlTextNode_v1_0_0 {
    return node.node == 'text';
}

// export function _isListNode(node: Html2JsonNode): node is Html2JsonListNode {
//     return node.node == 'element' && node.tag == 'List';
// }


export function getListIdFromNodeAttrs_v1_0_0(node: WimlListElement_v1_0_0) {
    const retVal = node.attr?.listId || node.attr?.key || getNodeKey_v1_0_0(node);
    return retVal;
}

function getComponentIdForListNode_v1_0_0(node: WimlListElement_v1_0_0) {
    const key = getNodeKey_v1_0_0(node);
    // uppercase first char of key and return key
    const upperKey = key.charAt(0).toUpperCase() + key.slice(1);

    // convert name to camel case e.g. Listblog -> listBlog
    const retVal = humps.camelize(`${node.tag}${upperKey}`);
    return retVal;
}



export function processListQualifiedNodeChildren_v1_0_0<T>(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string, recurFunction: (runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string) => T[]) {
    const retVal: T[] = [];
    const isList = _isListNode_v1_0_0(node);

    const children = node.child;
    if (children) {
        // set option that this is a list
        if (isList) {
            const currentListId = getListIdFromNodeAttrs_v1_0_0(node);
            if (!currentListId) throw new Error('list must have a id');

            const currentListComponentId = getComponentIdForListNode_v1_0_0(node);
            if (parentListComponentId) {
                const newListComponentId = `${parentListComponentId}.${currentListComponentId}`;
                const newRetVal = [...retVal, ...children.map((c) => recurFunction(runtimeComponents, c, currentListId, newListComponentId)).flat()];

                return newRetVal
            } else {
                const newRetVal = [...retVal, ...children.map((c) => recurFunction(runtimeComponents, c, currentListId, currentListComponentId)).flat()];

                return newRetVal
            }
        } else {
            const newRetVal = [...retVal, ...children.map((c) => recurFunction(runtimeComponents, c, parentListId, parentListComponentId)).flat()];

            return newRetVal
        }
    } else {
        return [];
    }
}



import _ from 'lodash';


export function _getPageNodeCollectionFromNode_v1_0_0(jsonTree: WimlNode_v1_0_0) {
    const pageNodes = jsonTree.child.filter((c) => c.tag === 'Page');

    const pageNames = pageNodes.map((p) => getNodeKey_v1_0_0(p));
    const groupedPageNames = _.groupBy(pageNames);
    const duplicatePageNames = Object.keys(groupedPageNames).filter((key) => groupedPageNames[key].length > 1);
    if (duplicatePageNames.length)
        throw new Error(`duplicate page names: ${duplicatePageNames.join(', ')}`);

    const pageNodesByKey = pageNodes.reduce((acc, page) => {
        acc[getNodeKey_v1_0_0(page)] = page;
        return acc;
    }, {} as WimlPageNodeCollection_v1_0_0);

    return pageNodesByKey;
}









import humps from 'humps';

import { _getPageDefinitionNodeCollectionFromNode_v1_0_0 } from './components';
import { WimlNode_v1_0_0, WimlElement_v1_0_0, WimlListElement_v1_0_0, WimlPageElement_v1_0_0, WimlPageNodeCollection_v1_0_0, WimlNodeValidationResult_v1_0_0, WimlTextNode_v1_0_0 } from './types';
import { WimlRootNode_v1 } from "../../../types";
import { RuntimeWimlComponentCollection_v1_0_0 } from '../renderer/types';
import { either } from 'fp-ts';

export const WIML_CONSTANTS = {
    JSON_CHILD_KEY: 'child',
    JSON_ATTR_KEY: 'attr',
    DEFAULT_PAGE_KEY: 'home',
    DEFAULT_LIST_KEY: 'default'
};

export function getWimlPageNodeCollectionFromNode_v1_0_0(jsonNode: WimlNode_v1_0_0) {
    const combined = _getWimlPageNodeCollectionFromNode_v1_0_0(jsonNode);

    // necessary for keys like attr['list-id']
    const allPages = humps.camelizeKeys(combined) as WimlPageNodeCollection_v1_0_0;

    return allPages;
}

function _getWimlPageNodeCollectionFromNode_v1_0_0(jsonNode: WimlNode_v1_0_0) {
    const layoutSpecificJsonNodes = _getPageDefinitionNodeCollectionFromNode_v1_0_0(jsonNode);
    const pageJsonNodes = _getPageNodeCollectionFromNode_v1_0_0(jsonNode);
    const combined: WimlPageNodeCollection_v1_0_0 = { ...pageJsonNodes, ...layoutSpecificJsonNodes };
    return combined;
}

export const getNodeAttr_v1_0_0 = (node: any) => node[WIML_CONSTANTS.JSON_ATTR_KEY] || {};

export function getNodeKey_v1_0_0(node: WimlNode_v1_0_0) {
    let retVal: string = null;
    if (_isPageNode_v1_0_0(node)) {
        retVal = node.attr?.key || WIML_CONSTANTS.DEFAULT_PAGE_KEY;
    } else if (_isListNode_v1_0_0(node)) {
        retVal = node.attr?.key || WIML_CONSTANTS.DEFAULT_LIST_KEY;
    } else {
        retVal = humps.camelize(node.tag);
    }

    return retVal;
}

export function _isElementNode_v1_0_0(node: WimlNode_v1_0_0): node is WimlElement_v1_0_0 {
    return node.node == 'element' && !!node.tag;
}

export function _isPageNode_v1_0_0(node: WimlNode_v1_0_0): node is WimlPageElement_v1_0_0 {
    return _isElementNode_v1_0_0(node) && node.tag == 'Page';
}

// add a user defined guard
export function _isListNode_v1_0_0(node: WimlNode_v1_0_0): node is WimlListElement_v1_0_0 {
    return _isElementNode_v1_0_0(node) && node.tag == 'List';
}


export function getFqComponentId_v1_0_0(listComponentId: string, componentId: string) {
    if (listComponentId) {
        return `${listComponentId}.${componentId}`;
    } else {
        return componentId;
    }
}

const selfClosingTags = ['area', 'base', 'br', 'col', 'command', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr'];
