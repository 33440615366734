import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBackward, faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createBook } from '@wip/common/event-store/website';
import ProductList from './product-list';
import ProductDetail from './product-detail';
import Product from "@wip/common/domain/product";
import ShopPageOptions from './shop-page-options';
import { useAuth } from "@wip/common/lib/auth";

const Products = (props) => {
    const {
        user,
    } = useAuth();

    const [productDetail, setProductDetail] = useState(null);

    let pageViewElelement;

    function doThing() {
        setProductDetail(null);
    }

    if (props.websiteData.ecommerce.accountActive) {
        if (props.websiteData.contact.email) {
            if (productDetail) {
                const productData = props.websiteData.products.find(s => s.id == productDetail);
                const product = new Product(productData.id, productData);

                pageViewElelement = (
                    <>
                        <Button color="link" onClick={doThing}><FontAwesomeIcon icon={faArrowLeft} /> Back</Button>
                        <ProductDetail product={product} {...props} />
                    </>
                );
            } else {
                pageViewElelement = (
                    <>
                        <ShopPageOptions {...props} />
                        <ProductList {...props} onSelectProduct={setProductDetail} />
                    </>
                );
            }
        } else {
            pageViewElelement = (
                <p>Please setup your email in the <u>Contact</u> tab.</p>
            );
        }
    } else {
        const url = process.env.REACT_APP_API_URL;
        const email = user.email;
        const returnUrl = window.location;

        const ecommerceApiUrl = `${url}/ecommerce/onboard?slug=${props.websiteData.meta.slug}&email=${email}&return_url=${returnUrl}`;

        let instructions;
        let buttonMessage;

        if (props.websiteData.ecommerce.onboardInProgress) {
            instructions = (
                <>
                    <p>Stripe is still setting up your account.</p>
                    <p>Click the button below to refresh the status of your account.</p>
                </>
            );
            buttonMessage = "Refresh Status ↺";
        }
        else {
            instructions = (
                <>
                    <p>To add products, we first need to setup your payment info. This way you can get paid when someone purchases a product.</p>
                    <p>For the security of your account, Wild Ink Pages partners with the payment processor Stripe.</p>
                    <p>This will take 5-10 minutes. Please make sure you have your bank account and routing information ready. Your progress will be saved so you can do part now and finish it later.</p>
                </>
            );

            buttonMessage = "Setup My Payments!";
        }
        pageViewElelement = (
            <div>
                {instructions}
                <a className="btn-primary btn" href={ecommerceApiUrl}>{buttonMessage}</a>
            </div>
        );
    }

    return pageViewElelement;
}

export default Products;
