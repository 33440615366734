import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBackward, faBook, faCalendar, faHome, faPaintBrush, faPencilAlt, faPhone, faPlus, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { createBook } from '@wip/common/event-store/website';
import EventList from './event-list';
import EventDetail from './event-detail';
import Event from "@wip/common/domain/event";

const Events = (props) => {

    const [eventDetail, setEventDetail] = useState(null);

    let eventViewElement;

    function doThing() {
        setEventDetail(null);
    }
    
    if (eventDetail) {
        const eventData = props.websiteData.events.find(e => e.id == eventDetail);
        
        const event = new Event(eventData.id, {...eventData});

        eventViewElement = (
            <>
                <Button color="link" onClick={doThing}><FontAwesomeIcon icon={faArrowLeft} /> Back</Button>
                <EventDetail event={event} {...props} />
            </>
        );
    } else {
        eventViewElement = (<EventList {...props} onSelectEvent={setEventDetail} />);
    }

    return eventViewElement;
}

export default Events;
  