//https://getbootstrap.com/docs/4.6/layout/overview/#responsive-breakpoints
const maxSize = {
    xs: '575.98px', // Small devices (landscape phones, 576px and up)
    sm: '767.98px', // Medium devices (tablets, 768px and up)
    md: '991.98px', // Large devices (desktops, 992px and up)
    lg: '1199.98px', // Extra large devices (large desktops, 1200px and up)
};

export const breakpoints_v1_1_0 = {
    xs: `(max-width: ${maxSize.xs})`,
    sm: `(max-width: ${maxSize.sm})`,
    md: `(max-width: ${maxSize.md})`,
    lg: `(max-width: ${maxSize.lg})`,
};

const minSize = {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px'
};

export const breakpointsMin_v1_1_0 = {
    sm: `(min-width: ${minSize.sm})`,
    md: `(min-width: ${minSize.md})`,
    lg: `(min-width: ${minSize.lg})`,
    xl: `(min-width: ${minSize.xl})`,
};
