import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useState, useEffect } from 'react';

import 'nprogress/nprogress.css'
import NProgress from 'nprogress';

import MobileNotice from '../mobile-notice';
import Layout, { breakPoints, MiniLayout } from '../layout';
import { Card, CardBody, CardHeader, Collapse, Form, FormGroup, Input, Label } from "reactstrap";
import { ImageAnalyzer, ThemeModificationImageInspiration_v1_3_0 } from "../manage/v4/wiml/versions/v1/v1.3.0/image-style-inspiration-v1.3.0";
import { makeSerialilzable } from '@wip/common/lib/object-utils';
import Website from '@wip/common/domain/website';
import { fetchThemeData_v2 } from '@wip/common/app/themes';
import { __staticThemes } from './__staticThemes';

const previewUrl = process.env.REACT_APP_PREVIEW_URL;
// const previewUrl = process.env.REACT_APP_PREVIEW_URL;
export default function WebsiteGenerator(props: React.PropsWithChildren<any>) {
  const { data: userData, isLoading: userIsLoading } = useSelector(
    (state: any) => (state).user
  );

  type State = {
    isLoading: boolean;
    results: any[];
    hasErrors: boolean;
    // files: File[];
    selectedResultId: string;
    selectedResultWiml: string;
    themesIsLoaded: boolean;
    themesResults: any[];
    themesHasErrors: boolean;
  };

  const [state, setState] = useState<State>({
    isLoading: false,
    results: [],
    // results: [{ id: 1 }, { id: 2 },],
    hasErrors: false,
    // files: [],
    selectedResultId: "",
    selectedResultWiml: "",
    themesIsLoaded: false,
    themesResults: [],
    themesHasErrors: false,
  });

  const keywordsRef = useRef<HTMLInputElement>(null);
  const imageAnalyzerRef = useRef<any>();

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (imageAnalyzerRef.current.getImages().length === 0) {
      alert('Please upload at least one image to generate a website.');
      return;
    }
    handleStateChange('isLoading', true);
    const keywords = keywordsRef?.current?.value;

    const analyzeImages = async () => {

      // const generationResults = [{ id: 1, name: "The Slammer" }];
      // const generationResults = [{ id: 1, name: "The Slammer" }, { id: 2, name: "The Ice Goat" }, { id: 3, name: "The Shredded Parkas" }, { id: 4, name: "The Bark that Binds" }]);
      const generationResults = await imageAnalyzerRef.current.analyzeImages(keywords);
      // const generationResults = {
      //   style_results: [
      //     {
      //       "style_name": "Winter Woods Mystery",
      //       "css_import": "@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');",
      //       "css_vars": "--global-color-primary: #111111; --global-color-light: #ffffff; --global-color-dark: var(--global-color-primary); --global-color-accent: #F2994A; --global-bg-color: var(--global-color-light); --global-header-bg-color: var(--global-color-dark); --global-header-text-color: var(--global-color-light); --global-header-link-color: var(--global-color-light); --global-footer-bg-color: var(--global-color-dark); --global-footer-text-color: var(--global-color-light); --global-footer-link-color: var(--global-color-light); --global-text-color: var(--global-color-primary); --global-text-accent-color: var(--global-color-accent); --global-text-body-font: \"Poppins\", sans-serif; --global-text-body-color: var(--global-text-color); --global-text-body-accent-color: var(--global-text-accent-color); --global-text-heading-font: \"Poppins\", sans-serif; --global-text-heading-color: var(--global-text-color); --global-text-heading-accent-color: var(--global-text-accent-color); --global-text-sub-heading-font: var(--global-text-heading-font); --global-text-sub-heading-color: var(--global-text-color); --global-text-sub-heading-accent-color: var(--global-text-accent-color); --global-button-bg-color: var(--global-color-accent); --global-button-text-color: var(--global-text-color); --global-hr-line-color: var(--global-color-accent);"
      //     },
      //     {
      //       "style_name": "Mountain Escape",
      //       "css_import": "@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');",
      //       "css_vars": "--global-color-primary: #000000; --global-color-light: #ffffff; --global-color-dark: #232323; --global-color-accent: #F8E71C; --global-bg-color: var(--global-color-light); --global-header-bg-color: var(--global-color-dark); --global-header-text-color: var(--global-color-light); --global-header-link-color: var(--global-color-light); --global-footer-bg-color: var(--global-color-dark); --global-footer-text-color: var(--global-color-light); --global-footer-link-color: var(--global-color-light); --global-text-color: var(--global-color-primary); --global-text-accent-color: var(--global-color-accent); --global-text-body-font: \"Roboto\", sans-serif; --global-text-body-color: var(--global-text-color); --global-text-body-accent-color: var(--global-text-accent-color); --global-text-heading-font: \"Roboto\", sans-serif; --global-text-heading-color: var(--global-text-color); --global-text-heading-accent-color: var(--global-text-accent-color); --global-text-sub-heading-font: var(--global-text-heading-font); --global-text-sub-heading-color: var(--global-text-color); --global-text-sub-heading-accent-color: var(--global-text-accent-color); --global-button-bg-color: var(--global-color-accent); --global-button-text-color: var(--global-text-color); --global-hr-line-color: var(--global-color-accent); "
      //     }
      //   ]
      // };

      const randomThemes = state.themesResults.sort(() => Math.random() - 0.5).slice(0, generationResults.length);

      const results = generationResults.style_results.map((result, index) => {
        const theme = randomThemes[index] || randomThemes[index % randomThemes.length];
        return { ...result, id: result.style_name, wimlId: theme.id, wiml: theme.wiml };
      });

      handleStateChange('results', results);
      handleStateChange('isLoading', false);
    };

    try {
      await analyzeImages();
    } catch (error) {
      console.error(error);
      handleStateChange('hasErrors', true);
      handleStateChange('isLoading', false);
    }

    // formData.append('keywords', keywords);
    // setState({ ...state, isLoading: true });
    // try {
    //   const response = await fetch('/api/website-generator', {
    //     method: 'POST',
    //     body: formData,
    //   });
    //   if (response.ok) {
    //     const data = await response.json();
    //     console.log(data);
    //     setState({ ...state, isLoading: false });
    //   } else {
    //     throw new Error('There was an error generating the website');
    //   }
    // } catch (error) {
    //   console.error(error);
    //   setState({ ...state, isLoading: false, hasErrors: true });
    // }
  }
  // type StateUpdateData = string | Partial<State>;
  // keyof notworking, eslint error "," expected - eslint error with create-react-app
  //eslint-disable-next-line
  type StateUpdateData = keyof State | Partial<State>;

  const handleStateChange = (nameOrNewState: StateUpdateData, value?: any) => {
    if (typeof nameOrNewState === 'object') {
      setState(prevState => ({ ...prevState, ...nameOrNewState }));
    } else {
      setState(prevState => ({ ...prevState, [nameOrNewState]: value }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const themes = await fetchThemeData_v2();
        // console.log({ themes })
        const themes = __staticThemes;
        handleStateChange({
          themesResults: themes,
          themesIsLoaded: true
        });
      } catch (error) {
        handleStateChange({
          themesHasErrors: true,
          themesIsLoaded: true
        });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (state.isLoading) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [state.isLoading]);

  useEffect(() => {
    localStorage.setItem('new_website_data__website_generator_theme_id', state.selectedResultId);
  }, [state.selectedResultId]);

  useEffect(() => {
    localStorage.setItem('new_website_data__website_generator_wiml', JSON.stringify(state.selectedResultWiml));
  }, [state.selectedResultWiml]);


  const advanceRef = useRef();

  useEffect(() => {
    if (state.selectedResultId) {
      advanceRef!.current!.scrollIntoView({ behavior: 'smooth' });
    }
  }, [state.selectedResultId]);
  // NOTE -- the order of ...props and ...sate is important
  // we want our state to overwrite any props sent to component so that we can actually change things.
  const newProps = { ...props, ...state, onChange: handleStateChange, userData, };

  if (!state.themesIsLoaded) return 'loading...';
  if (state.themesHasErrors) return 'we ran into an unexpected error. Please email us at support@wildinkmarketing.com.';
  if (state.hasErrors) return 'we ran into an unexpected error. Please email us at support@wildinkmarketing.com.';

  // reduce width - it causes window to have horizontal scroll bar
  // https://github.com/alampros/react-confetti/blob/484bad0a0aaddbcfcc2fb4c1a4a7eeceaa6d4879/src/ReactConfetti.tsx#L59
  // https://github.com/alampros/react-confetti/blob/develop/README.md#use
  // https://github.com/alampros/react-confetti/issues/130

  const websiteGenerationPreviewData = {
    firstName: "Nancy", lastName: "Drew",
    email: props.email, bookCategory: null, inviteCode: null, wimlThemeId: props.wimlThemeId, wimlMarkup: props.wiml, websiteType: 'preview'
  };

  const resultsComponents = state.results.map((result, index) => (
    <div key={result.id} className="col-lg-6 fade-in-delay" style={{ animationDelay: `${index * 0.5}s`, animationDuration: '1s' }}>
      <GenerationResult {...newProps} result={result} websiteGenerationPreviewData={websiteGenerationPreviewData} />
    </div>
  ));

  const resultsSection = resultsComponents.length > 0 ? (
    <section className="results d-flex flex-row justify-content-center row">
      {resultsComponents}
    </section>
  ) : null;

  const advanceStepSection = state.selectedResultId ? (
    <section className="advance-step d-flex flex-row justify-content-center row" ref={advanceRef}>
      <div className="col-lg-3 fade-in-delay" style={{ animationDelay: `0.15s`, animationDuration: '1s' }}>
        <Card className="mb-3">
          <CardHeader>
            <h3>Advance to the next step</h3>
          </CardHeader>
          <CardBody>
            <p>Now that you have selected a website, you can customize it further by adding your own content.</p>
            <p>Click the button below to start editing your website.</p>
            <a href="/" className="btn btn-primary">Edit Website</a>
          </CardBody>
        </Card>
      </div>
    </section>
  ) : null;

  return (
    <MiniLayout {...newProps} navTitle="Website Generator" >
      <MobileNotice fullscreen={true} />
      <div className="layout container-fluid d-flex flex-column align-items-center">
        <div className="page-title">
          <h1>The #1 Website Generator</h1>
          <h2>Create a website as ambitious as your goals</h2>
        </div>
        <div className="mt-4 content-form">
          <Form className="shadow p-4 mb-5 bg-white rounded d-flex flex-column align-items-center">
            <h4 className="align-self-start">Upload your book cover(s) to generate custom website designs.</h4>
            <p>Our AI tool will design your custom author website with ease. Don’t have a book cover yet? No problem! Simply upload any graphic or photo that matches your desired style. If you have multiple book covers, you can upload up to three images to showcase your work.</p>
            <FormGroup className="w-100 mb-2 mr-sm-2 mb-sm-0 border border-primary p-3 d-flex flex-row">
              <ImageAnalyzer ref={imageAnalyzerRef} {...newProps} maxImages={3} additionalContext={keywordsRef.current?.value} />
            </FormGroup>

            <div className="mt-4  align-self-start text-muted">
              <h6 className="mb-0">(Optional)</h6>
              <p className="m-0">Add a few keywords to help us generate a website that fits your needs.</p>
            </div>
            <FormGroup className="w-100 my-2 mr-sm-2 mb-sm-0 border border-primary keywords-form-group">
              <Input innerRef={keywordsRef} type="textarea" name="keywords" id="keywords" placeholder="e.g. 'modern', 'clean', 'professional'" className="h-100" maxLength={150} style={{ resize: 'none' }} />
            </FormGroup>

            <FormGroup className="submit-group my-2 mr-sm-2 mb-sm-0 p-3">
              <Input type="submit" value="Generate" className="submit-btn" onClick={handleSubmit} disabled={state.isLoading /*|| !imageAnalyzerRef?.current?.getImages()?.length */} />
            </FormGroup>
          </Form>
          {resultsSection}
          {advanceStepSection}
        </div>
      </div>
      <style jsx>{`
        :global(*:has(> .layout)) {
          display: flex;
          flex-direction: column;
          min-height: 100vh;
        }
        .layout {
          display: flex;
          flex-direction: column;
          flex: 1;
          
          /* the 80px should be whatever the height of the navbar is */
          /* height: calc(100vh - 80px); */
          padding: 0;
          background-color: ${props.themeConfig.backgroundPrimaryColor};
        }

        .layout :global(h1) {
          letter-spacing: .1rem;
          padding: .5rem;
        }

        .layout :global(h2) {
          font-weight: bold;
        }

        .layout :global(h3) {
        }

        .layout :global(.ql-editor, .rich-text-editor p) {
          margin-bottom: 1rem;
        }
        
        .layout .content-form {
           width: 90%;
        }

        {/* @media ${breakPoints.lg} { 
          .layout .content-form {
             width: 900px;
         } 
        } */}

        .layout .page-title {
          display: contents;
        }

        .layout :global(.submit-group) {
          width: 200px;
        }

        .layout :global(.dropzone-container) {
          flex: 1;
        }

        .layout :global(.keywords-form-group) {
          height: 80px;
        }

        .layout :global(.card-body) {
          aspect-ratio: 1 / 1;
        }
        @keyframes fade-in-delay {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        .layout :global(.fade-in-delay) {
          animation-name: fade-in-delay;
          animation-fill-mode: both;
        }

        .layout :global(form .btn-primary) {
          display: none;
        }
        /* https://stackoverflow.com/questions/12035400/how-can-i-remove-the-no-file-chosen-tooltip-from-a-file-input-in-chrome */
        .layout :global(input[type="file"]) {
          color: transparent;
        }
    `}</style>
    </MiniLayout>
  );
}

const previewFrameVirtualWidth = 1200;
function GenerationResult(props: React.PropsWithChildren<any>) {
  const iframeRef = useRef();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      iframeRef!.current!.addEventListener('load', () => {
        setIsLoaded(true);
      });
    }
  }, [isLoaded]);


  useEffect(() => {
    const updateIframeScale = () => {

      if (generationResultRef.current && iframeRef.current) {
        const actualWidth = generationResultRef.current.getBoundingClientRect().width;
        const scaleFactor = actualWidth / previewFrameVirtualWidth;

        // Adjusting iframe scale
        iframeRef.current.style.transform = `scale(${scaleFactor})`;
        iframeRef.current.style.transformOrigin = 'top left';
        // Adjusting iframe height based on the scale to maintain aspect ratio
        const originalHeight = 3400; // Original height of the iframe content
        iframeRef.current.style.height = `${originalHeight * scaleFactor}px`;
      }
    };

    // Call once to set initial scale
    updateIframeScale();

    // Add event listener to update scale on window resize
    window.addEventListener('resize', updateIframeScale);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateIframeScale);
  }, []);


  const result = props.result;
  const resultName = result.style_name;
  const selectedResultId = props.selectedResultId;
  const isSelected = selectedResultId === result.id;

  const handleSelect = () => {
    if (isLoaded) {
      props.onChange({
        selectedResultId: result.id,
        selectedResultWiml: websiteData.style.wiml.themeData.markup,
      });
    } else {
      throw new Error('The iframe has not loaded yet');
    }
  }

  const generationResultRef = useRef(null); // Step 1: Create a ref

  const websiteData = Website.createNewWebsite(`site_preview_generation__${result.id}`, { slug: `site_preview_generation`, firstName: props.websiteGenerationPreviewData.firstName, lastName: props.websiteGenerationPreviewData.lastName, email: props.websiteGenerationPreviewData.email, bookCategory: null, inviteCode: null, wimlThemeId: props.result.wimlThemeId, wimlMarkup: props.result.wiml, websiteType: 'preview' });

  websiteData.replaceStyleImportStatement(result.css_import);
  websiteData.replaceStyleCssVars(result.css_vars);

  // const camlized = humps.camelizeKeys(websiteData);
  // passed offline using serialization algorithm specified in spec (2.9.1)
  // ensure keys match the same case in preview mode as in live mode

  // because the redux selecto returns an actual website object, we need to convert it to a serializable object
  const serializedWebsiteData = makeSerialilzable(websiteData, undefined);

  useEffect(() => {
    if (isLoaded) {

      iframeRef!.current!.contentWindow!.postMessage({ name: 'update_website', payload: { websiteData: serializedWebsiteData } }, '*');
    }
  }, [isLoaded]);

  return <Card innerRef={generationResultRef} className={`geneartion-result mb-3 ${isSelected ? 'is-selected' : ''}`}>
    <CardHeader role='button' onClick={handleSelect} className="d-flex justify-content-between align-items-center">
      <h3>Theme: {resultName} {isSelected ? '(selected)' : ''}</h3>
      {isLoaded && isSelected ? null : <button className="btn btn-primary" >Select</button>}
    </CardHeader>
    <CardBody className="p-0">
      <div className="embed-responsive gen-preview-container h-100" >
        <iframe ref={iframeRef} className="embed-responsive-item gen-preview-frame" src={previewUrl} />
      </div>
    </CardBody>

    <style jsx global>{`
      
      .embed-responsive.gen-preview-container {
        overflow-y: scroll;
        overflow-x: hidden;
      }

      .embed-responsive-item.gen-preview-frame  {
        width: ${previewFrameVirtualWidth}px;
        height: 3400px;
        transform-origin: 0 0;
        border: none;
      }

      .geneartion-result:hover {
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
        border: 1px solid #ccc;
      }

      .geneartion-result {
        transition: all 0.3s;
        border-radius: 25px;
        overflow: hidden;
      }
    
      .geneartion-result.is-selected {
        border: 1px solid #007bff;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
        background-color: #f8f9fa;
      }
    `}</style>
  </Card>;
}

