import { useEffect, useState } from "react";
import { Preview } from "./preview";
import { Sidebar } from "./sidebar";
import { fetchThemes } from '@wip/common/event-store/themes';
import { useDispatch } from "react-redux";


export function ManageV3Editor(props) {
    const [themesLoaded, setThemesLoaded] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(fetchThemes()).then(action => {
            // dispatch(fetchWebsiteData(slug));
            setThemesLoaded(true);
        });
    }, [dispatch]);

    if (!themesLoaded) return <div>Loading Themes...</div>;
    return (
        <>
            <Sidebar {...props} />
            <Preview {...props} />
        </>
    );
}
