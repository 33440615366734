import dot from 'dot-object';
import { Button } from 'reactstrap';


export function ListItem_v1_0_0(props) {
    const defaultAdminListLabelKey = props.defaultAdminListLabelKey;
    if (!defaultAdminListLabelKey) {
        throw new Error('defaultAdminListLabelKey is required');
    }
    const item = props.item;
    const component = props.component;
    const themeProps = component.themeProps;
    const labelKey = themeProps?.adminLabelKey || defaultAdminListLabelKey;
    // const labelKeyParts = labelKey.split('.');
    // const componentId = humps.camelize(labelKeyParts[0], { separator: '-' });
    // const qualifiedLabelKey = `${componentId}.${labelKeyParts[1]}`;

    const labelValue = dot.pick(labelKey, item.components?.items) || `<i title='Warning: The value "${labelKey}" is missing.'>${item.id}</i>`;

    const retVal = (
        <Button color="primary" onClick={props.onClick}>
            <div dangerouslySetInnerHTML={{ __html: labelValue }} />
        </Button>
    );
    return retVal;
}
