import React from 'react';

import { renderChildren_v1_0_0 } from '../render-components';
import { getComponentClassName_v1_0_0 } from '../render-components';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";


export function Footer_v1_0_0(props: RuntimeWimlComponentProps_v1) {
  let footer;

  const className = getComponentClassName_v1_0_0(props, 'footer');
  const renderedChildren = renderChildren_v1_0_0(props.children);

  // return <footer className={className}>{props.children}</footer>;
  // const themeColor = (props.colorThemeConfig || props.baseThemeConfig).fourthColor;
  // let themeHex = themeColor;
  // if (!themeHex.startsWith('#')) {
  //   themeHex = toHex(themeColor);
  // }
  // TODO fix the hexToCSSFilter dependency.
  // its algorithm relies on randomness.
  // this screws up the jsx classnames.
  // const footerSvgFilter = hexToCSSFilter(themeHex).filter;
  // const ensureBlackSvgFilter = "brightness(0) saturate(100%) " + footerSvgFilter;
  // // {
  //           /* https://stackoverflow.com/a/53336754/173957 */
  //           /* filter: ${ensureBlackSvgFilter}; */
  //         }
  footer = (
    <footer suppressHydrationWarning className={className}>
      <div>{renderedChildren}</div>
      <div className="legal">
        <div suppressHydrationWarning className="colophon">
          <div suppressHydrationWarning className="copyright">
            © {`${props.websiteData.about.firstName} ${props.websiteData.about.lastName}`}. All Rights Reserved.
          </div>
          <div suppressHydrationWarning className="design-credits mt-1">
            Made with ❤ by{' '}
            <a suppressHydrationWarning href="//www.wildinkpages.com/" target="_blank">
              Wild Ink Pages
            </a>
            .
          </div>
        </div>
      </div>
      {/* todo move to next projectect https://stackoverflow.com/questions/66011598/styled-jsx-typescript-error-after-migrating-to-monorepo-structure-property-jsx */}
      {/*
      // fix old reliance on props.baseThemeConfig and props.colorThemeConfig -- the default was fanning-the-pages:default
      // and the primary color was #272436
      // @ts-ignore */}
      <style jsx>{`
        .legal {
          color: '#272436';
          z-index: 1;
          position: relative;
          text-align: center;
          padding: 1.5rem 0;
          user-select: none;
        }

        .legal::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          content: '';
          background: url('/images/brush-stroke-1.svg') no-repeat center;
          filter: 'brightness(0) saturate(100%) contrast(0.5) hue-rotate(0deg)';
          background-size: 400px;
          background-position-y: 10px;
        }

        .colophon {
          position: relative;
          z-index: 2;
        }

        .design-credits {
          font-size: 0.8rem;
        }

        .design-credits a {
          color: inherit;
        }
      `}</style>
    </footer>
  );

  return footer;
}
