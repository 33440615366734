import React from 'react';

import { renderChildren_v1_0_0 } from '../render-components';
import { getComponentHtmlAttrs_v1_0_0 } from '../render-components';
import { getComponentClassName_v1_0_0 } from '../render-components';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";

export function Col_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    const className = getComponentClassName_v1_0_0(props, 'col');
    const extraAttrs = getComponentHtmlAttrs_v1_0_0(props);
    const renderedChildren = renderChildren_v1_0_0(props.children);

    return <div className={className} {...extraAttrs}>{renderedChildren}</div>;
}
