import { breakPoints } from "./layout";

export default function MobileNotice(props) {
  const fullscreen = props.fullscreen || false;
  const className = `notice-overlay d-flex align-items-center ${fullscreen ? 'fullscreen' : ''}`;
  const retVal = <section className={className}>
    <h1 className="text-center">
      This app is not available on mobile just yet. Please try again on a laptop or desktop.
    </h1>
    <style jsx>{`
      section.notice-overlay.fullscreen {
          position: fixed;
          width: 100%;
          height 100%;
          background-color: #fff;
          z-index: 999;
          top: 0;
          left: 0;
      }

      @media ${breakPoints.lg} {
        section.notice-overlay {
          display: none !important;
        }
      }
  `}
    </style>
  </section>;

  return retVal;
}
