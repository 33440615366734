import _ from 'lodash';


import { ComponentThemeData_v1_0_0 } from "./types";
import { convertWimlToNodes_v1_0_0 } from './nodes';
import { getThemeDataListCollectionFromNode_v1_0_0 } from './list-components';
import { getThemeDataPageCollectionFromNode_v1_0_0 } from './page-components';
import { wimlComponents_v1_0_0 } from '../renderer/wiml-components-v1.0.0';

export function _markupToComponentThemeData_v1_0_0(wimlString: string) {
    // todo rename to wiml theme data
    const retVal: ComponentThemeData_v1_0_0 = {
        rootNode: null,
        pages: null,
        lists: null,
        version: '1.0.0'
    };

    // v1.2 todo split this out somewhere - so it's seperate from theme data
    const rootNode = convertWimlToNodes_v1_0_0(wimlString);
    retVal.rootNode = rootNode;

    const pages = getThemeDataPageCollectionFromNode_v1_0_0(wimlComponents_v1_0_0, rootNode);
    retVal.pages = pages;

    // todo put header, footer, etc components in layout separate from pages
    // otherwise make header/footer not eligible for page names

    const lists = getThemeDataListCollectionFromNode_v1_0_0(wimlComponents_v1_0_0, rootNode);
    retVal.lists = lists;

    return retVal;
}
