
const genres = [
    {
        key: "all",
        value: "ALL THEMES"
    },
    {
        key: "kidlit",
        value: "Kid Lit"
    },
    {
        key: "literary",
        value: "Literary"
    },
    {
        key: "educational",
        value: "Educational"
    },
    {
        key: "fantasy",
        value: "Fantasy"
    }, {
        key: "scifi",
        value: "Science Fiction"
    },
    {
        key: "mystery",
        value: "Mystery"
    },
    {
        key: "thriller",
        value: "Thriller/Suspense"
    },
    {
        key: "horror",
        value: "Horror"
    },
    {
        key: "contemporary",
        value: "Contemporary"
    },
    {
        key: "historical",
        value: "Historical"
    },
    {
        key: "romance",
        value: "Romance"
    },
    {
        key: "nonfiction",
        value: "Non-fiction"
    }];

export { genres };
